import React, { useState } from "react";
import userImg from "../assets/Images/Login/userImg.png";
import { useDispatch, useSelector } from "react-redux";
// import ProfileDropdown from "./ProfileDropdown";
import { MdOutlineMenu } from "react-icons/md";
import { IoIosSearch } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Weather from "./Weather";
import { toggleSidebar } from '../slices/sidebarSlice';
import navbarCollapseIcon from "../assets/Images/Sidebar/navbarCollapseIcon.png";
import navbarExpandIcon from "../assets/Images/Sidebar/navbarExpandIcon.png";

function Navbar({ weatherData }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const expanded = useSelector((state) => state.sidebar.expanded);
  const { settings } = useSelector((state) => state.masterData);
  const { logo } = useSelector((state) => state.profile);
  const { token } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.profile);
  const { allDoctors } = useSelector(
    (state) => state.masterData
  );
  const matchedDoctor = allDoctors.find((doctor) => doctor.Email === user.Email);

  const [searchInput, setSearchInput] = useState("");
  const handleChange = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
  };

  return (
    <nav className="flex h-[4rem] items-center justify-between px-10 pl-3 py-2 w-full bg-white border-b-2 shadow-lg">
      {/* Sidebar Toggle Button */}
      <button
        onClick={() => dispatch(toggleSidebar())}
        className="flex items-center justify-center p-2 w-12 h-10 bg-[#F3F5F7] hover:bg-[#F0F8FF] border-none rounded-md mr-4"
      >
        {expanded ? (
          <img src={navbarCollapseIcon} alt="Collapse Sidebar" className="w-5 h-5" />
        ) : (
          <img src={navbarExpandIcon} alt="Expand Sidebar" className="w-5 h-5" />
        )}
      </button>
      <div className="p-2 flex justify-between items-center">
        <img
          src={settings?.OrgImage ? settings?.OrgImage : logo}
          className={`overflow-hidden transition-all w-40 h-11 object-contain cursor-pointer`}
          alt=""
          onClick={() => navigate("/")}
        />
      </div>
      <label
        className={`flex flex-row items-center justify-center bg-gray-100 rounded-full py-2 px-4 gap-2 ml-auto mr-12`}
      >
        <IoIosSearch className="text-[#acacac] w-5 h-5" />
        <input
          type="text"
          placeholder="Search here"
          className={`bg-gray-100 text-sm text-[#acacac] font-normal font-poppins focus:outline-none focus:border-transparent`}
        />
      </label>
      <div className="mr-16">
        {weatherData && weatherData?.length !== 0 && (
          <Weather weatherData={weatherData} />
        )}
      </div>
      <div className="flex flex-row justify-center items-center">
        <div className="flex flex-col justify-center items-center w-[40px] h-[40px] rounded-full mr-5">
          <img
            src={matchedDoctor?.Image || user?.image}
            alt={`profile-${user?.firstName}`}
            className={`w-full h-full ${matchedDoctor?.Image ? "object-cover" : "object-contain"} rounded-full`}
          />
        </div>
        <div className="flex flex-col justify-center items-start cursor-pointer">
          <h1 className="text-black text-sm font-bold font-poppins">
            {user?.Name}
          </h1>
          <h1 className="text-[#98A1B0] text-xs font-regular font-poppins">
            {user?.UserType}
          </h1>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;

// import React, { useState } from "react";
// import { NavLink } from "react-router-dom";
// import logo from '../assets/Images/logo.png';
// import { IoMenu } from "react-icons/io5";
// import { IoClose } from "react-icons/io5";
// import { IoIosSearch } from "react-icons/io";

// function Navbar() {
//     const [searchInput, setSearchInput] = useState("");
//     const handleChange = (e) => {
//         e.preventDefault();
//         setSearchInput(e.target.value);
//     };

//     return (
//         <nav style={{ overflow: 'hidden' }} className="fixed top-0 left-0 z-50 flex items-center justify-between items-center px-10 py-2 bg-white w-full border-b-5 shadow-lg h-[10vh]">
//             {/* Logo */}
//             <NavLink
//                 to={"/"}
//                 className='company-logo'
//             >
//                 <img src={logo} alt="company-logo" className="w-60 h-[8vh] object-contain" />
//             </NavLink>

//             {/*  */}
//             <ul className='flex items-center justify-center ml-auto'>
//                 <div className="flex flex-row justify-center items-center bg-[#F3F5F6] rounded-3xl px-5 gap-2 py-2">
//                     <IoIosSearch className="text-[#ABABAB]" />
//                     <input
//                         type="text"
//                         className="bg-[#F3F5F6] rounded-3xl px-5 border-0 border-[#F3F5F6] text-[#ABABAB]"
//                         placeholder="Search here"
//                         onChange={handleChange}
//                         value={searchInput} />
//                 </div>
//                 {/* <NavLink
//             to={item.link}
//             style={({ isActive }) => ({
//               color: isActive ? '#3CA5DC' : '',
//             })}
//             key={item.id}
//             className='p-4 hover:text-[#3CA5DC] text-base font-bold font-poppins rounded-xl m-2 cursor-pointer duration-300'
//           >
//             {item.name}
//           </NavLink> */}
//             </ul>
//         </nav>
//     );
// }

// export default Navbar;
