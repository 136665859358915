import React from 'react'
import './style.css'

const RadioButtonReward2 = ({ name, id, value, onChange, checked, text, text1, textFont, editText, onEdit }) => {
    return (
        <label htmlFor={id} className="radio-label flex flex-row justify-start items-start bg-[#F3F5F7] p-4 flex-1 basis-56 rounded-lg">
            <input
                className="radio-input"
                type="radio"
                name={name}
                id={id}
                value={value}
                onChange={onChange}
                checked={checked}
            />
            <span className="custom-radio" />
            <div className='flex flex-col justify-center items-start flex-1 gap-2 ml-3'>
                <h1 className={`text-[#1C1C1C] text-sm ${textFont ? textFont : "font-semibold"} font-poppins text-left`}>
                    {text}
                </h1>
                <h1 className={`text-[#545454] text-sm ${textFont ? textFont : "font-semibold"} font-poppins text-left`}>
                    {text1}
                </h1>
            </div>
            {editText && (
                <button onClick={onEdit} className={`text-[#3CA5DC] text-sm ${textFont ? textFont : "font-semibold"} font-poppins ml-auto underline`}>
                    Edit
                </button>
            )}
        </label>
    )
}

export default RadioButtonReward2