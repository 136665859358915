import React, { useEffect, useState } from "react";
import ModalComponent from "./Modal";
import moment from "moment";
import telehealthIcon from "../assets/Images/telehealthIcon.svg";
import inclinicIcon from "../assets/Images/inclinicIcon.svg";
import { useDispatch, useSelector } from "react-redux";
import Dropdown from "./Dropdown";
import { TimePicker } from "antd";
import { IoAddSharp } from "react-icons/io5";
import { RxCross1 } from "react-icons/rx";
import RadioIcon from "./RadioIcon";
import toast from "react-hot-toast";
import {
  addNotesToPatientProfile,
  addPrescription,
} from "../services/operations/patientManagementAPI";
import { useNavigate } from "react-router-dom";
import { set } from "date-fns";

function ConsultationDetails({ show, hide, patient, ITEM, isViewing = false }) {
  const {
    loading: patientLoading,
    patientProfile,
    mentalHealth,
    familyMembers,
    medicalCondition,
    dosageAdherence,
    consultationHistory,
    diagnosisType,
    allMedicines,
    allPreMedicalConditions,
    allergies,
    allergiesType,
  } = useSelector((state) => state.patient);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);
  const { symptomsType } = useSelector((state) => state.masterData);
  const { doctorDetails } = useSelector((state) => state.profile);
  const [premedicalInput, setPremedicalInput] = useState("");
  const [symptomsInput, setSymptomsInput] = useState("");
  const [premedicalConditionSuggestions, setPremedicalConditionSuggestions] =
    useState([]);
  const [symptomsSuggestions, setSymptomsSuggestions] = useState([]);

  const [formData, setFormData] = useState({
    DiseaseType: "",
    Stage: "",
    PremedicalCondition: [],
    PersonalNotes: "",
    Symptoms: [],
    DiagnosisInformation: "",
  });
  const [medications, setMedications] = useState([
    // {
    //   id: 1,
    //   MedicationID: "",
    //   DosageAmount: "",
    //   Route: "",
    //   MealTime: "Pre Meal",
    //   Frequency: "Once",
    //   Duration: "",
    //   DurationType: "Day",
    //   SpecialInstructions: "",
    //   CustomTimes: [""],
    // },
  ]);

  const [errors, setErrors] = useState({
    DiseaseType: "",
    // Stage: "",
    PremedicalCondition: "",
    PersonalNotes: "",
    Symptoms: "",
    DiagnosisInformation: "",
    medications: [],
  });

  //   Dropdown selection logic
  function handleSelect(name, option) {
    setFormData((prev) => ({
      ...prev,
      [name]: option,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  }

  //  Input change logic
  function handleOnChange(event) {
    const { name, value } = event.target;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  }

  const handlePremedicalCondition = (e) => {
    const value = e.target.value;
    setPremedicalInput(value);
    if (!value) {
      setPremedicalConditionSuggestions([]);
    } else {
      const filteredSuggestions = allPreMedicalConditions.filter((condition) =>
        condition.Name.toLowerCase().includes(value.toLowerCase())
      );
      setPremedicalConditionSuggestions(filteredSuggestions);
    }
  };

  const handleSymptoms = (e) => {
    const value = e.target.value;
    setSymptomsInput(value);
    if (!value) {
      setSymptomsSuggestions([]);
    } else {
      const filteredSuggestions = symptomsType.filter((condition) =>
        condition.Name.toLowerCase().includes(value.toLowerCase())
      );
      setSymptomsSuggestions(filteredSuggestions);
    }
  };

  const handleAddPremedicalCondition = (condition) => {
    const conditionExists = formData?.PremedicalCondition.find(
      (c) => c.PMCID === condition.PMCID
    );
    if (!conditionExists) {
      setFormData((prev) => ({
        ...prev,
        PremedicalCondition: [...prev.PremedicalCondition, condition],
      }));
      setErrors((prev) => ({
        ...prev,
        PremedicalCondition: "",
      }));
    }
    setPremedicalInput("");
    setPremedicalConditionSuggestions([]);
  };

  const handleAddSymptoms = (symptoms) => {
    const symptomExists = formData?.Symptoms.find(
      (c) => c.PSID === symptoms.PSID
    );
    if (!symptomExists) {
      setFormData((prev) => ({
        ...prev,
        Symptoms: [...prev.Symptoms, symptoms],
      }));
      setErrors((prev) => ({
        ...prev,
        Symptoms: "",
      }));
    }
    setSymptomsInput("");
    setSymptomsSuggestions([]);
  };

  const handleRemovePremedicalCondition = (id) => {
    setFormData((prev) => ({
      ...prev,
      PremedicalCondition: prev.PremedicalCondition.filter(
        (condition) => condition.PMCID !== id
      ),
    }));
  };

  const handleRemoveSymptoms = (id) => {
    setFormData((prev) => ({
      ...prev,
      Symptoms: prev.Symptoms.filter((symptoms) => symptoms.PSID !== id),
    }));
  };

  // ###########################################################

  const addMedication = () => {
    const newId = medications?.length + 1;
    setMedications([
      ...medications,
      {
        id: newId,
        MedicationID: "",
        DosageAmount: "",
        Route: "",
        MealTime: "Pre Meal",
        Frequency: "Once",
        Duration: "",
        DurationType: "Day",
        SpecialInstructions: "",
        CustomTimes: [""],
      },
    ]);
  };

  const removeMedication = (id) => {
    setMedications(medications.filter((med) => med.id !== id));
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      Object.keys(newErrors).forEach((key) => {
        if (key.endsWith(id.toString())) {
          delete newErrors[key];
        }
      });
      return newErrors;
    });
  };

  const handleInputChange = (id, field, value) => {
    setMedications(
      medications?.map((med) =>
        med.id === id
          ? {
              ...med,
              [field]: value,
              ...(field === "Frequency" && value !== "Custom"
                ? { CustomTimes: [""] }
                : {}),
            }
          : med
      )
    );
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[`${field}${id}`];
      return newErrors;
    });
  };

  const handleDropdownSelect = (selectedOption, field, id) => {
    setMedications((prev) => {
      return prev?.map((med) => {
        if (med.id === id) {
          return {
            ...med,
            [field]: selectedOption,
          };
        }
        return med;
      });
    });
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[`${field}${id}`];
      return newErrors;
    });
  };

  const handleTimeChange = (id, index, time) => {
    setMedications(
      medications?.map((med) =>
        med.id === id
          ? {
              ...med,
              CustomTimes: med.CustomTimes?.map((t, i) =>
                i === index ? time : t
              ),
            }
          : med
      )
    );
  };

  const addCustomTime = (id) => {
    setMedications(
      medications?.map((med) =>
        med.id === id && med.CustomTimes.length < 4
          ? { ...med, CustomTimes: [...med.CustomTimes, ""] }
          : med
      )
    );
  };

  const removeCustomTime = (id, index) => {
    setMedications(
      medications?.map((med) =>
        med.id === id
          ? {
              ...med,
              CustomTimes: med.CustomTimes.filter((_, i) => i !== index),
            }
          : med
      )
    );
  };

  const validateForm = () => {
    let newErrors = {};
    medications.forEach((med) => {
      if (!med.MedicationID)
        newErrors[`MedicationID${med.id}`] =
          "Medicine MedicationID is required";
      if (!med.Route) newErrors[`Route${med.id}`] = "Route is required";
      if (!med.DosageAmount)
        newErrors[`DosageAmount${med.id}`] = "Dosage is required";
      if (!med.Duration)
        newErrors[`Duration${med.id}`] = "Duration is required";
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    const errors = {};
    if (!formData.DiseaseType) {
      errors.DiseaseType = "Disease Type is required";
    }
    // if (!formData.Stage) {
    //   errors.Stage = "Stage is required";
    // }
    if (!formData.PremedicalCondition.length) {
      errors.PremedicalCondition = "Premedical Condition is required";
    }
    if (!formData.PersonalNotes) {
      errors.PersonalNotes = "Personal Notes is required";
    }
    if (!formData.Symptoms.length) {
      errors.Symptoms = "Symptoms is required";
    }
    if (!formData.DiagnosisInformation) {
      errors.DiagnosisInformation = "Diagnosis Information is required";
    }

    if (
      errors.DiseaseType ||
      // errors.Stage ||
      errors.PremedicalCondition ||
      errors.PersonalNotes ||
      errors.Symptoms ||
      errors.DiagnosisInformation
    ) {
      setErrors(errors);
      toast.error("Please fill all the required fields");
      return;
    }
    if (!validateForm()) {
      toast.error("Please fill all the required fields in medications");
      return;
    }

    // API call to save the data
    let transformedMedicines = medications?.map((medicine, index) => {
      return {
        SessionID: ITEM?.AppointmentID,
        MedicationID: medicine?.MedicationID,
        MedicineName: allMedicines.find(
          (med) => med.MedicationID === medicine?.MedicationID
        )?.Name,
        DosageAmount: medicine?.DosageAmount,
        DID: doctorDetails?.DID,
        AHUID: patient?.AHUID,
        Frequency: medicine?.Frequency,
        StartDate: moment().format("YYYY-MM-DD"),
        EndDate: moment()
          .add(
            moment.duration(
              medicine.DurationType === "Day"
                ? medicine.Duration - 1
                : medicine.Duration,
              medicine.DurationType
            )
          )
          .format("YYYY-MM-DD"),
        Duration: medicine?.Duration,
        Route: medicine?.Route,
        DurationType: medicine?.DurationType,
        MealTime: medicine?.MealTime,
        SpecialInstructions: medicine.SpecialInstructions,
        ...(medicine.Frequency === "Custom" && {
          customMealTimes: {
            ...(medicine.MealTime === "Pre Meal" && {
              "Pre Meal": medicine.CustomTimes?.map((time) =>
                moment(time, "h:mm a").format("HH:mm")
              ),
            }),
            ...(medicine.MealTime === "Post Meal" && {
              "Post Meal": medicine.CustomTimes?.map((time) =>
                moment(time, "h:mm a").format("HH:mm")
              ),
            }),
          },
        }),
      };
    });

    let body = {
      DosageData: transformedMedicines,
    };

    let data = {
      DiagnosisID: diagnosisType.find(
        (diagnosis) => diagnosis.DiagnosisName === formData?.DiseaseType
      )?.ID,
      PersonalNote: formData?.PersonalNotes,
      DiagnosisInformation: formData?.DiagnosisInformation,
      AppointmentType: ITEM?.AppointmentType,
      ...(formData?.PremedicalCondition.length > 0 && {
        PreMedicalCondition: formData?.PremedicalCondition?.map(
          (condition) => condition.PMCID
        ),
      }),
      ...(formData?.Symptoms.length > 0 && {
        PSID: formData?.Symptoms?.map((symptoms) => symptoms.PSID),
      }),
      ...(formData?.Stage && {
        Stage: formData.Stage,
      }),
    };
    let ID = ITEM?.AppointmentID;
    console.log("====================================");
    console.log("API BODY-->", body, data, ID);
    console.log("====================================");
    dispatch(addPrescription(token, body, navigate, patient));
    dispatch(addNotesToPatientProfile(token, ID, data, navigate, patient));
    handleCloseModal();
  };

  const handleCloseModal = () => {
    hide();
    setFormData({
      DiseaseType: "",
      Stage: "",
      PremedicalCondition: [],
      PersonalNotes: "",
      Symptoms: [],
      DiagnosisInformation: "",
    });
    setMedications([]);
    setErrors({
      DiseaseType: "",
      Stage: "",
      PremedicalCondition: "",
      PersonalNotes: "",
      Symptoms: "",
      DiagnosisInformation: "",
      medications: [],
    });
  };

  useEffect(() => {
    if (isViewing) {
      const preMedicalConditions =
        ITEM?.PreMedicalConditions?.map((id) =>
          allPreMedicalConditions.find((condition) => condition.PMCID === id)
        ) || [];

      const symptoms =
        ITEM?.Symptoms?.map((id) =>
          symptomsType.find((symptom) => symptom.PSID === id)
        ) || [];

      setFormData({
        DiseaseType: diagnosisType.find(
          (diagnosis) => diagnosis.ID === ITEM?.DiagnosisID
        )?.DiagnosisName,
        Stage: ITEM?.Stage || "",
        PremedicalCondition: preMedicalConditions,
        PersonalNotes: ITEM?.PersonalNote || "",
        Symptoms: symptoms,
        DiagnosisInformation: ITEM?.DiagnosisInformation || "",
      });

      setMedications(
        ITEM?.DosageData?.map((med, index) => {
          return {
            id: index,
            MedicationID: med.MedicationID,
            DosageAmount: med.DosageAmount,
            Route: med.Route,
            MealTime: med.MealTime,
            Frequency: med.Frequency,
            Duration: med.Duration,
            DurationType: med.DurationType,
            SpecialInstructions: med.SpecialInstructions,
            CustomTimes: med.customMealTimes || [],
          };
        }) || []
      );
    }
  }, [isViewing, ITEM]);

  return (
    <ModalComponent
      show={show}
      handleClose={handleCloseModal}
      outSideModalPress={false}
    >
      <div className="w-[45vw]">
        <div className="text-slate-800 text-2xl font-semibold font-poppins leading-loose">
          {!isViewing && "Add"} Consultation Notes for {patient?.Name}
        </div>
        <div className="h-px bg-gray-200 my-4" />
        <div className="grid grid-cols-3 gap-6 content-center items-center">
          <div>
            <p className="text-neutral-600 text-sm font-normal font-poppins">
              Consultation Date
            </p>
            <p className="text-zinc-900 text-sm font-normal font-poppins mt-1">
              {moment(ITEM?.ConsultationDate).format("DD.MM.YYYY")}
            </p>
          </div>
          <div>
            <p className="text-neutral-600 text-sm font-normal font-poppins">
              Session ID
            </p>
            <p className="text-zinc-900 text-sm font-normal font-poppins mt-1">
              {ITEM?.AppointmentID}
            </p>
          </div>
          <div>
            <p className="text-neutral-600 text-sm font-normal font-poppins">
              Appointment Type
            </p>
            <div className="flex items-center space-x-2 mt-1">
              <div className="w-8 h-8 p-1.5 bg-white rounded-2xl border border-neutral-300 justify-center items-center inline-flex">
                <img
                  src={
                    ITEM?.AppointmentType === "TeleHealth"
                      ? telehealthIcon
                      : inclinicIcon
                  }
                  alt=""
                  className="w-5 h-5"
                />
              </div>
              <p className="text-zinc-900 text-sm font-normal font-poppins leading-tight">
                {ITEM?.AppointmentType}
              </p>
            </div>
          </div>
        </div>
        <div className="h-px bg-gray-200 my-4" />
        <div className="p-2 h-[calc(100vh-25rem)] overflow-y-auto scroll-smooth scrollbar-thin scrollbar-thumb-sky-500 scrollbar-track-sky-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
          {/* # Basic Notes  */}
          <div className="flex flex-col space-y-4 rounded-md shadow p-4">
            <div className="grid grid-cols-2 gap-4">
              {/* Disease Type  */}
              <div>
                <label>
                  <p className="text-zinc-900 text-sm font-normal font-poppins mb-2">
                    Disease Type
                  </p>
                  <Dropdown
                    disabled={isViewing}
                    enableSearch={true}
                    placeholder={"Select Disease"}
                    error={errors.DiseaseType}
                    name="DiseaseType"
                    options={diagnosisType}
                    onSelect={handleSelect}
                    value={formData.DiseaseType}
                    labelKey={"DiagnosisName"}
                    valueKey={"DiagnosisName"}
                  />
                </label>
              </div>
              {/* Stage  */}
              <div>
                <label>
                  <p className="text-zinc-900 text-sm font-normal font-poppins mb-2">
                    Stage
                  </p>
                  <input
                    type="text"
                    id="Stage"
                    name="Stage"
                    disabled={isViewing}
                    value={formData.Stage}
                    maxLength={10}
                    onChange={handleOnChange}
                    placeholder="Enter Stage here"
                    className={`w-full text-zinc-900 text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                      errors.Stage ? "border-red-500" : ""
                    } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  />
                </label>
              </div>
              {/* Premedical Condition  */}
              <div className="relative">
                <label>
                  <p className="text-zinc-900 text-sm font-normal font-poppins mb-2">
                    Premedical Condition
                  </p>
                  <div className="relative">
                    {!isViewing && (
                      <>
                        <input
                          type="text"
                          value={premedicalInput}
                          disabled={isViewing}
                          onChange={handlePremedicalCondition}
                          placeholder="Enter Pre Medical Condition here"
                          className={`w-full ${
                            errors.PremedicalCondition ? "border-red-500" : ""
                          } text-zinc-900 text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                          autoComplete="off"
                        />
                        <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400">
                          <svg
                            className="h-4 w-4"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                          </svg>
                        </div>
                      </>
                    )}
                  </div>

                  {premedicalConditionSuggestions?.length > 0 && (
                    <div className="absolute w-1/2 bg-white border rounded-md mt-1">
                      {premedicalConditionSuggestions?.map((condition) => (
                        <div
                          key={condition.PMCID}
                          onClick={() =>
                            handleAddPremedicalCondition(condition)
                          }
                          className="px-3 py-1 hover:bg-gray-200 cursor-pointer"
                        >
                          {condition.Name}
                        </div>
                      ))}
                    </div>
                  )}
                </label>
              </div>
            </div>
            {formData?.PremedicalCondition?.length !== 0 && (
              <div className="w-full flex flex-row items-center flex-wrap gap-2 mt-4">
                {formData?.PremedicalCondition?.map((condition) => (
                  <div
                    key={condition.PMCID}
                    className={`px-4 py-1 bg-gray-100 rounded-2xl ${
                      !isViewing && "cursor-pointer"
                    } select-none`}
                    onClick={() =>
                      !isViewing &&
                      handleRemovePremedicalCondition(condition.PMCID)
                    }
                  >
                    <div className="text-neutral-600 text-xs font-poppins">
                      {condition.Name}
                    </div>
                  </div>
                ))}
              </div>
            )}
            {/* Personal Notes */}
            <div>
              <label>
                <p className="text-zinc-900 text-sm font-normal font-poppins mb-2">
                  Personal Notes ( not shared with patients)
                </p>
                <textarea
                  type="text"
                  id="PersonalNotes"
                  name="PersonalNotes"
                  disabled={isViewing}
                  value={formData.PersonalNotes}
                  // maxLength={20}
                  onChange={handleOnChange}
                  placeholder="Enter Personal Notes here"
                  className={`w-full text-zinc-900 text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                    errors.PersonalNotes ? "border-red-500" : ""
                  } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>
            {/* Symptoms  */}
            <div className="relative">
              <label>
                <p className="text-zinc-900 text-sm font-normal font-poppins mb-2">
                  Symptoms
                </p>
                <div className="relative">
                  {!isViewing && (
                    <>
                      <input
                        type="text"
                        value={symptomsInput}
                        disabled={isViewing}
                        onChange={handleSymptoms}
                        placeholder="Enter Symptoms here"
                        className={`w-full ${
                          errors.Symptoms ? "border-red-500" : ""
                        } text-zinc-900 text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                        autoComplete="off"
                      />
                      <div className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400">
                        <svg
                          className="h-4 w-4"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                        </svg>
                      </div>
                    </>
                  )}
                </div>

                {symptomsSuggestions?.length > 0 && (
                  <div className="absolute w-1/2 bg-white border rounded-md mt-1">
                    {symptomsSuggestions?.map((symptoms) => (
                      <div
                        key={symptoms.PSID}
                        onClick={() => handleAddSymptoms(symptoms)}
                        className="px-3 py-1 hover:bg-gray-200 cursor-pointer"
                      >
                        {symptoms.Name}
                      </div>
                    ))}
                  </div>
                )}
              </label>
            </div>
            {formData?.Symptoms?.length !== 0 && (
              <div className="w-full flex flex-row items-center flex-wrap gap-2 mt-4">
                {formData?.Symptoms?.map((symptoms) => (
                  <div
                    key={symptoms.PSID}
                    className={`px-4 py-1 bg-gray-100 rounded-2xl ${
                      !isViewing && "cursor-pointer"
                    } select-none`}
                    onClick={() =>
                      !isViewing && handleRemoveSymptoms(symptoms.PSID)
                    }
                  >
                    <div className="text-neutral-600 text-xs font-poppins">
                      {symptoms.Name}
                    </div>
                  </div>
                ))}
              </div>
            )}
            {/* Diagnosis Information */}
            <div>
              <label>
                <p className="text-zinc-900 text-sm font-normal font-poppins mb-2">
                  Diagnosis Information
                </p>
                <textarea
                  type="text"
                  id="DiagnosisInformation"
                  name="DiagnosisInformation"
                  disabled={isViewing}
                  value={formData.DiagnosisInformation}
                  // maxLength={20}
                  onChange={handleOnChange}
                  placeholder="Enter Diagnosis Information here"
                  className={`w-full text-zinc-900 text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                    errors.DiagnosisInformation ? "border-red-500" : ""
                  } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>
          </div>

          {medications?.length > 0 ? (
            <>
              {medications?.map((medication, index) => (
                <div
                  key={medication.id}
                  className="p-4 rounded-lg shadow my-4 relative"
                >
                  {!isViewing && (
                    <button
                      type="button"
                      onClick={() => removeMedication(medication.id)}
                      className="absolute bottom-5 right-5 flex items-center space-x-1 active:scale-95"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.3008 5.5L18.6811 15.5251C18.5227 18.0864 18.4436 19.3671 17.8016 20.2879C17.4841 20.7431 17.0755 21.1273 16.6015 21.416C15.6429 22 14.3598 22 11.7935 22C9.2239 22 7.93908 22 6.97983 21.4149C6.50558 21.1257 6.09678 20.7408 5.77946 20.2848C5.13766 19.3626 5.06023 18.0801 4.90539 15.5152L4.30078 5.5"
                          stroke="#D92D20"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                        <path
                          d="M2.80078 5.5H20.8008M15.8565 5.5L15.1739 4.09173C14.7204 3.15626 14.4936 2.68852 14.1025 2.39681C14.0158 2.3321 13.9239 2.27454 13.8278 2.2247C13.3947 2 12.8749 2 11.8353 2C10.7696 2 10.2368 2 9.79646 2.23412C9.69888 2.28601 9.60576 2.3459 9.51807 2.41317C9.12242 2.7167 8.90141 3.20155 8.45939 4.17126L7.8537 5.5"
                          stroke="#D92D20"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                        <path
                          d="M9.30078 16.5V10.5"
                          stroke="#D92D20"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                        <path
                          d="M14.3008 16.5V10.5"
                          stroke="#D92D20"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                        />
                      </svg>
                      <div className="text-red-600 text-sm font-normal font-poppins underline leading-none">
                        Remove
                      </div>
                    </button>
                  )}
                  <div className="grid grid-cols-2 items-center gap-4 mb-4">
                    <div>
                      <label>
                        <p className="text-zinc-900 text-sm font-normal font-poppins mb-2">
                          Medicine Name {index + 1}
                        </p>
                        <Dropdown
                          disabled={isViewing}
                          enableSearch={true}
                          placeholder={"Select Medicine"}
                          error={errors[`MedicationID${medication.id}`]}
                          MedicationID="MedicationID"
                          options={allMedicines}
                          onSelect={(MedicationID, option) =>
                            handleDropdownSelect(
                              option,
                              "MedicationID",
                              medication.id
                            )
                          }
                          value={medication.MedicationID}
                          labelKey={"Name"}
                          valueKey={"MedicationID"}
                        />
                      </label>
                    </div>
                    <div>
                      <label>
                        <p className="text-zinc-900 text-sm font-normal font-poppins mb-2">
                          Dosage
                        </p>
                        <input
                          type="text"
                          disabled={isViewing}
                          id={`Dosage${medication.id}`}
                          MedicationID="Dosage"
                          value={medication.DosageAmount}
                          maxLength={10}
                          onChange={(e) =>
                            handleInputChange(
                              medication.id,
                              "DosageAmount",
                              e.target.value
                            )
                          }
                          placeholder="Ex: 5 mg"
                          className={`w-full text-zinc-900 text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                            errors[`DosageAmount${medication.id}`]
                              ? "border-red-500"
                              : ""
                          } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                        />
                      </label>
                    </div>
                    <div>
                      <label>
                        <p className="text-zinc-900 text-sm font-normal font-poppins mb-2">
                          Route
                        </p>
                        <Dropdown
                          disabled={isViewing}
                          enableSearch={true}
                          placeholder={"Select Route"}
                          error={errors[`Route${medication.id}`]}
                          MedicationID="Route"
                          options={[
                            "Oral",
                            "Sublingual",
                            "Intravenous",
                            "Topical",
                            "Inhalation",
                            "Intramuscular",
                            "Subcutaneous",
                            "Rectal",
                            "Transdermal",
                          ]}
                          onSelect={(MedicationID, option) =>
                            handleDropdownSelect(option, "Route", medication.id)
                          }
                          value={medication.Route}
                        />
                      </label>
                    </div>
                    <div className="flex items-center space-x-6">
                      <p className="text-zinc-900 text-sm font-medium font-poppins leading-none">
                        Meal Intake:
                      </p>
                      {["Pre Meal", "Post Meal"]?.map((option) => (
                        <label
                          key={option}
                          className={`flex items-center ${
                            !isViewing ? "cursor-pointer" : ""
                          }`}
                        >
                          <input
                            type="radio"
                            disabled={isViewing}
                            className="hidden"
                            MedicationID={`MealTime${medication.id}`}
                            value={option}
                            checked={medication.MealTime === option}
                            onChange={(e) =>
                              handleInputChange(
                                medication.id,
                                "MealTime",
                                e.target.value
                              )
                            }
                          />
                          <RadioIcon
                            selected={medication.MealTime === option}
                          />
                          <span className="text-zinc-900 text-sm font-normal font-poppins leading-none">
                            {option.charAt(0).toUpperCase() + option.slice(1)}
                          </span>
                        </label>
                      ))}
                    </div>
                    <div className="flex items-center space-x-6 col-span-2">
                      <p className="text-zinc-900 text-sm font-medium font-poppins leading-none">
                        Frequency:
                      </p>
                      {["Once", "Twice", "Thrice", "Custom"]?.map((option) => (
                        <label
                          key={option}
                          className={`flex items-center ${
                            !isViewing ? "cursor-pointer" : ""
                          }`}
                        >
                          <input
                            type="radio"
                            disabled={isViewing}
                            className="hidden"
                            MedicationID={`Frequency${medication.id}`}
                            value={option}
                            checked={medication.Frequency === option}
                            onChange={(e) =>
                              handleInputChange(
                                medication.id,
                                "Frequency",
                                e.target.value
                              )
                            }
                          />
                          <RadioIcon
                            selected={medication.Frequency === option}
                          />
                          <span className="text-zinc-900 text-sm font-normal font-poppins leading-none">
                            {option.charAt(0).toUpperCase() + option.slice(1)}{" "}
                            {option !== "Custom" ? "a day" : ""}
                          </span>
                        </label>
                      ))}
                    </div>
                    {medication.Frequency === "Custom" && (
                      <div className="flex items-center space-x-4 col-span-2">
                        {medication?.CustomTimes?.map((time, index) => (
                          <div key={index} className="flex items-center mb-2">
                            <TimePicker
                              disabled={isViewing}
                              use12Hours
                              format="h:mm a"
                              value={time ? moment(time, "HH:mm") : null}
                              onChange={(time, timeString) =>
                                handleTimeChange(
                                  medication.id,
                                  index,
                                  timeString
                                )
                              }
                              className="mr-2"
                            />
                            {medication?.CustomTimes?.length > 1 &&
                              !isViewing && (
                                <button
                                  onClick={() =>
                                    removeCustomTime(medication.id, index)
                                  }
                                  className="p-1.5 rounded-md justify-center items-center gap-2 inline-flex border border-gray-300 mr-2"
                                >
                                  <RxCross1 className="" />
                                </button>
                              )}
                            {index === medication?.CustomTimes?.length - 1 &&
                              index < 3 &&
                              !isViewing && (
                                <button
                                  onClick={() => addCustomTime(medication.id)}
                                  className="p-1.5 rounded-md justify-center items-center gap-2 inline-flex border border-gray-300"
                                >
                                  <IoAddSharp className="text-sky-500 text-md" />
                                </button>
                              )}
                          </div>
                        ))}
                      </div>
                    )}
                    <div className="flex items-center space-x-6 col-span-2">
                      <p className="text-zinc-900 text-sm font-medium font-poppins leading-none">
                        Duration:
                      </p>
                      <input
                        type="text"
                        disabled={isViewing}
                        id={`Duration${medication.id}`}
                        MedicationID="Duration"
                        value={medication.Duration}
                        maxLength={2}
                        onChange={(e) =>
                          handleInputChange(
                            medication.id,
                            "Duration",
                            e.target.value
                          )
                        }
                        placeholder="Enter no. of"
                        className={`text-zinc-900 text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                          errors[`Duration${medication.id}`]
                            ? "border-red-500"
                            : ""
                        } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                      />
                      {["Day", "Week", "Month"]?.map((option) => (
                        <label
                          key={option}
                          className={`flex items-center ${
                            !isViewing ? "cursor-pointer" : ""
                          }`}
                        >
                          <input
                            type="radio"
                            className="hidden"
                            disabled={isViewing}
                            MedicationID={`DurationType${medication.id}`}
                            value={option}
                            checked={medication.DurationType === option}
                            onChange={(e) =>
                              handleInputChange(
                                medication.id,
                                "DurationType",
                                e.target.value
                              )
                            }
                          />
                          <RadioIcon
                            selected={medication.DurationType === option}
                          />
                          <span className="text-zinc-900 text-sm font-normal font-poppins leading-none">
                            {option.charAt(0).toUpperCase() + option.slice(1)}
                          </span>
                        </label>
                      ))}
                    </div>
                    <div className="col-span-2 mb-4">
                      <label>
                        <p className="text-zinc-900 text-sm font-normal font-poppins mb-2">
                          Special Instructions (Optional)
                        </p>
                        <textarea
                          type="text"
                          disabled={isViewing}
                          id={`SpecialInstructions${medication.id}`}
                          MedicationID="SpecialInstructions"
                          value={medication.SpecialInstructions}
                          // maxLength={30}
                          onChange={(e) =>
                            handleInputChange(
                              medication.id,
                              "SpecialInstructions",
                              e.target.value
                            )
                          }
                          placeholder="Enter Special Instructions"
                          className={`w-full text-zinc-900 text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              ))}
              {!isViewing && (
                <button
                  onClick={addMedication}
                  className="mt-6 flex items-center justify-center text-sky-500 text-base font-semibold font-poppins leading-tight"
                >
                  <IoAddSharp className="mr-2 text-2xl" />
                  Add Another Medication
                </button>
              )}
            </>
          ) : (
            !isViewing && (
              <button
                onClick={addMedication}
                className="mt-6 flex items-center justify-center text-sky-500 text-base font-semibold font-poppins leading-tight"
              >
                <IoAddSharp className="mr-2 text-2xl" />
                Add Prescription
              </button>
            )
          )}
        </div>
        {!isViewing && (
          <div className="flex flex-row items-center justify-center mt-4 space-x-4">
            <button
              onClick={handleCloseModal}
              className="w-96 h-11 flex items-center justify-center bg-white rounded-lg border border-sky-500 active:scale-95"
            >
              <div className="text-sky-500 text-base font-semibold font-poppins leading-normal">
                Cancel
              </div>
            </button>
            <button
              onClick={handleSubmit}
              className="w-96 h-11 flex items-center justify-center bg-sky-500 rounded-lg border border-sky-500 active:scale-95"
            >
              <div className="text-white text-base font-semibold font-poppins leading-normal">
                Save Details
              </div>
            </button>
          </div>
        )}
      </div>
    </ModalComponent>
  );
}

export default ConsultationDetails;
