import "./App.css";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import OpenRoute from "./components/OpenRoute";
import Error from "./screens/Error";
import Login from "./screens/Login";
import ForgotPassword from "./screens/ForgotPassword";
import ForgotPasswordOtp from "./screens/ForgotPasswordOtp";
import Dashboard from "./screens/Dashboard";
import TelehealthDashboard from "./screens/TelehealthDashboard";
import PatientRegistration from "./screens/PatientRegistration";
import PatientDashboard from "./screens/PatientDashboard";
import FullscreenVidCall from "./screens/FullscreenVidCall";
import PatientProfile from "./screens/PatientProfile";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getUserDetails } from "./services/operations/profileAPI";
import TreatmentProgram from "./screens/TreatmentProgram/TreatmentProgram";
import AddTreatmentProgram from "./screens/TreatmentProgram/AddTreatmentProgram";
import CreateTreatmentProgram from "./screens/TreatmentProgram/CreateTreatmentProgram";
import Invoicing from "./screens/Invoicing/Invoicing";
import AddInvoice from "./screens/Invoicing/AddInvoice";
import ConsultationHistory from "./screens/ConsultationHistory";
import MentalHealthAssessment from "./screens/MentalHealthAssessment";
import HealthProgramFullScreen from "./screens/HealthProgramFullScreen";
import ServicesAndRateCard from "./screens/ServicesAndRateCard";
import Settings from "./screens/Settings";
import DoctorManagement from "./screens/DoctorManagement";
import TodayPatientListFullScreen from "./screens/TodayPatientListFullScreen";
import NextScheduleFullScreen from "./screens/NextScheduleFullScreen";
import PayoutsFullScreen from "./screens/PayoutsFullScreen";
import ClinicCapacity from "./screens/ClinicCapacity";
import AddNewDoctor from "./screens/AddNewDoctor";
import NurseManagement from "./screens/NurseManagement";
import AddNewNurse from "./screens/AddNewNurse";
import ResetPassword from "./screens/ResetPassword";
import ResetPasswordSuccess from "./screens/ResetPasswordSuccess";
import ManagePatient from "./screens/ManagePatient";
import RescheduleAppointment from "./screens/RescheduleAppointment";
import HealthJourney from "./screens/HealthJourney";
import AccessManagement from "./screens/AccessManagement";
import BranchManagement from "./screens/BranchManagement";
import ServiceManagement from "./screens/ServiceManagement";
import OperationsManagement from "./screens/OperationsManagement";
import LabPartners from "./screens/LabPartners";
import AddLabPartner from "./screens/AddLabPartner";
import ComingSoon from "./screens/ComingSoon";
import PartnerLogin from "./screens/PartnerLogin";
import LabPartnerDashboard from "./screens/LabPartnerDashboard";
import { ACCESS_ROLES, hasAccess, USER_TYPE } from "./utils/constants";
import BookAppointment from "./screens/BookAppointment";
import MedicalConcierge from "./screens/MedicalConcierge";
import PartnerForgotPassword from "./screens/PartnerForgotPassword";
import PartnerResetPassword from "./screens/PartnerResetPassword";
import AddNewConceirgePatient from "./components/AddNewConceirgePatient";
import HealthManagerDashboard from "./screens/HealthManagerDashboard";
import MedicalConceirgeSettings from "./screens/MedicalConceirgeSettings";
import AddNewConceirgePlan from "./screens/AddNewConceirgePlan";
import AddNewHealthManager from "./screens/AddNewHealthManager";
import OurServices from "./screens/OurServices/OurServices";
import AddServices from "./screens/OurServices/AddService";
import AddTreatmentCategory from "./screens/OurServices/AddTreatmentCategory";
import ServiceDetails from "./screens/OurServices/ServiceDetails";
import RewardManagement from "./screens/RewardsManagement/RewardManagement";
import CreateReward from "./screens/RewardsManagement/CreateReward";
import SalesDetail from "./screens/RewardsManagement/SalesDetail";
import MedicationFullScreen from "./screens/MedicationFullScreen";
import ActivityLog from "./screens/ActivityLog";
import ArchivePatients from "./screens/ArchivePatients";
import TelehealthData from "./screens/TelehealthData/TelehealthData";
import NotificationManagement from "./screens/NotificationManagement/NotificationManagement";

function App() {
  const { user, accessRoles } = useSelector((state) => state.profile);
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (token) {
      dispatch(getUserDetails(token, navigate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <OpenRoute>
              <Login />
            </OpenRoute>
          }
        />
        <Route
          path="login"
          element={
            <OpenRoute>
              <Login />
            </OpenRoute>
          }
        />
        <Route
          path="partnerLogin"
          element={
            <OpenRoute>
              <PartnerLogin />
            </OpenRoute>
          }
        />
        <Route
          path="forgot-password"
          element={
            <OpenRoute>
              <ForgotPassword />
            </OpenRoute>
          }
        />
        <Route
          path="partner-forgot-password"
          element={
            <OpenRoute>
              <PartnerForgotPassword />
            </OpenRoute>
          }
        />
        <Route
          path="verify-forgot-password-otp"
          element={
            <OpenRoute>
              <ForgotPasswordOtp />
            </OpenRoute>
          }
        />
        <Route
          path="partner-verify-forgot-password-otp"
          element={
            <OpenRoute>
              <PartnerForgotPassword />
            </OpenRoute>
          }
        />
        <Route
          path="reset-password"
          element={
            <OpenRoute>
              <ResetPassword />
            </OpenRoute>
          }
        />
        <Route
          path="partner-reset-password"
          element={
            <OpenRoute>
              <PartnerResetPassword />
            </OpenRoute>
          }
        />
        <Route
          path="reset-password-success"
          element={
            <OpenRoute>
              <ResetPasswordSuccess />
            </OpenRoute>
          }
        />
        <Route
          path="partner-reset-password-success"
          element={
            <OpenRoute>
              <ResetPasswordSuccess />
            </OpenRoute>
          }
        />
        {/* Private Route - for Only Logged in User */}
        <Route
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        >
          {/* SCREENS FOR ALL USER TYPE EXCEPT LAB PARTNER  */}
          {user?.UserType !== USER_TYPE.LAB_PARTNER && (
            <>
              {/* SCREENS FOR ALL ROLES */}
              {hasAccess(accessRoles, [
                ACCESS_ROLES.ADMIN,
                ACCESS_ROLES.NURSE,
                ACCESS_ROLES.DOCTOR,
                ACCESS_ROLES.TELEHEALTH_DOCTOR,
                ACCESS_ROLES.HEALTH_MANAGER,
              ]) && (
                  <>
                    {/* Clinic Dashboard Screens */}
                    <Route path="clinicianDashboard">
                      <Route index element={<TelehealthDashboard />} />
                      <Route
                        path="todaysPatientList"
                        element={<TodayPatientListFullScreen />}
                      />
                      <Route
                        path="nextSchedule"
                        element={<NextScheduleFullScreen />}
                      />
                      <Route path="payouts" element={<PayoutsFullScreen />} />
                      <Route
                        path="fullscreenVideoCall"
                        element={<FullscreenVidCall />}
                      />
                    </Route>

                    <Route path="patientDashboard">
                      <Route index element={<PatientDashboard />} />
                      <Route
                        path="patientRegistration"
                        element={<PatientRegistration />}
                      />
                      <Route
                        path="archivePatients"
                        element={<ArchivePatients />}
                      />
                      <Route
                        path="bookAppointment"
                        element={<BookAppointment />}
                      />
                      <Route path="patientProfile">
                        <Route index element={<PatientProfile />} />
                        <Route path="healthJourney" element={<HealthJourney />} />
                        <Route path="activityLog" element={<ActivityLog />} />
                        <Route
                          path="mentalHealthAssessment"
                          element={<MentalHealthAssessment />}
                        />
                        <Route
                          path="medication"
                          element={<MedicationFullScreen />}
                        />
                        <Route
                          path="consultationHistory"
                          element={<ConsultationHistory />}
                        />
                        <Route
                          path="healthProgram"
                          element={<HealthProgramFullScreen />}
                        />
                        <Route
                          path="addTreatmentProgram"
                          element={<AddTreatmentProgram />}
                        />
                      </Route>
                      <Route path="managePatient">
                        <Route index element={<ManagePatient />} />
                        <Route
                          path="reschedulePatient"
                          element={<RescheduleAppointment />}
                        />
                      </Route>
                    </Route>
                    <Route path="comingSoon" element={<ComingSoon />} />
                  </>
                )}

              {/* SCREENS FOR HEALTH MANAGER */}
              {hasAccess(accessRoles, [ACCESS_ROLES.HEALTH_MANAGER]) && (
                <>
                  <Route
                    path="healthManagerDashboard"
                    element={<HealthManagerDashboard />}
                  />
                  <Route
                    path="patientRegistration"
                    element={<PatientRegistration />}
                  />
                  <Route path="healthJourney" element={<HealthJourney />} />
                  <Route
                    path="mentalHealthAssessment"
                    element={<MentalHealthAssessment />}
                  />
                  <Route
                    path="consultationHistory"
                    element={<ConsultationHistory />}
                  />
                  <Route
                    path="healthProgram"
                    element={<HealthProgramFullScreen />}
                  />
                </>
              )}

              {/* SCREENS FOR ADMIN AND NURSE */}
              {hasAccess(accessRoles, [
                ACCESS_ROLES.ADMIN,
                ACCESS_ROLES.NURSE,
              ]) && (
                  <>
                    <Route
                      path="healthManagerDashboard"
                      element={<HealthManagerDashboard />}
                    />
                    <Route
                      path="serviceManagement"
                      element={<ServiceManagement />}
                    />
                    <Route
                      path="operationsManagement"
                      element={<OperationsManagement />}
                    />
                    <Route
                      path="medicalConcierge"
                      element={<MedicalConcierge />}
                    />
                    <Route
                      path="medicalConciergeSettings"
                      element={<MedicalConceirgeSettings />}
                    />
                    <Route
                      path="addNewConciergePlan"
                      element={<AddNewConceirgePlan />}
                    />
                    <Route
                      path="addNewHealthManager"
                      element={<AddNewHealthManager />}
                    />
                    <Route
                      path="addNewConciergePatient"
                      element={<AddNewConceirgePatient />}
                    />
                    <Route
                      path="treatmentProgram"
                      element={<TreatmentProgram />}
                    />
                    <Route
                      path="createTreatmentProgram"
                      element={<CreateTreatmentProgram />}
                    />
                    <Route path="invoicing">
                      <Route index element={<Invoicing />} />
                      <Route path="AddInvoice" element={<AddInvoice />} />
                    </Route>
                    <Route
                      path="servicesAndRateCard"
                      element={<ServicesAndRateCard />}
                    />
                    <Route path="ourServices">
                      <Route index element={<OurServices />} />
                      <Route
                        path="addTreatmentCategory"
                        element={<AddTreatmentCategory />}
                      />
                      <Route path="serviceDetail">
                        <Route index element={<ServiceDetails />} />
                        <Route path="addServices" element={<AddServices />} />
                      </Route>
                    </Route>
                    <Route path="rewardManagement">
                      <Route index element={<RewardManagement />} />
                      <Route
                        path="createReward"
                        element={<CreateReward />}
                      />
                      <Route
                        path="salesDetail"
                        element={<SalesDetail />}
                      />
                    </Route>
                    <Route path="telehealthData">
                      <Route index element={<TelehealthData />} />
                    </Route>
                    <Route path="notificationManagement">
                      <Route index element={<NotificationManagement />} />
                    </Route>
                    <Route path="labPartners" element={<LabPartners />} />
                    <Route path="addLabPartner" element={<AddLabPartner />} />
                    <Route
                      path="doctorManagement"
                      element={<DoctorManagement />}
                    />
                    <Route path="addNewDoctor" element={<AddNewDoctor />} />
                    <Route path="nurseMangement" element={<NurseManagement />} />
                    <Route path="addNewNurse" element={<AddNewNurse />} />
                    <Route
                      path="accessManagement"
                      element={<AccessManagement />}
                    />
                    <Route path="clinicCapacity" element={<ClinicCapacity />} />
                    <Route
                      path="branchManagement"
                      element={<BranchManagement />}
                    />
                    <Route path="settings" element={<Settings />} />
                  </>
                )}
            </>
          )}

          {/* SCREENS FOR LAB PARTNER  */}
          {user?.UserType === USER_TYPE.LAB_PARTNER && (
            <>
              <Route
                path="labPartnerDashboard"
                element={<LabPartnerDashboard />}
              />
            </>
          )}
        </Route>
        <Route path="*" element={<Error />} status={404} />
      </Routes>
    </>
  );
}

export default App;
