import React, { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const dummyData = [
  { name: 'Insulin', duration: '4 Weeks', followUp: '2 Weeks', schedule: [true, true, null, null] },
  { name: 'Sertranin', duration: '4 Weeks', followUp: '2 Weeks', schedule: [true, false, 'delayed', null] },
  { name: 'Rifanpil', duration: '6 Weeks', followUp: '2 Weeks', schedule: [true, false, true, null] },
  { name: 'Albeterol', duration: '14 Weeks', followUp: '4 Weeks', schedule: [true, true, null, null] },
  { name: 'Atorvastatin', duration: '3 Weeks', followUp: 'Ongoing from last month', schedule: [true, 'discontinued', null, null] },
  { name: 'Sertranin', duration: '6 Weeks', followUp: '4 Weeks', schedule: [true, null, null, null] },
];

const DosageAdherence = () => {
  const [currentWeek, setCurrentWeek] = useState(1);

  const getStatusIcon = (status) => {
    switch(status) {
      case true: return <span className="text-green-500">✓</span>;
      case false: return <span className="text-red-500">✗</span>;
      case 'delayed': return <span className="text-yellow-500">🕒</span>;
      case 'discontinued': return <span className="text-gray-500">⊘</span>;
      default: return <span className="text-gray-300">○</span>;
    }
  };

  const getLineColor = (status) => {
    switch(status) {
      case true: return 'bg-green-500';
      case false: return 'bg-red-500';
      case 'delayed': return 'bg-yellow-500';
      case 'discontinued': return 'bg-gray-500';
      default: return 'bg-gray-300';
    }
  };

  const getWeekDates = (weekNumber) => {
    const startDate = new Date(2024, 8, 2 + (weekNumber - 1) * 7);
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 6);
    return `(${startDate.getDate()} Sept- ${endDate.getDate()} Sept)`;
  };

  return (
    <div className="p-4 bg-white rounded-lg shadow">
      <div className="flex justify-between items-center mb-4">
        <div className="flex space-x-2">
          <span className="flex items-center"><span className="w-3 h-3 bg-green-500 rounded-full mr-1"></span> Taken on time</span>
          <span className="flex items-center"><span className="w-3 h-3 bg-red-500 rounded-full mr-1"></span> Missed</span>
          <span className="flex items-center"><span className="w-3 h-3 bg-yellow-500 rounded-full mr-1"></span> Delayed/Overdue</span>
          <span className="flex items-center"><span className="w-3 h-3 bg-gray-300 rounded-full mr-1"></span> Future</span>
          <span className="flex items-center"><span className="w-3 h-3 border border-gray-500 rounded-full mr-1"></span> Discontinued</span>
        </div>
        <div className="flex items-center space-x-2">
          <button onClick={() => setCurrentWeek(prev => Math.max(1, prev - 1))}>
            <ChevronLeft className="w-5 h-5" />
          </button>
          <span className="font-semibold">Sept</span>
          <button onClick={() => setCurrentWeek(prev => Math.min(4, prev + 1))}>
            <ChevronRight className="w-5 h-5" />
          </button>
          <button className="px-3 py-1 rounded bg-blue-100 text-blue-600">Monthly</button>
          <button className="px-3 py-1 rounded bg-gray-100">Yearly</button>
        </div>
      </div>
      <table className="w-full">
        <thead>
          <tr className="text-left">
            <th className="py-2 w-1/5">Medicine Name</th>
            <th className="w-1/5">Duration</th>
            {[1, 2, 3, 4].map(week => (
              <th key={week} className={`py-2 w-1/6 ${currentWeek === week ? 'bg-blue-50' : ''}`}>
                Week {week} {currentWeek === week && <span className="inline-block w-2 h-2 bg-blue-500 rounded-full ml-1"></span>}
                <div className="text-xs text-gray-500">{getWeekDates(week)}</div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {dummyData.map((medicine, index) => (
            <tr key={index}>
              <td className="py-2 border-t">{medicine.name}</td>
              <td className="border-t">{medicine.duration}<br /><span className="text-sm text-gray-500">Follow up: {medicine.followUp}</span></td>
              {medicine.schedule.map((status, i) => (
                <td key={i} className={`py-2 relative ${currentWeek === i + 1 ? 'bg-blue-50' : ''}`}>
                  <div className="absolute inset-0 flex items-center">
                    <div className={`h-0.5 w-full ${getLineColor(status)}`}></div>
                  </div>
                  <div className="relative flex justify-center">{getStatusIcon(status)}</div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DosageAdherence;