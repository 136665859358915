import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  MeetingProvider,
  useMeeting,
  useParticipant,
} from "@videosdk.live/react-sdk";
import settingIcon from "../../assets/Images/TelehealthDashboard/settingIcon.png";
import { useNavigate } from "react-router-dom";
import ParticipantView from "./ParticipantView";
import { setShowMeetingView } from "../../slices/dashboardSlice";
import { useDispatch } from "react-redux";

export default function MeetingView(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    DiseaseType: "",
    PersonalNotes: "",
    DiagnosisInformation: "",
  });
  const [isAudioDone, setIsAudioDone] = useState(false);
  const [joined, setJoined] = useState(null);
  const [showSettings, setShowSettings] = useState(false);

  // State for available devices
  const [cameras, setCameras] = useState([]);
  const [selectedCamera, setSelectedCamera] = useState("");
  const [mics, setMics] = useState([]);
  const [selectedMic, setSelectedMic] = useState("");

  // Reconnection related refs
  const reconnectionAttempts = useRef(0);
  const maxReconnectionAttempts = 5;
  const reconnectionInterval = useRef(null);

  const {
    join,
    leave,
    disableWebcam,
    muteMic,
    participants,
    localParticipant,
    getMics,
    changeMic,
    getWebcams,
    changeWebcam,
    localMicOn,
    localWebcamOn,
  } = useMeeting({
    onMeetingJoined: () => {
      setJoined("JOINED");
      handleGetWebcams();
      handleGetMics();
      // Ensure the local mic and webcam states are synced after reconnection
      if (!localMicOn) {
        muteMic();
      }
      if (!localWebcamOn) {
        disableWebcam();
      }
      // Reset reconnection attempts on successful join
      reconnectionAttempts.current = 0;
    },
    onMeetingLeft: () => {
      cleanupMeeting();
    },
    onMeetingStateChanged: (state) => {
      if (state === "FAILED" || state === "DISCONNECTED") {
        handleReconnection();
      }
      if (state === "JOINED") {
        // Clear any existing reconnection intervals upon successful join
        clearReconnectionInterval();
      }
    },
  });

  // Function to handle reconnection logic
  const handleReconnection = () => {
    if (reconnectionAttempts.current < maxReconnectionAttempts) {
      reconnectionAttempts.current += 1;
      const delay = Math.min(5000 * reconnectionAttempts.current, 20000); // Exponential backoff with cap at 20 seconds

      reconnectionInterval.current = setTimeout(() => {
        join().then(() => {
          // Ensure the local mic and webcam states are synced after reconnection
          if (!localMicOn) {
            muteMic();
          }
          if (!localWebcamOn) {
            disableWebcam();
          }
        });
      }, delay);
    } else {
      console.error("Max reconnection attempts reached. Could not rejoin the meeting.");
      setJoined("FAILED");
    }
  };

  // Function to clear reconnection interval
  const clearReconnectionInterval = () => {
    if (reconnectionInterval.current) {
      clearTimeout(reconnectionInterval.current);
      reconnectionInterval.current = null;
    }
  };

  // Cleanup function to leave meeting and reset states
  const cleanupMeeting = () => {
    disableWebcam();
    muteMic();
    leave();
    props.setCanStartMeet(null);
    props.setMeetingId(null);
    props.setMeetingToken(null);
    props.onExpand(false);
    dispatch(setShowMeetingView(false));
    setJoined(null);
    clearReconnectionInterval();
  };

  // Fetch available webcams
  const handleGetWebcams = async () => {
    const webcams = await getWebcams();
    setCameras(webcams);
  };

  const handleChangeCamera = (event) => {
    changeWebcam(event.target.value);
    setSelectedCamera(event.target.value);
  };

  // Fetch available microphones
  const handleGetMics = async () => {
    const mics = await getMics();
    setMics(mics);
};

  const handleFormDataSave = (data) => {
    setFormData(data);
  };

  const handleChangeMic = (event) => {
    changeMic(event.target.value);
    setSelectedMic(event.target.value);
  };

  useEffect(() => {
    setJoined("JOINING");
    join();

    if (document.hidden) {
      cleanupMeeting();
    }

    return () => {
      cleanupMeeting();
    };
  }, []);

  return (
    <div className="container">
      {joined === "JOINED" && (
        <div className="flex flex-col justify-between w-full h-auto items-center gap-4 rounded-xl bg-[#F3F3F3] relative mb-8">
          {participants.size === 1 ? (
            <>
              {[...participants.keys()].map((participantId) => (
                <div
                  key={participantId}
                  className={`rounded-lg ${participantId === localParticipant.id
                    ? "w-full relative"
                    : "absolute right-3 top-3 w-[30%]"
                    }`}
                >
                  <ParticipantView
                    participantId={participantId}
                    key={participantId}
                    isLocal={participantId === localParticipant.id}
                    className="rounded-lg"
                    controls={true}
                    onExpand={props.onExpand}
                    patient={props.patient}
                    isExpanded={props.isExpanded}
                    formData={formData}
                    isAudioDone={isAudioDone}
                    style={{ borderRadius: '20%' }}
                  />
                </div>
              ))}
            </>
          ) : (
            <>
              {[...participants.keys()].map((participantId) => (
                <div
                  key={participantId}
                  className={`rounded-lg ${participantId === localParticipant.id
                    ? "absolute right-3 top-3 w-[30%] z-50"
                    : "w-full relative z-10"
                    }`}
                >
                  <ParticipantView
                    participantId={participantId}
                    key={participantId}
                    isLocal={participantId === localParticipant.id}
                    className="rounded-lg"
                    controls={false}
                    onExpand={props.onExpand}
                    patient={props.patient}
                    isExpanded={props.isExpanded}
                    onSaveFormData={handleFormDataSave}
                    setIsAudioDone={setIsAudioDone}
                    style={{ borderRadius: '20%' }}
                  />
                </div>
              ))}
            </>
          )}
          <div className="absolute bottom-2 right-4 z-50">
            <button
              onClick={() => {
                setShowSettings(!showSettings);
              }}
              className={`flex justify-center items-center w-[45px] h-[45px] p-1 rounded-full cursor-pointer hover:scale-110 duration-500`}
            >
              <img
                src={settingIcon}
                alt="Setting icon"
                className="w-full h-auto rounded-full shadow-xl"
              />
            </button>
          </div>
          {showSettings && (
            <div className="absolute bottom-[-3.5vh] w-full flex flex-row justify-center items-center z-50 gap-2">
              <select
                value={selectedCamera}
                onChange={handleChangeCamera}
                className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md px-4 py-1 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
              >
                {cameras?.map((camera) => (
                  <option key={camera.deviceId} value={camera.deviceId}>
                    {camera.label}
                  </option>
                ))}
              </select>
              <select
                value={selectedMic}
                onChange={handleChangeMic}
                className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md px-4 py-1 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
              >
                {mics?.map((mic) => (
                  <option key={mic.deviceId} value={mic.deviceId}>
                    {mic.label}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
      )}
      {joined === "JOINING" && (
        <div className="flex flex-col justify-center w-full h-auto items-center gap-4 relative rounded-xl bg-[#F3F3F3] py-2">
          <p className="font-poppins text-sm font-semibold text-[#3CA5DC]">
            Joining the meeting...
          </p>
        </div>
      )}
    </div>
  );
}
