import styles from "./styles.module.css";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaChevronLeft, FaPlus } from "react-icons/fa6";
import { IoIosSearch } from "react-icons/io";
// import { FaList } from "react-icons/fa6";
import { TbLayoutList } from "react-icons/tb";
import { RxDashboard } from "react-icons/rx";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import profilePic from "../assets/Images/TelehealthDashboard/profilePic.png";
import DoctorListView from "../components/DoctorListVIew";
import DoctorCardView from "../components/DoctorCardView";
import noPatientFound from "../assets/Images/PatientDashboard/noPatientFound.png";

function DoctorManagement() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isListView, setIsListView] = useState(
    sessionStorage.getItem("isListView") !== "false"
  );
  const [searchInput, setSearchInput] = useState("");
  const { allDoctors, loading: masterDataLoading } = useSelector(
    (state) => state.masterData
  );
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = isListView ? 6 : 8;

  const filteredDoctors = allDoctors.filter((p) => {
    const dob = new Date(p.DOB);
    const month = dob.toLocaleString("default", { month: "long" });
    return `${p.Name} ${p.DOB} ${month}`
      .toLowerCase()
      .includes(searchInput.toLowerCase());
  });

  const currentDoctors = filteredDoctors.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  useEffect(() => {
    sessionStorage.setItem("isListView", isListView);
    setCurrentPage(1);
  }, [isListView]);

  if (masterDataLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <div className="spinner"></div>
      </div>
    );
  }

  if (allDoctors.length === 0) {
    return (
      <div className="py-2 px-2 sm:py-5 sm:px-10 scroll-smooth">
        <div className="bg-white rounded-lg shadow-lg flex flex-col items-center justify-center h-full p-20">
          <img
            src={noPatientFound}
            alt=""
            className="w-[533px] h-72 object-contain"
          />
          <div className="text-black text-xl font-bold font-Inter">
            Add New Doctor to view them on this screen
          </div>
          <button
            className="w-1/2 mx-auto bg-sky-500 text-center text-stone-50 text-sm font-semibold font-poppins py-3 rounded-lg mt-4 hover:opacity-80"
            onClick={() => {
              navigate("/addNewDoctor");
            }}
          >
            Add New Doctor
          </button>
        </div>
      </div>
    );
  }

  const handleSearchChange = (e) => {
    setSearchInput(e.target.value);
  };

  return (
    <div className="bg-[#F3F3F3] min-h-full py-2 px-2 sm:py-5 sm:px-10 scroll-smooth">
      <div className="bg-white rounded-lg shadow-lg p-6">
        {/* Header Section */}
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-center space-x-4">
            <button
              className="active:scale-90"
              onClick={() => {
                navigate(-1);
              }}
            >
              <FaChevronLeft className="h-5" />
            </button>
            <div>
              <h1 className="text-black text-lg font-bold font-poppins">
                Doctors in the Clinics
              </h1>
              <p className="text-zinc-500 text-xs font-normal font-poppins">
                Here are list of Doctors in the Clinics
              </p>
            </div>
          </div>
          <div className="flex flex-row items-center space-x-5">
            <div className="flex flex-row items-center space-x-2">
              <button
                onClick={() => setIsListView(false)}
                className={`w-10 h-10 rounded-full ${
                  !isListView ? "bg-sky-500" : "bg-gray-100"
                } flex items-center justify-center active:scale-95`}
              >
                <RxDashboard
                  className={`${!isListView ? "text-white" : "text-zinc-500"}`}
                />
              </button>
              <button
                onClick={() => setIsListView(true)}
                className={`w-10 h-10 rounded-full ${
                  isListView ? "bg-sky-500" : "bg-gray-100"
                } flex items-center justify-center active:scale-95`}
              >
                <TbLayoutList
                  className={`${isListView ? "text-white" : "text-zinc-500"}`}
                />
              </button>
            </div>
            <label className="w-64 flex flex-row items-center justify-start bg-gray-100 rounded-2xl py-2 px-4 gap-2">
              <IoIosSearch className="text-neutral-400 w-4 h-5" />
              <input
                type="text"
                placeholder="Search here"
                value={searchInput}
                onChange={handleSearchChange}
                className={`bg-gray-100 text-xs text-neutral-400 rounded-r-2xl focus:outline-none focus:border-transparent`}
              />
            </label>
            <button
              onClick={() => {
                navigate("/addNewDoctor");
              }}
              className="px-3 py-2 bg-sky-500 rounded shadow justify-center items-center gap-2.5 inline-flex cursor-pointer active:scale-95"
            >
              <FaPlus className="text-white text-sm" />
              <div className="text-white text-sm font-normal font-poppins">
                Add New Doctor
              </div>
            </button>
          </div>
        </div>
        {isListView ? (
          <DoctorListView doctor={currentDoctors} />
        ) : (
          <DoctorCardView doctor={currentDoctors} />
        )}
        <div
          className={`border border-b-0.5 border-gray-100 ${
            isListView ? "mt-2" : "mt-10"
          }`}
        ></div>
        <div className="flex flex-row items-center justify-between mt-4">
          <div className="text-zinc-500 text-sm font-normal font-poppins">
            Showing {currentDoctors.length} items per page
          </div>
          <div className="flex flex-row items-center space-x-3">
            <button
              className="bg-gray-100 active:bg-gray-200 w-10 h-10 rounded-full flex items-center justify-center"
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage > 1 ? prevPage - 1 : prevPage
                )
              }
            >
              <MdOutlineKeyboardArrowLeft
                className={`w-6 h-6 ${
                  currentPage > 1 ? "text-sky-400" : "text-gray-400"
                }`}
              />
            </button>
            <div className="text-center text-black text-xs font-normal font-poppins">
              {`${(currentPage - 1) * pageSize + 1} - ${Math.min(
                currentPage * pageSize,
                allDoctors.length
              )} of ${allDoctors.length}`}
            </div>
            <button
              className="bg-gray-100 active:bg-gray-200 w-10 h-10 rounded-full flex items-center justify-center"
              onClick={() =>
                setCurrentPage((prevPage) =>
                  prevPage < Math.ceil(allDoctors.length / pageSize)
                    ? prevPage + 1
                    : prevPage
                )
              }
            >
              <MdOutlineKeyboardArrowRight
                className={`w-6 h-6 ${
                  currentPage < Math.ceil(allDoctors.length / pageSize)
                    ? "text-sky-400"
                    : "text-gray-400"
                }`}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DoctorManagement;

const allDoctors = [
  {
    id: 1,
    Name: "John Doe",
    Image: profilePic,
    DID: 101,
    dateOfJoining: "15 March 2023",
    speciality: "Dermatology",
    workingHours: "40 Hours",
  },
  {
    id: 2,
    Name: "Jane Smith",
    Image: profilePic,
    DID: 102,
    dateOfJoining: "22 April 2023",
    speciality: "Pediatrics",
    workingHours: "35 Hours",
  },
  {
    id: 3,
    Name: "Emily Johnson",
    Image: profilePic,
    DID: 103,
    dateOfJoining: "10 January 2022",
    speciality: "Neurology",
    workingHours: "30 Hours",
  },
  {
    id: 4,
    Name: "Michael Brown",
    Image: profilePic,
    DID: 104,
    dateOfJoining: "5 May 2024",
    speciality: "Orthopedics",
    workingHours: "45 Hours",
  },
  {
    id: 5,
    Name: "Linda Davis",
    Image: profilePic,
    DID: 105,
    dateOfJoining: "12 July 2023",
    speciality: "Cardiology",
    workingHours: "50 Hours",
  },
  {
    id: 6,
    Name: "Robert Wilson",
    Image: profilePic,
    DID: 106,
    dateOfJoining: "20 August 2022",
    speciality: "Gastroenterology",
    workingHours: "25 Hours",
  },
  {
    id: 7,
    Name: "Patricia Martinez",
    Image: profilePic,
    DID: 107,
    dateOfJoining: "30 September 2023",
    speciality: "Oncology",
    workingHours: "20 Hours",
  },
  {
    id: 8,
    Name: "James Anderson",
    Image: profilePic,
    DID: 108,
    dateOfJoining: "18 November 2023",
    speciality: "Urology",
    workingHours: "40 Hours",
  },
  {
    id: 9,
    Name: "Mary Thomas",
    Image: profilePic,
    DID: 109,
    dateOfJoining: "6 June 2023",
    speciality: "Endocrinology",
    workingHours: "30 Hours",
  },
];
