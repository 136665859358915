import { toast } from "react-hot-toast";
import { apiConnector } from "../apiConnector";
import { masterDataEndpoints, patientManagementEndpoints } from "../apis";
import {
  setAllBrances,
  setAllBranches,
  setAllDoctors,
  setAllergyCategory,
  setAllLabPartners,
  setAllLabTypes,
  setAllNurses,
  setAllOrganizationUsers,
  setClinicCapacity,
  setLoading,
  setServiceCardRates,
  setSettings,
  setSymptomsType,
  setTelehealthPricing,
} from "../../slices/masterDataSlice";
import { getBucketURL } from "../../utils/regex";
import { getAllBookedSlots } from "./dashboardAPI";
import {
  setAllergiesType,
  setAllMedicines,
  setAllPreMedicalConditions,
} from "../../slices/patientSlice";

const {
  INVOICING,
  UPDATE_INVOICE,
  GET_ALL_SERVICES_CARD_RATE,
  ADD_SERVICES_CARD_RATE,
  UPDATE_SERVICES_CARD_RATE,
  DELETE_SERVICES_CARD_RATE,
  GET_ALL_SETTINGS,
  UPLOAD_ORGANIZATION_LOGO,
  UPDATE_SETTINGS,
  GET_ALL_INVOICES,
  GET_SPECIFIC_INVOICES,
  GET_ALL_TREATMENT_PROGRAMS,
  CREATE_NEW_HEALTH_TREATMENT_PROGRAM,
  GET_ALL_TREATMENT_PROGRAM_WEEK_DATA,
  UPDATE_HEALTH_TREATMENT_PROGRAM,
  DELETE_HEALTH_TREATMENT_PROGRAM,
  GET_ALL_DIAGNOSIS,
  GET_ALL_MEDICINES,
  GET_ALL_PRE_MEDICAL_CONDITIONS,
  ADD_NEW_PRE_MEDICAL_CONDITIONS,
  GET_ALL_ALLERGIES,
  ADD_NEW_ALLERGY,
  GET_ALL_SERVICE_RATE_ENTRY,
  CREATE_INVOICE,
  GET_ALL_NURSES,
  ADD_NURSE,
  DELETE_NURSE,
  UPDATE_NURSE,
  GET_ALL_DOCTOR,
  ADD_DOCTOR,
  DELETE_DOCTOR,
  UPDATE_DOCTOR,
  GET_CLINIC_CAPACITY,
  GET_KEY_PERFORMANCE_INDICATOR,
  GET_CUSTOM_KEY_PERFORMANCE_INDICATOR,
  GET_CLINIC_BRANCHES,
  CREATE_CLINIC_BRANCH,
  DELETE_CLINIC_BRANCH,
  GET_ALL_LAB_PARTNERS,
  DELETE_LAB_PARTNER,
  GET_LAB_TYPES,
  ADD_LAB_PARTNER,
  UPDATE_LAB_PARTNER,
  GET_ALL_ORGANIZATION_USER_DETAILS,
  UPDATE_ORGANIZATION_USER_DETAILS,
  GET_ALL_ALLERGIES_CATEGORY,
  GET_ALL_SYMPTOMS,
  SEND_INVOICE_TO_PATIENT,
  GET_ALL_DOCTOR_SPECIALITY,
  GET_TELEHEALTH_SESSION_PRICE,
  CREATE_TELEHEALTH_SESSION_PRICE,
  UPDATE_TELEHEALTH_SESSION_PRICE,
} = masterDataEndpoints;

const { ADD_NOTES, ADD_PRESCRIPTION } = patientManagementEndpoints;

// GET INVOICES
export function getInvoices(token, body) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    let result;
    try {
      const response = await apiConnector("POST", INVOICING, body, {
        Authorization: `Bearer ${token}`,
      });
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      // console.log("INVOICE API RESPONSE", response);
      result = response?.data;
    } catch (error) {
      console.log("INVOICES API ERROR :", error);
      // toast.error(
      //   error?.response?.data?.message || "ERROR WHILE FETCHING INVOICES"
      // );
    }
    dispatch(setLoading(false));
    // toast.dismiss(toastId);
    return result;
  };
}

// GET ALL SERVICE RATE ENTRY
export function getAllServiceRateEntry(token) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    let result;
    try {
      const response = await apiConnector(
        "GET",
        GET_ALL_SERVICE_RATE_ENTRY,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      result = response?.data?.AllServiceRate;
      // console.log("GET ALL SERVICE RATE ENTRY RESPONSE: ", response);
    } catch (error) {
      console.log("GET ALL SERVICE RATE ENTRY ERROR............", error);
      toast.error("Could Not GET ALL SERVICE RATE ENTRY");
    }
    dispatch(setLoading(false));
    // toast.dismiss(toastId);
    return result;
  };
}

// GET ALL INVOICES
export function getAllInvoices(token) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    let result;
    try {
      const response = await apiConnector("GET", GET_ALL_INVOICES, null, {
        Authorization: `Bearer ${token}`,
      });
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      // console.log("GET ALL INVOICES API RESPONSE", response);
      result = response?.data;
    } catch (error) {
      console.log("GET ALL INVOICES API ERROR :", error);
      toast.error(
        error?.response?.data?.message || "ERROR WHILE FETCHING INVOICES"
      );
    }
    dispatch(setLoading(false));
    // toast.dismiss(toastId);
    return result;
  };
}

// GET SPECIFIC INVOICE
export function getSpecificInvoice(token, id) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    let result;
    try {
      const response = await apiConnector(
        "GET",
        GET_SPECIFIC_INVOICES + "/" + id,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      // console.log("GET SPECIFIC INVOICE API RESPONSE", response);
      result = response?.data;
    } catch (error) {
      console.log("GET SPECIFIC INVOICE API ERROR :", error);
      toast.error(
        error?.response?.data?.message ||
          "ERROR WHILE FETCHING SPECIFIC INVOICE"
      );
    }
    dispatch(setLoading(false));
    // toast.dismiss(toastId);
    return result;
  };
}

// CREATE INVOICE
export function createInvoice(token, body, msg, navigating, navigate) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    let result;
    try {
      const response = await apiConnector("POST", CREATE_INVOICE, body, {
        Authorization: `Bearer ${token}`,
      });
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      toast.success(msg);
      result = response?.data;
    } catch (error) {
      console.log("CREATE INVOICE API ERROR :", error);
      toast.error(
        error?.response?.data?.message || "ERROR WHILE CREATING INVOICE"
      );
    }
    dispatch(setLoading(false));
    if (navigating) navigate("/invoicing");
    return result;
    // toast.dismiss(toastId);
  };
}

// UPDATE INVOICE
export function updateInvoice(token, ID, body, msg, navigating, navigate) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "PUT",
        UPDATE_INVOICE + "/" + ID,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      toast.success(msg);
    } catch (error) {
      console.log("UPDATE INVOICE API ERROR :", error);
      toast.error(
        error?.response?.data?.message || "ERROR WHILE UPDATING INVOICE"
      );
    }
    dispatch(setLoading(false));
    if (navigating) navigate("/invoicing");
    // toast.dismiss(toastId);
  };
}

// GET ALL TREATMENT PROGRAMS
export function getAllTreatmentPrograms(token) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    let result;
    try {
      const response = await apiConnector(
        "GET",
        GET_ALL_TREATMENT_PROGRAMS,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      result = response?.data?.TreatmentProgram;
      // console.log("GET ALL TREATMENT PROGRAM RESPONSE: ", response);
    } catch (error) {
      console.log("GET ALL TREATMENT PROGRAM ERROR............", error);
      toast.error("Could Not Get Treatment Program Data");
    }
    dispatch(setLoading(false));
    // toast.dismiss(toastId);
    return result;
  };
}

// GET ALL TREATMENT PROGRAM WEEK DATA
export function getAllTreatmentProgramWeekData(token) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    let result;
    try {
      const response = await apiConnector(
        "GET",
        GET_ALL_TREATMENT_PROGRAM_WEEK_DATA,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      result = response?.data?.AllProgramWeeks;
      // console.log("GET ALL TREATMENT PROGRAM WEEK DATA RESPONSE: ", response);
    } catch (error) {
      console.log(
        "GET ALL TREATMENT PROGRAM WEEK DATA ERROR............",
        error
      );
      toast.error("Could Not Get Treatment Program Week Data");
    }
    dispatch(setLoading(false));
    // toast.dismiss(toastId);
    return result;
  };
}

// CREATE NEW HEALTH TREATMENT PROGRAM
export function createTreatmentProgram(token, body) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "POST",
        CREATE_NEW_HEALTH_TREATMENT_PROGRAM,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (!response?.status === 200) {
        throw new Error(response?.data?.msg);
      }
      // console.log("CREATE NEW HEALTH TREATMENT PROGRAM API RESPONSE", response);
      toast.success(response?.data?.msg);
    } catch (error) {
      console.log("CREATE NEW HEALTH TREATMENT PROGRAM API ERROR :", error);
      toast.error(
        error?.response?.data?.msg ||
          "ERROR WHILE CREATING NEW HEALTH TREATMENT PROGRAM"
      );
    }
    dispatch(setLoading(false));
    // toast.dismiss(toastId);
  };
}

// UPDATE NEW HEALTH TREATMENT PROGRAM
export function updateTreatmentProgram(token, ID, body) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "PUT",
        UPDATE_HEALTH_TREATMENT_PROGRAM + "/" + ID,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      // console.log("UPDATE HEALTH TREATMENT PROGRAM API RESPONSE", response);
      toast.success(response?.data?.message);
    } catch (error) {
      console.log("UPDATE HEALTH TREATMENT PROGRAM API ERROR :", error);
      toast.error(
        error?.response?.data?.message ||
          "ERROR WHILE UPDATING HEALTH TREATMENT PROGRAM"
      );
    }
    dispatch(setLoading(false));
    // toast.dismiss(toastId);
  };
}

// DELETE HEALTH TREATMENT PROGRAM
export function deleteTreatmentProgram(token, ID, navigate) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "DELETE",
        DELETE_HEALTH_TREATMENT_PROGRAM + "/" + ID,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      // console.log("DELETE HEALTH TREATMENT PROGRAM API RESPONSE", response);
      toast.success(response?.data?.message);
    } catch (error) {
      console.log("DELETE HEALTH TREATMENT PROGRAM API ERROR :", error);
      toast.error(
        error?.response?.data?.message ||
          "ERROR WHILE DELETING HEALTH TREATMENT PROGRAM"
      );
    }
    dispatch(setLoading(false));
    navigate("/treatmentProgram");
    // toast.dismiss(toastId);
  };
}

// GET ALL DIAGNOSIS
export function getAllDiagnosis(token) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    let result;
    try {
      const response = await apiConnector("GET", GET_ALL_DIAGNOSIS, null, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      // console.log("GET_ALL_DIAGNOSIS RESPONSE............", response);
      result = response?.data;
    } catch (error) {
      console.log("GET_ALL_DIAGNOSIS ERROR............", error);
      toast.error("Could Not Get Diagnosis Data");
    }
    dispatch(setLoading(false));
    // toast.dismiss(toastId);
    return result;
  };
}

// GET ALL MEDICINES
export function getAllMedicines(token) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("GET", GET_ALL_MEDICINES, null, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      // console.log("GET_ALL_MEDICINES RESPONSE............", response);
      dispatch(setAllMedicines(response?.data?.Medicines));
    } catch (error) {
      console.log("GET_ALL_MEDICINES ERROR............", error);
      // toast.error("Could Not Get Medicines");
    } finally {
      dispatch(setLoading(false));
    }
  };
}
//GET ALL PRE MEDICAL CONDITIONS
export function getAllPreMedicalConditions(token) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "GET",
        GET_ALL_PRE_MEDICAL_CONDITIONS,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      // console.log("GET_ALL_MEDICINES RESPONSE............", response);
      dispatch(
        setAllPreMedicalConditions(response?.data?.premedicalConditions)
      );
    } catch (error) {
      console.log("GET_ALL_MEDICINES ERROR............", error);
      // toast.error("Could Not Get Medicines");
    } finally {
      dispatch(setLoading(false));
    }
  };
}

// ADD NEW PRE MEDICAL CONDITION
export function addNewPreMedicalCondition(token, body) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "POST",
        ADD_NEW_PRE_MEDICAL_CONDITIONS,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      toast.success("Added Successfully");
      dispatch(getAllPreMedicalConditions(token));
    } catch (error) {
      console.log("ADD_NEW_PRE_MEDICAL_CONDITIONS API ERROR :", error);
      toast.error(error?.response?.data?.message || "Error While Adding ");
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

//GET ALL ALLERGIES
export function getAllAllergies(token) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("GET", GET_ALL_ALLERGIES, null, {
        Authorization: `Bearer ${token}`,
      });
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      console.log("GET_ALL_ALLERGIES RESPONSE............", response);
      dispatch(setAllergiesType(response?.data?.AllergiesData));
    } catch (error) {
      console.log("GET_ALL_ALLERGIES ERROR............", error);
      // toast.error("Could Not Get Allergies");
    } finally {
      dispatch(setLoading(false));
    }
  };
}

// ADD NEW ALLERGY
export function addNewAllergy(token, body) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", ADD_NEW_ALLERGY, body, {
        Authorization: `Bearer ${token}`,
      });
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      toast.success("Added Successfully");
      dispatch(getAllAllergies(token));
    } catch (error) {
      console.log("ADD_NEW_ALLERGY API ERROR :", error);
      toast.error(error?.response?.data?.message || "Error While Adding ");
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

//ADD NOTES TO PATIENT PROFILE
export function addNotesToPatientProfile1(token, ID, notesBody, body) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "PUT",
        ADD_NOTES + "/" + ID,
        notesBody,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      // console.log("ADD_NOTES RESPONSE............", response);
      if (body) dispatch(getAllBookedSlots(token, body));
    } catch (error) {
      console.log("ADD_NOTES ERROR............", error);
      toast.error("Could Not Add Notes");
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

//ADD PRESCRIPTION
export function addPrescription1(token, body) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", ADD_PRESCRIPTION, body, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      // console.log("ADD_PRESCRIPTION RESPONSE............", response);
      toast.success("Data Added Successfully");
    } catch (error) {
      console.log("ADD_PRESCRIPTION ERROR............", error);
      toast.error("Could Not Add Prescription");
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

//GET ALL SERVICES CARD RATE
export function getAllServicesCardRate(token) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "GET",
        GET_ALL_SERVICES_CARD_RATE,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      const reversedData = response?.data?.AllServiceRate.reverse();
      dispatch(setServiceCardRates(reversedData));
    } catch (error) {
      console.log("GET_ALL_SERVICES_CARD_RATE API ERROR............", error);
      // toast.error("Unable to fetch service card rate");
    }
    dispatch(setLoading(false));
  };
}

//ADD SERVICES CARD RATE
export function addServicesCardRate(token, body) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "POST",
        ADD_SERVICES_CARD_RATE,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      // console.log("ADD SERVICES CARD RATE API RESPONSE", response);
      toast.success("Service Card Rate Added Successfully");
      dispatch(getAllServicesCardRate(token));
    } catch (error) {
      console.log("ADD SERVICES CARD RATE API ERROR :", error);
      toast.error(
        error?.response?.data?.message || "Error While Adding Service Card Rate"
      );
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

//UPADTE SERVICES CARD RATE
export function updateServicesCardRate(token, ID, body) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "PUT",
        UPDATE_SERVICES_CARD_RATE + "/" + ID,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      // console.log("UPDATE SERVICES CARD RATE API RESPONSE", response);
      toast.success("Updated Successfully");
      dispatch(getAllServicesCardRate(token));
    } catch (error) {
      console.log("UPDATE SERVICES CARD RATE API ERROR :", error);
      toast.error(
        error?.response?.data?.message ||
          "Error While Updating Service Card Rate"
      );
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

//DELETE SERVICES CARD RATE
export function deleteServicesCardRate(token, ID) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "DELETE",
        DELETE_SERVICES_CARD_RATE + "/" + ID,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      // console.log("DELETE SERVICES CARD RATE API RESPONSE", response);
      toast.success("Deleted Successfully");
      dispatch(getAllServicesCardRate(token));
    } catch (error) {
      console.log("DELETE SERVICES CARD RATE API ERROR :", error);
      toast.error(
        error?.response?.data?.message ||
          "Error While Deleting Service Card Rate"
      );
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

//GET ALL SETTINGS
export function getAllSettings(token) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("GET", GET_ALL_SETTINGS, null, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      // console.log("GET_ALL_SETTINGS API RESPONSE............", response);
      if (response.data && response.data.Setting) {
        const orgLogo = await getBucketURL(
          response?.data?.Setting?.Profile_Image
        );
        response.data.Setting.OrgImage = orgLogo;
      }
      dispatch(setSettings(response?.data?.Setting));
    } catch (error) {
      console.log("GET_ALL_SETTINGS API ERROR............", error);
      // toast.error(error?.response?.data?.message || "Unable to fetch settings");
    }
    dispatch(setLoading(false));
  };
}

//UPLOAD ORGANIZATION LOGO
export async function uploadOrganizationLogo(token, formData) {
  const toastId = toast.loading("Loading...");
  let result = [];
  try {
    const response = await apiConnector(
      "POST",
      UPLOAD_ORGANIZATION_LOGO,
      formData,
      {
        Authorization: `Bearer ${token}`,
      }
    );
    // console.log("UPLOAD IMAGE API RESPONSE", response);
    result = response?.data;
    if (response.status !== 200) {
      throw new Error(response.data.message);
    }
  } catch (error) {
    console.error("UPLOAD IMAGE API ERROR:", error);
    toast.error("Oops! Something went wrong. Please try again.");
  }
  toast.dismiss(toastId);
  return result;
}

//UPDATE SETTINGS
export function updateSettings(token, body, ID) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "PUT",
        UPDATE_SETTINGS + "/" + ID,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      // console.log("UPDATE_SETTINGS API RESPONSE", response);
      toast.success("Settings Updated Successfully");
      dispatch(getAllSettings(token));
    } catch (error) {
      console.log("UPDATE_SETTINGS ERROR :", error);
      toast.error(
        error?.response?.data?.message || "Error while updating settings"
      );
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

//GET ALL NURSES
export function getAllNurses(token) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("GET", GET_ALL_NURSES, null, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      console.log("====================================");
      console.log("GET_ALL_NURSES API RESPONSE", response);
      console.log("====================================");
      const nursesWithImages = await Promise.all(
        response?.data?.Nurses.map(async (nurse) => {
          let Image = null;
          if (nurse?.ProfileImageUrl) {
            Image = await getBucketURL(nurse?.ProfileImageUrl);
          }
          return { ...nurse, Image }; // Correctly spread the nurse object and add the Image property
        })
      );

      console.log("GET_ALL_NURSES API RESPONSE............", response);
      dispatch(setAllNurses(nursesWithImages));
    } catch (error) {
      console.log("GET_ALL_NURSES API ERROR............", error);
      // toast.error(error?.response?.data?.message || "Unable to fetch nurses");
    }
    dispatch(setLoading(false));
  };
}

//ADD NEW NURSE
export function addNewNurse(token, body, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", ADD_NURSE, body, {
        Authorization: `Bearer ${token}`,
      });
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      console.log("ADD NEW NURSE API RESPONSE", response);
      toast.success("Nurse Added Successfully");
      dispatch(getAllNurses(token));
      navigate(-1);
    } catch (error) {
      console.log("ADD NEW NURSE API ERROR :", error);
      toast.error(
        error?.response?.data?.message || "Error While Adding New Nurse"
      );
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

//DELETE NURSE
export function deleteNurse(token, ID) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "DELETE",
        DELETE_NURSE + "/" + ID,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      console.log("DELETE NURSE API RESPONSE", response);
      toast.success("Nurse Deleted Successfully");
      dispatch(getAllNurses(token));
    } catch (error) {
      console.log("DELETE NURSE API ERROR :", error);
      toast.error(
        error?.response?.data?.message || "Error While Deleting Nurse"
      );
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

//UPDATE NURSE
export function updateNurse(token, ID, body, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "PUT",
        UPDATE_NURSE + "/" + ID,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      console.log("UPDATE NURSE API RESPONSE", response);
      toast.success("Nurse Updated Successfully");
      dispatch(getAllNurses(token));
      navigate(-1);
    } catch (error) {
      console.log("UPDATE NURSE API ERROR :", error);
      toast.error(
        error?.response?.data?.message || "Error While Updating Nurse"
      );
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

//GET ALL DOCTOR
export function getAllDoctors(token) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("GET", GET_ALL_DOCTOR, null, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      const doctorsWithImages = await Promise.all(
        response?.data?.Doctors.map(async (doctor) => {
          let Image = null;
          if (doctor?.ProfileImageUrl) {
            Image = await getBucketURL(doctor?.ProfileImageUrl);
          }
          return { ...doctor, Image }; // Correctly spread the doctor object and add the Image property
        })
      );

      console.log("GET_ALL_DOCTORS API RESPONSE............", response);
      dispatch(setAllDoctors(doctorsWithImages));
    } catch (error) {
      console.log("GET_ALL_DOCTORS API ERROR............", error);
      // toast.error(error?.response?.data?.message || "Unable to fetch doctors");
    }
    dispatch(setLoading(false));
  };
}

//ADD NEW DOCTOR
export function addNewDoctor(token, body, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", ADD_DOCTOR, body, {
        Authorization: `Bearer ${token}`,
      });
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      console.log("ADD NEW DOCTOR API RESPONSE", response);
      toast.success("Doctor Added Successfully");
      dispatch(getAllDoctors(token));
      navigate(-1);
    } catch (error) {
      console.log("ADD NEW DOCTOR API ERROR :", error);
      toast.error(
        error?.response?.data?.message ||
          error?.response?.data?.msg ||
          "Error While Adding New Doctor"
      );
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

//DELETE DOCTOR
export function deleteDoctor(token, ID) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "DELETE",
        DELETE_DOCTOR + "/" + ID,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      console.log("DELETE DOCTOR API RESPONSE", response);
      toast.success("Doctor Deleted Successfully");
      dispatch(getAllDoctors(token));
    } catch (error) {
      console.log("DELETE DOCTOR API ERROR :", error);
      toast.error(
        error?.response?.data?.message ||
          error?.response?.data?.msg ||
          "Error While Deleting Doctor"
      );
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

//UPDATE DOCTOR
export function updateDoctor(token, ID, body, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "PUT",
        UPDATE_DOCTOR + "/" + ID,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      console.log("UPDATE DOCTOR API RESPONSE", response);
      toast.success("Doctor Updated Successfully");
      dispatch(getAllDoctors(token));
      navigate(-1);
    } catch (error) {
      console.log("UPDATE DOCTOR API ERROR :", error);
      toast.error(
        error?.response?.data?.message || "Error While Updating Doctor"
      );
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

//GET CLINIC CAPACITY
export function getClinicCapacity(token) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("GET", GET_CLINIC_CAPACITY, null, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      console.log("GET_CLINIC_CAPACITY API RESPONSE............", response);
      if (response?.data) {
        dispatch(setClinicCapacity(response?.data));
      }
    } catch (error) {
      console.log("GET_CLINIC_CAPACITY API ERROR............", error);
      toast.error(
        error?.response?.data?.message || "Unable to fetch clinic capacity"
      );
    }
    dispatch(setLoading(false));
  };
}

//GET KEY PERFORMANCE INDICATOR
export function getKeyPerformanceIndicator(token) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    let result;
    try {
      const response = await apiConnector(
        "GET",
        GET_KEY_PERFORMANCE_INDICATOR,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      console.log(
        "KEY_PERFORMANCE_INDICATOR API RESPONSE............",
        response
      );
      if (response?.data) {
        result = response.data;
      }
    } catch (error) {
      console.log("KEY_PERFORMANCE_INDICATOR API ERROR............", error);
      // toast.error(
      //   error?.response?.data?.message || "Unable to KEY PERFORMANCE INDICATOR"
      // );
    }
    dispatch(setLoading(false));
    return result;
  };
}

//GET CUSTOM KEY PERFORMANCE INDICATOR
export function getCustomKeyPerformanceIndicator(token, body) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    let result;
    try {
      let url = GET_CUSTOM_KEY_PERFORMANCE_INDICATOR;
      if (body) {
        const params = new URLSearchParams();
        if (body.startDate) params.append("DateFrom", body.startDate);
        if (body.endDate) params.append("DateUpto", body.endDate);
        url += `?${params.toString()}`;
      }
      const response = await apiConnector("GET", url, null, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      console.log(
        "CUSTOM_KEY_PERFORMANCE_INDICATOR API RESPONSE............",
        response
      );
      if (response?.data) {
        result = response.data;
      }
    } catch (error) {
      console.log(
        "CUSTOM_KEY_PERFORMANCE_INDICATOR API ERROR............",
        error
      );
      toast.error(
        error?.response?.data?.message ||
          "Unable to fetch CUSTOM KEY PERFORMANCE INDICATOR"
      );
    }
    dispatch(setLoading(false));
    return result;
  };
}

//GET CLINIC BRANCES
export function getClinicBranches(token) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("GET", GET_CLINIC_BRANCHES, null, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      console.log(
        "GET_CLINIC_BRANCHES API RESPONSE............",
        response?.data?.data
      );
      dispatch(setAllBranches(response?.data?.data || []));
    } catch (error) {
      console.log("GET_CLINIC_BRANCHES API ERROR............", error);
      // toast.error(error?.response?.data?.message || "Unable to fetch branches");
    }
    dispatch(setLoading(false));
  };
}

// CREATE BRANCH
export function createClinicBranch(token, body, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", CREATE_CLINIC_BRANCH, body, {
        Authorization: `Bearer ${token}`,
      });
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      console.log("ADD NEW BRANCH API RESPONSE", response);
      toast.success("Branch Added Successfully");
      dispatch(getClinicBranches(token));
    } catch (error) {
      console.log("ADD NEW BRANCH API ERROR :", error);
      toast.error(
        error?.response?.data?.message ||
          error?.response?.data?.msg ||
          "Error While Adding New Branch"
      );
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// DELETE CLINIC BRANCHES
export function deleteClinicBranches(token, ID) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "DELETE",
        DELETE_CLINIC_BRANCH + "/" + ID,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      toast.success("Deleted Successfully");
      dispatch(getClinicBranches(token));
    } catch (error) {
      console.log("DELETE BRANCH API ERROR :", error);
      toast.error(
        error?.response?.data?.message || "Error While Deleting Branches"
      );
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// GET ALL LAB PARTNERS
export function getAllLabPartners(token) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("GET", GET_ALL_LAB_PARTNERS, null, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      console.log("GET_ALL_LAB_PARTNERS API RESPONSE............", response);
      dispatch(setAllLabPartners(response?.data?.data || []));
    } catch (error) {
      dispatch(setAllLabPartners([]));
      console.log("GET_ALL_LAB_PARTNERS API ERROR............", error);
      // toast.error(error?.response?.data?.message || "Unable to fetch lab partners");
    }
    dispatch(setLoading(false));
  };
}

// GET LAB TYPES
export function getLabTypes(token) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("GET", GET_LAB_TYPES, null, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      dispatch(setAllLabTypes(response?.data?.AlllabTypes || []));
    } catch (error) {
      console.log("GET_LAB_TYPES API ERROR............", error);
      // toast.error(error?.response?.data?.message || "Unable to fetch lab types");
    }
    dispatch(setLoading(false));
  };
}

// DELETE LAB PARTNERS
export function deleteLabPartners(token, ID) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "DELETE",
        DELETE_LAB_PARTNER + "/" + ID,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      toast.success("Deleted Successfully");
      dispatch(getAllLabPartners(token));
    } catch (error) {
      console.log("DELETE BRANCH API ERROR :", error);
      toast.error(
        error?.response?.data?.message || "Error While Deleting Lab Partners"
      );
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// ADD LAB PARTNERS
export function addLabPartners(token, body, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", ADD_LAB_PARTNER, body, {
        Authorization: `Bearer ${token}`,
      });
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      console.log("ADD NEW LAB PARTNER API RESPONSE", response);
      toast.success("Lab Partner Added Successfully");
      dispatch(getAllLabPartners(token));
      navigate(-1);
    } catch (error) {
      console.log("ADD NEW LAB PARTNER API ERROR :", error);
      toast.error(
        error?.response?.data?.message ||
          error?.response?.data?.msg ||
          "Error While Adding New Lab Partner"
      );
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// UPDATE LAB PARTNERS
export function updateLabPartners(token, ID, body, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "PUT",
        UPDATE_LAB_PARTNER + "/" + ID,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      console.log("UPDATE LAB PARTNER API RESPONSE", response);
      toast.success("Lab Partner Updated Successfully");
      dispatch(getAllLabPartners(token));
      navigate(-1);
    } catch (error) {
      console.log("UPDATE LAB PARTNER API ERROR :", error);
      toast.error(
        error?.response?.data?.message || "Error While Updating Lab Partner"
      );
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// GET ALL ORGANIZATION USER DETAILS
export function getAllOrganizationUserDetails(token) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "GET",
        GET_ALL_ORGANIZATION_USER_DETAILS,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      console.log(
        "GET_ORGANIZATION_USER_DETAILS API RESPONSE............",
        response
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      const updatedUsers = await Promise.all(
        response?.data?.Users.map(async (user) => {
          let Image = null;
          if (user?.ProfileImageUrl) {
            Image = await getBucketURL(user?.ProfileImageUrl);
          }
          return { ...user, Image };
        })
      );
      dispatch(setAllOrganizationUsers(updatedUsers || []));
    } catch (error) {
      console.log("GET_ORGANIZATION_USER_DETAILS API ERROR............", error);
      // toast.error(error?.response?.data?.message || "Unable to fetch organization user details");
    }
    dispatch(setLoading(false));
  };
}

// UPDATE ORGANIZATION USER DETAILS
export function updateOrganizationUserDetails(token, ID, body) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "PUT",
        UPDATE_ORGANIZATION_USER_DETAILS + "/" + ID,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      console.log("UPDATE ORGANIZATION USER DETAILS API RESPONSE", response);
      toast.success("Updated Successfully");
      dispatch(getAllOrganizationUserDetails(token));
    } catch (error) {
      console.log("UPDATE ORGANIZATION USER DETAILS API ERROR :", error);
      toast.error(
        error?.response?.data?.message || "Error While Updating User Details"
      );
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// DELETE ORGANIZATION USER DETAILS
// export function deleteOrganizationUserDetails(token, ID) {
//   return async (dispatch) => {
//     const toastId = toast.loading("Loading...");
//     // dispatch(setLoading(true));
//     try {
//       const response = await apiConnector(
//         "DELETE",
//         DELETE_ORGANIZATION_USER_DETAILS + "/" + ID,
//         null,
//         {
//           Authorization: `Bearer ${token}`,
//         }
//       );
//       if (!response?.status === 200) {
//         throw new Error(response?.data?.message);
//       }
//       console.log("DELETE ORGANIZATION USER DETAILS API RESPONSE", response);
//       toast.success("Deleted Successfully");
//       dispatch(getOrganizationUserDetails(token));
//     } catch (error) {
//       console.log("DELETE ORGANIZATION USER DETAILS API ERROR :", error);
//       toast.error(
//         error?.response?.data?.message || "Error While Deleting User Details"
//       );
//     }
//     // dispatch(setLoading(false));
//     toast.dismiss(toastId);
//   };
// }

// GET ALLERGY CATEGORY
export function getAllAllergyCategory(token) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "GET",
        GET_ALL_ALLERGIES_CATEGORY,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      dispatch(setAllergyCategory(response?.data?.AllergyCategories));
    } catch (error) {
      console.log("GET_ALL_ALLERGIES_CATEGORY ERROR............", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
}

// GET ALL SYMPTOMS
export function getAllSymptomsType(token) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("GET", GET_ALL_SYMPTOMS, null, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      console.log("GET_ALL_SYMPTOMS RESPONSE............", response);
      dispatch(setSymptomsType(response?.data?.PatientSymptomsData));
    } catch (error) {
      console.log("GET_ALL_SYMPTOMS ERROR............", error);
    } finally {
      dispatch(setLoading(false));
    }
  };
}

// SEND PATIENT TO PATIENT
export function sendInvoice(token, body, navigate) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", SEND_INVOICE_TO_PATIENT, body, {
        Authorization: `Bearer ${token}`,
      });
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      console.log("SEND INVOICE API RESPONSE", response);
    } catch (error) {
      console.log("SEND INVOICE API ERROR :", error);
      toast.error(
        error?.response?.data?.message || "ERROR WHILE SENDING INVOICE"
      );
    }
    dispatch(setLoading(false));
    navigate("/invoicing");
  };
}

// GET ALL DOCTOR SPECIALITY
export function getAllDoctorSpeciality(token) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    let result = [];
    try {
      const response = await apiConnector(
        "GET",
        GET_ALL_DOCTOR_SPECIALITY,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      console.log(
        "GET_ALL_DOCTOR_SPECIALITY API RESPONSE............",
        response
      );
      result = response?.data?.Speciality;
    } catch (error) {
      console.log("GET_ALL_DOCTOR_SPECIALITY API ERROR............", error);
    }
    dispatch(setLoading(false));
    return result;
  };
}

// GET TELEHEALTH SESSION PRICE
export function getTelehealthSessionPrice(token) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    let result = [];
    try {
      const response = await apiConnector(
        "GET",
        GET_TELEHEALTH_SESSION_PRICE,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      console.log(
        "GET_TELEHEALTH_SESSION_PRICE API RESPONSE............",
        response?.data?.TeleHealthPrice
      );
      result = response?.data?.TeleHealthPrice;
      dispatch(setTelehealthPricing(result));
    } catch (error) {
      console.log("GET_TELEHEALTH_SESSION_PRICE API ERROR............", error);
    }
    dispatch(setLoading(false));
    return result;
  };
}

// UPDATE TELEHEALTH SESSION PRICE
export function updateTelehealthSessionPrice(token, body, navigate) {
  return async (dispatch) => {
    // const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "PUT",
        UPDATE_TELEHEALTH_SESSION_PRICE,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      getTelehealthSessionPrice(token);
    } catch (error) {
      console.log("UPDATE TELEHEALTH SESSION PRICE API ERROR :", error);
      toast.error(
        error?.response?.data?.message || "ERROR WHILE UPDATING TELEHEALTH SESSION PRICE"
      );
    }
    dispatch(setLoading(false));
    // toast.dismiss(toastId);
  };
}