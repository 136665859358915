import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ChartComponent = ({ salesData }) => {
    const salesCounts = salesData?.monthlySales.map((monthData) => monthData.salesCount) || [];
    const data = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [
            {
                label: 'Sales',
                data: salesCounts,
                backgroundColor: '#9DDF52',
                borderColor: '#9DDF52',
                borderWidth: 1,
                borderRadius: 5,
                barThickness: 45,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false, // Ensure the chart uses the defined height
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                callbacks: {
                    label: (context) => `${context.raw}%`, // Show percentage on tooltip
                },
            },
        },
        scales: {
            y: {
                beginAtZero: true,
                max: 100,
                ticks: {
                    stepSize: 25,
                    callback: (value) => `${value}%`, // Show percentages on the y-axis
                },
                grid: {
                    drawBorder: false,
                },
            },
            x: {
                grid: {
                    drawBorder: false,
                    drawOnChartArea: false,
                    color: '#000000',
                    drawTicks: true, 
                },
                ticks: {
                    color: '#000000',
                    padding: 10,
                    align: 'center',
                    display: true,
                },
                border: {
                    color: '#000000',
                    width: 1,
                },
            },
        },
    };

    return (
        <div className="w-[98%]" style={{ height: '210px' }}>
            <Bar data={data} options={options} />
        </div>
    );
};

export default ChartComponent;
