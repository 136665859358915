import React, { useState, useEffect } from "react";

function Pagination({ totalItems, itemsPerPage, currentPage, onPageChange }) {
  const [pageNumbers, setPageNumbers] = useState([]);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  useEffect(() => {
    const calculatePageNumbers = () => {
      let numbers = [];
      const maxVisiblePages = 5;

      if (totalPages <= maxVisiblePages) {
        numbers = Array.from({ length: totalPages }, (_, i) => i + 1);
      } else {
        let start = Math.max(1, currentPage - 2);
        let end = Math.min(totalPages, start + maxVisiblePages - 1);

        if (end - start < maxVisiblePages - 1) {
          start = Math.max(1, end - maxVisiblePages + 1);
        }

        numbers = Array.from({ length: end - start + 1 }, (_, i) => start + i);

        if (start > 1) {
          numbers = [1, "...", ...numbers];
        }

        if (end < totalPages) {
          numbers = [...numbers, "...", totalPages];
        }
      }

      setPageNumbers(numbers);
    };

    calculatePageNumbers();
  }, [currentPage, totalPages]);

  return (
    <div className="w-full mx-auto mt-4 px-6 py-3 shadow rounded-md border-t-2 border-gray-200">
      <div className="flex flex-row items-center justify-between">
        <div className="text-slate-700 text-sm font-normal font-poppins leading-tight">
          Showing {(currentPage - 1) * itemsPerPage + 1} to{" "}
          {Math.min(currentPage * itemsPerPage, totalItems)} out of {totalItems}{" "}
          results
        </div>
        {totalPages > 1 && (
          <div className="flex flex-row items-center">
            <button
              onClick={() => onPageChange(Math.max(1, currentPage - 1))}
              disabled={currentPage === 1}
              className={`px-4 py-2 bg-white rounded-tl-lg rounded-bl-lg border border-neutral-300 flex flex-row items-center ${
                currentPage > 1 ? "active:scale-95" : "cursor-not-allowed"
              } `}
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mr-1"
              >
                <path
                  d="M15 6C15 6 9 10.4189 9 12C9 13.5812 15 18 15 18"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              <div className="text-zinc-900 text-sm font-medium font-poppins leading-tight">
                Previous
              </div>
            </button>
            {pageNumbers?.map((number, index) => (
              <button
                key={index}
                onClick={() =>
                  typeof number === "number" && onPageChange(number)
                }
                className={`px-4 py-2 border-t border-b border-neutral-300 text-sm font-medium font-poppins leading-tight ${
                  number === currentPage
                    ? "bg-sky-100 text-sky-400"
                    : "text-zinc-900 hover:bg-gray-100"
                } ${index !== 0 ? "border-l" : ""}`}
              >
                {number}
              </button>
            ))}
            <button
              onClick={() =>
                onPageChange(Math.min(totalPages, currentPage + 1))
              }
              disabled={currentPage === totalPages}
              className={`px-4 py-2 bg-white rounded-tr-lg rounded-br-lg border border-neutral-300 flex flex-row items-center ${
                currentPage < totalPages
                  ? "active:scale-95"
                  : "cursor-not-allowed"
              } `}
            >
              <div className="text-zinc-900 text-sm font-medium font-poppins leading-tight">
                Next
              </div>
              <svg
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="ml-1"
              >
                <path
                  d="M9.00005 6C9.00005 6 15 10.4189 15 12C15 13.5812 9 18 9 18"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default Pagination;
