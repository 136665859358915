import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaPlus, FaMinus, FaChevronLeft, FaHeartPulse } from "react-icons/fa6";
import Vertical_switch_light from "../../assets/Images/TreatmentProgram/Vertical_switch_light.png";
import Tile1 from "../../assets/Images/RewardManagement/Tile1.png";
import offlineIcon from "../../assets/Images/RewardManagement/offlineIcon.png";
import onlineIcon from "../../assets/Images/RewardManagement/onlineIcon.png";
import deactivateStatus from "../../assets/Images/RewardManagement/deactivateStatus.png";
import activateStatus from "../../assets/Images/RewardManagement/activateStatus.png";
import labTestIcon from "../../assets/Images/RewardManagement/labTestIcon.png";
import treatmentIcon from "../../assets/Images/RewardManagement/treatmentIcon.png";
import merchandiseIcon from "../../assets/Images/RewardManagement/merchandiseIcon.png";
import noSalesData from "../../assets/Images/RewardManagement/noSalesData.png";
import noCustomerList from "../../assets/Images/RewardManagement/noCustomerList.png";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import AHlogo from "../../assets/Images/logo.png";
import Breadcrumb from "../../components/Breadcrumb";
import qrCodeImg from "../../assets/Images/Invoicing/qrCodeImg.png";
import { IoIosSearch } from "react-icons/io";
import { HiOutlineDotsVertical } from "react-icons/hi";
import ModalComponent from "../../components/Modal";
import imageCompression from "browser-image-compression";
import toast from "react-hot-toast";
import Tooltip from "../../components/Tooltip";
import { FaCheckCircle } from "react-icons/fa";
import SalesDetailChart from "../../components/Chart/SalesDetailChart";
import { FaCheckSquare } from "react-icons/fa";
import { getRewardCustomerList, getRewardSalesReport, updateRewardCustomerList } from "../../services/operations/rewardManagementAPI";
import alertIcon from "../../assets/Images/RewardManagement/alertIcon.png";

function SalesDetail() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { token } = useSelector((state) => state.auth);
    const reward = location.state.item;
    const [isYearDropdownOpen, setIsYearDropdownOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState('ALL');
    const [isOpen, setIsOpen] = useState(true);
    const [yearSelected, setYearSelected] = useState(moment().year());
    const [rewardSalesReport, setRewardSalesReport] = useState([]);
    const [rewardCustomerList, setRewardCustomerList] = useState([]);
    const [filteredCustomerList, setFilteredCustomerList] = useState([]);
    const [selectedMonth, setSelectedMonth] = useState(moment().month());
    const [selectedYear, setSelectedYear] = useState(moment().year());

    const toggleCart = () => {
        setIsOpen(!isOpen);
    };

    // Function to handle year dropdown change
    const handleYearChange = (yearOption) => {
        if (yearOption === 'This Year') {
            setYearSelected(moment().year());
        } else if (yearOption === 'Past Year') {
            setYearSelected(moment().year() - 1);
        }
        setIsYearDropdownOpen(false); // Close the dropdown after selection
    };

    // Function to toggle the month (left arrow: decrease, right arrow: increase)
    const handleMonthChange = (direction) => {
        if (direction === 'decrease') {
            if (selectedMonth === 0) {
                setSelectedMonth(11);
                setSelectedYear(prevYear => prevYear - 1);
            } else {
                setSelectedMonth(prevMonth => prevMonth - 1);
            }
        } else if (direction === 'increase') {
            if (selectedMonth === 11) {
                setSelectedMonth(0);
                setSelectedYear(prevYear => prevYear + 1);
            } else {
                setSelectedMonth(prevMonth => prevMonth + 1);
            }
        }
    };

    // Handle filter change
    const handleFilterChange = (filter) => {
        setSelectedFilter(filter.toUpperCase());
    };

    // Function to mark as complete
    const handleMarkAsComplete = async (RSID) => {
        let body = {
            SalesStatus: "Completed",
        }
        try {
            dispatch(updateRewardCustomerList(token, RSID, body, navigate));
        } catch (error) {
            console.log("Error marking as complete", error);
        }
    };

    // Filter logic
    useEffect(() => {
        // Filter by sales status first
        let filteredList = [];
        if (selectedFilter === 'ALL') {
            filteredList = rewardCustomerList?.rewardCustomerList || [];
        } else if (selectedFilter === 'COMPLETED') {
            filteredList = rewardCustomerList?.rewardCustomerList?.filter(cus => cus?.SalesStatus === 'Completed') || [];
        } else if (selectedFilter === 'PENDING') {
            filteredList = rewardCustomerList?.rewardCustomerList?.filter(cus => cus?.SalesStatus === 'Mark As Complete') || [];
        }

        // Filter by selected month and year
        filteredList = filteredList?.filter(cus => {
            const customerMonthYear = moment(cus.MonthYear, 'MMMM YYYY'); // Assuming 'MonthYear' format is like 'September 2024'
            return customerMonthYear.month() === selectedMonth && customerMonthYear.year() === selectedYear;
        });

        setFilteredCustomerList(filteredList);
    }, [selectedFilter, selectedMonth, selectedYear, rewardCustomerList]);

    useEffect(() => {
        const fetchSalesReport = async () => {
            try {
                const res = await getRewardSalesReport(token, yearSelected);
                setRewardSalesReport(res);
            } catch (error) {
                setRewardSalesReport([]);
                console.error("Failed to fetch Reward Sales Report", error);
            } finally {
                setIsLoading(false);
            }
        };

        if (reward?.RewardID && yearSelected) {
            setIsLoading(true);
            fetchSalesReport();
        }
    }, [reward?.RewardID, yearSelected, token]);

    // Fetch Customer List when rewardID is available
    useEffect(() => {
        const fetchCustomerList = async () => {
            try {
                const res = await getRewardCustomerList(token, reward?.RewardID);
                setRewardCustomerList(res);
                setFilteredCustomerList(res?.rewardCustomerList);
            } catch (error) {
                setRewardCustomerList([]);
                setFilteredCustomerList([]);
                console.error("Failed to fetch Reward Customer List", error);
            } finally{ 
                setIsLoading(false);
            }
        };

        if (reward?.RewardID) {
            setIsLoading(true);
            fetchCustomerList();
        } else {
            console.error("Failed to fetch Customer List");
        }
    }, [reward?.RewardID, token]);

    if (isLoading) {
        return (
            <div className="h-screen flex items-center justify-center">
                <div className="spinner"></div>
            </div>
        );
    }

    return (
        <div className="bg-white min-h-full py-2 px-2 sm:py-5 sm:px-10 scroll-smooth flex flex-col">
            {/* HEADER */}
            <div className="flex flex-row justify-between items-center">
                {/* TITLE */}
                <div className="flex flex-row items-center w-full">
                    <div className="flex flex-col justify-center items-start w-full gap-1">
                        <div className="flex flex-row justify-between items-center w-full">
                            <h1 className="text-[#1C1C1C] text-2xl font-medium font-poppins leading-9">
                                {reward?.title} Sales Details
                            </h1>
                        </div>
                        <Breadcrumb />
                    </div>
                </div>
            </div>

            <div className="flex flex-col justify-center items-center w-full mt-6">
                <div className="flex flex-row justify-center items-center w-full gap-4">
                    <img
                        src={reward?.Image}
                        className="w-14 h-14 object-contain rounded-full"
                        alt={reward?.RewardName}
                    />
                    <div className="flex flex-col justify-center items-start gap-1 w-44">
                        <h1 className="text-[#000000] text-sm font-normal font-poppins leading-[21px]">
                            {reward?.RewardName}
                        </h1>
                        <h1 className="text-[#545454] text-xs font-normal font-poppins leading-[18px]">
                            (Reward Id: {reward?.RewardID})
                        </h1>
                    </div>
                    {/* Status */}
                    <div
                        className={`flex flex-row justify-center items-center rounded-[48px] ${reward?.Status === 'Active' ? "bg-[#D4FEE2]" : "bg-[#F3F5F7]"} px-3 py-2 w-20`}
                    >
                        {reward?.Status === 'Active' && (
                            <FaCheckCircle className="text-[#86D511] mr-2" />
                        )}
                        <h1 className={`${reward?.Status === 'Active' ? "text-[#3E8920]" : "text-[#8A8A8A]"} text-xs font-normal font-poppins`}>
                            {reward?.Status === 'Active' ? "Active" : reward?.Status === 'Draft' ? "Draft" : "Deactivated"}
                        </h1>
                    </div>
                    <div className="flex flex-row justify-start items-center w-full">
                        <h1 className="text-[#545454] text-xs font-normal font-poppins leading-[18px]">
                            Reward Type
                        </h1>
                        <img src={reward?.TypeOfReward === 'Treatment' ? treatmentIcon : reward?.TypeOfReward === 'Merchandise' ? merchandiseIcon : labTestIcon} alt={reward?.TypeOfReward === 'Treatment' ? "Treatment Icon" : reward?.TypeOfReward === 'Merchandise' ? "Merchandise Icon" : "Lab Test Icon"} className="w-5 h-5 ml-2 mr-1" />
                        <h1 className="text-[#1C1C1C] text-xs font-medium font-poppins leading-[18px]">
                            {reward?.TypeOfReward}
                        </h1>
                    </div>
                </div>

                {/* Sales */}
                <div className="flex flex-row justify-start items-center w-full mt-6 gap-2">
                    <h1 className="text-[#000000] text-base font-medium font-poppins leading-6">
                        View Sales Over Time
                    </h1>
                    <div className="h-[1px] flex-grow bg-[#D4D4D4]"></div>
                    <button onClick={toggleCart}>
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className={`w-6 h-6 ${isOpen
                                ? "transform rotate-180 transition-transform duration-200"
                                : "transform rotate-0 transition-transform duration-200"
                                }`}
                        >
                            <path
                                d="M18 9.00005C18 9.00005 13.5811 15 12 15C10.4188 15 6 9 6 9"
                                stroke="black"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>
                </div>

                {/* SALES CHART */}
                {rewardSalesReport === null || rewardSalesReport?.length === 0 ? (
                    <>
                        {isOpen && (
                            <div className="flex flex-col justify-center items-center mt-4 w-full bg-white py-4 h-[374px] px-6 shadow rounded-[4px]">
                                <img src={noSalesData} alt="No Sales Data" className="w-24 h-28 object-contain" />
                                <h1 className="text-[#1C1C1C] text-lg font-semibold font-poppins leading-[21px] mt-4 mb-1">
                                    No Sales Data Yet
                                </h1>
                                <h1 className="text-center text-[#545454] text-sm font-normal font-poppins leading-[21px]">
                                    Once your rewards start engaging customers,
                                </h1>
                                <h1 className="text-center text-[#545454] text-sm font-normal font-poppins leading-[21px]">
                                    you'll see detailed sales insights here.
                                </h1>
                            </div>
                        )}
                    </>
                ) : (
                    <>
                        {isOpen && (
                            <div className="mt-4 w-full bg-white py-4 px-6 shadow rounded-[4px]">
                                {/* Year Dropdown */}
                                <div className="flex flex-row justify-end items-center w-full gap-1 mb-[11px] relative">
                                    <h1 className="text-center text-[#000000] text-xs font-medium font-poppins leading-[18px]">
                                        {yearSelected === moment().year() ? 'This Year' : 'Past Year'}
                                    </h1>
                                    <button onClick={() => setIsYearDropdownOpen(!isYearDropdownOpen)}>
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className={`w-6 h-6 ${isYearDropdownOpen
                                                ? "transform rotate-180 transition-transform duration-200"
                                                : "transform rotate-0 transition-transform duration-200"
                                                }`}
                                        >
                                            <path
                                                d="M18 9.00005C18 9.00005 13.5811 15 12 15C10.4188 15 6 9 6 9"
                                                stroke="black"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                    </button>
                                    {isYearDropdownOpen && (
                                        <div className="absolute top-4 mt-2 right-0 bg-white border border-gray-300 rounded-md shadow-lg z-10">
                                            <button
                                                onClick={() => handleYearChange('This Year')}
                                                className="block w-full text-left text-[#000000] text-xs font-medium font-poppins leading-[18px] px-4 py-2 hover:bg-gray-100"
                                            >
                                                This Year
                                            </button>
                                            <button
                                                onClick={() => handleYearChange('Past Year')}
                                                className="block w-full text-left text-[#000000] text-xs font-medium font-poppins leading-[18px] px-4 py-2 hover:bg-gray-100"
                                            >
                                                Past Year
                                            </button>
                                        </div>
                                    )}
                                </div>
                                <SalesDetailChart salesData={rewardSalesReport} />
                                {rewardCustomerList !== null && Object.keys(rewardCustomerList)?.length !== 0 && (
                                    <div className="flex flex-row justify-start items-center w-full mt-4 gap-4">
                                        <div className="flex flex-col justify-center items-start w-40 gap-1">
                                            <h1 className="text-[#545454] text-sm font-normal font-poppins">
                                                Total Sales generated
                                            </h1>
                                            <h1 className="text-[#1C1C1C] text-sm font-semibold font-poppins">
                                                {rewardCustomerList?.totalSalesGenerated}
                                            </h1>
                                        </div>
                                        <div className="flex flex-col justify-center items-start w-44 gap-1">
                                            <h1 className="text-[#545454] text-sm font-normal font-poppins">
                                                Peak redemption Month
                                            </h1>
                                            <h1 className="text-[#1C1C1C] text-sm font-semibold font-poppins">
                                                {rewardCustomerList?.peakRedemptionMonth}
                                            </h1>
                                        </div>
                                        <div className="flex flex-col justify-center items-start w-40 gap-1">
                                            <h1 className="text-[#545454] text-sm font-normal font-poppins">
                                                Total Redemptions
                                            </h1>
                                            <h1 className="text-[#1C1C1C] text-sm font-semibold font-poppins">
                                                {rewardCustomerList?.totalRedemptions}
                                            </h1>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </>
                )}

                <div className="flex flex-col justify-center items-start w-full mt-6">

                    <div className="flex flex-row justify-start items-center w-full gap-2">
                        <h1 className="text-[#1C1C1C] text-lg font-medium font-poppins leading-7 w-36">
                            Customer List
                        </h1>
                        {rewardCustomerList?.pendingSales > 0 ? (
                            <div className="flex flex-row justify-start items-center gap-1 mt-1">
                                <img src={alertIcon} alt="Alert Icon" className="w-4 h-4" />
                                <h1 className="text-[#954D05] text-xs font-medium font-poppins">
                                    {rewardCustomerList?.pendingSales} pending sales
                                </h1>
                            </div>
                        ) : (
                            <div className="flex flex-row justify-start items-center w-full gap-1 mt-1 h-4"></div>
                        )}
                    </div>

                    {/* Month Filter */}
                    <div className="flex flex-row justify-between items-center w-full mt-[10px]">
                        <div className="flex flex-row justify-center items-center bg-white shadow rounded-lg py-2 px-2 gap-2 w-48">
                            <svg onClick={() => handleMonthChange('decrease')}
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className={`w-16 h-6 transform rotate-90 transition-transform duration-200 cursor-pointer hover:scale-95`}
                            >
                                <path
                                    d="M18 9.00005C18 9.00005 13.5811 15 12 15C10.4188 15 6 9 6 9"
                                    stroke="black"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                            <h1 className={`text-[#1C1C1C] text-base text-center font-medium font-poppins w-full`}>
                                {moment().month(selectedMonth).format('MMM')} {selectedYear}
                            </h1>
                            <svg onClick={() => handleMonthChange('increase')}
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className={`w-16 h-6 transform -rotate-90 transition-transform duration-200 cursor-pointer hover:scale-95`}
                            >
                                <path
                                    d="M18 9.00005C18 9.00005 13.5811 15 12 15C10.4188 15 6 9 6 9"
                                    stroke="black"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </div>

                        {/* Filter */}
                        <div className="flex flex-row justify-center items-center">
                            {['All', 'Completed', 'Pending'].map((filter, index, array) => (
                                <button
                                    key={filter}
                                    className={`
                                    py-2 px-4
                                    ${index === 0 ? 'rounded-l-lg' : ''} 
                                    ${index === array.length - 1 ? 'rounded-r-lg' : ''} 
                                    ${selectedFilter === filter.toUpperCase()
                                            ? 'bg-[#E5F4FE] border-[#AFD8F2]'
                                            : 'bg-white border-[#D4D4D4]'
                                        }
                                    ${selectedFilter === filter.toUpperCase() ? 'border' : 'border'}
                                `}
                                    onClick={() => { handleFilterChange(filter) }}
                                >
                                    <h1 className={`${selectedFilter === filter.toUpperCase() ? "text-[#007AAE]" : "text-[#545454]"} text-sm font-medium font-poppins`}>
                                        {filter}
                                    </h1>
                                </button>
                            ))}
                        </div>
                    </div>

                    {/* Customer List Header */}
                    <div className="flex flex-row justify-between items-center w-full bg-[#F9FAFB] py-3 rounded-none border-b border-[#EAECF0] mt-4">
                        <h1 className="text-[#545454] text-xs font-medium font-poppins leading-[18px] w-[237px] px-6">
                            Customer Name
                        </h1>
                        <h1 className="text-[#545454] text-xs font-medium font-poppins leading-[18px] w-[188px] px-6">
                            Date of redemption
                        </h1>
                        <h1 className="text-[#545454] text-xs font-medium font-poppins leading-[18px] w-[150px] px-6">
                            Order ID
                        </h1>
                        <h1 className="text-[#545454] text-xs font-medium font-poppins leading-[18px] w-[196px] px-6">
                            Total Amount Spent
                        </h1>
                        <h1 className="text-[#545454] text-xs font-medium font-poppins leading-[18px] w-[365px] px-6">
                            Status
                        </h1>
                    </div>

                    {/* Customer List Detail */}
                    {filteredCustomerList?.length === 0 ? (
                        <div className="flex flex-col justify-center items-center w-full bg-white py-4 h-[374px] px-6 shadow rounded-b-[4px]">
                            <img src={noCustomerList} alt="No Customer Data" className="w-32 h-32 object-contain" />
                            <h1 className="text-[#1C1C1C] text-lg font-semibold font-poppins leading-[21px] mt-4 mb-1">
                                No Data Yet
                            </h1>
                            <h1 className="text-center text-[#545454] text-sm font-normal font-poppins leading-[21px]">
                                Once your rewards start engaging customers,
                            </h1>
                            <h1 className="text-center text-[#545454] text-sm font-normal font-poppins leading-[21px]">
                                you'll see your customer list here.
                            </h1>
                        </div>
                    ) : (
                        <>
                            {filteredCustomerList?.map((cus, index) => (
                                <div key={index} className="flex flex-row justify-between items-center w-full bg-[#FFFFFF] py-4 rounded-none border-b border-[#EAECF0]">
                                    <div className="flex flex-row justify-start items-center w-[237px] px-6 gap-3">
                                        <img
                                            src={cus?.Image}
                                            className="w-8 h-8 object-contain rounded-full"
                                            alt={`${cus?.FirstName} ${cus?.LastName}`}
                                        />
                                        <div className="flex flex-col justify-center items-start w-28">
                                            <h1 className="text-[#101828] text-sm font-medium font-poppins leading-[20px]">
                                                {`${cus?.FirstName} ${cus?.LastName}`}
                                            </h1>
                                            <h1 className="text-[#545454] text-xs font-normal font-poppins leading-[20px]">
                                                Patient ID: {cus?.AHUID}
                                            </h1>
                                        </div>
                                    </div>
                                    <h1 className="text-[#545454] text-xs font-medium font-poppins leading-[18px] w-[188px] px-6">
                                        {cus?.RedemptionDate}
                                    </h1>
                                    <h1 className="text-[#545454] text-xs font-medium font-poppins leading-[18px] w-[150px] px-6">
                                        #{cus?.RSID}
                                    </h1>
                                    <h1 className="text-[#545454] text-xs font-medium font-poppins leading-[18px] w-[196px] px-6">
                                        CHF {cus?.OfferPrice}
                                    </h1>
                                    <div className="w-[365px] px-6">
                                        {cus?.SalesStatus === "Mark As Complete" && (
                                            <button
                                                onClick={() => { handleMarkAsComplete(cus?.RSID) }}
                                                className="flex flex-row justify-start items-center rounded-lg border border-[#3CA5DC] px-5 py-3 cursor-pointer hover:scale-95 duration-500 w-52 gap-3"
                                            >
                                                <div className="w-4 h-4 rounded-sm border border-[#007AAE]"></div>
                                                <h1 className="text-[#007AAE] text-sm font-semibold font-poppins">
                                                    Mark as Complete
                                                </h1>
                                            </button>
                                        )}
                                        {cus?.SalesStatus === "Completed" && (
                                            <div
                                                className="flex flex-row justify-start items-center rounded-lg border bg-[#ECFDF3] px-5 py-3 w-52 gap-3"
                                            >
                                                <FaCheckSquare className="w-4 h-4 text-[#86D511]" />
                                                <h1 className="text-[#007AAE] text-sm font-semibold font-poppins">
                                                    Completed
                                                </h1>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default SalesDetail;
