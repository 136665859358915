import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import qrCodeImg from "../../assets/Images/Invoicing/qrCodeImg.png";
import postInvoiceIcon from "../../assets/Images/Invoicing/postInvoiceIcon.png";
import mailInvoiceIcon from "../../assets/Images/Invoicing/mailInvoiceIcon.png";
import cal_Icon from "../../assets/Images/BookAppointment/cal_Icon.png";
import moment from "moment";
import { useLocation } from "react-router-dom";
import AHlogo from "../../assets/Images/logo.png";
import ModalComponent from "../../components/Modal";
import { IoIosSearch } from "react-icons/io";
import { DayPicker } from "react-day-picker";
import { FaChevronLeft, FaPlus } from "react-icons/fa6";
import "react-day-picker/dist/style.css";
import {
  createInvoice,
  getAllServiceRateEntry,
  sendInvoice,
  updateInvoice,
} from "../../services/operations/masterDataAPI";
import { RiDeleteBinLine } from "react-icons/ri";
import Breadcrumb from "../../components/Breadcrumb";
import Dropdown from "../../components/Dropdown";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { FiPlus } from "react-icons/fi";
import { RxCross2 } from "react-icons/rx";
import { RiSendPlaneFill } from "react-icons/ri";

function AddInvoice() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchInput, setSearchInput] = useState("");
  const invoice = location?.state?.invoice || null;
  const orgData = location?.state?.orgData || null;
  const draft = location?.state?.draft || null;
  const { logo } = useSelector((state) => state.profile);
  const { settings } = useSelector((state) => state.masterData);
  const { token } = useSelector((state) => state.auth);
  const [showCalendar, setShowCalendar] = useState(false);
  // const [selected, setSelected] = useState(new Date());
  const [showModal, setShowModal] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [invoiceIDToEdit, setInvoiceIDToEdit] = useState(null);
  const [serviceRateEntry, setServiceRateEntry] = useState([]);
  const [deleteTempService, setDeleteTempService] = useState(false);
  const [filteredServices, setFilteredServices] = useState([]);
  const [aggregatedServiceData, setAggregatedServiceData] = useState([]);
  const [selectedServiceOption, setSelectedServiceOption] = useState("");
  const [showServiceTypes, setShowServiceTypes] = useState(false);
  // Define a unique key for your dropdown
  const [dropdownKey, setDropdownKey] = useState(0);

  const serviceOptions = [
    { label: "Hospital Visit", value: "HospitalVisit" },
    { label: "Home Visit", value: "HomeVisit" },
    { label: "In Clinic Consultation", value: "InClinicConsultation" },
    { label: "TeleHealth", value: "TeleHealth" },
  ];

  const [tempServiceData, setTempServiceData] = useState({
    ServiceCode: "",
    ServiceID: "",
    ServiceName: "",
    Time: "",
    Rate: "",
    Cost: "",
    Tax: "",
    Quantity: "",
  });
  const [tempServiceErrors, setTempServiceErrors] = useState({
    ServiceCode: "",
    ServiceID: "",
    ServiceName: "",
    Time: "",
    Rate: "",
    Tax: "",
    Cost: "",
    Quantity: "",
  });

  const [formData, setFormData] = useState({
    AppointmentID: "",
    InvoiceID: "",
    PatientName: "",
    Services: "",
    Date: "",
    Address: "",
    DrName: "",
    AHVNumber: "",
    DOB: "",
    Canton: "",
    AHUID: "",
    Tax: "",
    InvoiceAmount: "",
    Currency: "CHF",
    ServiceData: [],
    Subtotal: "0.00",
    Total: "0.00",
  });

  const [errors, setErrors] = useState({
    AppointmentID: "",
    InvoiceID: "",
    PatientName: "",
    Services: "",
    Date: "",
    InvoiceAmount: "",
    ServiceData: "",
    Subtotal: "",
    Total: "",
  });

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchInput(value);

    if (value.trim() === "") {
      setFilteredServices([]);
    } else {
      const filtered = serviceRateEntry?.filter((service) =>
        service?.Name?.toLowerCase().includes(value?.toLowerCase())
      );
      setFilteredServices(filtered);
    }
  };

  const handleServiceClick = (service) => {
    setTempServiceData((prevData) => ({
      ...prevData,
      ServiceName: service?.Name,
      ServiceCode: service?.ReimbursementCode,
      Rate: service?.Price,
      Time: service?.Times,
      Quantity: "",
      Cost: "",
      ServiceID: service?.SRCID,
      Currency: service?.Currency,
    }));
    setTempServiceErrors((prevData) => ({
      ...prevData,
      ServiceName: "",
      ServiceCode: "",
      Rate: "",
      ServiceID: "",
      Currency: "",
    }));
    setSearchInput("");
    setFilteredServices([]);

    const { Times, Price } = service;
    if (Times && Price) {
      const serviceAmount = parseFloat(Price);
      const Cost = serviceAmount?.toFixed(2);

      setTempServiceData((prevData) => ({
        ...prevData,
        Cost: Cost,
      }));
      setTempServiceErrors((prevData) => ({
        ...prevData,
        Time: "",
        Cost: "",
        Rate: "",
      }));

      // Check if formData.ServiceData is empty
      if (!formData?.ServiceData || formData?.ServiceData?.length === 0) {
        setFormData((prevData) => {
          const subtotal = parseFloat(serviceAmount?.toFixed(2));
          const tax = parseFloat((subtotal * 0.18)?.toFixed(2));
          const total = parseFloat((subtotal + tax)?.toFixed(2));
          return {
            ...prevData,
            InvoiceAmount: serviceAmount?.toFixed(2),
            Subtotal: subtotal,
            Tax: tax,
            Total: total,
          };
        });
        setErrors((prevData) => ({
          ...prevData,
          InvoiceAmount: "",
          Subtotal: "",
          Total: "",
        }));
      } else {
        const updatedServiceData = [...(formData?.ServiceData || [])];
        // Calculate the total and subtotal amounts
        let totalAmount = updatedServiceData?.reduce(
          (sum, service) => sum + parseFloat(service.Cost || 0),
          0
        );
        totalAmount += parseFloat(serviceAmount);

        // Update formData with the new service data and calculated amounts
        setFormData((prevData) => {
          const subtotal = parseFloat(totalAmount?.toFixed(2));
          const tax = parseFloat((subtotal * 0.18)?.toFixed(2));
          const total = parseFloat((subtotal + tax)?.toFixed(2));

          return {
            ...prevData,
            InvoiceAmount: totalAmount?.toFixed(2),
            Subtotal: subtotal,
            Tax: tax,
            Total: total,
          };
        });
        setErrors((prevData) => ({
          ...prevData,
          InvoiceAmount: "",
          Subtotal: "",
          Total: "",
        }));
      }
    }
  };

  const handleAddMoreService = (e) => {
    e.preventDefault();

    if (deleteTempService) {
      setDeleteTempService(false);
    } else {
      const newErrors = {};
      const mandatoryFields = tempServiceData?.Quantity
        ? ["ServiceCode", "ServiceID", "ServiceName", "Tax", "Rate", "Cost", "Quantity"]
        : ["ServiceCode", "ServiceID", "ServiceName", "Time", "Tax", "Rate", "Cost"];

      mandatoryFields.forEach((key) => {
        const value = tempServiceData[key];
        if (!value || (Array.isArray(value) && value.some((v) => v.trim() === "")) || (typeof value === "string" && value.trim() === "")) {
          newErrors[key] = "This field is required";
        }
      });

      if (Object.keys(newErrors).length > 0) {
        toast.error("Please fill all the required fields");
        setTempServiceErrors(newErrors);
        return;
      }

      // Add tempServiceData to formData.ServiceData
      const updatedServiceData = [
        ...(formData?.ServiceData || []),
        { ...tempServiceData },
      ];

      // Calculate the total and subtotal amounts
      const totalAmount = updatedServiceData?.reduce(
        (sum, service) => sum + parseFloat(service?.Cost || 0),
        0
      );

      // Update formData with the new service data and calculated amounts
      setFormData((prevData) => {
        const subtotal = parseFloat(totalAmount?.toFixed(2));
        const tax = parseFloat((subtotal * 0.18)?.toFixed(2));
        const total = parseFloat(subtotal?.toFixed(2));

        return {
          ...prevData,
          ServiceData: updatedServiceData,
          InvoiceAmount: totalAmount?.toFixed(2),
          Subtotal: subtotal,
          Tax: tax,
          Total: total,
        };
      });

      // Reset tempServiceData and tempServiceErrors before rendering the dropdown
      setTempServiceData({
        ServiceName: "",
        ServiceCode: "",
        Quantity: "",
        Time: "",
        Tax: "",
        Rate: "",
        Cost: "",
        Currency: "CHF",
      });

      setTempServiceErrors({
        ServiceName: "",
        ServiceCode: "",
        Quantity: "",
        Time: "",
        Tax: "",
        Rate: "",
        Cost: "",
        Currency: "",
      });

      // Force re-render by changing the key
      setDropdownKey((prevKey) => prevKey + 1);

      setDeleteTempService(false);
    }
  };

  const handleTempDeleteService = (e) => {
    e.preventDefault();
    // Reset tempServiceData and tempServiceErrors
    setTempServiceData((prevData) => ({
      ...Object.keys(prevData)?.reduce(
        (acc, key) => ({ ...acc, [key]: "" }),
        {}
      ),
      Currency: "CHF",
    }));
    setTempServiceErrors((prevData) => ({
      ...Object.keys(prevData)?.reduce(
        (acc, key) => ({ ...acc, [key]: "" }),
        {}
      ),
      Currency: "",
    }));
    setDeleteTempService(true);

    // Ensure formData.ServiceData exists and is an array before reducing
    if (formData?.ServiceData && Array.isArray(formData?.ServiceData)) {
      // Calculate the total and subtotal amounts
      const totalAmount = formData?.ServiceData.reduce(
        (sum, service) => sum + parseFloat(service?.Cost || 0),
        0
      );

      const subtotal = parseFloat(totalAmount?.toFixed(2));
      const tax = parseFloat((subtotal * 0.18)?.toFixed(2));
      const total = parseFloat((subtotal)?.toFixed(2));
      // const total = parseFloat((subtotal + tax).toFixed(2));

      // Update the formData with the new ServiceData
      setFormData({
        ...formData,
        InvoiceAmount: total,
        Subtotal: subtotal,
        Tax: tax,
        Total: total,
      });
      setErrors((prevData) => ({
        ...prevData,
        InvoiceAmount: "",
        Subtotal: "",
        Total: "",
      }));
    }
  };

  const handleDeleteService = (e, indexToRemove) => {
    e.preventDefault();

    // Check if formData and ServiceData exist
    if (formData && formData?.ServiceData) {
      // Filter out the service at the given index
      const updatedServiceData = formData?.ServiceData?.filter(
        (service, index) => index !== indexToRemove
      );
      // Calculate the total and subtotal amounts
      let totalAmount = updatedServiceData?.reduce(
        (sum, service) => sum + parseFloat(service?.Cost || 0),
        0
      );
      totalAmount += parseFloat(tempServiceData?.Cost || 0);

      const subtotal = parseFloat(totalAmount?.toFixed(2));
      const tax = parseFloat((subtotal * 0.18)?.toFixed(2));
      // const total = parseFloat((subtotal + tax).toFixed(2));
      const total = parseFloat((subtotal)?.toFixed(2));

      // Update the formData with the new ServiceData
      setFormData({
        ...formData,
        ServiceData: updatedServiceData,
        InvoiceAmount: total,
        Subtotal: subtotal,
        Tax: tax,
        Total: total,
      });
      setErrors((prevData) => ({
        ...prevData,
        ServiceData: "",
        InvoiceAmount: "",
        Subtotal: "",
        Total: "",
      }));
    }
  };

  const handleServiceChange = (e) => {
    const { name, value } = e.target;

    let updatedFormData = {
      ...tempServiceData,
      [name]: value,
    };

    if (name === "ServiceName") {
      const selectedService = serviceRateEntry?.find(
        (service) => service?.Name === value
      );
      if (selectedService?.Quantity >= 1) {
        updatedFormData = {
          ...updatedFormData,
          ServiceName: selectedService?.Name || "",
          ServiceCode: selectedService?.ReimbursementCode || "",
          Tax: (selectedService?.Price * 0.081).toFixed(2) || "",
          Rate: selectedService?.Price || "",
          Quantity: selectedService?.Quantity || "",
          ServiceID: selectedService?.SRCID || "",
          Currency: selectedService?.Currency || "CHF",
        };
        setTempServiceErrors((prevData) => ({
          ...prevData,
          ServiceName: "",
          ServiceCode: "",
          Rate: "",
          Quantity: "",
          Time: "",
          Tax: "",
          ServiceID: "",
          Currency: "",
        }));
      } else {
        updatedFormData = {
          ...updatedFormData,
          ServiceName: selectedService?.Name || "",
          ServiceCode: selectedService?.ReimbursementCode || "",
          Tax: (selectedService?.Price * 0.081).toFixed(2) || "",
          Rate: selectedService?.Price || "",
          Time: selectedService?.Times || "",
          ServiceID: selectedService?.SRCID || "",
          Currency: selectedService?.Currency || "CHF",
        };
        setTempServiceErrors((prevData) => ({
          ...prevData,
          ServiceName: "",
          ServiceCode: "",
          Rate: "",
          Quantity: "",
          Time: "",
          Tax: "",
          ServiceID: "",
          Currency: "",
        }));
      }
    } else if (name === "ServiceCode") {
      const selectedService = serviceRateEntry?.find(
        (service) => service?.ReimbursementCode === value
      );
      if (selectedService?.Quantity >= 1) {
        updatedFormData = {
          ...updatedFormData,
          ServiceName: selectedService?.Name || "",
          ServiceCode: selectedService?.ReimbursementCode || "",
          Rate: selectedService?.Price || "",
          Tax: (selectedService?.Price * 0.081)?.toFixed(2) || "",
          Quantity: selectedService?.Quantity || "",
          ServiceID: selectedService?.SRCID || "",
          Currency: selectedService?.Currency || "CHF",
        };
        setTempServiceErrors((prevData) => ({
          ...prevData,
          ServiceName: "",
          ServiceCode: "",
          Rate: "",
          Tax: "",
          Quantity: "",
          Time: "",
          ServiceID: "",
          Currency: "",
        }));
      } else {
        updatedFormData = {
          ...updatedFormData,
          ServiceName: selectedService?.Name || "",
          ServiceCode: selectedService?.ReimbursementCode || "",
          Rate: selectedService?.Price || "",
          Tax: (selectedService?.Price * 0.081)?.toFixed(2) || "",
          Time: selectedService?.Times || "",
          ServiceID: selectedService?.SRCID || "",
          Currency: selectedService?.Currency || "CHF",
        };
        setTempServiceErrors((prevData) => ({
          ...prevData,
          ServiceName: "",
          ServiceCode: "",
          Rate: "",
          Tax: "",
          Quantity: "",
          Time: "",
          ServiceID: "",
          Currency: "",
        }));
      }
    }

    const { Quantity, Time, Rate, Tax } = updatedFormData;
    if (Rate) {
      const serviceAmount = parseFloat(Rate);
      const serviceTax = parseFloat(Tax);

      updatedFormData.Cost = (serviceAmount + serviceTax).toFixed(2);
      setTempServiceErrors((prevData) => ({
        ...prevData,
        Cost: "",
        Quantity: "",
        Time: "",
        Rate: "",
      }));

      // Check if formData.ServiceData is empty
      if (!formData?.ServiceData || formData?.ServiceData?.length === 0) {
        setFormData((prevData) => {
          const subtotal = parseFloat((serviceAmount + serviceTax)?.toFixed(2));
          const tax = parseFloat((subtotal * 0.18)?.toFixed(2));
          const total = parseFloat((subtotal)?.toFixed(2));
          // const total = parseFloat((subtotal + tax).toFixed(2));

          return {
            ...prevData,
            InvoiceAmount: total,
            Subtotal: subtotal,
            Tax: tax,
            Total: total,
          };
        });
        setErrors((prevData) => ({
          ...prevData,
          InvoiceAmount: "",
          Subtotal: "",
          Total: "",
        }));
      } else {
        const updatedServiceData = [...(formData?.ServiceData || [])];
        // Calculate the total and subtotal amounts
        let totalAmount = updatedServiceData?.reduce(
          (sum, service) => sum + parseFloat(service?.Cost || 0),
          0
        );
        totalAmount += parseFloat(serviceAmount + serviceTax);

        // Update formData with the new service data and calculated amounts
        setFormData((prevData) => {
          const subtotal = parseFloat(totalAmount?.toFixed(2));
          const tax = parseFloat((subtotal * 0.18)?.toFixed(2));
          const total = parseFloat((subtotal)?.toFixed(2));
          // const total = parseFloat((subtotal + tax).toFixed(2));

          return {
            ...prevData,
            InvoiceAmount: total,
            Subtotal: subtotal,
            Tax: tax,
            Total: total,
          };
        });
        setErrors((prevData) => ({
          ...prevData,
          InvoiceAmount: "",
          Subtotal: "",
          Total: "",
        }));
      }
    }

    setTempServiceData(updatedFormData);
    setTempServiceErrors((prevData) => ({
      ...prevData,
      [name]: "",
    }));
  };

  function handleSelect(name, option) {
    setFormData((prev) => ({
      ...prev,
      [name]: option,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  }

  // Aggregate the service data first
  const aggregateServices = (services) => {
    const aggregated = {};

    services.forEach((service) => {
      const key = `${service.ServiceCode}-${service.ServiceName}`;

      if (!aggregated[key]) {
        aggregated[key] = {
          ...service,
          Quantity: parseInt(service.Quantity, 10),
          Time: parseInt(service.Time, 10),
          Rate: service.Rate,
          Tax: parseFloat(service.Tax).toFixed(2),
          Cost: parseFloat(service.Cost).toFixed(2),
        };
      } else {
        if (service.Quantity >= 1) {
          aggregated[key].Quantity += parseInt(service.Quantity, 10);
        } else {
          aggregated[key].Time += parseInt(service.Time, 10);
        }
        aggregated[key].Rate = parseInt(aggregated[key].Rate) + parseInt(service.Rate);
        aggregated[key].Tax = (parseFloat(aggregated[key].Tax) + parseFloat(service.Tax)).toFixed(2);
        aggregated[key].Cost = (parseFloat(aggregated[key].Cost) + parseFloat(service.Cost)).toFixed(2);
      }
    });

    return Object.values(aggregated);
  };

  useEffect(() => {
    if (formData?.ServiceData) {
      const aggregated = aggregateServices(formData.ServiceData);
      setAggregatedServiceData(aggregated);
    }
  }, [formData.ServiceData]);

  const submitHandler = async (event, msg, isDraft, type) => {
    event.preventDefault();
    let updatedServiceData = [...(formData?.ServiceData || [])];
    let totalAmount;
    let total;

    // Add the Temporary Service Data state in formData
    if (!deleteTempService) {
      if (tempServiceData?.Quantity) {
        const mandatoryFields1 = [
          "ServiceCode",
          "ServiceID",
          "ServiceName",
          "Quantity",
          "Tax",
          "Rate",
          "Cost",
        ];
        const newErrors1 = {};

        mandatoryFields1.forEach((key) => {
          const value = tempServiceData[key];

          if (
            !value ||
            (Array.isArray(value) && value.some((v) => v.trim() === ""))
          ) {
            newErrors1[key] = "This field is required";
          } else if (typeof value === "string" && value.trim() === "") {
            newErrors1[key] = "This field cannot be empty";
          }
        });

        if (Object.keys(newErrors1).length > 0) {
          toast.error("Please fill all the required fields");
          setTempServiceErrors(newErrors1);
          return;
        }
      } else {
        const mandatoryFields1 = [
          "ServiceCode",
          "ServiceID",
          "ServiceName",
          "Time",
          "Tax",
          "Rate",
          "Cost",
        ];
        const newErrors1 = {};

        mandatoryFields1.forEach((key) => {
          const value = tempServiceData[key];

          if (
            !value ||
            (Array.isArray(value) && value.some((v) => v.trim() === ""))
          ) {
            newErrors1[key] = "This field is required";
          } else if (typeof value === "string" && value.trim() === "") {
            newErrors1[key] = "This field cannot be empty";
          }
        });

        if (Object.keys(newErrors1).length > 0) {
          toast.error("Please fill all the required fields");
          setTempServiceErrors(newErrors1);
          return;
        }
      }

      // Add tempServiceData to formData.ServiceData
      updatedServiceData = [
        ...(formData?.ServiceData || []),
        { ...tempServiceData },
      ];

      // Calculate the total and subtotal amounts
      totalAmount = updatedServiceData?.reduce(
        (sum, service) => sum + parseFloat(service?.Cost || 0),
        0
      );

      const subtotal = parseFloat(totalAmount?.toFixed(2));
      const tax = parseFloat((subtotal * 0.18)?.toFixed(2));
      total = parseFloat((subtotal)?.toFixed(2));
      // total = parseFloat((subtotal + tax).toFixed(2));

      // Update formData with the new service data and calculated amounts
      setFormData((prevData) => ({
        ...prevData,
        ServiceData: updatedServiceData,
        InvoiceAmount: total,
        Subtotal: subtotal,
        Tax: tax,
        Total: total,
      }));
      setErrors((prevData) => ({
        ...prevData,
        ServiceData: "",
        InvoiceAmount: "",
        Subtotal: "",
        Total: "",
      }));

      // Reset tempServiceData and tempServiceErrors
      setTempServiceData((prevData) => ({
        ...Object.keys(prevData).reduce(
          (acc, key) => ({ ...acc, [key]: "" }),
          {}
        ),
        Currency: "CHF",
      }));
      setTempServiceErrors((prevData) => ({
        ...Object.keys(prevData).reduce(
          (acc, key) => ({ ...acc, [key]: "" }),
          {}
        ),
        Currency: "",
      }));
    } else {
      // Calculate the total and subtotal amounts
      totalAmount = updatedServiceData?.reduce(
        (sum, service) => sum + parseFloat(service?.Cost || 0),
        0
      );
      const subtotal = parseFloat(totalAmount?.toFixed(2));
      const tax = parseFloat((subtotal * 0.18)?.toFixed(2));
      // total = parseFloat((subtotal + tax).toFixed(2));
      total = parseFloat((subtotal)?.toFixed(2));

      // Update formData with the new service data and calculated amounts
      setFormData((prevData) => ({
        ...prevData,
        ServiceData: updatedServiceData,
        InvoiceAmount: total,
        Subtotal: subtotal,
        Tax: tax,
        Total: total,
      }));
      setErrors((prevData) => ({
        ...prevData,
        ServiceData: "",
        InvoiceAmount: "",
        Subtotal: "",
        Total: "",
      }));
    }

    const mandatoryFields = ["AppointmentID", "InvoiceID", "Services", "Total"];
    const newErrors = {};

    mandatoryFields.forEach((key) => {
      const value = formData[key];

      if (
        !value ||
        (Array.isArray(value) && value.some((v) => v.trim() === ""))
      ) {
        newErrors[key] = "This field is required";
      } else if (typeof value === "string" && value.trim() === "") {
        newErrors[key] = "This field cannot be empty";
      }
    });

    if (Object.keys(newErrors).length > 0) {
      toast.error("Please fill all the required fields");
      setErrors(newErrors);
      return;
    }

    // Fill basicData with formData
    const basicData = {
      SessionID: formData?.AppointmentID,
      InvoiceNumber: formData?.InvoiceID,
      TotalAmount: total,
      Currency: "CHF",
      InvoiceFile: null,
      IsDraft: isDraft,
      PaymentDueDate: moment(new Date()).add(30, 'days').format("DD/MM/YYYY"),
      ServiceData: updatedServiceData?.map((service) => ({
        ServiceID: service?.ServiceID,
        ServiceTime: service?.Time || null,
        ServiceCode: service?.ServiceCode,
        ServiceName: service?.ServiceName,
        Rate: service?.Rate.toString(), // Convert Rate to string
        Tax: service?.Tax,
        Cost: service?.Cost,
        Quantity: parseInt(service?.Quantity, 10) || 0,
      })),
    };

    let sendBody = {};
    if (!isDraft) {
      if (type === "INSURANCE" || type === "PATIENT") {
        sendBody = {
          "SendTo": type === "INSURANCE" ? "InsuranceCompany" : "Patient",
        }
      }
    }

    if (updating) {
      try {
        let navigating = 0;
        if (isDraft || !(type === "INSURANCE" || type === "PATIENT")) navigating = 1;
        else navigating = 0;

        await dispatch(updateInvoice(token, invoiceIDToEdit, basicData, msg, navigating, navigate));
        if (!isDraft && (type === "INSURANCE" || type === "PATIENT")) {
          sendBody = {
            ...sendBody,
            "INVOICEID": invoiceIDToEdit,
          }
          await dispatch(sendInvoice(token, sendBody, navigate));
        }
      } catch (error) {
        console.error("Error updating invoice:", error);
      }
    }
    else {
      try {
        let navigating = 0;
        if (isDraft || !(type === "INSURANCE" || type === "PATIENT")) navigating = 1;
        else navigating = 0;

        const res = await dispatch(createInvoice(token, basicData, msg, navigating, navigate));
        if (!isDraft && (type === "INSURANCE" || type === "PATIENT")) {
          let invoiceID = res?.Invoice?.INVOICEID;
          sendBody = {
            ...sendBody,
            "INVOICEID": invoiceID,
          }
          await dispatch(sendInvoice(token, sendBody, navigate));
        }
      } catch (error) {
        console.error("Error creating invoice:", error);
      }
    }
  };

  useEffect(() => {
    if (draft) {
      setUpdating(true);
      setInvoiceIDToEdit(draft?.INVOICEID);
      setFormData({
        AppointmentID: draft?.SessionID,
        InvoiceID: draft?.InvoiceNumber,
        PatientName: `${draft?.AHMember?.FirstName} ${draft?.AHMember?.LastName}`,
        Services: draft?.AppointmentType,
        Address: [
          draft?.AHMember?.Address,
          `${draft?.AHMember?.City} ${draft?.AHMember?.State}`,
          `${draft?.AHMember?.Country} - ${draft?.AHMember?.PinCode}`,
        ]
          .filter(Boolean)
          .join(", "),
        ServiceData: draft?.ServicePerInvoices?.map(service => ({
          ServiceCode: service?.ServiceCode,
          ServiceID: service?.ID,
          ServiceName: service?.ServiceName,
          Time: service?.ServiceTime,
          Quantity: service?.Quantity,
          Tax: service?.Tax,
          Rate: service?.Rate,
          Cost: service?.Cost,
        })),
        Date: draft?.CreatedAt,
        DrName: draft?.RelatedDoctor?.Name,
        AHVNumber: draft?.AHMember?.AHVNumber,
        DOB: draft?.AHMember?.DOB,
        Canton: draft?.AHMember?.State,
        AHUID: draft?.AHMember?.AHUID,
        Total: draft?.Invoice?.TotalAmount,
      });
    }
    if (invoice) {
      setUpdating(false);
      setInvoiceIDToEdit(null);
      const invoiceID = `INV-${String(invoice?.ID).padStart(3, "0")}`;
      setFormData({
        AppointmentID: invoice?.ID,
        InvoiceID: invoiceID,
        PatientName: invoice?.Name,
        Services: invoice?.AppointmentType,
        Address: [
          invoice?.Address,
          `${invoice?.City} ${invoice?.State}`,
          `${invoice?.Country} - ${invoice?.PinCode}`,
        ]
          .filter(Boolean)
          .join(", "),
        Date: invoice?.CreatedAt,
        DrName: invoice?.RelatedDoctor?.Name,
        AHVNumber: invoice?.AHVNumber,
        DOB: invoice?.DOB,
        Canton: invoice?.State,
        AHUID: invoice?.AHUID,
      });
    }
  }, [invoice, draft]);

  useEffect(() => {
    const fetchServiceEntry = async () => {
      if (token) {
        try {
          const res = await dispatch(getAllServiceRateEntry(token));
          setServiceRateEntry(res);
        } catch (error) {
          console.error("Failed to fetch service rate entries", error);
        }
      }
    };

    fetchServiceEntry();
    setDeleteTempService(false);
  }, [token, dispatch]);

  return (
    <div className="bg-white min-h-screen py-2 px-2 sm:py-5 sm:px-10 scroll-smooth flex flex-col">
      {/* HEADER */}
      <div className="flex flex-row justify-between items-center">
        {/* TITLE */}
        <div className="flex flex-row items-center">
          <div className="flex flex-col justify-center items-start gap-2">
            <h1 className="text-[#1C1C1C] text-2xl font-medium font-poppins leading-9">
              Add New Invoice
            </h1>
            <Breadcrumb currentState={location?.state} />
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="w-full flex flex-row justify-between items-start gap-11 mt-6">
        {/* Left Content */}
        <div className="flex flex-row justify-between items-center w-1/2">
          {/* Left Form */}
          <div className="w-full">
            <div className="flex flex-row justify-between items-center w-full gap-6 mb-4">
              {/* Patient Name */}
              <div className="w-24 py-2">
                <label>
                  <p className="text-[#070808] text-xs font-medium font-poppins mb-1 text-left leading-[18px]">
                    Patient Name
                  </p>
                  <p className="text-[#545454] text-xs font-normal font-poppins text-left leading-[18px]">
                    {formData?.PatientName}
                  </p>
                </label>
              </div>

              {/* Patient ID */}
              <div className="w-20 py-2">
                <label>
                  <p className="text-[#070808] text-xs font-medium font-poppins mb-1 text-left leading-[18px]">
                    Patient ID
                  </p>
                  <p className="text-[#545454] text-xs font-normal font-poppins text-left leading-[18px]">
                    {formData?.AHUID}
                  </p>
                </label>
              </div>

              {/* Invoice Number */}
              <div className="w-24 py-2">
                <label>
                  <p className="text-[#070808] text-xs font-medium font-poppins mb-1 text-left leading-[18px]">
                    Invoice Number
                  </p>
                  <p className="text-[#545454] text-xs font-normal font-poppins text-left leading-[18px]">
                    {formData?.InvoiceID}
                  </p>
                </label>
              </div>

              {/* Session ID */}
              <div className="w-20 py-2">
                <label>
                  <p className="text-[#070808] text-xs font-medium font-poppins mb-1 text-left leading-[18px]">
                    Session ID
                  </p>
                  <p className="text-[#545454] text-xs font-normal font-poppins text-left leading-[18px]">
                    #{formData?.AppointmentID}
                  </p>
                </label>
              </div>

              {/* VAT no. */}
              <div className="w-36 py-2">
                <label>
                  <p className="text-[#070808] text-xs font-medium font-poppins mb-1 text-left leading-[18px]">
                    VAT no.
                  </p>
                  <p className="text-[#545454] text-xs font-normal font-poppins text-left leading-[18px]">
                    Not registered for VAT
                  </p>
                </label>
              </div>
            </div>

            {/* Consultation Date */}
            <div className="w-44 py-2 mb-4">
              <label>
                <p className="text-[#070808] text-xs font-medium font-poppins mb-1 text-left leading-[18px]">
                  Consultation/ Delivery date
                </p>
                <p className="text-[#545454] text-xs font-normal font-poppins text-left leading-[18px]">
                  {moment(formData?.Date).format('DD MMM YYYY')}
                </p>
              </label>
            </div>

            {/* Services */}
            <div className="mb-4 w-[90%]">
              <label>
                <p className="text-[#1C1C1C] text-sm font-medium font-poppins mb-1 text-left leading-5">
                  Service Type
                  <sup className="text-red-600">*</sup>
                </p>
                <Dropdown
                  name="Services"
                  placeholder="Select Services"
                  options={serviceOptions}
                  onSelect={handleSelect}
                  error={errors?.Services}
                  value={formData?.Services}
                  labelKey="label"
                  valueKey="value"
                />
              </label>
            </div>

            <div className="flex flex-col justify-center items-start gap-2 w-full">
              {/* Service Header */}
              <div className="flex flex-row justify-between items-start w-[90%] gap-4">
                <p className="w-1/3 text-[#1C1C1C] text-sm font-medium font-poppins text-left">
                  Service Name
                  <sup className="text-red-600">*</sup>
                </p>

                <p className="w-1/3 text-[#1C1C1C] text-sm font-medium font-poppins text-left">
                  Service Code
                  <sup className="text-red-600">*</sup>
                </p>

                <p className="w-1/3 text-[#1C1C1C] text-sm font-medium font-poppins text-left">
                  Qty/Time
                  <sup className="text-red-600">*</sup>
                </p>
              </div>

              {/* Previous Service Data */}
              {formData?.ServiceData &&
                formData?.ServiceData?.length !== 0 &&
                formData?.ServiceData?.map((service, index) => (
                  <div
                    key={service?.ID}
                    className="flex flex-row justify-between items-start w-[90%] gap-4 relative"
                  >
                    {/* Service Name */}
                    <div className="w-1/3">
                      <label className="w-full flex flex-col justify-start items-start">
                        <div
                          className={`flex flex-row justify-start items-center w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                        >
                          <h1 className="text-black text-xs font-normal font-poppins text-wrap truncate">
                            {service?.ServiceName}
                          </h1>
                        </div>
                      </label>
                    </div>

                    {/* Service Code */}
                    <div className="w-1/3">
                      <label className="flex flex-col justify-start items-start gap-4">
                        <div
                          className={`flex flex-row justify-start items-center w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                        >
                          <h1 className="text-black text-xs font-normal font-poppins text-wrap truncate">
                            {service?.ServiceCode}
                          </h1>
                        </div>
                      </label>
                    </div>

                    {/* Time */}
                    {service?.Quantity >= 1 ? (
                      <div className="w-1/3">
                        <label className="flex flex-col justify-start items-start gap-4">
                          <div
                            className={`flex flex-row justify-start items-center w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                          >
                            <h1 className="text-black text-xs font-normal font-poppins text-wrap truncate">
                              {service?.Quantity}
                            </h1>
                          </div>
                        </label>
                      </div>
                    ) : (
                      <div className="w-1/3">
                        <label className="flex flex-col justify-start items-start gap-4">
                          <div
                            className={`flex flex-row justify-start items-center w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                          >
                            <h1 className="text-black text-xs font-normal font-poppins text-wrap truncate">
                              {service?.Time} Mins
                            </h1>
                          </div>
                        </label>
                      </div>
                    )}

                    {deleteTempService &&
                      formData?.ServiceData &&
                      index === formData?.ServiceData?.length - 1 ? (
                      <>
                        {formData?.ServiceData?.length === 1 ? (
                          <>
                            {/* If tempService Deleted then Show Add Icon in Last Service Data but no Delete Btn */}
                            {/* <div
                              className={`flex flex-row justify-center items-center gap-2 absolute right-[-2rem] top-0`}
                            >
                              <button
                                onClick={handleAddMoreService}
                                className="cursor-pointer flex justify-center items-center bg-[#E5F2FF] rounded-full p-1"
                              >
                                <FaPlus className="text-[#3CA5DC] text-md" />
                              </button>
                            </div> */}
                          </>
                        ) : (
                          <>
                            <div
                              className={`flex flex-row justify-center items-center gap-2 absolute right-[-4rem] top-0`}
                            >
                              {/* Delete Icon */}
                              <button
                                onClick={(e) => {
                                  handleDeleteService(e, index);
                                }}
                                className="cursor-pointer flex justify-center items-center bg-white rounded-lg w-10 h-10 p-2 border-2 border-[#D4D4D4]"
                              >
                                <RxCross2 className="text-[#1C1C1C] text-xl" />
                              </button>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        {/* Delete Icon */}
                        <button
                          onClick={(e) => {
                            handleDeleteService(e, index);
                          }}
                          className="absolute top-0 right-[-4rem] cursor-pointer flex justify-center items-center bg-white rounded-lg w-10 h-10 p-2 border-2 border-[#D4D4D4]"
                        >
                          <RxCross2 className="text-[#1C1C1C] text-2xl" />
                        </button>
                      </>
                    )}
                  </div>
                ))}

              {/* Service Data being Added */}
              {!deleteTempService && (
                <div className="flex flex-row justify-between items-center gap-4 w-[90%] relative">
                  {/* Service Name */}
                  <div className="w-1/3">
                    <label className="w-full flex flex-col justify-start items-start">
                      <div className="w-full">
                        <Dropdown
                          key={dropdownKey}
                          name="ServiceName"
                          placeholder="Select"
                          options={serviceRateEntry}
                          onSelect={(name, value) => handleServiceChange({ target: { name, value } })}
                          error={tempServiceErrors?.ServiceName}
                          value={tempServiceData?.ServiceName}
                          labelKey="Name"
                          valueKey="Name"
                          enableSearch={true}
                        />
                      </div>
                    </label>
                  </div>

                  {/* Service Code */}
                  <div className="w-1/3">
                    <label className="w-full flex flex-col justify-start items-start">
                      <div className="w-full">
                        <Dropdown
                          key={dropdownKey}
                          name="ServiceCode"
                          placeholder="Select"
                          options={serviceRateEntry}
                          onSelect={(name, value) => handleServiceChange({ target: { name, value } })}
                          error={tempServiceErrors?.ServiceCode}
                          value={tempServiceData?.ServiceCode}
                          labelKey="ReimbursementCode"
                          valueKey="ReimbursementCode"
                        />
                      </div>
                    </label>
                  </div>

                  {/* Time / Quantity */}
                  {tempServiceData?.Quantity >= 1 ? (
                    <>
                      <div className="w-1/3">
                        <label className="flex flex-col justify-start items-start gap-4">
                          <div
                            id="Quantity"
                            name="Quantity"
                            value={tempServiceData?.Quantity}
                            // onChange={handleTimeChange}
                            className={`flex flex-row justify-start items-center w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${tempServiceErrors.Quantity ? "border-red-500" : ""
                              } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent cursor-pointer`}
                          >
                            <h1 className="text-black text-xs font-normal font-poppins">
                              {tempServiceData?.Quantity
                                ? `${tempServiceData?.Quantity}`
                                : "-"}
                            </h1>
                          </div>
                        </label>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="w-1/3">
                        <label className="flex flex-col justify-start items-start gap-4">
                          <div
                            id="Time"
                            name="Time"
                            value={tempServiceData?.Time}
                            // onChange={handleTimeChange}
                            className={`flex flex-row justify-start items-center w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${tempServiceErrors.Time ? "border-red-500" : ""
                              } focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent cursor-pointer`}
                          >
                            <h1 className="text-black text-xs font-normal font-poppins">
                              {tempServiceData?.Time
                                ? `${tempServiceData?.Time} Mins`
                                : "-"}
                            </h1>
                          </div>
                        </label>
                      </div>
                    </>
                  )}

                  <div
                    className={`flex flex-row justify-center items-center gap-2 absolute ${formData?.ServiceData &&
                      formData?.ServiceData?.length !== 0
                      ? "right-[-4rem]"
                      : "right-[-2rem]"
                      } top-0`}
                  >
                    {/* Delete Service */}
                    {formData?.ServiceData &&
                      formData?.ServiceData?.length !== 0 && (
                        <button
                          onClick={handleTempDeleteService}
                          className="cursor-pointer flex justify-center items-center bg-white rounded-lg w-10 h-10 p-2 border-2 border-[#D4D4D4]"
                        >
                          <RxCross2 className="text-[#1C1C1C] text-2xl" />
                        </button>
                      )}
                  </div>
                </div>
              )}

              {/* Add more Services */}
              <button
                onClick={(e) => handleAddMoreService(e)}
                className="cursor-pointer flex flex-row justify-center items-center p-1 gap-2 mt-4"
              >
                <FiPlus className="text-[#3CA5DC] text-xl" />
                <p className="text-[#3CA5DC] text-base font-semibold font-poppins text-left">
                  Add Another Service
                </p>
              </button>

              {/* Save Draft */}
              <button
                className="mt-4 w-1/2 bg-white rounded-lg text-center text-[#3CA5DC] text-base font-semibold font-poppins p-2 hover:bg-sky-500 hover:text-white active:text-white transition duration-200 ease-in active:bg-sky-700 focus:outline-none focus:ring focus:ring-sky-300 border-2 border-[#3CA5DC]"
                onClick={(event) => {
                  let msg = "Invoice Saved As Draft";
                  submitHandler(event, msg, 1, "DRAFT");
                }}
              >
                Save Draft
              </button>
            </div>
          </div>
        </div>

        {/* Right Content */}
        <div className="w-1/2">
          <div className="pt-5 flex flex-col h-[45rem] self-start bg-[#F3F5F6] rounded-xl justify-start items-center">
            <h1 className="text-[#1C1C1C] text-base font-semibold font-poppins mb-7 px-7">
              Invoice Preview
            </h1>

            {/* Invoice */}
            <div className="overflow-y-auto w-full px-7 scroll-smooth scrollbar scrollbar-thin scrollbar-thumb-[#C6C6C8] scrollbar-track-[#F3F5F6] scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
              <div className="flex flex-col justify-center w-full items-start bg-white rounded-xl shadow-lg mb-6">
                {/* INVOICE HEADER */}
                <div className="flex flex-row justify-between w-full items-start px-6 py-2">
                  <div className="flex justify-start items-start w-40">
                    <img
                      src={
                        logo
                          ? logo
                          : settings?.OrgImage
                            ? settings?.OrgImage
                            : AHlogo
                      }
                      className="w-32 h-auto object-contain"
                      alt="Company Logo"
                    />
                  </div>
                  <h1 className="text-[#545454] text-[10px] font-normal font-poppins py-1">
                    Page 1 of 2
                  </h1>
                </div>

                <div className="w-[100%] bg-[#F3F5F6] h-[2px]"></div>

                {/* INVOICE CONTENT */}
                <div className="flex flex-col justify-center items-start w-full mt-3">
                  <div className="flex flex-row justify-center items-start w-full gap-7 px-6">
                    {/* Bill From */}
                    <div className="w-1/2 flex flex-col justify-center items-start gap-2">
                      <h1 className="text-[#1C1C1C] text-sm font-semibold font-poppins">
                        Bill From
                      </h1>
                      <div className="flex flex-col justify-center items-start w-full gap-1">
                        <h1 className="text-[#000000] text-xs font-semibold font-poppins">
                          Company Details
                        </h1>
                        <p className="text-[#000000] text-[10px] font-medium font-poppins">
                          Company Name & Address: <span className="text-[#000000] text-[10px] font-normal font-poppins">{orgData?.Name} {orgData?.LocationInfo}</span>
                        </p>
                        <p className="text-[#000000] text-[10px] font-semibold font-poppins">
                          VAT no: <span className="text-[#000000] text-[10px] font-medium font-poppins">{orgData?.VAT_No}</span>
                        </p>
                        <p className="text-[#000000] text-[10px] font-semibold font-poppins">
                          Phone no: <span className="text-[#000000] text-[10px] font-normal font-poppins">{orgData?.Phone}</span>
                        </p>
                        <p className="text-[#000000] text-[10px] font-semibold font-poppins">
                          Email: <span className="text-[#000000] text-[10px] font-normal font-poppins">{orgData?.Email}</span>
                        </p>
                      </div>
                    </div>

                    {/* Bill To */}
                    <div className="w-1/2 flex flex-col justify-center items-start gap-2">
                      <h1 className="text-[#1C1C1C] text-sm font-semibold font-poppins">
                        Bill To
                      </h1>
                      <div className="flex flex-col justify-center items-start w-full gap-1">
                        <h1 className="text-[#000000] text-xs font-semibold font-poppins">
                          Patient Details
                        </h1>
                        <p className="text-[#000000] text-[10px] font-medium font-poppins">
                          <span className="text-[#000000] text-[10px] font-normal font-poppins">{formData?.PatientName} <br />
                            {formData?.Address &&
                              formData?.Address?.split(",").map((line, index) => (
                                <span
                                  key={index}
                                  className="text-[#000000] text-[10px] font-normal font-poppins"
                                >
                                  {line.trim()}
                                  {index < formData?.Address?.split(",").length - 1 && (
                                    <br />
                                  )}
                                </span>
                              ))}</span>
                        </p>
                        <p className="text-[#000000] text-[10px] font-semibold font-poppins">
                          Patient ID: <span className="text-[#000000] text-[10px] font-medium font-poppins">{formData?.AHUID}</span>
                        </p>
                        <p className="text-[#000000] text-[10px] font-semibold font-poppins">
                          Phone no: <span className="text-[#000000] text-[10px] font-normal font-poppins">{invoice?.Phone}</span>
                        </p>
                        <p className="text-[#000000] text-[10px] font-semibold font-poppins">
                          Email: <span className="text-[#000000] text-[10px] font-normal font-poppins">{invoice?.Email}</span>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="flex flex-row justify-center items-start w-full gap-7 mt-4 px-6">
                    {/* Invoice Details */}
                    <div className="w-1/2 flex flex-col justify-center items-start gap-2">
                      <h1 className="text-[#1C1C1C] text-sm font-semibold font-poppins">
                        Invoice Details
                      </h1>
                      <div className="flex flex-col justify-center items-start w-full gap-1">
                        <p className="text-[#000000] text-[10px] font-semibold font-poppins">
                          Invoice No: <span className="text-[#000000] text-[10px] font-normal font-poppins">#{formData?.InvoiceID}</span>
                        </p>
                        <p className="text-[#000000] text-[10px] font-semibold font-poppins">
                          Invoice Date: <span className="text-[#000000] text-[10px] font-medium font-poppins">{moment(new Date()).format("DD/MM/YYYY")}</span>
                        </p>
                        <p className="text-[#000000] text-[10px] font-semibold font-poppins">
                          Payment Due Date: <span className="text-[#000000] text-[10px] font-normal font-poppins">{moment(new Date()).add(30, 'days').format("DD/MM/YYYY")}</span>
                        </p>
                      </div>
                    </div>

                    {/* Invoice Details cont */}
                    <div className="w-1/2 flex flex-col justify-center items-start gap-2">
                      <div className="flex flex-col justify-center items-start w-full gap-1">
                        <p className="text-[#000000] text-[10px] font-semibold font-poppins">
                          Session ID:  <span className="text-[#000000] text-[10px] font-medium font-poppins">#{formData?.AppointmentID}</span>
                        </p>
                        <p className="text-[#000000] text-[10px] font-semibold font-poppins">
                          Consultation Date: <span className="text-[#000000] text-[10px] font-normal font-poppins">{moment(formData?.Date).format("DD.MM.YY")}</span>
                        </p>
                        <p className="text-[#000000] text-[10px] font-semibold font-poppins">
                          Doctor Name: <span className="text-[#000000] text-[10px] font-normal font-poppins">Dr. {formData?.DrName}</span>
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* Table header */}
                  <div className="flex flex-row gap-2 justify-center items-center w-full mt-6 bg-[#E5F2FF] py-1 px-2">
                    <h1 className="w-1/6 text-[#1C1C1C] text-xs font-semibold font-poppins">
                      Service Code
                    </h1>
                    <h1 className="w-1/6 text-[#1C1C1C] text-xs font-semibold font-poppins">
                      Description
                    </h1>
                    <h1 className="w-1/6 text-[#1C1C1C] text-xs font-semibold font-poppins">
                      Qty/Time
                    </h1>
                    <h1 className="w-1/6 text-[#1C1C1C] text-xs font-semibold font-poppins">
                      Rate
                    </h1>
                    <h1 className="w-1/6 text-[#1C1C1C] text-xs font-semibold font-poppins">
                      Tax ( VAT)
                    </h1>
                    <h1 className="w-1/6 text-[#1C1C1C] text-xs font-semibold font-poppins">
                      Amount
                    </h1>
                  </div>

                  {/* Table Content */}
                  <div className="flex flex-col justify-center items-center gap-2 w-full mt-2 px-2">
                    {/* Previous Service Data */}
                    {aggregatedServiceData &&
                      aggregatedServiceData?.length !== 0 &&
                      aggregatedServiceData?.map((service) => (
                        <div
                          key={service?.ID}
                          className="flex flex-row gap-2 justify-between items-start w-full mb-2"
                        >
                          <div className="w-1/6 flex flex-row justify-start items-center">
                            <h1 className="text-[#8A8A8A] text-[10px] font-normal font-poppins">
                              {service?.ServiceCode}
                            </h1>
                          </div>
                          <div className="w-1/6 flex flex-row justify-start items-center">
                            <h1 className="text-[#8A8A8A] text-[10px] font-normal font-poppins">
                              {service?.ServiceName}
                            </h1>
                          </div>
                          <div className="w-1/6 flex flex-row justify-start items-center">
                            <h1 className="text-[#8A8A8A] text-[10px] font-normal font-poppins">
                              {service?.Quantity >= 1 ? service?.Quantity : `${service?.Time} Mins`}
                            </h1>
                          </div>
                          <div className="w-1/6 flex flex-row justify-start items-center">
                            <h1 className="text-[#8A8A8A] text-[10px] font-normal font-poppins">
                              CHF {service?.Rate}
                            </h1>
                          </div>
                          <div className="w-1/6 flex flex-row justify-start items-center">
                            <h1 className="text-[#8A8A8A] text-[10px] font-normal font-poppins">
                              CHF {service?.Tax}
                            </h1>
                          </div>
                          <div className="w-1/6 flex flex-row justify-start items-center">
                            <h1 className="text-[#8A8A8A] text-[10px] font-normal font-poppins">
                              CHF {service?.Cost}
                            </h1>
                          </div>
                        </div>
                      ))}

                    {/* Service Data being Added */}
                    <div className="flex flex-row gap-2 justify-between items-center w-full mb-2">
                      <div className="w-1/6 flex flex-row justify-start items-center">
                        {tempServiceData?.ServiceCode ? (
                          <h1 className="text-[#8A8A8A] text-[10px] font-normal font-poppins">
                            {tempServiceData?.ServiceCode}
                          </h1>
                        ) : (
                          <div className="flex w-full flex-row justify-center items-center bg-[#E9E8E8] px-4 py-2 mr-2">
                            <h1 className="text-[#8A8A8A] text-[10px] font-normal font-poppins">
                            </h1>
                          </div>
                        )}
                      </div>
                      <div className="w-1/6 flex flex-row justify-start items-center">
                        {tempServiceData?.ServiceName ? (
                          <h1 className="text-[#8A8A8A] text-[10px] font-normal font-poppins text-wrap truncate">
                            {tempServiceData?.ServiceName}
                          </h1>
                        ) : (
                          <div className="flex w-full flex-row justify-center items-center bg-[#E9E8E8] px-4 py-2 mr-2">
                            <h1 className="text-[#8A8A8A] text-[10px] font-normal font-poppins">
                            </h1>
                          </div>
                        )}
                      </div>
                      <div className="w-1/6 flex flex-row justify-start items-center">
                        {tempServiceData?.Quantity >= 1 ? (
                          <h1 className="text-[#8A8A8A] text-[10px] font-normal font-poppins">
                            {tempServiceData?.Quantity}
                          </h1>
                        ) : (
                          tempServiceData?.Time ? (
                            <h1 className="text-[#8A8A8A] text-[10px] font-normal font-poppins">
                              {tempServiceData?.Time} Mins
                            </h1>
                          ) : (
                            <div className="flex w-full flex-row justify-center items-center bg-[#E9E8E8] px-4 py-2 mr-2">
                              <h1 className="text-[#8A8A8A] text-[10px] font-normal font-poppins">
                              </h1>
                            </div>
                          )
                        )}
                      </div>
                      <div className="w-1/6 flex flex-row justify-start items-center">
                        {tempServiceData?.Rate ? (
                          <h1 className="text-[#8A8A8A] text-[10px] font-normal font-poppins">
                            CHF {tempServiceData?.Rate}
                          </h1>
                        ) : (
                          <div className="flex w-full flex-row justify-center items-center bg-[#E9E8E8] px-4 py-2 mr-2">
                            <h1 className="text-[#8A8A8A] text-[10px] font-normal font-poppins">
                            </h1>
                          </div>
                        )}
                      </div>
                      <div className="w-1/6 flex flex-row justify-start items-center">
                        {tempServiceData?.Tax ? (
                          <h1 className="text-[#8A8A8A] text-[10px] font-normal font-poppins">
                            CHF {tempServiceData?.Tax}
                          </h1>
                        ) : (
                          <div className="flex w-full flex-row justify-center items-center bg-[#E9E8E8] px-4 py-2 mr-2">
                            <h1 className="text-[#8A8A8A] text-[10px] font-normal font-poppins">
                            </h1>
                          </div>
                        )}
                      </div>
                      <div className="w-1/6 flex flex-row justify-start items-center">
                        {tempServiceData?.Cost ? (
                          <h1 className="text-[#8A8A8A] text-[10px] font-normal font-poppins">
                            CHF {tempServiceData?.Cost}
                          </h1>
                        ) : (
                          <div className="flex w-full flex-row justify-center items-center bg-[#E9E8E8] px-4 py-2 mr-2">
                            <h1 className="text-[#8A8A8A] text-[10px] font-normal font-poppins">
                            </h1>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="w-[100%] bg-[#F3F5F6] h-[2px]"></div>

                  <div className="flex flex-col justify-center items-center w-full mt-3 mb-2 gap-1 pr-6">
                    {/* <div className="flex flex-row self-end justify-center items-center w-1/3">
                      <h1 className="text-[#545454] text-[10px] font-normal font-poppins w-[60%]">
                        Subtotal
                      </h1>
                      <h1 className="text-black text-[10px] font-normal font-poppins w-[40%] text-right">
                        CHF {formData.Subtotal}
                      </h1>
                    </div>
                    <div className="flex flex-row self-end justify-center items-center w-1/3 mt-1">
                      <h1 className="text-[#545454] text-[10px] font-normal font-poppins w-[60%]">
                        VAT
                      </h1>
                      <h1 className="text-black text-[10px] font-normal font-poppins w-[40%] text-right">
                        CHF {formData.Tax}
                      </h1>
                    </div> */}
                    <div className="flex flex-row self-end justify-center items-center w-1/4">
                      <h1 className="text-black text-xs font-semibold font-poppins w-[60%]">
                        Total
                      </h1>
                      <h1 className="text-black text-xs font-semibold font-poppins w-[40%] text-right">
                        CHF {formData?.Total || `0.00`}
                      </h1>
                    </div>
                    <h1 className="self-end text-[#545454] text-xs font-normal font-poppins w-1/4 text-left mt-1">
                      (Inclusive of VAT)
                    </h1>
                  </div>

                  <div className="w-[95%] mx-4 bg-[#F3F5F6] h-[2px]"></div>

                  {/* Notes */}
                  <div className="w-full flex flex-col justify-center items-start gap-2 px-6 my-3">
                    <h1 className="text-black text-sm font-semibold font-poppins">
                      Notes
                    </h1>
                    <ul className="flex flex-col justify-center items-start w-full gap-1">
                      <li className="text-[#545454] text-[10px] font-normal font-poppins list-disc list-inside">
                        Payment Due in <span className="text-[#1C1C1C] font-semibold">30 days</span> from the invoice sent date.
                      </li>
                      <li className="text-[#545454] text-[10px] font-normal font-poppins list-disc list-inside">
                        Please contact us at{" "}
                        <a href={`mailto:${orgData?.Email}`} className="text-[#1C1C1C] font-semibold">{orgData?.Email}</a>
                        {" "}or{" "}
                        <a href={`tel:${orgData?.Phone}`} className="text-[#1C1C1C] font-semibold">{orgData?.Phone}</a>
                        {" "}for any queries.
                      </li>
                    </ul>
                  </div>

                  <div className="w-[95%] mx-4 border-t-2 border-dashed border-[#F3F5F6]"></div>

                  <div className="flex flex-row justify-center items-start w-full px-6 mb-8 mt-3">
                    {/* Receipt */}
                    <div className="w-1/2 border-r-2 border-dashed border-[#F3F5F6]">
                      <div className="w-[90%] flex flex-col justify-center items-start gap-2">
                        {/* Account/ Payable To: */}
                        <h1 className="text-[#1C1C1C] text-sm font-semibold font-poppins">
                          Receipt
                        </h1>
                        <div className="flex flex-col justify-center items-start w-full gap-1">
                          <h1 className="text-[#000000] text-xs font-semibold font-poppins">
                            Account/ Payable To:
                          </h1>
                          <p className="text-[#000000] text-[10px] font-medium font-poppins mt-1">
                            Company Name & Address: <span className="text-[#000000] text-[10px] font-normal font-poppins">{orgData?.Name} {orgData?.LocationInfo}</span>
                          </p>
                          <p className="text-[#000000] text-[10px] font-semibold font-poppins">
                            IBAN No: <span className="text-[#000000] text-[10px] font-medium font-poppins">{orgData?.IBAN_No}</span>
                          </p>
                          <p className="text-[#000000] text-[10px] font-semibold font-poppins">
                            Bank: <span className="text-[#000000] text-[10px] font-normal font-poppins">{orgData?.Bank}</span>
                          </p>
                          <p className="text-[#000000] text-[10px] font-semibold font-poppins">
                            Beneficiary: <span className="text-[#000000] text-[10px] font-normal font-poppins">{orgData?.Beneficiary}</span>
                          </p>
                          <p className="text-[#000000] text-[10px] font-semibold font-poppins">
                            Referance No. <span className="text-[#000000] text-[10px] font-normal font-poppins">{orgData?.Referance_No}</span>
                          </p>
                        </div>

                        {/* Account/ Payable By: */}
                        <div className="flex flex-col justify-center items-start w-full gap-1">
                          <h1 className="text-[#000000] text-xs font-semibold font-poppins">
                            Account/ Payable By:
                          </h1>
                          <p className="text-[#000000] text-[10px] font-medium font-poppins mt-1">
                            Patient Name & Address: <span className="text-[#000000] text-[10px] font-normal font-poppins">{formData?.PatientName} <br />
                              {formData?.Address &&
                                formData?.Address?.split(",").map((line, index) => (
                                  <span
                                    key={index}
                                    className="text-[#000000] text-[10px] font-normal font-poppins"
                                  >
                                    {line.trim()}
                                    {index < formData?.Address?.split(",").length - 1 && (
                                      <br />
                                    )}
                                  </span>
                                ))}</span>
                          </p>
                          <div className="flex flex-row justify-start items-start w-full gap-2 mt-2">
                            <div className="flex flex-col justify-center items-start w-12 gap-1">
                              <h1 className="text-[#000000] text-[10px] font-semibold font-poppins">
                                Currency:
                              </h1>
                              <p className="text-[#545454] text-[10px] font-normal font-poppins mt-1">CHF
                              </p>
                            </div>
                            <div className="flex flex-col justify-center items-start w-12 gap-1">
                              <h1 className="text-[#000000] text-[10px] font-semibold font-poppins">
                                Amount:
                              </h1>
                              <p className="text-[#545454] text-[10px] font-normal font-poppins mt-1">{formData?.Total || "0.00"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Scan QR */}
                    <div className="w-1/2 flex flex-col justify-center items-start gap-2 ml-5">
                      <h1 className="text-[#1C1C1C] text-sm font-semibold font-poppins">
                        Scan QR
                      </h1>
                      <div className="flex flex-col justify-start items-start w-full gap-2">
                        <div className="flex justify-start items-start w-full">
                          <img
                            src={qrCodeImg}
                            className="w-32 h-32 h-auto object-contain"
                            alt="QR Code"
                          />
                        </div>
                        <div className="flex flex-row justify-start items-start w-full gap-2">
                          <div className="flex flex-col justify-center items-start w-12 gap-1">
                            <h1 className="text-[#000000] text-[10px] font-semibold font-poppins">
                              Currency:
                            </h1>
                            <p className="text-[#545454] text-xs font-normal font-poppins mt-1">CHF
                            </p>
                          </div>
                          <div className="flex flex-col justify-center items-start w-12 gap-1">
                            <h1 className="text-[#000000] text-[10px] font-semibold font-poppins">
                              Amount:
                            </h1>
                            <p className="text-[#545454] text-xs font-normal font-poppins mt-1">{formData?.Total || "0.00"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Btns */}
          <div className="flex flex-row justify-center items-center w-full gap-4 mt-4">
            <button
              style={{ backgroundColor: "#FFFFFF" }}
              className="flex flex-row justify-center items-center gap-2 mt-4 w-1/2 bg-white rounded-lg text-center text-[#3CA5DC] text-base font-semibold font-poppins p-4 hover:bg-sky-500 transition duration-200 ease-in active:bg-sky-700 focus:outline-none focus:ring focus:ring-sky-300 border-2 border-[#3CA5DC]"
              onClick={() => {
                setShowModal(true);
              }}
            >
              <RiSendPlaneFill />
              <p>Send to Patient</p>
            </button>
            <button
              style={{ backgroundColor: "#3CA5DC" }}
              className="mt-4 w-1/2 bg-sky-400 rounded-lg text-center text-white text-base font-semibold font-poppins p-4 hover:bg-sky-500 transition duration-200 ease-in active:bg-sky-700 focus:outline-none focus:ring focus:ring-sky-300 border-2 border-[#3CA5DC]"
              onClick={(event) => {
                let msg = "Invoice will be sent to Insurance Company";
                submitHandler(event, msg, 0, "INSURANCE");
              }}
            >
              Send to Insurance Company
            </button>

            {/* Send Invoice Modal */}
            <ModalComponent
              show={showModal}
              handleClose={() => setShowModal(false)}
              outSideModalPress={false}
            >
              <div className="bg-[#FFFFFF] p-4">
                {/* Widget HEADER */}
                <div className="flex flex-row justify-between items-center">
                  {/* TITLE */}
                  <h1 className="text-black text-lg font-bold font-poppins rounded-lg">
                    Send Invoice With
                  </h1>
                </div>

                <div className="w-full bg-[#F3F5F6] h-[1px] my-5"></div>

                {/* Send Invoice Content */}
                <div className="flex flex-row justify-center items-center w-full gap-4 mt-4">
                  <div
                    onClick={(event) => {
                      let msg = "Invoice will be sent to Patient via Post";
                      submitHandler(event, msg, 0, "POST");
                    }}
                    className="py-10 flex flex-col justify-center items-center flex-1 bg-[#E5F2FF] rounded-lg cursor-pointer hover:scale-105 duration-500 relative overflow-hidden"
                  >
                    <div className="flex flex-col justify-center items-center w-[25%] h-auto z-50">
                      <img
                        src={postInvoiceIcon}
                        className="w-full h-full object-contain"
                        alt="Tile Icon"
                      />
                    </div>
                    <h1 className="text-[#3CA5DC] text-lg font-bold font-poppins mt-2">
                      Post
                    </h1>
                  </div>
                  <div
                    onClick={(event) => {
                      let msg = "Invoice will be sent to Patient via Email";
                      submitHandler(event, msg, 0, "PATIENT");
                    }}
                    className="py-10 flex flex-col justify-center items-center flex-1 bg-[#E5F2FF] rounded-lg cursor-pointer hover:scale-105 duration-500 relative overflow-hidden"
                  >
                    <div className="flex flex-col justify-center items-center w-[25%] h-auto z-50">
                      <img
                        src={mailInvoiceIcon}
                        className="w-full h-full object-contain"
                        alt="Tile Icon"
                      />
                    </div>
                    <h1 className="text-[#3CA5DC] text-lg font-bold font-poppins mt-2">
                      Email
                    </h1>
                  </div>
                </div>
              </div>
            </ModalComponent>
          </div>
        </div>
      </div >
    </div >
  );
}

export default AddInvoice;
