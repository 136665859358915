// store/breadcrumbSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const breadcrumbSlice = createSlice({
  name: 'breadcrumbs',
  initialState,
  reducers: {
    setBreadcrumbState: (state, action) => {
      const { path, stateData } = action.payload;
      state[path] = stateData;
    },
  },
});

export const { setBreadcrumbState } = breadcrumbSlice.actions;
export default breadcrumbSlice.reducer;
