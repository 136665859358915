import moment from "moment";
import React from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Pagination from "./Pagination";
import Tooltip from "./Tooltip";

function PatientListView({ patient }) {
  const navigate = useNavigate();
  return (
    <>
      <div className="w-full mx-auto mt-4 shadow rounded-md">
        <table className="min-w-full">
          <thead>
            <tr className="text-left bg-gray-50 border-b border-gray-200">
              <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                Name
              </th>
              <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                Contact Details
              </th>
              <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                Last Activity
              </th>
              <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                Actions
              </th>
              <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none"></th>
            </tr>
          </thead>
          <tbody>
            {patient?.map((user) => (
              <tr
                key={user.AHUID}
                className="border-b border-gray-200 hover:bg-sky-50"
              >
                <td className="p-4">
                  <div
                    onClick={() => {
                      navigate("/patientDashboard/patientProfile", {
                        state: user,
                      });
                    }}
                    className="flex items-center cursor-pointer"
                  >
                    <div
                      className={`${
                        user?.IsMedicalConciergeMember &&
                        " bg-gradient-to-r from-[#F6C86E] to-[#FFEE54] rounded-full p-0.5"
                      } mr-3`}
                    >
                      <img
                        src={user?.Image}
                        alt="PatientPic"
                        className="w-10 h-10 rounded-full"
                      />
                    </div>
                    <div>
                      <p className="text-gray-900 text-sm font-medium font-poppins leading-tight">
                        {user?.Name}
                      </p>
                      <p className="text-zinc-500 text-sm font-normal font-poppins leading-tight">
                        {user?.Gender},{" "}
                        {new Date().getFullYear() -
                          new Date(user.DOB).getFullYear()}
                      </p>
                    </div>
                  </div>
                </td>
                <td className="p-4">
                  <div className="flex flex-row items-center space-x-2">
                    <Tooltip
                      message={user?.Phone || "No phone number available"}
                      // activation="click"
                      showCopyButton={true}
                    >
                      <button className="p-1 rounded-full group group-hover:bg-sky-100">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6 text-neutral-600 group-hover:text-sky-400"
                        >
                          <path
                            d="M9.15826 5.71223L8.7556 4.80625C8.49232 4.21388 8.36068 3.91768 8.16381 3.69101C7.91708 3.40694 7.59547 3.19794 7.23568 3.08785C6.94859 3 6.62446 3 5.97621 3C5.02791 3 4.55376 3 4.15573 3.18229C3.68687 3.39702 3.26344 3.86328 3.09473 4.3506C2.95151 4.76429 2.99254 5.18943 3.07458 6.0397C3.94791 15.0902 8.90982 20.0521 17.9603 20.9254C18.8106 21.0075 19.2358 21.0485 19.6494 20.9053C20.1368 20.7366 20.603 20.3131 20.8178 19.8443C21 19.4462 21 18.9721 21 18.0238C21 17.3755 21 17.0514 20.9122 16.7643C20.8021 16.4045 20.5931 16.0829 20.309 15.8362C20.0824 15.6393 19.7862 15.5077 19.1938 15.2444L18.2878 14.8417C17.6463 14.5566 17.3255 14.4141 16.9996 14.3831C16.6876 14.3534 16.3731 14.3972 16.0811 14.5109C15.776 14.6297 15.5064 14.8544 14.967 15.3038C14.4302 15.7512 14.1618 15.9749 13.8338 16.0947C13.543 16.2009 13.1586 16.2403 12.8524 16.1951C12.5069 16.1442 12.2424 16.0029 11.7133 15.7201C10.0673 14.8405 9.15953 13.9328 8.27987 12.2867C7.99714 11.7577 7.85578 11.4931 7.80487 11.1477C7.75974 10.8414 7.79908 10.457 7.9053 10.1663C8.02512 9.83828 8.24881 9.56986 8.69619 9.033C9.14562 8.49368 9.37034 8.22402 9.48915 7.91891C9.60285 7.62694 9.64662 7.3124 9.61695 7.00048C9.58594 6.67452 9.44338 6.35376 9.15826 5.71223Z"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                        </svg>
                      </button>
                    </Tooltip>

                    <Tooltip
                      message={user?.Email || "No email available"}
                      // activation="click"
                      showCopyButton={true}
                    >
                      <button className="p-1 rounded-full group group-hover:bg-sky-100">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6 text-neutral-600 group-hover:text-sky-400"
                        >
                          <path
                            d="M2 6L8.91302 9.91697C11.4616 11.361 12.5384 11.361 15.087 9.91697L22 6"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M2.01577 13.4756C2.08114 16.5412 2.11383 18.0739 3.24496 19.2094C4.37608 20.3448 5.95033 20.3843 9.09883 20.4634C11.0393 20.5122 12.9607 20.5122 14.9012 20.4634C18.0497 20.3843 19.6239 20.3448 20.7551 19.2094C21.8862 18.0739 21.9189 16.5412 21.9842 13.4756C22.0053 12.4899 22.0053 11.5101 21.9842 10.5244C21.9189 7.45886 21.8862 5.92609 20.7551 4.79066C19.6239 3.65523 18.0497 3.61568 14.9012 3.53657C12.9607 3.48781 11.0393 3.48781 9.09882 3.53656C5.95033 3.61566 4.37608 3.65521 3.24495 4.79065C2.11382 5.92608 2.08114 7.45885 2.01576 10.5244C1.99474 11.5101 1.99475 12.4899 2.01577 13.4756Z"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </button>
                    </Tooltip>
                  </div>
                </td>
                <td className="p-4 text-neutral-600 text-sm font-normal font-poppins leading-tight">
                  {user?.LastActivity || moment().format("DD MMM YYYY")}
                </td>

                <td className="p-4">
                  <div className="flex flex-row items-center space-x-2">
                    <Tooltip message="View Profile">
                      <button
                        onClick={() => {
                          navigate("/patientDashboard/patientProfile", {
                            state: user,
                          });
                        }}
                        className="p-1 rounded-full group group-hover:bg-sky-100"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6 text-neutral-600 group-hover:text-sky-400"
                        >
                          <path
                            d="M21.3448 11.045C21.6488 11.4713 21.8008 11.6845 21.8008 12C21.8008 12.3155 21.6488 12.5287 21.3448 12.955C19.9787 14.8706 16.49 19 11.8008 19C7.11156 19 3.62288 14.8706 2.25682 12.955C1.95279 12.5287 1.80078 12.3155 1.80078 12C1.80078 11.6845 1.95279 11.4713 2.25682 11.045C3.62288 9.12944 7.11156 5 11.8008 5C16.49 5 19.9787 9.12944 21.3448 11.045Z"
                            stroke="currentColor"
                            stroke-width="1.5"
                          />
                          <path
                            d="M14.8008 12C14.8008 10.3431 13.4577 9 11.8008 9C10.1439 9 8.80078 10.3431 8.80078 12C8.80078 13.6569 10.1439 15 11.8008 15C13.4577 15 14.8008 13.6569 14.8008 12Z"
                            stroke="currentColor"
                            stroke-width="1.5"
                          />
                        </svg>
                      </button>
                    </Tooltip>

                    <Tooltip message="Manage">
                      <button
                        onClick={() => {
                          navigate("/patientDashboard/managePatient", {
                            state: user,
                          });
                        }}
                        className="p-1 rounded-full group group-hover:bg-sky-100"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="w-6 h-6 text-neutral-600 group-hover:text-sky-400"
                        >
                          <path
                            d="M16.0149 4.98239L17.4166 3.58063C18.1908 2.80646 19.446 2.80646 20.2202 3.58063C20.9943 4.3548 20.9943 5.60998 20.2202 6.38415L18.8184 7.78591M16.0149 4.98239L10.781 10.2163C9.73571 11.2616 9.21304 11.7842 8.85715 12.4211C8.50125 13.058 8.14318 14.5619 7.80078 16C9.23887 15.6576 10.7428 15.2995 11.3797 14.9436C12.0166 14.5877 12.5392 14.0651 13.5845 13.0198L18.8184 7.78591M16.0149 4.98239L18.8184 7.78591"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M20.8008 12C20.8008 16.2426 20.8008 18.364 19.4828 19.682C18.1648 21 16.0434 21 11.8008 21C7.55814 21 5.43682 21 4.1188 19.682C2.80078 18.364 2.80078 16.2426 2.80078 12C2.80078 7.75736 2.80078 5.63604 4.1188 4.31802C5.43682 3 7.55814 3 11.8008 3"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                        </svg>
                      </button>
                    </Tooltip>
                  </div>
                </td>
                <td className="p-4">
                  <button
                    onClick={() => {
                      navigate("/patientDashboard/bookAppointment", {
                        state: user,
                      });
                    }}
                    className="p-2 bg-gray-100 rounded shadow flex flex-row items-center justify-center space-x-2 active:scale-95"
                  >
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-6 h-6 text-zinc-900"
                    >
                      <path
                        d="M7.99072 1.5C7.57617 1.5 7.24072 1.83545 7.24072 2.25V2.93042H6.06592C3.94263 2.93042 2.21484 4.65747 2.21484 6.78076V18.6489C2.21484 20.7722 3.94263 22.5 6.06592 22.5H17.9341C20.0574 22.5 21.7852 20.7722 21.7852 18.6489V6.78076C21.7852 4.65747 20.0574 2.93042 17.9341 2.93042H16.7593V2.25C16.7593 1.83545 16.4238 1.5 16.0093 1.5C15.5947 1.5 15.2593 1.83545 15.2593 2.25V2.93042H8.74072V2.25C8.74072 1.83545 8.40527 1.5 7.99072 1.5ZM15.2593 4.43042V5.11011C15.2593 5.52466 15.5947 5.86011 16.0093 5.86011C16.4238 5.86011 16.7593 5.52466 16.7593 5.11011V4.43042H17.9341C19.2305 4.43042 20.2852 5.48511 20.2852 6.78076V18.6489C20.2852 19.9453 19.2305 21 17.9341 21H6.06592C4.76953 21 3.71484 19.9453 3.71484 18.6489V6.78076C3.71484 5.48511 4.76953 4.43042 6.06592 4.43042H7.24072V5.11011C7.24072 5.52466 7.57617 5.86011 7.99072 5.86011C8.40527 5.86011 8.74072 5.52466 8.74072 5.11011V4.43042H15.2593Z"
                        fill="currentColor"
                      />
                      <path
                        d="M6.375 12.7148C6.375 15.8167 8.89819 18.3398 12 18.3398C15.1018 18.3398 17.625 15.8167 17.625 12.7148C17.625 9.61304 15.1018 7.08984 12 7.08984C8.89819 7.08984 6.375 9.61304 6.375 12.7148ZM12 8.58984C14.2742 8.58984 16.125 10.4407 16.125 12.7148C16.125 14.989 14.2742 16.8398 12 16.8398C9.72583 16.8398 7.875 14.989 7.875 12.7148C7.875 10.4407 9.72583 8.58984 12 8.58984Z"
                        fill="currentColor"
                      />
                      <path
                        d="M10.125 13.4648H11.25V14.5898C11.25 15.0044 11.5854 15.3398 12 15.3398C12.4146 15.3398 12.75 15.0044 12.75 14.5898V13.4648H13.875C14.2896 13.4648 14.625 13.1294 14.625 12.7148C14.625 12.3003 14.2896 11.9648 13.875 11.9648H12.75V10.8398C12.75 10.4253 12.4146 10.0898 12 10.0898C11.5854 10.0898 11.25 10.4253 11.25 10.8398V11.9648H10.125C9.71045 11.9648 9.375 12.3003 9.375 12.7148C9.375 13.1294 9.71045 13.4648 10.125 13.4648Z"
                        fill="currentColor"
                      />
                    </svg>

                    <div className="text-zinc-900 text-xs font-medium font-poppins">
                      Add New Appointment
                    </div>
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* <Pagination
        totalItems={filteredUsers.length}
        itemsPerPage={itemsPerPage}
        currentPage={currentPage}
        onPageChange={handlePageChange}
      /> */}
    </>
  );
}

export default PatientListView;
