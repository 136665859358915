import React from "react";
import TitleWithAddButton from "./TitleWithAddButton";

function PowerOfAttorney() {
  return (
    <div>
      <TitleWithAddButton title="Power of Attorney" />
    </div>
  );
}

export default PowerOfAttorney;
