import React, { useState } from "react";
import { FaChevronLeft } from "react-icons/fa6";
import { IoIosSearch } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { IoMdAdd } from "react-icons/io";
import { RiSubtractLine } from "react-icons/ri";
import sortIcon from "../assets/Images/TreatmentProgram/Vertical_switch_light.png";
import ModalComponent from "../components/Modal";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  addServicesCardRate,
  deleteServicesCardRate,
  updateOrganizationUserDetails,
  updateServicesCardRate,
} from "../services/operations/masterDataAPI";
import { HiOutlineDotsVertical } from "react-icons/hi";
import manage from "../assets/Images/AccessManagement/manage.svg";
import deleteUser from "../assets/Images/AccessManagement/deleteUser.svg";

function AccessManagement() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const { allOrganizationUsers, loading: masterDataLoading } = useSelector(
    (state) => state.masterData
  );
  const [itemsToShow, setItemsToShow] = useState(3);
  const [searchTerm, setSearchTerm] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [showManageAccessModal, setShowManageAccessModal] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const roles = [
    { name: "Admin", checked: false },
    { name: "Doctor", checked: false },
    { name: "TeleHealth Doctor", checked: false },
    { name: "Nurse", checked: false },
    { name: "Health Manager", checked: false },
  ];
  const [accessLevels, setAccessLevels] = useState(roles);
  const [status, setStatus] = useState("Pending");

  const toggleCheckbox = (index) => {
    const newAccessLevels = [...accessLevels];
    newAccessLevels[index].checked = !newAccessLevels[index].checked;
    setAccessLevels(newAccessLevels);
  };
  const selectedRoles = accessLevels.filter((role) => role.checked);

  const handleOptionsClick = (item) => {
    if (selectedItem?.DID === item?.DID) {
      setShowOptions(!showOptions);
    } else {
      setShowOptions(true);
      setSelectedItem(item);
    }
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredServices = allOrganizationUsers.filter((service) =>
    service.Name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const increaseItems = () => {
    setItemsToShow(itemsToShow * 2);
  };

  const decreaseItems = () => {
    setItemsToShow(itemsToShow / 2);
  };

  const handleShowManageAccessModal = () => {
    setStatus(selectedItem?.Status);

    const updatedAccessLevels = roles.map((role) => ({
      ...role,
      checked: selectedItem?.AccessRoles.includes(role.name)
        ? true
        : role.checked,
    }));

    setAccessLevels(updatedAccessLevels);
    setShowManageAccessModal(true);
    setShowOptions(false);
  };

  const handleCloseManageAccessModal = () => {
    setStatus("Pending");
    setShowMenu(false);
    setAccessLevels(roles);
    setShowManageAccessModal(false);
    setSelectedItem(null);
  };

  const submitHandler = async () => {
    const roles = accessLevels && accessLevels.filter((role) => role.checked);
    const accessRoles = roles.map((role) => role.name);
    const data = {
      AccessRoles: accessRoles,
      Status: status,
    };
    dispatch(updateOrganizationUserDetails(token, selectedItem?.DCID, data));
    handleCloseManageAccessModal();
  };

  if (masterDataLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <div className="spinner"></div>
      </div>
    );
  }


  return (
    <div className="bg-white p-6 scroll-smooth min-h-full">
      <ModalComponent
        show={showManageAccessModal}
        handleClose={() => handleCloseManageAccessModal()}
        outSideModalPress={false}
      >
        <div className="w-[40vw]">
          {/* Header */}
          <div className="flex items-center justify-between">
            <div>
              <h1 className="text-black text-lg font-bold font-poppins">
                Manage Access Levels
              </h1>
              <p className="text-zinc-500 text-xs font-normal font-poppins">
                Here you can provide access to the employees at your
                Organization
              </p>
            </div>
          </div>
          <div className="border border-b-1 border-gray-200 my-6"></div>
          <div className="flex space-x-5">
            <div className="flex flex-col items-center justify-center space-y-2">
              <img
                src={selectedItem?.Image}
                alt="PatientPic"
                className="w-12 h-12 rounded-full object-cover shadow-lg"
              />
              <div className="text-neutral-400 text-xs font-semibold font-poppins">
                ID - {selectedItem?.DID}
              </div>
            </div>
            <div className="flex flex-col mt-2">
              <div className="text-black text-sm font-semibold font-poppins">
                {selectedItem?.Name}
              </div>
              <div className="text-zinc-500 text-xs font-normal font-poppins">
                {selectedItem?.Email}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-[1fr,2fr,1fr] gap-4 mt-6">
            <div className="flex items-center space-x-2">
              <div className="text-black text-sm font-semibold font-poppins">
                Speciality
              </div>
              {/* <img src={sortIcon} alt="" className="w-6 h-6" /> */}
            </div>
            <div className="flex items-center justify-center space-x-2">
              <div className="text-black text-sm font-semibold font-poppins">
                Access Levels
              </div>
              {/* <img src={sortIcon} alt="" className="w-6 h-6" /> */}
            </div>
            <div className="flex items-center justify-end space-x-8">
              <div className="text-black text-sm font-semibold font-poppins">
                Status
              </div>
              <div className="relative">
                <button
                  onClick={() => setShowMenu(!showMenu)}
                  className="p-1 bg-zinc-100 text-sky-500 rounded-full active:bg-sky-100 active:scale-95 cursor-pointer"
                >
                  <HiOutlineDotsVertical />
                </button>
                {showMenu && (
                  <div className="absolute right-0 top-8 w-56 bg-white border rounded-lg p-2 space-y-2 shadow-lg z-10">
                    <div className="flex flex-col space-y-2">
                      <button
                        onClick={() => {
                          setStatus("Inactive");
                          setShowMenu(false);
                        }}
                        className="flex flex-row items-center justify-center hover:bg-gray-100 rounded py-1"
                      >
                        <div className="text-red-400 text-xs font-normal font-poppins">
                          Revoke Access - Unauthorized
                        </div>
                      </button>
                      <button
                        onClick={() => {
                          setStatus("Active");
                          setShowMenu(false);
                        }}
                        className="flex flex-row items-center justify-center hover:bg-gray-100 rounded py-1"
                      >
                        <div className="text-black text-xs font-normal font-poppins">
                          Provide Access - Authorized
                        </div>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="grid grid-cols-[1fr,2fr,1fr] gap-4 items-center mt-2">
            <div className="text-black text-xs font-normal font-poppins">
              {selectedItem?.RelatedSpecialities[0]?.Speciality}
            </div>
            <div className="flex items-center justify-center">
              <div className="flex flex-row flex-wrap gap-2">
                {selectedRoles?.map((role, index) => (
                  <div
                    key={index}
                    className="px-2.5 py-1 bg-sky-100 rounded-xl"
                  >
                    <div className="text-sky-400 text-xs font-semibold font-poppins">
                      {role?.name}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex items-center justify-center">
              <div
                className={`w-32 p-2 ${
                  status === "Active"
                    ? "bg-lime-100 text-lime-500"
                    : status === "Pending"
                    ? "bg-neutral-300 text-zinc-500"
                    : "bg-red-200 text-rose-500"
                } rounded-lg`}
              >
                <div className="text-center text-xs font-normal font-poppins">
                  {status === "Active"
                    ? "Authorized"
                    : status === "Inactive"
                    ? "Unauthorized"
                    : "Pending"}
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="my-4 relative">
              <div className="flex flex-col">
                <div className="text-black text-sm font-semibold font-poppins">
                  Change Access Level
                </div>
                <div className="absolute top-4 left-2 border-l border-dashed border-neutral-400 h-40" />
              </div>
              <ul>
                {accessLevels.map((role, index) => (
                  <li key={index} className="flex items-center space-y-2">
                    <div className="flex items-center justify-start mt-3 ml-2">
                      <div className="w-10 border-t border-dashed border-neutral-400" />
                      <label
                        className="relative flex cursor-pointer items-center"
                        htmlFor="rememberMe"
                        // data-ripple-dark="true"
                      >
                        <input
                          type="checkbox"
                          checked={role.checked}
                          onChange={() => toggleCheckbox(index)}
                          className="before:content[''] peer relative h-4 w-4 border-2 border-neutral-400
             cursor-pointer appearance-none transition-all before:absolute
              before:top-2/4 before:left-2/4 before:block before:h-8 before:w-8
               before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-lg 
               before:bg-blue-gray-500 before:opacity-0 before:transition-opacity
                checked:border-sky-400 checked:bg-sky-400 checked:before:bg-sky-400 
                hover:before:opacity-10"
                        />
                        <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-3.5 w-3.5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                            stroke="currentColor"
                            strokeWidth={1}
                          >
                            <path
                              fillRule="evenodd"
                              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        </div>
                      </label>
                      <p className="text-black text-sm font-normal font-poppins ml-2">
                        {role.name}
                      </p>
                    </div>
                  </li>
                ))}
              </ul>
            </div>

            <div className="flex flex-col">
              <div className="flex items-center justify-start mt-3 ml-2">
                <label
                  className="relative flex cursor-pointer items-center"
                  htmlFor="rememberMe"
                  // data-ripple-dark="true"
                >
                  <input
                    type="checkbox"
                    defaultChecked={true}
                    // onChange={handleOnChange}
                    className="before:content[''] peer relative h-6 w-6 border-2 border-neutral-400
        cursor-pointer appearance-none transition-all before:absolute
        before:top-2/4 before:left-2/4 before:block before:h-10 before:w-10
        before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-lg 
        before:bg-blue-gray-500 before:opacity-0 before:transition-opacity
        checked:border-sky-400 checked:bg-sky-400 checked:before:bg-sky-400 
        hover:before:opacity-10"
                  />
                  <div className="pointer-events-none absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 text-white opacity-0 transition-opacity peer-checked:opacity-100">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-5 w-5"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      stroke="currentColor"
                      strokeWidth={1}
                    >
                      <path
                        fillRule="evenodd"
                        d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                </label>

                <p className="text-center text-black text-sm font-semibold font-poppins ml-2">
                  Send Email
                </p>
              </div>
              <div className="w-44 text-neutral-400 text-xs font-normal font-poppins ml-10">
                Send the mail to the Selected Employee with new Access Level for
                your Organization
              </div>
            </div>
          </div>
        </div>

        <button
          onClick={() => submitHandler()}
          className="w-52 h-11 mt-5 ml-3 shadow-md shadow-sky-500 bg-sky-400 rounded-lg  active:scale-95 active:shadow-none text-center text-stone-50 text-sm font-semibold font-poppins "
        >
          Save
        </button>
      </ModalComponent>
      <div className="flex flex-row items-center space-x-4">
        <button
          className="active:scale-90"
          onClick={() => {
            navigate(-1);
          }}
        >
          <FaChevronLeft className="h-5" />
        </button>
        <div className="text-black text-lg font-bold font-poppins">
          Access Management
        </div>
      </div>
      <label className=" w-64 flex flex-row items-center justify-start bg-gray-100 rounded-2xl py-2 px-4 gap-2 mt-6">
        <IoIosSearch className="text-neutral-400 w-4 h-4" />
        <input
          type="text"
          placeholder="Search here"
          value={searchTerm}
          onChange={handleSearchChange}
          className={`bg-gray-100 text-xs text-neutral-400 rounded-r-2xl focus:outline-none focus:border-transparent`}
        />
      </label>
      <div className="rounded-2xl shadow-lg p-5 mt-8">
        <div className="grid grid-cols-[1fr,1fr,2fr,1fr] gap-4 items-center">
          <div className="flex items-center space-x-2">
            <div className="text-black text-sm font-semibold font-poppins">
              Name
            </div>
            <img src={sortIcon} alt="" className="w-6 h-6" />
          </div>
          <div className="flex items-center space-x-2">
            <div className="text-black text-sm font-semibold font-poppins">
              Speciality
            </div>
            {/* <img src={sortIcon} alt="" className="w-6 h-6" /> */}
          </div>
          <div className="flex items-center space-x-2">
            <div className="text-black text-sm font-semibold font-poppins">
              Access Level
            </div>
            {/* <img src={sortIcon} alt="" className="w-6 h-6" /> */}
          </div>
          <div className="flex items-center ">
            <div className="text-black text-sm font-semibold font-poppins">
              Status
            </div>
            {/* <img src={sortIcon} alt="" className="w-6 h-6" /> */}
          </div>
        </div>
        <div className="my-10 space-y-9">
          {filteredServices.slice(0, itemsToShow).map((item) => (
            <div
              key={item.DID}
              className="grid grid-cols-[1fr,1fr,2fr,1fr] gap-4 items-center"
            >
              <div className="flex items-center space-x-3">
                <img
                  src={item?.Image}
                  alt="PatientPic"
                  className="w-12 h-12 rounded-full object-cover"
                />
                <div className="flex flex-col w-32">
                  <div className="text-black text-sm font-semibold font-poppins">
                    {item.Name}
                  </div>
                  <div className="text-zinc-500 text-xs font-normal font-poppins">
                    ID : {item.DID}
                  </div>
                </div>
              </div>
              <div className="text-black text-sm font-normal font-poppins">
                {item?.RelatedSpecialities[0]?.Speciality}...
              </div>
              <div className="flex flex-row items-center flex-wrap gap-2">
                {item?.AccessRoles?.map((role, index) => (
                  <div
                    key={index}
                    className="px-2.5 py-1 bg-sky-100 rounded-xl"
                  >
                    <div className="text-sky-400 text-sm font-semibold font-poppins">
                      {role}
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex items-center justify-between">
                <div
                  className={`w-32 p-2 ${
                    item?.Status === "Active"
                      ? "bg-lime-100 text-lime-500"
                      : item?.Status === "Pending"
                      ? "bg-neutral-300 text-zinc-500"
                      : "bg-red-200 text-rose-500"
                  } rounded-lg`}
                >
                  <div className="text-center  text-xs font-semibold font-poppins">
                    {item?.Status === "Active"
                      ? "Authorized"
                      : item?.Status === "Inactive"
                      ? "Unauthorized"
                      : "Pending"}
                  </div>
                </div>
                <div className="relative">
                  <button
                    onClick={() => handleOptionsClick(item)}
                    className="p-1 bg-zinc-100 text-sky-500 rounded-full active:bg-sky-100 active:scale-95 cursor-pointer"
                  >
                    <HiOutlineDotsVertical />
                  </button>
                  {showOptions && selectedItem.DID === item.DID && (
                    <div className="absolute right-8 top-0 w-36 bg-white border rounded-lg p-2 space-y-2 shadow-lg z-10">
                      <div className="flex flex-col space-y-2">
                        <button
                          onClick={handleShowManageAccessModal}
                          className="flex flex-row items-center justify-center space-x-2 hover:bg-gray-100 rounded py-1"
                        >
                          <div className="text-black text-xs font-normal font-poppins">
                            Manage Access
                          </div>
                          <img src={manage} alt="" />
                        </button>
                        {/* <button
                          onClick={() => {
                            setShowOptions(false);
                            setSelectedItem(null);
                          }}
                          className="flex flex-row items-center justify-center space-x-2 hover:bg-gray-100 rounded py-1"
                        >
                          <div className="text-red-400 text-xs font-normal font-poppins">
                            Delete User
                          </div>
                          <img src={deleteUser} alt="" />
                        </button> */}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        <footer className="flex flex-row items-center justify-between">
          <div className="text-zinc-500 text-sm font-normal font-poppins">
            Showing {Math.min(itemsToShow, allOrganizationUsers.length)} items
            per page
          </div>
          <div className="flex flex-row items-center space-x-3">
            <button
              className={`active:scale-95 w-10 h-10 rounded-md flex items-center justify-center ${
                itemsToShow < allOrganizationUsers.length
                  ? "text-sky-500 bg-sky-100"
                  : "text-gray-400 bg-gray-100"
              }`}
              onClick={increaseItems}
              disabled={itemsToShow >= allOrganizationUsers.length}
            >
              <IoMdAdd />
            </button>
            <div className="text-center text-black text-xs font-normal font-poppins bg-gray-100 rounded w-12 h-10 flex items-center justify-center ">
              1 - {Math.min(itemsToShow, allOrganizationUsers.length)}
            </div>
            <button
              className={`active:scale-95 w-10 h-10 rounded-md flex items-center justify-center ${
                itemsToShow > 3
                  ? "text-sky-500 bg-sky-100"
                  : "text-gray-400 bg-gray-100"
              }`}
              onClick={decreaseItems}
              disabled={itemsToShow <= 3}
            >
              <RiSubtractLine />
            </button>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default AccessManagement;
