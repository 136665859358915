import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaPlus, FaMinus, FaChevronLeft, FaHeartPulse } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import iphoneIsland from "../../assets/Images/OurServices/iphoneIsland.png";
import servicePreviewBg from "../../assets/Images/OurServices/servicePreviewBg.png";
import dollarIcon from "../../assets/Images/OurServices/dollarIcon.png";
import durationIcon from "../../assets/Images/OurServices/durationIcon.png";
import uploadIcon from "../../assets/Images/OurServices/uploadIcon.png";
import Breadcrumb from "../../components/Breadcrumb";
import toast from "react-hot-toast";
import iphoneFrame from "../../assets/Images/MedicalConcierge/iphoneFrame.svg";
import Dropdown from "../../components/Dropdown";
import { FaExternalLinkAlt } from "react-icons/fa";
import { addNewTreatmentCategory, addNewTreatmentService, updateTreatmentService, uploadOurServicesImage } from "../../services/operations/ourServicesAPI";
import imageCompression from "browser-image-compression";

function AddServices() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { token } = useSelector((state) => state.auth);
    const service = location?.state?.specificService || null;
    const Ostcid = location?.state?.OSTCID || null;
    const [isLoading, setIsLoading] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [OSTSID, setOSTSID] = useState(null);
    const [OSTCID, setOSTCID] = useState(null);
    const [image, setImage] = useState("");
    const [formData, setFormData] = useState({
        LogoLocation: "",
        ServiceName: "",
        MinDuration: "",
        MaxDuration: "",
        DurationType: "",
        StartPrice: "",
        About: "",
    });
    const [errors, setErrors] = useState({
        LogoLocation: "",
        ServiceName: "",
        MinDuration: "",
        MaxDuration: "",
        DurationType: "",
        StartPrice: "",
        About: "",
    });

    useEffect(() => {
        setOSTCID(Ostcid);
    }, [Ostcid])


    useEffect(() => {
        if (service) {
            setUpdating(true);
            setOSTSID(service?.OSTSID);
            setOSTCID(service?.OSTCID);
            setImage(service?.Image);
            setFormData({
                LogoLocation: service?.Image,
                ServiceName: service?.ServiceName,
                MinDuration: service?.MinDuration,
                MaxDuration: service?.MaxDuration,
                DurationType: service?.DurationType,
                StartPrice: service?.StartPrice,
                About: service?.Description,
            });
        }
    }, [service]);

    if (isLoading) {
        return (
            <div className="h-screen flex items-center justify-center">
                <div className="spinner"></div>
            </div>
        );
    }

    return (
        <div className="bg-white min-h-full py-2 px-2 sm:py-5 sm:px-10 scroll-smooth flex flex-col">
            {/* HEADER */}
            <div className="flex flex-row justify-between items-center">
                {/* TITLE */}
                <div className="flex flex-row items-center">
                    <div className="flex flex-col justify-center items-start gap-1">
                        <h1 className="text-[#1C1C1C] text-2xl font-medium font-poppins leading-9">
                            Add New Service
                        </h1>
                        <Breadcrumb currentState={location?.state} />
                    </div>
                </div>
            </div>

            {/* Content */}
            <div className="flex gap-8 p-8">
                <ServiceForm
                    formData={formData}
                    setFormData={setFormData}
                    errors={errors}
                    setErrors={setErrors}
                    image={image}
                    setImage={setImage}
                    OSTCID={OSTCID}
                    OSTSID={OSTSID}
                    updating={updating}
                    setUpdating={setUpdating}
                />
                <PreviewSection
                    formData={formData}
                    image={image}
                />
            </div>
        </div>
    );
}

const ServiceForm = ({
    formData,
    setFormData,
    errors,
    setErrors,
    image,
    setImage,
    OSTCID,
    OSTSID,
    updating,
    setUpdating,
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { token } = useSelector((state) => state.auth);
    const [changeImage, setChangeImage] = useState(false);

    function handleOnChange(event) {
        const { name, value } = event.target;
        let maxLength;

        // Set the maxLength based on the field name
        switch (name) {
            case "MinDuration":
                maxLength = 2;
                break;
            case "MaxDuration":
                maxLength = 2;
                break;
            case "StartPrice":
                maxLength = 6;
                break;
            default:
                maxLength = Infinity; // No maxLength for other fields
        }

        // For other types, handle validation without removing non-numeric characters
        if (value.length <= maxLength) {
            setFormData((prev) => ({
                ...prev,
                [name]: value,
            }));
            setErrors((prev) => ({
                ...prev,
                [name]: "",
            }));
        }
    }

    function handleSelect(name, option) {
        setFormData((prev) => ({
            ...prev,
            [name]: option,
        }));
        setErrors((prev) => ({
            ...prev,
            [name]: "",
        }));
    }

    const handleImageChange = async (e) => {
        if (updating) setChangeImage(true);
        const file = e.target.files[0];
        if (!file) return;
        const options = {
            maxSizeMB: 0.5,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
        };
        const toastId = toast.loading("Compressing Image...");
        const compressedFile = await imageCompression(file, options);
        toast.dismiss(toastId);
        setImage(compressedFile);
        uploadImage(compressedFile);
    };

    const uploadImage = async (file) => {
        const formData = new FormData();
        formData.append("ServiceImage", file);
        try {
            const res = await uploadOurServicesImage(formData, token);
            setFormData((prev) => ({
                ...prev,
                LogoLocation: res?.ServiceImageUrl,
            }));
            setErrors((prev) => ({
                ...prev,
                LogoLocation: "",
            }));
            setImage(URL.createObjectURL(file));
        } catch (error) {
            console.log(error);
        }
    };

    const submitHandler = async (event) => {
        event.preventDefault();
        const mandatoryFields = [
            "LogoLocation",
            "ServiceName",
            "MinDuration",
            "MaxDuration",
            "DurationType",
            "StartPrice",
            "About",
        ];
        const newErrors = {};

        mandatoryFields.forEach((key) => {
            const value = formData[key];

            if (!value) {
                newErrors[key] = "This field is required";
            } else if (typeof value === "string" && value.trim() === "") {
                newErrors[key] = "This field cannot be empty";
            }
        });

        if (Object.keys(newErrors).length > 0) {
            toast.error("Please fill all the required fields");
            setErrors(newErrors);
            console.log("ERRORS", errors);
            return;
        }

        if (!updating) {
            if (OSTCID) {
                const body = {
                    OSTCID: OSTCID,
                    Image: formData?.LogoLocation,
                    ServiceName: formData?.ServiceName,
                    MinDuration: formData?.MinDuration,
                    MaxDuration: formData?.MaxDuration,
                    DurationType: formData?.DurationType,
                    StartPrice: formData?.StartPrice,
                    Description: formData?.About,
                };
                try {
                    dispatch(addNewTreatmentService(token, body, navigate));
                } catch (error) {
                    console.error("Error adding treatment service:", error);
                }
            } else {
                toast.error("Couldn't Add New Service! Try later")
            }
        } else {
            if (OSTSID) {
                let body;
                if (changeImage) {
                    body = {
                        Image: formData?.LogoLocation,
                        ServiceName: formData?.ServiceName,
                        MinDuration: formData?.MinDuration,
                        MaxDuration: formData?.MaxDuration,
                        DurationType: formData?.DurationType,
                        StartPrice: formData?.StartPrice,
                        Description: formData?.About,
                    };
                } else {
                    body = {
                        ServiceName: formData?.ServiceName,
                        MinDuration: formData?.MinDuration,
                        MaxDuration: formData?.MaxDuration,
                        DurationType: formData?.DurationType,
                        StartPrice: formData?.StartPrice,
                        Description: formData?.About,
                    };
                }

                try {
                    dispatch(updateTreatmentService(token, OSTSID, body, navigate));
                } catch (error) {
                    console.error("Error adding treatment service:", error);
                } finally {
                    setUpdating(false);
                    setChangeImage(false);
                }
            } else {
                toast.error("Couldn't Update Service! Try later")
            }
        }
    };

    return (
        <div className="w-1/2">
            <div className="bg-white rounded-lg shadow-md p-6 border border-sky-500">
                {/* Service Image */}
                <div className="mb-6">
                    <label
                        className={`w-full border-dashed ${errors.LogoLocation ? "border-red-500" : "border-[#8A8A8A]"
                            }  border-2 rounded-2xl p-4 flex flex-col items-center justify-center cursor-pointer bg-white
                    `}
                    >
                        {formData?.LogoLocation ? (
                            <>
                                <img
                                    src={image}
                                    alt="upload icon"
                                    className="w-14 h-14 object-contain"
                                />

                                <span className="text-sm font-medium font-poppins mt-2">
                                    Change Photo
                                </span>
                            </>
                        ) : (
                            <>
                                <img
                                    src={uploadIcon}
                                    alt="upload icon"
                                    className="w-14 h-14 object-contain"
                                />
                                <span className="text-sm font-medium font-poppins mt-2">
                                    Add Photo
                                </span>
                                <span className="text-[#555252] text-[10px] font-normal font-poppins mt-1">
                                    (Click to Add)
                                </span>
                            </>
                        )}

                        <input
                            type="file"
                            accept="image/*"
                            name="LogoLocation"
                            value={undefined}
                            onChange={(e) => handleImageChange(e)}
                            style={{ display: "none" }}
                        />
                    </label>
                </div>

                {/* Service Name */}
                <div className="mb-6">
                    <label>
                        <div className="flex items-center justify-between">
                            <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                                Add name of service
                                <sup className="text-red-600">*</sup>
                            </p>
                        </div>
                        <input
                            type="text"
                            name="ServiceName"
                            value={formData.ServiceName}
                            onChange={handleOnChange}
                            maxLength={30}
                            style={{ wordBreak: "break-word", overflowWrap: "break-word" }}
                            placeholder="Enter name of service"
                            className={`w-full ${errors.ServiceName ? "border-red-500" : ""
                                }  text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent break-words`}
                        />
                    </label>
                </div>

                {/* Select min. duration, Select max duration, Select days/week/month */}
                <div className="mb-2 flex space-x-4">
                    {/* Select min. duration */}
                    <label className="flex-1">
                        <p className="text-zinc-900 text-sm font-medium  font-poppins mb-2">
                            Select min. duration
                            <sup className="text-red-600">*</sup>
                        </p>
                        <input
                            type="number"
                            name="MinDuration"
                            maxlength={2}
                            max={2}
                            onKeyDown={(e) => {
                                if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                                    e.preventDefault();
                                }
                            }}
                            onWheel={(e) => {
                                e.preventDefault();
                                e.target.blur();
                            }}
                            value={formData.MinDuration}
                            onChange={handleOnChange}
                            placeholder="Ex: 1"
                            className={`w-full ${errors.MinDuration ? "border-red-500" : ""
                                }  text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                        />
                    </label>

                    {/* Select max duration */}
                    <label className="flex-1">
                        <p className="text-zinc-900 text-sm font-medium  font-poppins mb-2">
                            Select max duration
                            <sup className="text-red-600">*</sup>
                        </p>
                        <input
                            type="number"
                            name="MaxDuration"
                            maxlength={2}
                            max={2}
                            onKeyDown={(e) => {
                                if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                                    e.preventDefault();
                                }
                            }}
                            onWheel={(e) => {
                                e.preventDefault();
                                e.target.blur();
                            }}
                            value={formData.MaxDuration}
                            onChange={handleOnChange}
                            placeholder="Ex: 20"
                            className={`w-full ${errors.MaxDuration ? "border-red-500" : ""
                                }  text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                        />
                    </label>

                    {/* Select days/week/month */}
                    <label className="flex-1">
                        <p className="text-zinc-900 text-sm font-medium  font-poppins mb-2">
                            Select duration type
                            <sup className="text-red-600">*</sup>
                        </p>

                        <Dropdown
                            placeholder={"Select"}
                            error={errors.DurationType}
                            name="DurationType"
                            options={["Days", "Week", "Month", "Year"]}
                            onSelect={handleSelect}
                            value={formData.DurationType || ""}
                        />
                    </label>
                </div>

                <p className="text-[#8A8A8A] text-xs font-normal font-poppins mb-6">
                    Specify the expected range of treatment duration
                </p>

                {/* Start Price, End Price */}
                <div className="mb-2">
                    {/* Start Price */}
                    <label className="flex-1">
                        <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                            Start Price
                            <sup className="text-red-600">*</sup>
                        </p>
                        <input
                            type="number"
                            name="StartPrice"
                            maxlength={2}
                            onKeyDown={(e) => {
                                if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                                    e.preventDefault();
                                }
                            }}
                            onWheel={(e) => {
                                e.preventDefault();
                                e.target.blur();
                            }}
                            value={formData.StartPrice}
                            onChange={handleOnChange}
                            placeholder="Starting from..."
                            className={`w-full ${errors.StartPrice ? "border-red-500" : ""
                                }  text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                        />
                    </label>
                </div>

                <p className="text-[#8A8A8A] text-xs font-normal font-poppins mb-6">
                    Enter a min start price for the cost of this service
                </p>

                {/* About the service  */}
                <div className="mb-6">
                    <label>
                        <div className="flex items-center justify-between">
                            <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                                About the service
                                <sup className="text-red-600">*</sup>
                            </p>
                            <p className="text-right text-neutral-600 text-xs font-normal font-poppins">
                                {formData?.About.length}/100
                            </p>
                        </div>
                        <textarea
                            type="text"
                            name="About"
                            value={formData.About}
                            onChange={handleOnChange}
                            maxLength={100}
                            placeholder="Provide details about the service"
                            className={`w-full h-40 ${errors.About ? "border-red-500" : ""
                                }  text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                        />
                    </label>
                </div>
            </div>
            <div className="flex flex-row items-center justify-center mt-6 space-x-4">
                <button
                    onClick={submitHandler}
                    className="flex-1 px-5 py-3 flex items-center justify-center bg-sky-500 rounded-lg border border-sky-500 active:scale-95"
                >
                    <div className="text-white text-base font-semibold font-poppins leading-normal">
                        Done
                    </div>
                </button>
            </div>
        </div>
    );
};

const PhoneMockup = ({ children }) => {
    return (
        <div
            className="relative mx-auto w-[300px] h-[600px]"
        >
            <img
                src={iphoneFrame}
                alt="iPhone Frame"
                className="w-full h-full object-contain"
            />
            <div className="absolute inset-y-[1.6%] left-[5%] right-[5.8%] overflow-hidden rounded-[40px]">{children}</div>
        </div>
    );
};

const PreviewSection = ({
    formData,
    image,
}) => {
    return (
        <div className="w-1/2">
            <div className="bg-white rounded-lg shadow-md p-6">
                <div className="text-center text-zinc-900 text-xl font-semibold font-poppins mb-7">
                    Preview for Patient
                </div>
                <div className="relative flex items-center justify-center h-[600px]">
                    <div>
                        <div
                            className={`transition-all duration-300`}
                        >
                            <PhoneMockup
                            >
                                <FrontView
                                    formData={formData}
                                    image={image}
                                />
                            </PhoneMockup>
                        </div>
                    </div>
                </div>
                <div className="w-full bg-gray-100 rounded-lg mt-7 p-2.5 flex items-center justify-center">
                    <div className="w-96 text-center text-black text-xl font-normal font-poppins">
                        This is how your service will look to Patients on the App
                    </div>
                </div>
            </div>
        </div>
    );
};

const FrontView = ({ formData, image }) => (
    <div className="h-full w-full flex flex-col justify-between relative bg-cover"
        style={{ backgroundImage: `url(${servicePreviewBg})` }}>
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <img
            src={iphoneIsland}
            alt="iphone Island"
            className="w-16 h-6 object-contain absolute top-4 left-[6.5rem]"
        />

        {/* Card Content */}
        <div className="absolute bottom-28 z-50 flex flex-col justify-center items-center w-[90%] rounded-xl mx-4 bg-white py-3 px-4">
            {/*  */}
            <div className="flex flex-row justify-start items-start w-full gap-2">
                {formData?.LogoLocation ? (
                    <>
                        <img
                            src={image}
                            alt="Service Image"
                            className="w-10 h-10 object-cover rounded-full"
                        />
                    </>
                ) : (
                    <>
                        <img
                            src={uploadIcon}
                            alt="upload icon"
                            className="w-10 h-10 object-contain"
                        />
                    </>
                )}

                <div className="flex flex-col justify-center items-start">
                    <h1 style={{ wordBreak: "break-word", overflowWrap: "break-word" }} className="text-[#000000] text-[10px] font-semibold  font-poppins mb-2">
                        {formData?.ServiceName || "Service Name"}
                    </h1>
                    {/* Duration */}
                    <div className="flex flex-row justify-between items-center w-full mb-2 gap-2">
                        <div className="flex flex-row justify-start items-center gap-1">
                            <img
                                src={dollarIcon}
                                alt="dollar icon"
                                className="w-[10px] h-[10px] object-contain"
                            />
                            <h1 className="text-[#000000] text-[8px] font-normal font-poppins">
                                Duration of Treatment:
                            </h1>
                        </div>
                        <h1 className="text-[#000000] text-[8px] font-normal  font-poppins self-end">
                            {formData?.MinDuration} - {formData?.MaxDuration} {formData?.DurationType}
                        </h1>
                    </div>
                    {/* Cost */}
                    <div className="flex flex-row justify-between items-center w-full gap-2">
                        <div className="flex flex-row justify-start items-center gap-1">
                            <img
                                src={durationIcon}
                                alt="duration icon"
                                className="w-[10px] h-2 object-contain"
                            />
                            <h1 className="text-[#000000] text-[8px] font-normal  font-poppins">
                                Cost of Treatment: {" "}
                            </h1>
                        </div>
                        <h1 className="text-[#000000] text-[8px] font-normal  font-poppins self-end">
                            {formData?.StartPrice ? `From ${formData?.StartPrice} CHF` : '-'}
                        </h1>
                    </div>
                </div>
            </div>

            <div className="w-[100%] mx-2 mt-4 mb-6 bg-[#3CA5DC] h-[1px]"></div>

            {/* More Info */}
            <div className="flex flex-row justify-start items-start w-[90%] mx-2 gap-2">
                <h1 className="text-[#000000] text-[8px] font-semibold  font-poppins">
                    More Info:
                </h1>
                <h1 className="text-[#000000] text-[10px] font-normal font-poppins break-words ml-1">
                    {formData?.About || "-"}
                </h1>
            </div>

            <div className="flex flex-row items-center justify-center mt-4 space-x-2">
                <button
                    disabled={true}
                    className="flex-1 px-2 py-1 flex gap-2 flex-row items-center justify-center bg-[#E5F2FF] rounded-md active:scale-95"
                >
                    <div className="text-[#3CA5DC] text-[8px] font-normal font-poppins leading-normal">
                        Website
                    </div>
                    <FaExternalLinkAlt className="text-xs text-[#3CA5DC]" />
                </button>
                <button
                    disabled={true}
                    className="flex-1 px-2 py-1 flex items-center justify-center bg-sky-500 rounded-md active:scale-95"
                >
                    <div className="text-white text-[8px] font-normal font-poppins leading-normal">
                        Book Session
                    </div>
                </button>
            </div>
        </div>
    </div>
);

export default AddServices;
