import React, { useState } from "react";
import background from "../assets/Images/PatientProfile/backgroundVector.png";
import currentPlanIcon from "../assets/Images/PatientProfile/ConceirgePlan/currentPlan.png";
import checkmark from "../assets/Images/PatientProfile/ConceirgePlan/checkmark.svg";
import deleteIcon from "../assets/Images/MedicalConcierge/deleteRedIcon.svg";
import MedicalConceirgePlanCard from "./MedicalConceirgePlanCard";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  cancelCurrentPlan,
  changeConciergePlan,
} from "../services/operations/patientManagementAPI";
import { useNavigate } from "react-router-dom";
import { RiH3 } from "react-icons/ri";
import ModalComponent from "./Modal";
import RadioIcon from "./RadioIcon";

function PatientConciergePlan({
  hasEditAccess,
  patient,
  currentConciergePlan,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);
  const { loading: patientLoading } = useSelector((state) => state.patient);
  const { conciergePlans } = useSelector((state) => state.medicalConcierge);
  const [showChangeModal, setShowChangeModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const otherPlans = conciergePlans?.filter(
    (plan) => plan.MCPID !== currentConciergePlan?.Plan?.MCPID
  );
  const [selectedPlan, setSelectedPlan] = useState(otherPlans[0]);

  // if (currentConciergePlan === null) {
  //   return null;
  // }

  if (patientLoading) {
    return (
      <div className="flex items-center justify-center mt-32">
        <div className="spinner"></div>
      </div>
    );
  }

  const handleChangePlan = async (p) => {
    const data = {
      AHUID: patient?.AHUID,
      MCPID: selectedPlan?.MCPID.toString(),
    };
    dispatch(changeConciergePlan(token, data, navigate, patient));
  };

  const handleCancelPlan = async () => {
    dispatch(cancelCurrentPlan(token, patient?.AHUID, navigate, patient));
  };

  return (
    <>
      <ModalComponent
        show={showChangeModal}
        handleClose={() => setShowChangeModal(false)}
        outSideModalPress={false}
        showCloseButton={true}
      >
        <h1 className="text-zinc-900 text-2xl font-medium font-poppins] leading-7">
          Change Plan
        </h1>

        {/* Current Plan Card */}
        <div className="p-4 bg-white rounded-lg shadow my-6">
          <div className="px-3 py-1 bg-emerald-100 rounded-2xl text-center text-lime-700 text-sm font-semibold font-poppins leading-tight mb-2 inline-flex">
            Current Plan
          </div>
          <h3 className="text-zinc-900 text-sm font-semibold font-poppins leading-loose">
            {currentConciergePlan?.Plan?.Name}
          </h3>
          <div className="h-px bg-gray-200 my-2" />
          <p className="text-zinc-900 text-sm font-medium font-poppins] leading-loose">
            {currentConciergePlan?.Plan?.Price}{" "}
            {currentConciergePlan?.Plan?.Currency}{" "}
            <span className="text-neutral-600">( one time payment)</span>
          </p>
          <p className=" text-neutral-600 text-xs font-normal font-poppins] leading-loose">
            100 CHF/ day{" "}
          </p>
        </div>

        {/* Other Plans */}
        <h1 className="text-zinc-900 text-base font-medium font-['Poppins'] leading-tight tracking-tight">
          Available Plans
        </h1>

        <div className="grid grid-cols-2 gap-4 mt-4">
          {otherPlans?.map((plan, index) => (
            <div
              onClick={() => setSelectedPlan(plan)}
              className={`p-4 bg-white rounded-lg shadow cursor-pointer ${
                selectedPlan?.MCPID === plan.MCPID
                  ? "border border-sky-400"
                  : ""
              }`}
            >
              <div className="flex items-center space-x-1">
                <RadioIcon selected={selectedPlan?.MCPID === plan.MCPID} />
                <h3 className="text-zinc-900 text-sm font-semibold font-poppins leading-loose">
                  {plan?.Name}
                </h3>
              </div>
              <div className="h-px bg-gray-200 my-2" />
              <p className="text-zinc-900 text-sm font-medium font-poppins] leading-loose">
                {plan?.Price} {plan?.Currency}{" "}
                <span className="text-neutral-600">( one time payment)</span>
              </p>
              <p className=" text-neutral-600 text-xs font-normal font-poppins] leading-loose">
                100 {plan?.Currency}/ day{" "}
              </p>
            </div>
          ))}
        </div>

        <button
          onClick={handleChangePlan}
          className="w-full mt-6 rounded-lg bg-sky-400 py-2.5 text-center text-white text-base font-semibold font-poppins leading-tight active:scale-95"
        >
          Change
        </button>
      </ModalComponent>
      <ModalComponent
        show={showCancelModal}
        handleClose={() => setShowCancelModal(false)}
        outSideModalPress={false}
        showCloseButton={false}
      >
        <div className="flex flex-col items-center">
          <div className="w-14 h-14 p-3 bg-red-50 rounded-full mb-6">
            <img src={deleteIcon} alt="" />
          </div>
          <div className="max-w-sm text-center text-slate-800 text-base font-semibold font-poppins leading-tight">
            Cancel Conceirge Plan '{currentConciergePlan?.Plan?.Name}' for{" "}
            {patient?.Name || patient?.FirstName + " " + patient?.LastName} ?
          </div>
          <div className="text-center max-w-sm mx-auto text-zinc-500 text-sm font-normal font-poppins leading-tight mt-4">
            Once Cancelled, it will end all services & benefits associated with
            the plan after the current billing cycle.
          </div>
          <div className="flex flex-row items-center justify-end mt-6 space-x-4">
            <button
              onClick={() => setShowCancelModal(false)}
              className="w-48 h-11 flex items-center justify-center bg-white rounded-lg border border-sky-400 active:scale-95"
            >
              <div className="text-sky-400 text-base font-semibold font-poppins leading-normal">
                Keep Plan
              </div>
            </button>
            <button
              onClick={handleCancelPlan}
              className="w-48 h-11 flex items-center justify-center bg-sky-400 rounded-lg border border-sky-400 active:scale-95"
            >
              <div className="text-white text-base font-semibold font-poppins leading-normal">
                Cancel
              </div>
            </button>
          </div>
        </div>
      </ModalComponent>
      <div className="max-w-md">
        {/* Conceirge Plans Card */}
        <div className="bg-white rounded-lg shadow-md p-4 flex-1">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-black text-lg font-medium font-poppins leading-relaxed">
              Conceirge Plans
            </h2>
            {/* <button className="text-sky-700 text-sm font-semibold font-poppins leading-none">
            Add New
          </button> */}
          </div>
          <div className="px-3 py-1 bg-emerald-100 rounded-2xl text-center text-lime-700 text-sm font-semibold font-poppins leading-tight mb-2 inline-flex">
            Current Plan
          </div>
          <h3 className="text-zinc-900 text-sm font-semibold font-poppins leading-loose">
            {currentConciergePlan?.Plan?.Name}
          </h3>
          <p className="text-neutral-600 text-sm font-medium font-poppins leading-loose">
            Start Date:{" "}
            {moment(currentConciergePlan?.CreatedAt).format("DD MMM, YYYY")}
          </p>
          <p className="text-neutral-600 text-sm font-medium font-poppins leading-loose">
            Payment status:{" "}
            {currentConciergePlan?.StripeSubscriptionId ? (
              <span className="text-lime-700">Paid</span>
            ) : (
              <span className="text-red-600">Unpaid</span>
            )}
          </p>
          {hasEditAccess && (
            <div className="flex gap-4 mt-4">
              <button
                onClick={() => setShowChangeModal(true)}
                className="text-sky-700 text-sm font-semibold font-poppins underline leading-none active:scale-95"
              >
                Change Plan
              </button>
              <button
                onClick={() => setShowCancelModal(true)}
                className="text-red-600 text-sm font-normal font-poppins underline leading-none active:scale-95"
              >
                Cancel Plan
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default PatientConciergePlan;
