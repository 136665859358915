import React from "react";
import { IoIosAdd } from "react-icons/io";

function TitleWithAddButton({
  title,
  showAddButton = false,
  showViewButton = false,
  onPress,
}) {
  return (
    <div className="flex flex-row items-center justify-between">
      <div className="text-zinc-900 text-lg font-medium font-poppins leading-relaxed">
        {title}
      </div>
      {showAddButton && (
        <button
          className="text-3xl text-sky-500 active:scale-95 active:text-sky-400"
          onClick={onPress}
        >
          <IoIosAdd className="" />
        </button>
      )}
      {showViewButton && (
        <button
          className="text-center text-sky-500 text-sm font-normal font-poppins underline active:text-sky-400 active:scale-95"
          onClick={onPress}
        >
          View All
        </button>
      )}
    </div>
  );
}

export default TitleWithAddButton;
