import React, { useEffect, useState } from "react";
import Breadcrumb from "../components/Breadcrumb";
import TabBar from "../components/TabBar";
import patientProfileIcon from "../assets/Images/PatientProfile/TabBar/patientProfile.svg";
import patientProfileSelectedIcon from "../assets/Images/PatientProfile/TabBar/patientProfileSelected.svg";

import appointmentHistoryIcon from "../assets/Images/PatientProfile/TabBar/appointmentHistory.svg";
import appointmentHistorySelectedIcon from "../assets/Images/PatientProfile/TabBar/appointmentHistorySelected.svg";

import medicalCommitteeIcon from "../assets/Images/PatientProfile/TabBar/medicalCommittee.svg";
import medicalCommitteeSelectedIcon from "../assets/Images/PatientProfile/TabBar/medicalCommitteeSelected.svg";

import conceirgePlansIcon from "../assets/Images/PatientProfile/TabBar/conceirgePlans.svg";
import conceirgePlansSelectedIcon from "../assets/Images/PatientProfile/TabBar/conceirgePlansSelected.svg";
import requestsAndRemarksIcon from "../assets/Images/PatientProfile/TabBar/requestAndRemarks.svg";
import requestsAndRemarksSelectedIcon from "../assets/Images/PatientProfile/TabBar/requestAndRemarksSelected.svg";
import ConsultationHistory from "../components/ConsultationHistory";
import PatientConciergePlan from "../components/PatientConciergePlan";
import { FaPlus } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import MedicalConceirgePlanCard from "../components/MedicalConceirgePlanCard";
import HealthManagers from "../components/HealthManagers";
import NotificationSettings from "../components/NotificationSettings";
import { useDispatch, useSelector } from "react-redux";

function MedicalConceirgeSettings() {
  const navigate = useNavigate();
  const {
    conciergePlans,
    healthManagers,
    loading: medicalConciergeLoading,
  } = useSelector((state) => state.medicalConcierge);
  const [selectedTab, setSelectedTab] = useState(0);

  const renderTabContent = (tab) => {
    switch (tab) {
      case 0:
        return (
          <div className="mt-4">
            <div className="text-slate-800 text-base font-semibold font-poppins leading-tight tracking-tight">
              Available Plans
            </div>
            {medicalConciergeLoading ? (
              <div className="flex items-center justify-center mt-20">
                <div className="spinner"></div>
              </div>
            ) : conciergePlans?.length === 0 ? (
              <div className="text-neutral-400 text-2xl text-center my-10 font-semibold font-poppins">
                No Data
              </div>
            ) : (
              <div className="flex flex-col mt-6">
                {conciergePlans?.map((plan, index) => (
                  <MedicalConceirgePlanCard
                    showEditButton={true}
                    key={index}
                    plan={plan}
                  />
                ))}
              </div>
            )}
          </div>
        );
      case 1:
        return (
          <HealthManagers
            managers={healthManagers}
            loading={medicalConciergeLoading}
          />
        );
      case 2:
        return <NotificationSettings />;
      default:
        return null;
    }
  };
  return (
    <div className="bg-[#FCFCFC] min-h-full p-6 scroll-smooth">
      {/* Header */}
      <div className="text-zinc-900 text-2xl font-medium font-poppins">
        Medical Concierge Settings
      </div>
      <Breadcrumb />
      {/* Tabs & Add New Button */}
      <div className="flex items-center justify-between">
        <div className="flex items-center space-x-1 mt-6">
          {TABS.map((tab) => (
            <TabBar
              key={tab.id}
              id={tab.id}
              name={tab.name}
              icon={tab.icon}
              icon2={tab.icon2}
              selectedTab={selectedTab}
              onClick={setSelectedTab}
            />
          ))}
        </div>
        {selectedTab !== 2 && (
          <button
            onClick={() => {
              if (selectedTab === 0) navigate("/addNewConciergePlan");
              else {
                navigate("/addNewHealthManager");
              }
            }}
            className="px-3 py-2 bg-sky-500 rounded shadow justify-center items-center gap-2.5 flex cursor-pointer active:scale-95 duration-200"
          >
            <FaPlus className="text-white text-sm" />
            <div className="text-white text-sm font-normal font-poppins">
              {selectedTab === 0 ? "Add New Plan" : "Add New Health Manager"}
            </div>
          </button>
        )}
      </div>

      {renderTabContent(selectedTab)}
    </div>
  );
}

export default MedicalConceirgeSettings;

const TABS = [
  {
    id: 0,
    name: "Subscription Plans",
    icon: patientProfileIcon,
    icon2: patientProfileSelectedIcon,
  },
  {
    id: 1,
    name: "Health Managers",
    icon: appointmentHistoryIcon,
    icon2: appointmentHistorySelectedIcon,
  },
  {
    id: 2,
    name: "Notifications",
    icon: conceirgePlansIcon,
    icon2: conceirgePlansSelectedIcon,
  },
];
