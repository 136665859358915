export const languageList = [
  {
    id: 1,
    name: "English",
  },
  {
    id: 2,
    name: "Spanish",
  },
  {
    id: 3,
    name: "French",
  },
  {
    id: 4,
    name: "Hindi",
  },
  {
    id: 5,
    name: "German",
  },
  {
    id: 6,
    name: "Italian",
  },
];
