import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : null,
  logo: localStorage.getItem("logoURL")
    ? localStorage.getItem("logoURL")
    : null,
  accessRoles: localStorage.getItem("accessRoles")
    ? JSON.parse(localStorage.getItem("accessRoles"))
    : [],
  loading: false,
  doctorDetails: localStorage.getItem("doctorDetails")
    ? JSON.parse(localStorage.getItem("doctorDetails"))
    : null,
};

const profileSlice = createSlice({
  name: "profile",
  initialState: initialState,
  reducers: {
    setUser(state, action) {
      state.user = action.payload;
      localStorage.setItem("user", JSON.stringify(action.payload));
    },
    setDoctorDetails(state, action) {
      state.doctorDetails = action.payload;
      localStorage.setItem("doctorDetails", JSON.stringify(action.payload));
    },
    setAccessRoles(state, action) {
      state.accessRoles = action.payload;
      localStorage.setItem("accessRoles", JSON.stringify(action.payload));
    },
    setLogo(state, action) {
      state.logo = action.payload;
      localStorage.setItem("logoURL", action.payload);
    },
    setLoading(state, action) {
      state.loading = action.payload;
    },
  },
});

export const { setUser, setDoctorDetails, setUserType, setAccessRoles, setLogo, setLoading } =
  profileSlice.actions;

export default profileSlice.reducer;
