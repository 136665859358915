import React from "react";
import PatientConciergePlan from "../components/PatientConciergePlan";

function Concierge({ hasEditAccess, patient, currentConciergePlan }) {
  return (
    <div className="px-6">
      <PatientConciergePlan
        hasEditAccess={hasEditAccess}
        patient={patient}
        currentConciergePlan={currentConciergePlan}
      />
    </div>
  );
}

export default Concierge;
