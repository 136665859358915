import React from 'react';
import './style.css';

const CircularRadioButton = ({ name, id, value, onChange, checked, text, textFont, editText, onEdit }) => {
  return (
    <label htmlFor={id} className="circular-radio-label flex flex-row justify-center items-center mb-1 w-full gap-1">
      <input
        className="circular-radio-input"
        type="radio"
        name={name}
        id={id}
        value={value}
        onChange={onChange}
        checked={checked}
      />
      <span className="custom-circular-radio" />
      <h1 className={`text-[#070808] text-xs ${textFont ? textFont : "font-semibold"} font-poppins text-left`}>
        {text}
      </h1>
      {editText && (
        <button onClick={onEdit} className={`text-[#3CA5DC] text-xs ${textFont ? textFont : "font-semibold"} font-poppins ml-auto underline`}>
          Edit
        </button>
      )}
    </label>
  );
};

export default CircularRadioButton;
