import React from "react";
import TitleWithAddButton from "./TitleWithAddButton";

function LivingWill() {
  return (
    <div>
      <TitleWithAddButton title="Living Will" />
    </div>
  );
}

export default LivingWill;
