import React from "react";
import TitleWithAddButton from "./TitleWithAddButton";

function ConsentForms() {
  return (
    <div>
      <TitleWithAddButton title="Consent Forms (2)" showAddButton={true} />
    </div>
  );
}

export default ConsentForms;
