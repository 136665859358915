import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  MeetingProvider,
  useMeeting,
  useParticipant,
} from "@videosdk.live/react-sdk";
import ReactPlayer from "react-player";
import { RxDotsHorizontal } from "react-icons/rx";
import expandIcons from "../assets/Images/TelehealthDashboard/expandIcons.png";
import expandVid from "../assets/Images/TelehealthDashboard/expandVid.png";
import mic_on from "../assets/Images/TelehealthDashboard/mic_on.png";
import vid_on from "../assets/Images/TelehealthDashboard/vid_on.png";
import speaker_on from "../assets/Images/TelehealthDashboard/speaker_on.png";
import phone_on from "../assets/Images/TelehealthDashboard/phone_on.png";
import DrBg from "../assets/Images/TelehealthDashboard/DrBg.png";
import VideoBg from "../assets/Images/TelehealthDashboard/VideoBg.png";
import Camera_off from "../assets/Images/TelehealthDashboard/Camera_off.png";
import DrAvailable from "../assets/Images/TelehealthDashboard/DrAvailable.png";
import DrNotAvailable from "../assets/Images/TelehealthDashboard/DrNotAvailable.png";
import DrAvailableYes from "../assets/Images/TelehealthDashboard/DrAvailableYes.png";
import DrAvailableNo from "../assets/Images/TelehealthDashboard/DrAvailableNo.png";
import { FiVideo } from "react-icons/fi";
import profilePic from "../assets/Images/TelehealthDashboard/profilePic.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getBucketURL } from "../utils/regex";
import { IoMicSharp } from "react-icons/io5";
import { BsCameraFill } from "react-icons/bs";
import { ImPhone } from "react-icons/im";
import MeetingView from "./VideoCallComponents/MeetingView"
import moment from "moment";
import { addNotesToPatientProfile1 } from "../services/operations/masterDataAPI";
import { setShowMeetingView } from "../slices/dashboardSlice";
import { getAllBookedSlots } from "../services/operations/dashboardAPI";

const VideoCall = ({ onExpand, isExpanded, onHideTile4 }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const [isDocAvailable, setIsDocAvailable] = useState(false);
  const [isThereNextPatient, setIsThereNextPatient] = useState(false);
  const [nextPatient, setNextPatient] = useState(null);
  const [canStartMeet, setCanStartMeet] = useState(null);
  const [upcomingPatient, setUpcomingPatient] = useState(null);
  const [meetingId, setMeetingId] = useState(null);
  const [meetingToken, setMeetingToken] = useState(null);
  const { doctorDetails } = useSelector((state) => state.profile);
  const { dashboard, showMeetingView } = useSelector((state) => state.dashboard);
  // const [showMeetingView, setShowMeetingView] = useState(false);
  const [showHideTile, setShowHideTile] = useState(false)

  // This will set Meeting Id to null when meeting is left or ended
  const onMeetingLeave = () => {
    dispatch(setShowMeetingView(false));
    const now = new Date();
    // if (nextPatient.time < now)
    setMeetingId(null);
    setMeetingToken(null)
    setCanStartMeet(null);
    setIsDocAvailable(false);
  };

  const updateVideoStart = async (np) => {
    await dispatch(setShowMeetingView(true));
    await dispatch(addNotesToPatientProfile1(token, np?.sessionId, {
      IsSessionStart: 1,
    }, null));
  }

  const startVideoCall = async (np) => {
    setNextPatient(np);
    await setMeetingId(np?.meetingID)
    await setMeetingToken(np?.MeetingAccessToken.toString())
    await setCanStartMeet(true);
    updateVideoStart(np);
  }

  const fetchBookedPatients = async () => {
    if (dashboard) {
      const today = new Date();
      const formattedDate = moment(today).format('YYYY-MMM-DD').toUpperCase();

      const [year, month, day] = formattedDate.split('-');
      const dayInt = parseInt(day);

      const patientsForToday = dashboard[year]?.[month]?.[dayInt] || [];

      const formattedPatientsData = await Promise.all(
        patientsForToday?.map(async (patient) => {
          const profileImageUrl = await getBucketURL(patient?.Patient_Info?.ProfileImageUrl).catch(() => profilePic);
          const [hours, minutes] = patient?.Session_Info?.Start_time.split(':');
          const [hours1, minutes1] = patient?.Session_Info?.End_time.split(':');
          const appointmentTime = new Date(today);
          const appointmentEndTime = new Date(today);
          appointmentTime.setHours(hours, minutes, 0, 0);
          appointmentEndTime.setHours(hours1, minutes1, 0, 0);

          const canJoin = (appointmentTime - today) > 5 * 60 * 1000 ? 0 : 1;

          return {
            id: patient?.Patient_Info?.AHUID,
            img: profileImageUrl || profilePic,
            age: patient?.Patient_Info?.Age,
            gender: patient?.Patient_Info?.Gender,
            name: patient?.Patient_Info?.Name,
            AppointmentType: patient?.Session_Info?.AppointmentType,
            medicalHistory: patient?.Patient_Info?.medicalHistory || "",
            meetingID: patient?.Session_Info?.MeetingID,
            MeetingAccessToken: patient?.Session_Info?.MeetingAccessToken,
            IsSessionEnd: patient?.Session_Info?.IsSessionEnd,
            IsSessionStart: patient?.Session_Info?.IsSessionStart,
            time: appointmentTime,
            endTime: appointmentEndTime,
            sessionId: patient?.ID,
            canJoin: canJoin,
          };
        })
      );

      const now = new Date();
      // Find the next patient based on the current time
      const nextPatients = formattedPatientsData
        .filter(patient => (patient?.endTime >= now && patient?.IsSessionEnd === 0 && patient?.AppointmentType === "TeleHealth"))
        .sort((a, b) => a.time - b.time);
      if (nextPatients.length > 0) {
        setUpcomingPatient(nextPatients);
        // setNextPatient(nextPatients[0]);
        setMeetingToken(nextPatients[0].MeetingAccessToken)
        setIsThereNextPatient(true);
      }
      else {
        setUpcomingPatient(null);
        setMeetingId(null)
        setMeetingToken(null)
        setIsThereNextPatient(false);
        setCanStartMeet(false);
      }
    } else {
      setUpcomingPatient(null);
      setMeetingId(null)
      setMeetingToken(null)
      setIsThereNextPatient(false);
      setCanStartMeet(false);
    }
  };

  useEffect(() => {
    if (dashboard && !showMeetingView) {
      fetchBookedPatients();
    }
  }, [dashboard, showMeetingView]);

  useEffect(() => {
    if (isDocAvailable) {
      if (doctorDetails) {
        let body = {
          DID: doctorDetails.DID,
        };
        dispatch(getAllBookedSlots(token, body));
        const intervalId = setInterval(() => {
          dispatch(getAllBookedSlots(token, body));
        }, 5000);
        return () => clearInterval(intervalId);
      }
    }
    else {
      setIsThereNextPatient(false);
      setUpcomingPatient(null);
      setNextPatient(null)
      setMeetingId(null)
      setMeetingToken(null)
      setCanStartMeet(null);
      // setShowMeetingView(false);
      dispatch(setShowMeetingView(false));
    }
  }, [isDocAvailable, showMeetingView, dispatch, token]);

  // Keyframes for the animation
  const keyframes = `
    @keyframes borderWave {
      0% {
        transform: scale(1);
        opacity: 0.6;
      }
      50% {
        transform: scale(1.2);
        opacity: 0.3;
      }
      100% {
        transform: scale(1.5);
        opacity: 0;
      }
    }
  `;

  // Dynamic styles based on the session state
  const dynamicStyles = (np) => {
    if (np?.canJoin === 0) {
      return {
        container: {
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          backgroundColor: '#D3D3D3',
          cursor: 'not-allowed',
          overflow: 'visible', // Allows the wave effect to be visible outside the container
        },
        icon: {
          position: 'relative',
          zIndex: 1, // Ensures the icon is above the wave effect
          color: '#A9A9A9',
          fontSize: '1.25rem',
        },
        borderWave: {
          display: 'none',
        },
      };
    }

    const backgroundColor = np?.IsSessionStart === 1 ? '#85D114' : '#3CA5DC';
    const waveColor = np?.IsSessionStart === 1 ? '#A3D94B' : '#4D9BCC';

    return {
      container: {
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        backgroundColor: backgroundColor,
        cursor: 'pointer',
        overflow: 'visible', // Allows the wave effect to be visible outside the container
      },
      icon: {
        position: 'relative',
        zIndex: 1, // Ensures the icon is above the wave effect
        color: 'white',
        fontSize: '1.25rem',
      },
      borderWave: {
        content: '""',
        position: 'absolute',
        width: '100%',
        height: '100%',
        border: `5px solid ${waveColor}`,
        borderRadius: '50%',
        zIndex: 0, // Ensure the wave effect is behind the icon
        opacity: 0.6,
        animation: 'borderWave 1.5s infinite',
      },
    };
  };

  return (
    <>
      <div
        className={`overflow-y-auto flex-1 basis-[60%] ${isExpanded ? "h-full" : "h-[47vh]"} p-4 rounded-lg shadow bg-[#FFFFFF] scroll-smooth scrollbar scrollbar-thin scrollbar-thumb-[#3CA5DC] scrollbar-track-[#E5F2FF] scrollbar-thumb-rounded-full scrollbar-track-rounded-full relative`}
      >
        <div className={`flex flex-col justify-center item-center w-full`}>
          {/* Header */}
          {!isExpanded && (
            <div className="flex flex-row justify-between items-center relative w-full">
              {showHideTile ? (
                <div onClick={() => { setShowHideTile(false); onHideTile4("showTelehealth"); }} className={`cursor-pointer flex justify-center items-center py-1 px-2 bg-[#E5F2FF] rounded-3xl`}>
                  <h1 className={`text-[#3CA5DC] text-[11.03px] font-semibold font-poppins`}>
                    Hide Tile
                  </h1>
                </div>
              ) : (
                <div onClick={() => setShowHideTile(true)} className="cursor-pointer flex justify-center items-center bg-[#E5F2FF] rounded-full p-1 w-[30px] h-[30px]">
                  <RxDotsHorizontal className="text-[#3CA5DC] text-lg" />
                </div>
              )}
              <h1 className={`text-[#1C1C1C] text-sm font-semibold font-poppins`}>
                TeleHealth Service Video Call
              </h1>
              <div className="flex flex-row justify-center items-center">
                {!showMeetingView && (
                  <>
                    {isDocAvailable === true ? (
                      <>
                        <div onClick={() => setIsDocAvailable(!isDocAvailable)} className="flex justify-center items-center w-[25px] h-[25px] mr-[1.5rem] cursor-pointer">
                          <img
                            src={DrAvailableYes}
                            className="w-full h-full object-contain"
                            alt="Dr Availability Icon"
                          />
                        </div>
                        <h1 className="text-[#1C1C1C] text-[10.40px] font-semibold font-poppins">
                          Available
                        </h1>
                      </>
                    ) : (
                      <>
                        <div onClick={() => setIsDocAvailable(!isDocAvailable)} className="flex justify-center items-center w-[25px] h-[25px] mr-2 cursor-pointer">
                          <img
                            src={DrAvailableNo}
                            className="w-full h-full object-contain"
                            alt="Dr Availability Icon"
                          />
                        </div>
                        <h1 className="text-[#1C1C1C] text-[10.40px] font-semibold font-poppins">
                          Not Available
                        </h1>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          )}

          {/* Content */}
          {isDocAvailable === true ? (
            <>
              {isThereNextPatient === true ? (
                <>
                  {canStartMeet === true && (
                    <MeetingProvider
                      config={{
                        meetingId: meetingId,
                        participantId: doctorDetails?.DID,
                        name: doctorDetails?.Name,
                      }}
                      token={meetingToken}
                    >
                      <div className="flex flex-col w-full justify-center items-center mt-8 px-5">
                        {/* Video Call */}
                        {showMeetingView && (
                          <>
                            <MeetingView meetingId={meetingId} setMeetingId={setMeetingId} meetingToken={meetingToken} setMeetingToken={setMeetingToken} canStartMeet={canStartMeet} setCanStartMeet={setCanStartMeet} setShowMeetingView={setShowMeetingView} onMeetingLeave={onMeetingLeave} onExpand={onExpand} isExpanded={isExpanded} patient={nextPatient} />
                          </>
                        )}

                      </div>
                    </MeetingProvider>
                  )}
                  {/* Next Patient */}
                  {!showMeetingView && (
                    <>
                      {upcomingPatient.length > 0 && (
                        upcomingPatient?.map((np, index) => (
                          <div key={index}
                            className={`grid grid-cols-3 w-full justify-center items-center bg-[#FCFCFC] rounded-lg px-5 py-4 gap-5 mt-6`}
                          >
                            <div className="flex flex-row justify-start items-center gap-2">
                              <div className="flex justify-center items-center w-[40px] h-[40px] rounded-full">
                                <img
                                  src={np.img}
                                  className="w-full h-full object-contain rounded-full"
                                  alt="patient image"
                                />
                              </div>
                              <div className="flex flex-col justify-center items-start">
                                <h1 className="text-[#000000] text-xs font-regular font-poppins">
                                  Next Patient
                                </h1>
                                <h1 className="text-[#000000] text-sm font-bold font-poppins">
                                  {np.name}
                                </h1>
                              </div>
                            </div>
                            <div className="flex flex-col justify-center items-center">
                              <h1 className="text-[#000000] text-xs font-regular font-poppins">
                                Appointment Time
                              </h1>
                              <h1 className="text-[#000000] text-sm font-bold font-poppins">
                                {`${moment(np.time).format("HH:mm")} - ${moment(np.endTime).format("HH:mm")}`}
                              </h1>
                            </div>
                            {/* <div onClick={() => {
                              startVideoCall(np);
                            }} className="ml-auto flex justify-center items-center w-[40px] h-[40px] rounded-full bg-[#3CA5DC] cursor-pointer hover:scale-110 duration-500">
                              <FiVideo className="text-white text-lg" />
                            </div> */}
                            <div className="ml-auto flex flex-col justify-center items-center">
                              <button
                                onClick={() => startVideoCall(np)}
                                disabled={!np?.canJoin}
                                style={dynamicStyles(np).container}
                              >
                                {np?.canJoin === 1 && (
                                  <div style={dynamicStyles(np).borderWave}></div>
                                )}
                                <FiVideo style={dynamicStyles(np).icon} />
                                <style>{keyframes}</style>
                              </button>
                              <h1 className={`${np?.canJoin === 1 ? np?.IsSessionStart === 1 ? "text-[#85D114]" : "text-[#3CA5DC]" : "text-[#000000]"} mt-1 text-xs font-medium font-poppins`}>
                                {np?.canJoin === 1 ? np?.IsSessionStart === 1 ? "Rejoin" : "Join Now" : ""}
                              </h1>
                            </div>
                          </div>
                        ))
                      )}
                    </>
                  )}
                </>
              ) : (
                <div className="flex flex-col w-full justify-center items-center mt-7 px-5">
                  <div className="flex justify-center items-center w-[40%] h-auto px-4">
                    <img
                      src={DrAvailable}
                      className="w-full h-full object-contain"
                      alt="Dr Availability Image"
                    />
                  </div>
                  <h1 className="text-[#6b6c6d] text-sm font-normal font-poppins leading-none mt-3">
                    Patients will be assigned to you here.
                  </h1>
                </div>
              )}
            </>
          ) : (
            <div className="flex flex-col w-full justify-center items-center mt-8 px-5">
              <div className="flex justify-center items-center w-[12rem] h-auto px-4 my-2">
                <img
                  src={DrNotAvailable}
                  className="w-full h-full object-contain"
                  alt="Dr Availability Image"
                />
              </div>
              <h1 className="text-[#6b6c6d] text-sm font-normal font-poppins leading-none mt-3">
                You are Offline.
              </h1>
              <h1 className="text-[#6b6c6d] text-sm font-normal font-poppins leading-none mt-2">
                Go Online to receive TeleHealth Patients
              </h1>
              <div onClick={() => setIsDocAvailable(true)} className={`mt-5 cursor-pointer hover:scale-110 duration-500 flex justify-center items-center py-1 px-8 rounded-md bg-[#86D511]`}>
                <h1 className={`text-[#E5F2FF] text-sm font-semibold font-poppins`}>
                  Go Online
                </h1>
              </div>
            </div>
          )}
        </div>
      </div >
    </>
  );
};

export default VideoCall;
