import "./bookAppointment.css";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa6";
import bgImage from "../assets/Images/BookAppointment/bgImage.png";
import bgImage1 from "../assets/Images/BookAppointment/bgImage1.png";
import cal_Icon from "../assets/Images/BookAppointment/cal_Icon.png";
import time_Icon from "../assets/Images/BookAppointment/time_Icon.png";
import videoCallIcon from "../assets/Images/BookAppointment/videoCallIcon.png";
import inPersonIcon from "../assets/Images/BookAppointment/inPersonIcon.png";
import TimeSlotBgImage from "../assets/Images/BookAppointment/TimeSlotBgImage.png";
import breadcrumbsArrow from "../assets/Images/breadcrumbsArrow.png";
import patientNoImage from "../assets/Images/BookAppointment/patientNoImage.png";
import profilePic from "../assets/Images/TelehealthDashboard/profilePic.png";
import { useLocation } from 'react-router-dom';
import { toast } from "react-hot-toast";
import moment from 'moment'
import { TimePicker } from 'antd';
import dayjs from 'dayjs';
import { format } from 'date-fns';
import { FaCaretDown, FaCaretUp, FaChevronLeft } from "react-icons/fa";
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/style.css';
import { getAllBookedSlotsForBookAppointment, getAvailableSlotsForSpecifiedDate, getDateID, bookAppointment, getAvailableSlotsForDoctorOnSpecifiedDate, getSlotAvailabilityStatus } from "../services/operations/dashboardAPI";
import { getBucketURL } from "../utils/regex";
import Dropdown from "../components/Dropdown/Dropdown";
import Breadcrumb from "../components/Breadcrumb";

function BookAppointment() {
    const location = useLocation();
    const patientData = location.state || {};
    const { allDoctors } = useSelector((state) => state.masterData);
    const { token } = useSelector((state) => state.auth);
    const [patientsData, setPatientsData] = useState([])
    const { doctorDetails } = useSelector((state) => state.profile);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selected, setSelected] = useState(null);
    const [showCalendar, setShowCalendar] = useState(false)
    const [monthlyData, setMonthlyData] = useState([]);
    const [timeSlots, setTimeSlots] = useState([]);
    const [displaySlots, setDisplaySlots] = useState([]);
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [selectedAppointmentOption, setSelectedAppointmentOption] = useState("");
    const [showAppointmentTypes, setShowAppointmentTypes] = useState(false);
    const [selectedDoctorOption, setSelectedDoctorOption] = useState("");
    const [showDoctors, setShowDoctors] = useState(false);
    const [bookedDays, setBookedDays] = useState([]);
    const [unavailableDays, setUnavailableDays] = useState([]);
    const [slotData, setSlotData] = useState([]);

    const AppointmentOptions = [
        { value: "TeleHealth", label: "TeleHealth" },
        { value: "In-Clinic", label: "In-Clinic" },
        { value: "Personal Consultation", label: "Personal Consultation" },
        { value: "HomeVisit", label: "Home Visit" },
    ];

    const doctorOptions = allDoctors.map(dr => ({
        label: dr.Name,
        value: dr.DID,
    }));

    const [formData, setFormData] = useState({
        PatientID: patientData?.AHUID || "",
        PatientPic: patientData?.Image || patientNoImage,
        Name: patientData?.Name || "",
        DOB: patientData?.DOB || "",
        Gender: patientData?.Gender || "",
        AppointmentType: "",
        Doctor: "",
        AppointmentDate: "",
        AppointmentTime: "",
    })

    const [errors, setErrors] = useState({
        PatientID: "",
        PatientPic: "",
        Name: "",
        DOB: "",
        Gender: "",
        AppointmentType: "",
        Doctor: "",
        AppointmentDate: "",
        AppointmentTime: "",
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "selected") {
            setSelected(value)
        }
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
        setErrors((prevData) => ({
            ...prevData,
            [name]: "",
        }));
    };

    const submitHandler = async (event) => {
        event.preventDefault();
        const mandatoryFields = [
            "PatientID",
            "AppointmentType",
            "Doctor",
            "AppointmentDate",
        ];
        const newErrors = {};

        mandatoryFields.forEach((key) => {
            const value = formData[key];

            if (!value) {
                newErrors[key] = "This field is required";
            } else if (typeof value === "string" && value.trim() === "") {
                newErrors[key] = "This field cannot be empty";
            }
        });

        if (Object.keys(newErrors).length > 0) {
            toast.error("Please fill all the required fields");
            setErrors(newErrors);
            return;
        }

        if (!selected) { toast.error("Select Date"); return; }
        if (!selectedSlot) { toast.error("Select Time"); return; }

        // Split the AppointmentTime to get Start_time and End_time
        let [startTime, endTime] = selectedSlot?.split(' - ');
        startTime = `${startTime}:00`;
        endTime = `${endTime}:00`;
        const basicData = {
            "DateID": formData.AppointmentDate,
            "Start_time": startTime,
            "End_time": endTime,
            "AHUID": formData.PatientID,
            "DID": parseInt(formData.Doctor, 10),
            "AppointmentType": formData.AppointmentType
        }

        if (formData) {
            let body = {
                "DID": formData.Doctor
            }

            const res = await dispatch(bookAppointment(token, basicData, body))
            toast.success(res?.msg)
            navigate("/clinicianDashboard")
        }
        else { toast.error("Error Fetching Doctor ID") }
    };

    const getMonthlyData = async (patientsData) => {
        if (selected) {
            const formattedDate = moment(selected).format('YYYY-MMM-DD').toUpperCase();

            const [year, month, day] = formattedDate.split('-');
            const dayInt = parseInt(day);

            const monthlyData = [];
            const patientData = patientsData[year]?.[month]?.[dayInt] || [];

            for (const [index, patient] of patientData.entries()) {
                let profileImageUrl;
                try {
                    profileImageUrl = await getBucketURL(patient.Patient_Info.ProfileImageUrl);
                } catch {
                    profileImageUrl = profilePic; // Use default profile picture if there's an error
                }

                const data = {
                    id: index,
                    time: patient.Session_Info.Start_time.slice(0, 5), // Extract time (e.g., "08:00")
                    name: patient.Patient_Info.Name,
                    desc: `${patient.Patient_Info.Gender}, ${patient.Patient_Info.Age} Yrs`,
                    img: profileImageUrl,
                    precondition: patient?.Patient_Info?.Precondition || "N/A",
                    appointmentType: patient?.Session_Info?.AppointmentType === "TeleHealth" ? "Video Call" : patient?.Session_Info?.AppointmentType || "Video Call",
                };
                monthlyData.push(data);
            }

            monthlyData.sort((a, b) => {
                const timeA = a.time.split(':').map(Number);
                const timeB = b.time.split(':').map(Number);
                if (timeA[0] === timeB[0]) {
                    return timeA[1] - timeB[1];
                }
                return timeA[0] - timeB[0];
            });

            return monthlyData;
        }
    };

    const fetchAndFormatMonthlyData = async () => {
        // Get monthly data
        const monthlyData = await getMonthlyData(patientsData);

        // Set monthly data to state
        setMonthlyData(monthlyData);
    };

    const generateCallbackSlots = (availableTimes, selectedDate) => {
        const slots = [];
        const now = moment();
        const today = moment().format("YYYY-MM-DD");

        availableTimes?.forEach(timeRange => {
            const [start, end] = timeRange.split(' - ');
            let startTime = moment(start, 'HH:mm');
            let endTime = moment(end, 'HH:mm');

            // Handle the case where end time is 00:00 by extending it to the next day
            if (endTime.isSame(moment('00:00', 'HH:mm'))) {
                endTime = endTime.add(1, 'day'); // Extend end time to the next day
            }

            // If the selected date is today, check against the current time
            const isToday = selectedDate === today;

            // Generate 30-minute slots within the time range
            while (startTime.isBefore(endTime)) {
                const slotEnd = moment.min(
                    startTime.clone().add(30, 'minutes'),
                    endTime,
                );

                // Only add valid slots where slotEnd is after startTime
                if (slotEnd.isAfter(startTime) && (!isToday || (isToday && startTime.isAfter(now)))) {
                    slots.push({
                        Slots: `${startTime.format('HH:mm')} - ${slotEnd.format('HH:mm')}`,
                    });
                }
                startTime = slotEnd;
            }
        });
        if (slots.length === 0)
            toast.error("No Time Slots Left")
        return slots;
    };

    const fetchBookedPatients = async () => {
        if (token && formData.Doctor) {
            const body = {
                "DID": formData.Doctor
            }
            const patientsList = await dispatch(getAllBookedSlotsForBookAppointment(token, body));
            setPatientsData(patientsList)
        }
    };

    const fetchSlotAvailability = async () => {
        setSlotData([]);
        setBookedDays([]);
        setUnavailableDays([]);

        try {
            if (token && formData.Doctor) {
                const slotsData = await dispatch(getSlotAvailabilityStatus(token, formData.Doctor));
                setSlotData(slotsData.AvailabilityStatus);

                const bookedDays = [];
                const unavailableDays = [];

                Object.entries(slotsData.AvailabilityStatus).forEach(([date, status]) => {
                    const dateObj = new Date(date);
                    if (status) {
                        bookedDays.push(dateObj);
                    } else {
                        unavailableDays.push(dateObj);
                    }
                });

                setBookedDays(bookedDays);
                setUnavailableDays(unavailableDays);
            }
        } catch (error) {
            setSlotData([]);
            setBookedDays([]);
            setUnavailableDays([]);
            console.error("Error fetching slot availability:", error);
        }
    };

    useEffect(() => {
        fetchSlotAvailability();
    }, [formData.Doctor])

    useEffect(() => {
        // const getAvailableSlots = async (dateID, did) => {
        const getAvailableSlots = async (did, date) => {
            if (token) {
                // const body = {
                //     "DateID": dateID,
                //     "DID": did
                // }
                const body1 = {
                    "Date": moment(date).format("YYYY-MM-DD"),
                    "DID": did
                }
                const dataSlot = await dispatch(getAvailableSlotsForDoctorOnSpecifiedDate(token, body1));
                // const availableSlots = await dispatch(getAvailableSlotsForSpecifiedDate(token, body));
                const availableSlots = dataSlot?.AvailableTimes;
                const dateID = dataSlot?.DateID;
                setFormData((prev) => ({
                    ...prev,
                    "AppointmentDate": dateID,
                }));
                const slots = generateCallbackSlots(availableSlots, date);
                setTimeSlots(slots);
            }
        }

        let date1 = '';
        if (selected) {
            date1 = moment(selected).format("YYYY-MM-DD")
            if (formData.Doctor) {
                // fetchDateID(date1, formData.Doctor)
                getAvailableSlots(formData.Doctor, date1)
            }
            else if (!formData.Doctor && selected !== '')
                toast.error("Select Doctor!")
        }
    }, [selected, formData.Doctor])

    useEffect(() => {
        setDisplaySlots(timeSlots.slice(0, 10)) // Display at most 10 time slots
    }, [timeSlots])

    useEffect(() => {
        if (selected && patientsData) {
            fetchAndFormatMonthlyData()
        }
    }, [selected, patientsData])

    return (
        <div className="bg-[#F3F3F3] h-full py-2 px-2 sm:py-5 sm:px-10 scroll-smooth">
            <div className="bg-white rounded-lg shadow-lg p-6">
                {/* Header Section */}
                <div className="flex flex-row items-center justify-between">
                    <div className="flex flex-col items-start gap-4">
                        <h1 className="text-black text-xl font-semibold font-poppins leading-3 tracking-wide">
                            Appointment Booking
                        </h1>

                        <Breadcrumb currentState={patientData} />
                    </div>
                </div>

                <div className="flex flex-row justify-center items-start space-x-3 mt-5">
                    <div className="w-[40%] flex flex-col justify-center items-start">
                        {/* Patient Card */}
                        <h1 className="text-black text-base text-lg font-normal font-poppins leading-3 tracking-wide mb-8 text-left pt-5 w-[85%]">
                            Basic Details
                        </h1>

                        <div className="bg-white shadow rounded-lg p-8 w-[85%] flex flex-row items-center justify-start space-x-4">
                            <img
                                src={patientData.Image}
                                alt="PatientPic"
                                className="w-20 h-20 rounded-full"
                            />
                            <div className="text-center flex-col justify-center items-center ml-4">
                                <div className="text-black text-base font-semibold font-poppins text-left">
                                    {patientData.Name || patientData.FirstName + " " + patientData.LastName}
                                </div>
                                <div className="text-[#1c1c1c] text-xs font-medium mt-1 font-poppins text-left">
                                    {patientData.Gender}
                                </div>
                                <div className="text-[#1c1c1c] text-xs font-normal mt-1 font-poppins text-left">
                                    DOB : {moment(patientData.DOB).format("DD.MM.YYYY")}
                                </div>
                                <div className="text-zinc-500 text-xs font-normal mt-1 font-poppins text-left">
                                    No : {patientData.AHUID}
                                </div>
                                {patientData.Age !== null && patientData.Age !== undefined ? (
                                    <>
                                        <div className="text-black text-xs font-normal font-poppins text-left mt-1">
                                            {patientData.Age} Years Old
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="text-black text-xs font-normal font-poppins text-left mt-1">
                                            {new Date().getFullYear() - new Date(patientData.DOB).getFullYear()}{" "}
                                            Years Old
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>

                        {/* Appointment Type */}
                        <div className="mb-7 mt-12 w-[85%]">
                            <label>
                                <p className="text-[#060708] text-xs font-semibold font-poppins mb-2 text-left">
                                    Appointment Type
                                    <sup className="text-red-600">*</sup>
                                </p>
                                <div className="relative">
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation(); setShowAppointmentTypes((prev) => !prev);
                                        }}
                                        className={`w-full h-9 px-4 py-2 bg-[#F3F5F7] rounded-md flex justify-between items-center border ${errors.AppointmentType ? "border-red-500" : "border-transparent"
                                            } focus:outline-none focus:ring-1 focus:ring-sky-200`}
                                    >
                                        <span className="text-[#3ca5dc] text-xs font-normal font-poppins">
                                            {AppointmentOptions.find((opt) => opt.value === selectedAppointmentOption)?.label ||
                                                "Select Appointment"}
                                        </span>
                                        <FaCaretDown className="text-sky-500" />
                                    </button>

                                    {/* Custom Dropdown */}
                                    <Dropdown
                                        options={AppointmentOptions}
                                        selectedOption={selectedAppointmentOption}
                                        setSelectedOption={setSelectedAppointmentOption}
                                        showDropdown={showAppointmentTypes}
                                        setShowDropdown={setShowAppointmentTypes}
                                        handleChange={handleChange}
                                        name={'AppointmentType'}
                                    />
                                </div>
                            </label>
                        </div>

                        {/* Doctor */}
                        <div className="mb-4 w-[85%]">
                            <label>
                                <p className="text-[#060708] text-xs font-semibold font-poppins mb-2 text-left">
                                    Doctor
                                    <sup className="text-red-600">*</sup>
                                </p>
                                <div className="relative">
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation(); setShowDoctors((prev) => !prev);
                                        }}
                                        className={`w-full h-9 px-4 py-2 bg-[#F3F5F7] rounded-md flex justify-between items-center border ${errors.Doctor ? "border-red-500" : "border-transparent"
                                            } focus:outline-none focus:ring-1 focus:ring-sky-200`}
                                    >
                                        <span className="text-[#3ca5dc] text-xs font-normal font-poppins">
                                            {doctorOptions.find((opt) => opt.value === selectedDoctorOption)?.label ||
                                                "Select Doctor"}
                                        </span>
                                        <FaCaretDown className="text-sky-500" />
                                    </button>

                                    {/* Custom Dropdown */}
                                    <Dropdown
                                        options={doctorOptions}
                                        selectedOption={selectedDoctorOption}
                                        setSelectedOption={setSelectedDoctorOption}
                                        showDropdown={showDoctors}
                                        setShowDropdown={setShowDoctors}
                                        handleChange={handleChange}
                                        name={'Doctor'}
                                    />
                                </div>
                            </label>
                        </div>
                    </div>

                    {/* Patient Appointments */}
                    <div className="w-[60%] flex flex-col justify-center items-start rounded-lg shadow p-4 py-5">
                        <h1 className="text-black text-base text-lg font-normal font-poppins leading-3 tracking-wide">
                            Scheduling Calendar
                        </h1>

                        <div className="w-full bg-[#ACACAC] h-[1px] my-5"></div>

                        <div className="flex flex-row justify-between items-start w-full gap-2">
                            {/* Calendar */}
                            <div className="flex-1 flex flex-col justify-center items-start w-full">
                                <h1 className="text-black text-xs font-semibold font-poppins mb-5 leading-3 tracking-wide text-left">
                                    Select Date
                                    <sup className="text-red-600">*</sup>
                                </h1>
                                <DayPicker
                                    mode="single"
                                    name="selected"
                                    style={{ '--rdp-accent-color': '#3CA5DC', '--rdp-cell-size': '30px', backgroundColor: '#E5F2FF' }}
                                    className="p-2 shadow rounded-lg"
                                    selected={selected}
                                    onSelect={(date) => { setSelected(date); setSelectedSlot(null); }}
                                    fromDate={new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())}
                                    modifiers={{
                                        booked: bookedDays,
                                        cannotBeBooked: unavailableDays,
                                        disabled: { before: new Date() }
                                    }}
                                    modifiersClassNames={{
                                        booked: 'booked_day',
                                        cannotBeBooked: 'cannot_be_booked_day',
                                        disabled: 'disabled_day',
                                    }}
                                />
                            </div>

                            {/* Time */}
                            {displaySlots.length !== 0 ? (
                                <div className="flex-1 flex flex-col justify-center items-start w-full">
                                    <h1 className="text-black text-xs font-semibold font-poppins mb-5 leading-3 tracking-wide">
                                        Select Time
                                        <sup className="text-red-600">*</sup>
                                    </h1>
                                    <div className="grid grid-cols-3 gap-5">
                                        {displaySlots.map((slot, index) => (
                                            <button
                                                key={index}
                                                className={`p-2 py-3 rounded-2xl text-[10px] uppercase leading-3 tracking-wider font-semibold font-poppins ${selectedSlot === slot.Slots ? 'bg-[#3CA5DC] text-white' : 'bg-[#E5F2FF] text-[#3CA5DC]'}`}
                                                onClick={() => {
                                                    setSelectedSlot(slot.Slots);
                                                }}
                                            >
                                                {slot.Slots}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <>
                                    <div className="flex-1 flex flex-col self-end mb-5 justify-center items-start w-full">
                                        <img
                                            src={TimeSlotBgImage}
                                            alt="Select Date To Show Time Slots Bg Image"
                                            className="w-64 h-48"
                                        />
                                    </div>
                                </>
                            )}
                        </div>

                        {/* Selected Date and Time */}
                        {(selected || selectedSlot) && (
                            <div className="mt-4 p-4 w-full bg-[#F3F3F3] flex flex-col justify-center items-start rounded-lg">
                                <p className="text-black text-[15px] font-semibold font-poppins leading-3 tracking-wide">
                                    {selected && moment(selected).format('dddd, MMMM D YYYY')}
                                </p>
                                <p className="text-[13px] font-semibold font-poppins text-[#ACACAC] leading-3 tracking-wide mt-2">
                                    {selectedSlot && `${selectedSlot} CET`}
                                </p>
                            </div>
                        )}

                        {/* Btns */}
                        <div className="flex flex-row justify-center items-center w-full gap-4 mt-4">
                            <button
                                style={{ backgroundColor: "#3CA5DC" }}
                                className="w-full bg-sky-400 rounded-lg text-center text-white text-sm font-semibold font-poppins p-4 py-[14px] hover:bg-sky-500 transition duration-200 ease-in active:bg-[#3CA5DC] focus:outline-none focus:ring focus:ring-[#3CA5DC] leading-3 tracking-wide"
                                onClick={submitHandler}
                            >
                                Confirm Booking
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BookAppointment;
