import moment from "moment";
import React, { useState, useEffect } from "react";
import addPrescription from "../assets/Images/PatientProfile/addPrescription.svg";
import cameraIcon from "../assets/Images/PatientProfile/camera.png";
import completed from "../assets/Images/PatientProfile/completed.svg";
import ongoing from "../assets/Images/PatientProfile/ongoing.svg";
import discontinue from "../assets/Images/PatientProfile/discontinue.svg";
import FooterWithExpandButton from "./FooterWithExpandButton";
import { IoIosAdd } from "react-icons/io";
import Tooltip from "./Tooltip";
import { useNavigate } from "react-router-dom";
import Prescription from "./Prescription";

function Medication({ hasEditAccess, patient, dosageAdherence }) {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  if (dosageAdherence?.length === 0) {
    return (
      <div>
        <Prescription
          show={showModal}
          hide={() => setShowModal(false)}
          patient={patient}
          data={dosageAdherence}
        />
        <div className="flex flex-row items-center justify-between">
          <div className="text-zinc-900 text-lg font-medium font-poppins leading-relaxed">
            Medication
          </div>
          {hasEditAccess && (
            <button
              onClick={() => setShowModal(true)}
              className="px-4 py-2 bg-sky-400 rounded-lg border border-sky-400 flex justify-center items-center gap-2 active:scale-95"
            >
              <img
                src={addPrescription}
                alt="Add Prescription"
                className="w-5 h-5"
              />

              <div className="text-white text-sm font-semibold poppins leading-tight">
                Add Prescription
              </div>
            </button>
          )}
        </div>
        <div className="text-neutral-400 text-2xl text-center my-10 font-semibold font-poppins">
          No Data
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col justify-between h-full">
      <Prescription
        show={showModal}
        hide={() => setShowModal(false)}
        patient={patient}
        data={dosageAdherence}
      />
      <div>
        <div className="flex flex-row items-center justify-between">
          <div className="text-zinc-900 text-lg font-medium font-poppins leading-relaxed">
            Medication
          </div>
          {hasEditAccess && (
            <button
              onClick={() => setShowModal(true)}
              className="px-4 py-2 bg-sky-400 rounded-lg border border-sky-400 flex justify-center items-center gap-2 active:scale-95"
            >
              <img
                src={addPrescription}
                alt="Add Prescription"
                className="w-5 h-5"
              />

              <div className="text-white text-sm font-semibold poppins leading-tight">
                Add Prescription
              </div>
            </button>
          )}
        </div>
        <div className="mt-5 flex flex-col space-y-4">
          {dosageAdherence.slice(0, 4).map((item) => {
            return (
              <div
                key={item.ID}
                className="bg-neutral-50 rounded-lg shadow p-4 grid grid-cols-6 gap-4 items-center"
              >
                <div className="col-span-2">
                  <p className="text-zinc-900 text-sm font-normal font-poppins leading-tight">
                    {item?.MedicineName}
                  </p>
                  <p className="text-neutral-600 text-xs font-normal font-poppins leading-tight">
                    {item?.DosageAmount}
                  </p>
                </div>
                <div className="col-span-2">
                  <div className="text-zinc-500 text-xs font-normal font-poppins">
                    Duration
                  </div>
                  <div className=" text-zinc-900 text-xs font-semibold font-poppins mt-1">
                    {moment(item.StartDate).format("Do MMM")} -{" "}
                    {moment(item.EndDate).format("Do MMM YYYY")}
                  </div>
                </div>
                <div className="">
                  <div
                    className={` px-4 py-2 rounded-3xl justify-center items-center gap-1.5 inline-flex ${
                      item?.Status === "Ongoing"
                        ? "bg-orange-100 "
                        : item?.Status === "Completed"
                        ? "bg-emerald-100"
                        : "bg-gray-100"
                    }`}
                  >
                    <img
                      src={
                        item?.Status === "Ongoing"
                          ? ongoing
                          : item?.Status === "Completed"
                          ? completed
                          : discontinue
                      }
                      alt=""
                      className="w-4 h-4"
                    />

                    <div
                      className={`text-center text-xs font-semibold font-poppins leading-3 ${
                        item?.Status === "Ongoing"
                          ? "text-amber-800"
                          : item?.Status === "Completed"
                          ? "text-neutral-600"
                          : "text-neutral-600"
                      }`}
                    >
                      {item?.Status}
                    </div>
                  </div>
                </div>
                <div className="flex justify-end">
                  <Tooltip message="View">
                    <button
                      onClick={() => {
                        navigate("medication", {
                          state: patient,
                        });
                      }}
                      className="p-1 rounded-full group group-hover:bg-sky-100"
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6 text-neutral-600 group-hover:text-sky-400"
                      >
                        <path
                          d="M21.3448 11.045C21.6488 11.4713 21.8008 11.6845 21.8008 12C21.8008 12.3155 21.6488 12.5287 21.3448 12.955C19.9787 14.8706 16.49 19 11.8008 19C7.11156 19 3.62288 14.8706 2.25682 12.955C1.95279 12.5287 1.80078 12.3155 1.80078 12C1.80078 11.6845 1.95279 11.4713 2.25682 11.045C3.62288 9.12944 7.11156 5 11.8008 5C16.49 5 19.9787 9.12944 21.3448 11.045Z"
                          stroke="currentColor"
                          stroke-width="1.5"
                        />
                        <path
                          d="M14.8008 12C14.8008 10.3431 13.4577 9 11.8008 9C10.1439 9 8.80078 10.3431 8.80078 12C8.80078 13.6569 10.1439 15 11.8008 15C13.4577 15 14.8008 13.6569 14.8008 12Z"
                          stroke="currentColor"
                          stroke-width="1.5"
                        />
                      </svg>
                    </button>
                  </Tooltip>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <FooterWithExpandButton
        onPress={() => {
          navigate("medication", {
            state: patient,
            ...patient,
            hasEditAccess,
          });
        }}
      />
    </div>
  );
}

export default Medication;
