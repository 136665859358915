import moment from "moment";
import React, { useState } from "react";
import { CiSearch } from "react-icons/ci";
import { BiCollapseAlt } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import File_dock from "../assets/Images/PatientProfile/File_dock.png";
import { useLocation, useNavigate } from "react-router-dom";
import ModalComponent from "../components/Modal";
import seeDocument from "../assets/Images/PatientProfile/seeDocument.png";
import toast from "react-hot-toast";
import { pdfjs } from "react-pdf";
import PdfComp from "../components/PdfComp";
import { IoMdAdd } from "react-icons/io";
import { IoMdRemove } from "react-icons/io";
import doctorIcon from "../assets/Images/doctorIcon.svg";
import telehealthIcon from "../assets/Images/telehealthIcon.svg";
import inclinicIcon from "../assets/Images/inclinicIcon.svg";
import calendarIcon from "../assets/Images/calendarIcon.svg";
import notesIcon from "../assets/Images/notesIcon.svg";
import ConsultationDetails from "./ConsultationDetails";
import Tooltip from "./Tooltip";
import Pagination from "./Pagination";

function ConsultationHistory({
  hasEditAccess,
  consultationHistory,
  diagnosisType,
  patient,
  allMedicines,
  allPreMedicalConditions,
}) {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [filter, setFilter] = useState("all");
  const [showModal, setShowModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [isViewing, setIsViewing] = useState(false);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentConsultation = consultationHistory.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  if (consultationHistory?.length === 0) {
    return (
      <div className="bg-white rounded-lg shadow-lg p-6 mx-6">
        <div className="text-black text-lg font-bold font-poppins">
          Consultation
        </div>
        <div className="text-neutral-400 text-2xl text-center my-10 font-semibold font-poppins">
          No Data
        </div>
      </div>
    );
  }

  const handleModalOpen = (item) => {
    setSelectedItem(item);
    setIsViewing(false);
    setShowModal(true);
  };

  const handleViewDetails = (item) => {
    setSelectedItem(item);
    setIsViewing(true);
    setShowModal(true);
  };

  return (
    <>
      <ConsultationDetails
        show={showModal}
        patient={patient}
        ITEM={selectedItem}
        isViewing={isViewing}
        hide={() => setShowModal(false)}
      />
      <div className="px-6">
        {/* Search and Button  */}
        <div className="flex flex-row items-center justify-between mt-5">
          <label className="w-2/3 flex flex-row items-center justify-start bg-zinc-100  rounded-2xl py-2 px-4 gap-2">
            <CiSearch className="text-zinc-500 w-5 h-5 text-sm font-normal" />
            <input
              type="text"
              placeholder="Search for consultations"
              value={searchTerm}
              onChange={handleSearchChange}
              className={`w-full bg-zinc-100 placeholder:text-zinc-500 text-zinc-500 text-sm font-normal font-poppins rounded-r-2xl focus:outline-none focus:border-transparent`}
            />
          </label>

          <div class="flex flex-row items-center">
            <button
              onClick={() => handleFilterChange("all")}
              className={`px-4 py-2.5 ${
                filter === "all"
                  ? "bg-sky-100 text-sky-700 border-blue-200"
                  : "bg-white text-neutral-600 border-neutral-300"
              }  rounded-tl-lg rounded-bl-lg border-l border-t border-b text-sm font-medium font-poppins leading-tight`}
            >
              All
            </button>
            <button
              onClick={() => handleFilterChange("current")}
              className={`px-4 py-2.5 ${
                filter === "current"
                  ? "bg-sky-100 text-sky-700 border-blue-200"
                  : "bg-white text-neutral-600 border-neutral-300"
              }  border text-sm font-medium font-poppins leading-tight`}
            >
              Current
            </button>
            <button
              onClick={() => handleFilterChange("previous")}
              className={`px-4 py-2.5 ${
                filter === "previous"
                  ? "bg-sky-100 text-sky-700 border-blue-200"
                  : "bg-white text-neutral-600 border-neutral-300"
              }  rounded-tr-lg rounded-br-lg border-r border-t border-b text-sm font-medium font-poppins leading-tight`}
            >
              Previous
            </button>
          </div>
        </div>

        <div className="w-full mx-auto mt-4 shadow rounded-md">
          <table className="min-w-full">
            <thead>
              <tr className="text-left bg-gray-50 border-b border-gray-200">
                <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                  Session Id
                </th>
                <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                  Consultation Date
                </th>
                <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                  Doctor
                </th>
                <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                  Type Of Appointment
                </th>
                <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                  Notes
                </th>
                <th className="p-4 text-neutral-600 text-xs font-medium font-poppins leading-none">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {currentConsultation
                ?.filter((item) => {
                  if (filter === "all") return true;
                  if (filter === "current") return item.PersonalNote === null;
                  if (filter === "previous") return item.PersonalNote !== null;
                })
                .map((item) => {
                  const currentDate = moment();
                  const startDate = moment(item.StartDate);
                  const endDate = moment(item.EndDate);
                  const isOngoing = currentDate.isBetween(
                    startDate,
                    endDate,
                    null,
                    "[]"
                  );
                  return (
                    <tr
                      key={item?.AppointmentID}
                      className="border-b border-gray-200 hover:bg-sky-50"
                    >
                      <td className="p-4 text-neutral-600 text-sm font-normal font-poppins leading-tight">
                        #{item?.AppointmentID}
                      </td>
                      <td className="p-4 text-neutral-600 text-sm font-normal font-poppins leading-tight">
                        {moment(item?.ConsultationDate).format("DD MMMM YYYY")}
                      </td>
                      <td className="p-4 text-neutral-600 text-sm font-normal font-poppins leading-tight">
                        {item?.DoctorName}
                      </td>
                      <td className="p-4">
                        <div className="flex items-center space-x-2">
                          <img
                            src={
                              item?.AppointmentType === "TeleHealth"
                                ? telehealthIcon
                                : inclinicIcon
                            }
                            alt=""
                          />
                          <div className="text-neutral-600 text-sm font-normal font-poppins leading-tight">
                            {item?.AppointmentType}
                          </div>
                        </div>
                      </td>
                      <td className="p-4 text-neutral-600 text-sm font-normal font-poppins leading-tight">
                        {item?.PersonalNote || "_"}
                      </td>

                      <td className="p-4">
                        {item?.PersonalNote === null && hasEditAccess ? (
                          <button
                            onClick={() => {
                              handleModalOpen(item);
                            }}
                            className="text-sky-500 text-sm font-medium font-poppins underline leading-none"
                          >
                            Add Notes
                          </button>
                        ) : item?.PersonalNote !== null ? (
                          <Tooltip message="View">
                            <button
                              onClick={() => handleViewDetails(item)}
                              className="p-1 rounded-full group group-hover:bg-sky-100"
                            >
                              <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-6 h-6 text-neutral-600 group-hover:text-sky-400"
                              >
                                <path
                                  d="M21.3448 11.045C21.6488 11.4713 21.8008 11.6845 21.8008 12C21.8008 12.3155 21.6488 12.5287 21.3448 12.955C19.9787 14.8706 16.49 19 11.8008 19C7.11156 19 3.62288 14.8706 2.25682 12.955C1.95279 12.5287 1.80078 12.3155 1.80078 12C1.80078 11.6845 1.95279 11.4713 2.25682 11.045C3.62288 9.12944 7.11156 5 11.8008 5C16.49 5 19.9787 9.12944 21.3448 11.045Z"
                                  stroke="currentColor"
                                  stroke-width="1.5"
                                />
                                <path
                                  d="M14.8008 12C14.8008 10.3431 13.4577 9 11.8008 9C10.1439 9 8.80078 10.3431 8.80078 12C8.80078 13.6569 10.1439 15 11.8008 15C13.4577 15 14.8008 13.6569 14.8008 12Z"
                                  stroke="currentColor"
                                  stroke-width="1.5"
                                />
                              </svg>
                            </button>
                          </Tooltip>
                        ) : null}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        <Pagination
          totalItems={consultationHistory.length}
          itemsPerPage={itemsPerPage}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      </div>
    </>
  );
}

export default ConsultationHistory;
