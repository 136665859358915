import React, { useState } from "react";
import TitleWithAddButton from "./TitleWithAddButton";
import alleryImage from "../assets/Images/PatientProfile/allergyImage.png";
import { CiSearch } from "react-icons/ci";
import { Cat } from "lucide-react";
import Dropdown from "./Dropdown";
import { useDispatch, useSelector } from "react-redux";
import toast from "react-hot-toast";
import { addAllergy } from "../services/operations/patientManagementAPI";
import { useNavigate } from "react-router-dom";
import { addNewAllergy } from "../services/operations/masterDataAPI";

function Allergies({ hasEditAccess, allergiesType, allergies, patient }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { token } = useSelector((state) => state.auth);
  const { allergyCategory } = useSelector((state) => state.masterData);
  const [formData, setFormData] = useState({
    Name: "",
    ACID: "",
  });

  const [errors, setErrors] = useState({
    Name: "",
    ACID: "",
  });

  const [showAddCard, setShowAddCard] = useState(false);
  const [showAddView, setShowAddView] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestionList, setSuggestionList] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const handleInputChange2 = (e) => {
    const value = e.target.value;
    setInputValue(value);
    if (!value) {
      setSuggestionList([]);
      setShowSuggestions(false);
    } else {
      const filteredSuggestions = allergiesType.filter((allergy) =>
        allergy.Name.toLowerCase().includes(value.toLowerCase())
      );
      setSuggestionList(filteredSuggestions);
      setShowSuggestions(true);
    }
  };

  const handleOnChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  };

  function handleSelect(name, option) {
    setFormData((prev) => ({
      ...prev,
      [name]: option,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  }

  const handleAddAllergies = (allergy) => {
    const allergyExists = allergies.find(
      (c) => c.AllergyID === allergy.AllergyID
    );
    if (allergyExists) {
      toast.error("Allergy already exists");
      return;
    }

    const body = {
      AHUID: patient?.AHUID,
      AllergyID: [allergy?.AllergyID],
    };
    setInputValue("");
    setSuggestionList([]);
    setShowSuggestions(false);
    dispatch(addAllergy(token, body, patient));
  };

  const handleAddNewAllergy = () => {
    const mandatoryFields = ["Name", "ACID"];
    const newErrors = {};

    mandatoryFields.forEach((key) => {
      const value = formData[key];

      if (!value) {
        newErrors[key] = "This field is required";
      } else if (typeof value === "string" && value.trim() === "") {
        newErrors[key] = "This field cannot be empty";
      }
    });

    if (Object.keys(newErrors).length > 0) {
      toast.error("Please fill all the required fields");
      setErrors(newErrors);
      console.log("ERRORS", errors);
      return;
    }

    console.log("====================================");
    console.log("FORM DATA", formData);
    console.log("====================================");
    dispatch(addNewAllergy(token, formData, patient));
    handleCloseAddCard();
  };

  const handleCloseAddCard = () => {
    setShowAddCard(false);
    setFormData({ Name: "", ACID: "" });
    setErrors({ Name: "", ACID: "" });
  };

  if (allergies?.length === 0) {
    return (
      <div>
        {hasEditAccess ? (
          <TitleWithAddButton
            title="Allergies"
            showAddButton={!showAddCard}
            onPress={() => setShowAddCard(true)}
          />
        ) : (
          <TitleWithAddButton title="Allergies" showAddButton={false} />
        )}
        {showAddCard ? (
          <div>
            <div className="my-3">
              <label>
                <p className="text-zinc-900 text-sm font-normal font-poppins mb-2">
                  Name of the allergy
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="text"
                  name="Name"
                  maxLength={20}
                  value={formData.Name}
                  onChange={handleOnChange}
                  placeholder="Enter Name"
                  className={`w-full text-black text-sm font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                    errors.Name ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>
            <div className="mb-5">
              <label>
                <p className="text-zinc-900 text-sm font-normal font-poppins mb-2">
                  Select Category
                  <sup className="text-red-600">*</sup>
                </p>
                <Dropdown
                  placeholder={"Select Category"}
                  error={errors.ACID}
                  name="ACID"
                  options={allergyCategory}
                  onSelect={handleSelect}
                  value={formData.ACID}
                  labelKey={"Category"}
                  valueKey={"ACID"}
                />
              </label>
            </div>
            <div className="flex flex-row items-center justify-center mt-4 space-x-4">
              <button
                onClick={handleCloseAddCard}
                className="w-48 h-11 flex items-center justify-center bg-white rounded-lg border border-sky-500 active:scale-95"
              >
                <div className="text-sky-500 text-base font-semibold font-poppins leading-normal">
                  Cancel
                </div>
              </button>
              <button
                onClick={handleAddNewAllergy}
                className="w-48 h-11 flex items-center justify-center bg-sky-500 rounded-lg border border-sky-500 active:scale-95"
              >
                <div className="text-white text-base font-semibold font-poppins leading-normal">
                  Add
                </div>
              </button>
            </div>
          </div>
        ) : (
          <>
            {/* Search Bar */}
            {hasEditAccess && (
              <div className="relative my-4">
                <label className="w-full mx-auto flex flex-row items-center justify-start bg-zinc-100  rounded-2xl py-2 px-4 gap-2">
                  <CiSearch className="text-zinc-500 w-5 h-5 text-sm font-normal" />
                  <input
                    type="text"
                    placeholder="Add here"
                    value={inputValue}
                    onChange={handleInputChange2}
                    className={`w-full bg-zinc-100 placeholder:text-zinc-500 text-zinc-500 text-sm font-normal font-poppins rounded-r-2xl focus:outline-none focus:border-transparent`}
                  />
                </label>
                {showSuggestions && (
                  <div className="absolute left-0 mt-1 w-1/2 bg-white border rounded-lg py-2 space-y-1 shadow-lg z-10 max-h-[35vh] overflow-y-auto scroll-smooth scrollbar-thin scrollbar-thumb-sky-500 scrollbar-track-sky-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
                    {suggestionList?.length > 0 ? (
                      suggestionList?.slice(0, 4).map((allergy) => (
                        <button
                          key={allergy.AllergyID}
                          onClick={() => handleAddAllergies(allergy)}
                          className={`w-full px-4 py-2 hover:bg-gray-100`}
                        >
                          <p className="text-left text-zinc-900 text-base font-normal font-poppins leading-normal">
                            {allergy.Name}
                          </p>
                        </button>
                      ))
                    ) : (
                      <div className="p-2 flex flex-col space-y-4">
                        <div className="text-center text-black text-sm font-medium font-poppins leading-normal">
                          No Matches Found !
                        </div>
                        <button
                          onClick={() => {
                            setInputValue("");
                            setSuggestionList([]);
                            setShowSuggestions(false);
                            setFormData({ Name: inputValue });
                            setShowAddCard(true);
                          }}
                          className="text-sky-700 text-base font-semibold font-poppins leading-tight"
                        >
                          Add new
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
            <div className="text-neutral-400 text-2xl text-center font-semibold font-poppins">
              No Data
            </div>
          </>
        )}
      </div>
    );
  }

  return (
    <div>
      {hasEditAccess ? (
        <TitleWithAddButton
          title="Allergies"
          showAddButton={!showAddCard}
          onPress={() => setShowAddCard(true)}
        />
      ) : (
        <TitleWithAddButton title="Allergies" showAddButton={false} />
      )}
      {showAddCard ? (
        <div>
          <div className="my-3">
            <label>
              <p className="text-zinc-900 text-sm font-normal font-poppins mb-2">
                Name of the Allergy
                <sup className="text-red-600">*</sup>
              </p>
              <input
                type="text"
                name="Name"
                maxLength={20}
                value={formData.Name}
                onChange={handleOnChange}
                placeholder="Enter Name"
                className={`w-full text-black text-sm font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                  errors.Name ? "border-red-500" : ""
                }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
              />
            </label>
          </div>
          <div className="mb-5">
            <label>
              <p className="text-zinc-900 text-sm font-normal font-poppins mb-2">
                Select Category
                <sup className="text-red-600">*</sup>
              </p>
              <Dropdown
                placeholder={"Select Category"}
                error={errors.ACID}
                name="ACID"
                options={allergyCategory}
                onSelect={handleSelect}
                value={formData.ACID}
                labelKey={"Category"}
                valueKey={"ACID"}
              />
            </label>
          </div>
          <div className="flex flex-row items-center justify-center mt-4 space-x-4">
            <button
              onClick={handleCloseAddCard}
              className="w-48 h-11 flex items-center justify-center bg-white rounded-lg border border-sky-500 active:scale-95"
            >
              <div className="text-sky-500 text-base font-semibold font-poppins leading-normal">
                Cancel
              </div>
            </button>
            <button
              onClick={handleAddNewAllergy}
              className="w-48 h-11 flex items-center justify-center bg-sky-500 rounded-lg border border-sky-500 active:scale-95"
            >
              <div className="text-white text-base font-semibold font-poppins leading-normal">
                Add
              </div>
            </button>
          </div>
        </div>
      ) : (
        <>
          {/* Search Bar */}
          {hasEditAccess && (
            <div className="relative my-4">
              <label className="w-full mx-auto flex flex-row items-center justify-start bg-zinc-100  rounded-2xl py-2 px-4 gap-2">
                <CiSearch className="text-zinc-500 w-5 h-5 text-sm font-normal" />
                <input
                  type="text"
                  placeholder="Add here"
                  value={inputValue}
                  onChange={handleInputChange2}
                  className={`w-full bg-zinc-100 placeholder:text-zinc-500 text-zinc-500 text-sm font-normal font-poppins rounded-r-2xl focus:outline-none focus:border-transparent`}
                />
              </label>
              {showSuggestions && (
                <div className="absolute left-0 mt-1 w-1/2 bg-white border rounded-lg py-2 space-y-1 shadow-lg z-10 max-h-[35vh] overflow-y-auto scroll-smooth scrollbar-thin scrollbar-thumb-sky-500 scrollbar-track-sky-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
                  {suggestionList?.length > 0 ? (
                    suggestionList?.slice(0, 4).map((allergy) => (
                      <button
                        key={allergy.AllergyID}
                        onClick={() => handleAddAllergies(allergy)}
                        className={`w-full px-4 py-2 hover:bg-gray-100`}
                      >
                        <p className="text-left text-zinc-900 text-base font-normal font-poppins leading-normal">
                          {allergy.Name}
                        </p>
                      </button>
                    ))
                  ) : (
                    <div className="p-2 flex flex-col space-y-4">
                      <div className="text-center text-black text-sm font-medium font-poppins leading-normal">
                        No Matches Found !
                      </div>
                      <button
                        onClick={() => {
                          setInputValue("");
                          setSuggestionList([]);
                          setShowSuggestions(false);
                          setFormData({ Name: inputValue });
                          setShowAddCard(true);
                        }}
                        className="text-sky-700 text-base font-semibold font-poppins leading-tight"
                      >
                        Add new
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
          <div className="flex flex-row items-center flex-wrap gap-10">
            {allergies?.map((item) => (
              <button key={item.APPID} className="flex flex-col items-center">
                <div>
                  <img
                    src={alleryImage}
                    className="w-14 h-14 object-contain rounded-full"
                    alt=""
                  />
                </div>
                <div className=" text-center text-black text-xs font-normal font-poppins mt-2">
                  {item.Name}
                </div>
              </button>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default Allergies;
