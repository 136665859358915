import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import breadcrumbsArrow from "../assets/Images/breadcrumbsArrow.png";
import { setBreadcrumbState } from "../slices/breadcrumbSlice";

const routeNameMapping = {
  "/clinicianDashboard": "Clinician Dashboard",
  "/clinicianDashboard/todaysPatientList": "Patient List",
  "/clinicianDashboard/nextSchedule": "Weekly Calendar",
  "/clinicianDashboard/payouts": "Clinic Performance",
  "/clinicianDashboard/fullscreenVideoCall": "Fullscreen Video Call",
  "/patientDashboard": "Patient Management",
  "/patientDashboard/patientRegistration": "Patient Registration",
  "/patientDashboard/bookAppointment": "Book Appointment",
  "/patientDashboard/patientProfile": "Patient Profile",
  "/patientDashboard/patientProfile/healthJourney": "Health Journey",
  "/patientDashboard/patientProfile/mentalHealthAssessment": "Mental Health Assessment",
  "/patientDashboard/patientProfile/consultationHistory": "Consultation History",
  "/patientDashboard/patientProfile/healthProgram": "Health Program",
  "/patientDashboard/patientProfile/addTreatmentProgram": "Add Treatment Program",
  "/patientDashboard/managePatient": "Manage Patient",
  "/patientDashboard/managePatient/reschedulePatient": "Reschedule Appointment",
  "/comingSoon": "Coming Soon",
};

const Breadcrumb = ({ currentState }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const breadcrumbStates = useSelector((state) => state.breadcrumbs);

  // Split the pathname into parts
  const paths = location.pathname.split("/").filter(Boolean);

  useEffect(() => {
    // Update Redux store with the current state
    const fullPath = `/${paths.join("/")}`;
    dispatch(setBreadcrumbState({ path: fullPath, stateData: currentState }));
  }, [location.pathname, currentState, dispatch]);

  const breadcrumbs = paths.map((_, index) => {
    const fullPath = `/${paths.slice(0, index + 1).join("/")}`;
    return {
      path: fullPath,
      name: routeNameMapping[fullPath] ||
        fullPath.split("/").pop().replace(/([A-Z])/g, " $1").replace(/^./, (str) => str.toUpperCase()),
      state: breadcrumbStates[fullPath] || null,
    };
  });

  return (
    <nav className="flex items-center space-x-2">
      {breadcrumbs.map(({ path, name, state }, index) => (
        <div key={path} className="flex items-center">
          {index !== breadcrumbs.length - 1 ? (
            <Link
              to={path}
              state={state}
              className="text-sm font-poppins text-[#3ca5dc] font-semibold"
            >
              {name}
            </Link>
          ) : (
            <span className="text-sm font-poppins text-[#545454] font-normal">
              {name}
            </span>
          )}
          {index < breadcrumbs.length - 1 && (
            <img
              src={breadcrumbsArrow}
              className="w-4 h-4 mx-2"
              alt="breadcrumb arrow"
            />
          )}
        </div>
      ))}
    </nav>
  );
};

export default Breadcrumb;
