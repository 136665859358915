import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FaPlus, FaMinus, FaChevronLeft, FaHeartPulse } from "react-icons/fa6";
import Tile1 from "../../assets/Images/RewardManagement/Tile1.png";
import offlineIcon from "../../assets/Images/RewardManagement/offlineIcon.png";
import onlineIcon from "../../assets/Images/RewardManagement/onlineIcon.png";
import deactivateStatus from "../../assets/Images/RewardManagement/deactivateStatus.png";
import activateStatus from "../../assets/Images/RewardManagement/activateStatus.png";
import labTestIcon from "../../assets/Images/RewardManagement/labTestIcon.png";
import treatmentIcon from "../../assets/Images/RewardManagement/treatmentIcon.png";
import merchandiseIcon from "../../assets/Images/RewardManagement/merchandiseIcon.png";
import editIcon from "../../assets/Images/RewardManagement/editIcon.png";
import binIcon from "../../assets/Images/OurServices/binIcon.png";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import AHlogo from "../../assets/Images/logo.png";
import Breadcrumb from "../../components/Breadcrumb";
import qrCodeImg from "../../assets/Images/Invoicing/qrCodeImg.png";
import { IoIosSearch } from "react-icons/io";
import { HiOutlineDotsVertical } from "react-icons/hi";
import ModalComponent from "../../components/Modal";
import imageCompression from "browser-image-compression";
import toast from "react-hot-toast";
import Tooltip from "../../components/Tooltip";
import { FaCheckCircle } from "react-icons/fa";
import { updateRewardOffer } from "../../services/operations/rewardManagementAPI";
import alertIcon from "../../assets/Images/RewardManagement/alertIcon.png";

function RewardManagement() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { token } = useSelector((state) => state.auth);
    const { rewardOffers, loading } = useSelector((state) => state.reward);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState('All');
    const [specificReward, setSpecificReward] = useState("");

    const handleStatusChange = async (item) => {
        if (!token || !item?.RewardID) return;

        const newStatus = item.Status === 'Active' ? 'Deactivated' : 'Active';
        const body = { Status: newStatus };

        try {
            await dispatch(updateRewardOffer(token, item?.RewardID, body, navigate));
        } catch (error) {
            console.error("Error updating reward offer status:", error);
        }
    };

    const handleButtonClick = async (item) => {
        if (item?.Status === "Draft") {
            if (!token || !item?.RewardID) return;

            const body = {
                Status: "Active",
            };

            try {
                await dispatch(updateRewardOffer(token, item?.RewardID, body, navigate));
            } catch (error) {
                console.error("Error publishing reward offer:", error);
            }
        } else {
            navigate("salesDetail", { state: { item } })
        }
    };

    const filteredOffers = rewardOffers?.filter((item) => {
        if (selectedFilter === 'All') return true;
        return item?.Status.toLowerCase() === selectedFilter.toLowerCase();
    });

    useEffect(() => {
        const editReward = async () => {
            if (token && specificReward) {
                navigate("createReward", { state: { specificReward: specificReward } });
            }
        };

        editReward();
    }, [token, specificReward]);

    const RenderRewardBlocks = () => {
        return (
            <div className="grid grid-cols-4 w-full justify-start items-stretch gap-4">
                {filteredOffers?.map((item, index) => (
                    <div key={item?.RewardID} className="bg-[#FFFFFF] rounded-lg shadow flex flex-col justify-center items-center w-full p-4">
                        {/* Header */}
                        <div className="flex flex-row justify-between items-start w-full">
                            <img
                                src={item?.Image}
                                className="w-14 h-14 object-cover rounded-full"
                                alt={item?.RewardName}
                            />
                            <div className="flex flex-row justify-center items-center gap-4">
                                <Tooltip message="Edit">
                                    <button onClick={() => { setSpecificReward(item) }} className="">
                                        <img src={editIcon} alt="Edit" className="w-6 h-6" />
                                    </button>
                                </Tooltip>
                                {item?.Status === 'Active' ? (
                                    <Tooltip message="Deactivate">
                                        <button onClick={() => { handleStatusChange(item) }} className="">
                                            <img src={activateStatus} alt="Active Icon" className="w-6 h-6" />
                                        </button>
                                    </Tooltip>
                                ) : (
                                    <Tooltip message="Activate">
                                        <button onClick={() => { handleStatusChange(item) }} className="">
                                            <img src={deactivateStatus} alt="Deactivate Icon" className="w-6 h-6" />
                                        </button>
                                    </Tooltip>
                                )}
                            </div>
                        </div>

                        {/* Content */}
                        <div className="flex flex-col justify-center items-start w-full mt-2">
                            <h1 className="text-[#000000] text-sm font-normal font-poppins leading-[21px]">
                                {item?.RewardName}
                            </h1>
                            <h1 className="text-[#545454] text-xs font-normal font-poppins leading-[18px]">
                                (Reward Id: {item?.RewardID})
                            </h1>
                            <h1 className="text-[#545454] text-xs font-normal font-poppins leading-[18px] mt-2">
                                {item?.Description}
                            </h1>
                            <div className="flex flex-row justify-start items-center w-full my-2">
                                <h1 className="text-[#545454] text-xs font-normal font-poppins leading-[18px]">
                                    Type of Offer:
                                </h1>
                                <h1 className="text-[#1C1C1C] text-xs font-medium font-poppins leading-[18px] ml-2 mr-1">
                                    {item?.TypeOfOffer}
                                </h1>
                                <img src={item?.TypeOfOffer === 'Online' ? onlineIcon : offlineIcon} alt={item?.TypeOfOffer === 'Online' ? "Online Icon" : "Offline Icon"} className="w-5 h-5" />
                            </div>
                            <div className="flex flex-row justify-start items-center w-full">
                                <h1 className="text-[#545454] text-xs font-normal font-poppins leading-[18px]">
                                    Reward Type
                                </h1>
                                <img src={item?.TypeOfReward === 'Treatment' ? treatmentIcon : item?.TypeOfReward === 'Merchandise' ? merchandiseIcon : labTestIcon} alt={item?.TypeOfReward === 'Treatment' ? "Treatment Icon" : item?.TypeOfReward === 'Merchandise' ? "Merchandise Icon" : "Lab Test Icon"} className="w-5 h-5 ml-2 mr-1" />
                                <h1 className="text-[#1C1C1C] text-xs font-medium font-poppins leading-[18px]">
                                    {item?.TypeOfReward}
                                </h1>
                            </div>

                            {/* Status */}
                            <div
                                className={`flex flex-row justify-center items-center rounded-[48px] ${item?.Status === 'Active' ? "bg-[#D4FEE2]" : "bg-[#F3F5F7]"} px-3 py-2 w-24 mt-4`}
                            >
                                {item?.Status === 'Active' && (
                                    <FaCheckCircle className="text-[#86D511] mr-2" />
                                )}
                                <h1 className={`${item?.Status === 'Active' ? "text-[#3E8920]" : "text-[#8A8A8A]"} text-xs font-normal font-poppins`}>
                                    {item?.Status === 'Active' ? "Active" : item?.Status === 'Draft' ? "Draft" : "Deactivated"}
                                </h1>
                            </div>

                            <div className="h-[1px] w-full bg-[#EAECF0] my-4"></div>

                            <button
                                onClick={() => handleButtonClick(item)}
                                className="flex flex-row justify-center items-center rounded-lg border bg-white border-[#3CA5DC] px-4 py-2 cursor-pointer hover:scale-95 duration-500 w-full"
                            >
                                <h1 className="text-[#007AAE] text-sm font-semibold font-poppins">
                                    {item?.Status === 'Draft' ? "Publish" : "View Customer List"}
                                </h1>
                            </button>

                            {item?.pendingSales > 0 ? (
                                <div className="flex flex-row justify-start items-center w-full gap-1 mt-1">
                                    <img src={alertIcon} alt="Alert Icon" className="w-4 h-4" />
                                    <h1 className="text-[#954D05] text-xs font-medium font-poppins">
                                        {item?.pendingSales} pending sales
                                    </h1>
                                </div>
                            ) : (
                                <div className="flex flex-row justify-start items-center w-full gap-1 mt-1 h-4"></div>
                            )}

                            {/* Price of the offer */}
                            <div className="flex flex-row justify-center items-center w-full mt-[18px]">
                                <h1 className="text-[#000000] text-xs font-normal font-poppins leading-[18px] w-1/2">
                                    Price of the offer:
                                </h1>
                                <h1 className="text-[#545454] text-xs font-normal font-poppins leading-[18px] w-1/2">
                                    {item?.Currency} {item?.OfferPrice}
                                </h1>
                            </div>
                            <div className="flex flex-row justify-center items-center w-full mt-2">
                                <h1 className="text-[#000000] text-sm font-normal font-poppins leading-[18px] w-1/2">
                                    Discount Offered:
                                </h1>
                                {item?.TypeOfDiscount === 'Free' ? (
                                    <h1 className="text-[#545454] text-sm font-normal font-poppins leading-[18px] w-1/2">
                                        100 %
                                    </h1>
                                ) : (
                                    <h1 className="text-[#545454] text-sm font-normal font-poppins leading-[18px] w-1/2">
                                        {item?.TypeOfDiscount !== 'Percentage' && "CHF"} {item?.DiscountInput} {item?.TypeOfDiscount === 'Percentage' && "%"}
                                    </h1>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    if (loading) {
        return (
            <div className="h-screen flex items-center justify-center">
                <div className="spinner"></div>
            </div>
        );
    }

    return (
        <div className="bg-white min-h-full py-2 px-2 sm:py-5 sm:px-10 scroll-smooth flex flex-col">
            {/* HEADER */}
            <div className="flex flex-row justify-between items-center">
                {/* TITLE */}
                <div className="flex flex-row items-center w-full">
                    <div className="flex flex-col justify-center items-start w-full gap-1">
                        <div className="flex flex-row justify-between items-center w-full">
                            <h1 className="text-[#1C1C1C] text-2xl font-medium font-poppins leading-9">
                                Reward Management
                            </h1>
                            <div className="flex flex-row justify-between items-center gap-5">
                                <button
                                    onClick={() => { setShowCreateModal(true); }}
                                    className="flex flex-row justify-center items-center rounded-md bg-[#3CA5DC] px-3 py-2 cursor-pointer hover:scale-95 duration-500 w-60"
                                >
                                    <h1 className="text-[#FFFFFF] text-base font-semibold font-poppins">
                                        Create Reward
                                    </h1>
                                </button>
                            </div>
                        </div>
                        <Breadcrumb />
                    </div>
                </div>
            </div>

            <div className="flex flex-col justify-center items-center w-full mt-6">
                <div className="flex flex-row justify-between items-center w-full">
                    <h1 className="text-[#1C1C1C] text-lg font-medium font-poppins leading-7 w-full">
                        Rewards
                    </h1>
                    {/* Filter */}
                    <div className="flex flex-row justify-center items-center">
                        {['All', 'Active', 'Draft'].map((filter, index, array) => (
                            <button
                                key={filter}
                                className={`
                                    py-2 px-4
                                    ${index === 0 ? 'rounded-l-lg' : ''} 
                                    ${index === array.length - 1 ? 'rounded-r-lg' : ''} 
                                    ${selectedFilter === filter
                                        ? 'bg-[#E5F4FE] border-[#AFD8F2]'
                                        : 'bg-white border-[#D4D4D4]'
                                    }
                                    ${selectedFilter === filter ? 'border' : 'border'}
                                `}
                                onClick={() => { setSelectedFilter(filter) }}
                            >
                                <h1 className={`${selectedFilter === filter ? "text-[#007AAE]" : "text-[#545454]"} text-sm font-medium font-poppins`}>
                                    {filter}
                                </h1>
                            </button>
                        ))}
                    </div>
                </div>

                {loading ? (
                    <div className="h-screen flex items-center justify-center">
                        <div className="spinner"></div>
                    </div>
                ) : (
                    <>
                        {/* Content */}
                        {filteredOffers?.length === 0 ? (
                            <>
                                <div className="flex flex-col justify-center items-center w-full">
                                    {/* <img
                                        src={noTreatmentPrgBg}
                                        alt="No Treatment Category Available"
                                        className="w-[46.875rem] h-[31.25rem] rounded-[20px] object-cover"
                                    /> */}
                                    <h1 className="text-[#545454] text-2xl font-normal font-poppins mt-2 leading-9">
                                        No Reward is added as of yet
                                    </h1>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="flex flex-wrap flex-row justify-center items-center w-full mt-4">
                                    <RenderRewardBlocks />
                                </div>
                            </>
                        )
                        }
                    </>
                )}
            </div>

            {/* CREATE REWARD MODAL */}
            <ModalComponent
                show={showCreateModal}
                handleClose={() => { setShowCreateModal(false); }}
                outSideModalPress={false}
            >
                <div className="w-[427px]">
                    <div className="flex flex-col justify-center items-start w-full">
                        <h1 className="text-[#1D2939] text-xl font-medium font-poppins leading-6">
                            Create Reward
                        </h1>
                        <button
                            onClick={() => { setShowCreateModal(false); navigate("createReward", { state: { RewardType: "Lab Test" } }) }}
                            className="mt-6 flex flex-row justify-between items-center rounded-2xl shadow bg-white p-4 cursor-pointer hover:scale-95 duration-500 w-full"
                        >
                            <div className="flex flex-row justify-start items-center w-full gap-2">
                                <img src={labTestIcon} alt="Lab Test Icon" className="w-6 h-6" />
                                <h1 className="text-[#545454] text-base font-medium font-poppins">
                                    Lab Test
                                </h1>
                            </div>
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className={`w-16 h-6 transform -rotate-90 transition-transform duration-200`}
                            >
                                <path
                                    d="M18 9.00005C18 9.00005 13.5811 15 12 15C10.4188 15 6 9 6 9"
                                    stroke="black"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                        <button
                            onClick={() => { setShowCreateModal(false); navigate("createReward", { state: { RewardType: "Treatment" } }) }}
                            className="mt-6 flex flex-row justify-between items-center rounded-2xl shadow bg-white p-4 cursor-pointer hover:scale-95 duration-500 w-full"
                        >
                            <div className="flex flex-row justify-start items-center w-full gap-2">
                                <img src={treatmentIcon} alt="Treatment Icon" className="w-6 h-6" />
                                <h1 className="text-[#545454] text-base font-medium font-poppins">
                                    Treatment
                                </h1>
                            </div>
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className={`w-16 h-6 transform -rotate-90 transition-transform duration-200`}
                            >
                                <path
                                    d="M18 9.00005C18 9.00005 13.5811 15 12 15C10.4188 15 6 9 6 9"
                                    stroke="black"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                        <button
                            onClick={() => { setShowCreateModal(false); navigate("createReward", { state: { RewardType: "Merchandise" } }) }}
                            className="mt-6 flex flex-row justify-between items-center rounded-2xl shadow bg-white p-4 cursor-pointer hover:scale-95 duration-500 w-full"
                        >
                            <div className="flex flex-row justify-start items-center w-full gap-2">
                                <img src={merchandiseIcon} alt="Merchandise Icon" className="w-6 h-6" />
                                <h1 className="text-[#545454] text-base font-medium font-poppins">
                                    Merchandise
                                </h1>
                            </div>
                            <svg
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                className={`w-16 h-6 transform -rotate-90 transition-transform duration-200`}
                            >
                                <path
                                    d="M18 9.00005C18 9.00005 13.5811 15 12 15C10.4188 15 6 9 6 9"
                                    stroke="black"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                    </div>
                </div>
            </ModalComponent>
        </div >
    );
}

export default RewardManagement;