import { toast } from "react-hot-toast";
import {
    setLoading,
    setRewardOffers,
} from "../../slices/rewardSlice";
import { apiConnector } from "../apiConnector";
import { rewardManagementEndpoints } from "../apis";
import { getBucketURL } from "../../utils/regex";

const {
    GET_ALL_REWARD_OFFERS,
    ADD_REWARD,
    UPDATE_REWARD,
    DELETE_REWARD,
    GET_REWARD_CUSTOMER_LIST,
    GET_REWARD_SALES_REPORT,
    UPDATE_REWARD_CUSTOMER_LIST,
    UPLOAD_OUR_REWARD_OFFER_IMAGE,
} = rewardManagementEndpoints;

// GET REWARD OFFERS
export function getRewardOffers(token) {
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            const response = await apiConnector(
                "GET",
                GET_ALL_REWARD_OFFERS,
                null,
                {
                    Authorization: `Bearer ${token}`,
                }
            );

            if (!response?.status === 200) {
                throw new Error(response?.data?.message);
            }

            const offersWithImages = await Promise.all(
                response?.data?.rewardOffers.map(async (offer) => {
                    // Fetch offer image only if it's not null
                    const offerImageURL = offer?.RewardImageURL ? await getBucketURL(offer?.RewardImageURL) : offer?.RewardImageURL;

                    return {
                        ...offer,
                        Image: offerImageURL,
                    };
                })
            );
            dispatch(setRewardOffers(offersWithImages || []));
        } catch (error) {
            dispatch(setRewardOffers([]));
            console.log(
                "GET_REWARD_OFFERS API ERROR............",
                error
            );
            // toast.error(error?.response?.data?.message || "Unable to fetch REWARD OFFERS");
        }
        dispatch(setLoading(false));
    };
}

// ADD REWARD OFFER
export function addNewRewardOffer(token, body, navigate) {
    return async (dispatch) => {
        const toastId = toast.loading("Loading...");
        dispatch(setLoading(true));
        try {
            const response = await apiConnector("POST", ADD_REWARD, body, {
                Authorization: `Bearer ${token}`,
            });

            if (!response?.status === 200) {
                throw new Error(response?.data?.message);
            }

            // console.log("addNewRewardOffer RESPONSE............", response);
            toast.success("Reward Offer Created Successfully");
            dispatch(getRewardOffers(token));
            navigate("/rewardManagement");
        } catch (error) {
            console.log("addNewRewardOffer ERROR............", error);
            toast.error(error?.response?.data?.message || "Could Not Add Reward Offer");
        }
        dispatch(setLoading(false));
        toast.dismiss(toastId);
    };
}

//PUT UPDATE REWARD OFFER
export function updateRewardOffer(token, ID, body, navigate) {
    return async (dispatch) => {
        const toastId = toast.loading("Loading...");
        dispatch(setLoading(true));
        try {
            const response = await apiConnector(
                "PUT",
                UPDATE_REWARD + "/" + ID,
                body,
                {
                    Authorization: `Bearer ${token}`,
                }
            );

            if (!response?.status === 200) {
                throw new Error(response?.data?.message);
            }
            toast.success("Reward Offer Updated Successfully");
            dispatch(getRewardOffers(token));
            navigate("/rewardManagement");
        } catch (error) {
            console.log("updateRewardOffer api ERROR............", error);
            toast.error("Could Not Update Reward Offer");
        }
        dispatch(setLoading(false));
        toast.dismiss(toastId);
    };
}

// DELETE REWARD OFFER
export function deleteRewardOffer(token, ID, navigate) {
    return async (dispatch) => {
        const toastId = toast.loading("Loading...");
        dispatch(setLoading(true));
        try {
            const response = await apiConnector(
                "DELETE",
                DELETE_REWARD + "/" + ID,
                null,
                {
                    Authorization: `Bearer ${token}`,
                }
            );
            if (!response?.status === 200) {
                throw new Error(response?.data?.message);
            }
            toast.success(
                response?.data?.message || "Reward Offer Deleted"
            );
            dispatch(getRewardOffers(token));
            navigate("/rewardManagement");
        } catch (error) {
            console.log(
                "DELETE REWARD OFFER API ERROR :",
                error
            );
            toast.error(
                error?.response?.data?.message ||
                "ERROR WHILE DELETING REWARD OFFER"
            );
        }
        dispatch(setLoading(false));
        toast.dismiss(toastId);
    };
}

// GET REWARD CUSTOMER LIST
export async function getRewardCustomerList(token, ID) {
    const toastId = toast.loading("Loading...");
    let result = [];
    try {
        const response = await apiConnector(
            "GET",
            GET_REWARD_CUSTOMER_LIST + "/" + ID,
            null,
            {
                Authorization: `Bearer ${token}`,
            }
        );

        if (!response?.status === 200) {
            throw new Error(response?.data?.message);
        }

        result = {
            ...response?.data,
            rewardCustomerList: await Promise.all(
                response?.data?.rewardCustomerList.map(async (customer) => {
                    // Fetch customer image only if PatientProfileImageUrl exists
                    const customerImageURL = customer?.PatientProfileImageUrl
                        ? await getBucketURL(customer?.PatientProfileImageUrl)
                        : null;

                    return {
                        ...customer,
                        Image: customerImageURL, // Store the fetched image as 'Image'
                    };
                })
            )
        };
    } catch (error) {
        console.log(
            "get_Reward_Customer_List API ERROR............",
            error
        );
    } finally {
        toast.dismiss(toastId);
    }
    return result;
}

//PUT UPDATE REWARD OFFER
export function updateRewardCustomerList(token, ID, body, navigate) {
    return async (dispatch) => {
        const toastId = toast.loading("Loading...");
        dispatch(setLoading(true));
        try {
            const response = await apiConnector(
                "PUT",
                UPDATE_REWARD_CUSTOMER_LIST + "/" + ID,
                body,
                {
                    Authorization: `Bearer ${token}`,
                }
            );

            if (!response?.status === 200) {
                throw new Error(response?.data?.message);
            }
            toast.success("Reward Sales Marked as Done");
            dispatch(getRewardOffers(token));
            navigate("/rewardManagement");
        } catch (error) {
            console.log("updateRewardCustomerList api ERROR............", error);
            toast.error("Could Not Update Reward Sales");
        }
        dispatch(setLoading(false));
        toast.dismiss(toastId);
    };
}

// GET REWARD SALES REPORT
export async function getRewardSalesReport(token, year) {
    let result = [];
    try {
        const response = await apiConnector(
            "GET",
            GET_REWARD_SALES_REPORT + "/" + year,
            null,
            {
                Authorization: `Bearer ${token}`,
            }
        );

        if (!response?.status === 200) {
            throw new Error(response?.data?.message);
        }

        result = response?.data;
    } catch (error) {
        console.log(
            "get_Reward_Sales_Report API ERROR............",
            error
        );
    }
    return result;
}

//UPLOAD REWARD OFFER IMAGE
export const uploadRewardOfferImage = async (formData, token) => {
    const toastId = toast.loading("Loading...");
    let result = [];
    try {
        const response = await apiConnector(
            "POST",
            UPLOAD_OUR_REWARD_OFFER_IMAGE,
            formData,
            {
                Authorization: `Bearer ${token}`,
            }
        );
        result = response?.data;
        if (response.status !== 200) {
            throw new Error(response.data.message);
        }
    } catch (error) {
        console.error("UPLOAD REWARD OFFER IMAGE API ERROR:", error);
        toast.error("Oops! Something went wrong. Please try again.");
    }
    toast.dismiss(toastId);
    return result;
};
