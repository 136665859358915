import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaPlus, FaMinus, FaChevronLeft, FaHeartPulse } from "react-icons/fa6";
import { useDispatch, useSelector } from "react-redux";
import iphoneIsland from "../../assets/Images/OurServices/iphoneIsland.png";
import rewardFrontBg from "../../assets/Images/RewardManagement/rewardFrontBg.png";
import getDirectionIcon from "../../assets/Images/RewardManagement/getDirectionIcon.png";
import coin from "../../assets/Images/RewardManagement/Coin.png";
import COINS from "../../assets/Images/RewardManagement/COins.png";
import locationIcon from "../../assets/Images/RewardManagement/locationIcon.png";
import basketShopIcon from "../../assets/Images/RewardManagement/basketShopIcon.png";
import uploadIcon from "../../assets/Images/OurServices/uploadIcon.png";
import Breadcrumb from "../../components/Breadcrumb";
import toast from "react-hot-toast";
import iphoneFrame from "../../assets/Images/MedicalConcierge/iphoneFrame.svg";
import Dropdown from "../../components/Dropdown";
import { FaExternalLinkAlt } from "react-icons/fa";
import imageCompression from "browser-image-compression";
import RadioButton from "../../components/RadioButton/RadioButton";
import RadioButtonReward from "../../components/RadioButton/RadioButtonReward";
import RadioButtonReward2 from "../../components/RadioButton/RadioButtonReward2";
import CurrencySelect from 'react-select-currency';
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import { PiHeartbeatBold } from "react-icons/pi";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import Tile1 from "../../assets/Images/RewardManagement/Tile1.png";
import labTestPreviewIcon from "../../assets/Images/RewardManagement/labTestPreviewIcon.png";
import merchandisePreviewIcon from "../../assets/Images/RewardManagement/merchandisePreviewIcon.png";
import treatmentPreviewIcon from "../../assets/Images/RewardManagement/treatmentPreviewIcon.png";
import starIcon from "../../assets/Images/RewardManagement/starIcon.png";
import { IoIosArrowDown } from "react-icons/io";
import { addNewRewardOffer, uploadRewardOfferImage, updateRewardOffer, deleteRewardOffer } from "../../services/operations/rewardManagementAPI";
import deleteModalIcon from "../../assets/Images/OurServices/deleteModalIcon.png";
import ModalComponent from "../../components/Modal";

function CreateReward() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { token } = useSelector((state) => state.auth);
    const { allBranches } = useSelector((state) => state.masterData);
    const reward = location?.state?.specificReward || null;
    const rewardType = location?.state?.RewardType || null;
    const [showBackView, setShowBackView] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [deleteItem, setDeleteItem] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [updating, setUpdating] = useState(false);
    const [rewardID, setRewardID] = useState(null);
    const [image, setImage] = useState("");
    const [formData, setFormData] = useState({
        LogoLocation: "",
        ProductName: "",
        Description: "",
        RewardType: "",
        CostPrice: "",
        Currency: "CHF",
        DiscountType: "",
        Discount: "",
        OfferPrice: "",
        OfferType: "",
        Address: "",
        AddressText: "",
    });
    const [errors, setErrors] = useState({
        LogoLocation: "",
        ProductName: "",
        Description: "",
        RewardType: "",
        CostPrice: "",
        Currency: "",
        DiscountType: "",
        Discount: "",
        OfferPrice: "",
        OfferType: "",
        Address: "",
    });

    useEffect(() => {
        setFormData((prevData) => ({
            ...prevData,
            RewardType: rewardType,
        }));
    }, [rewardType])

    useEffect(() => {
        if (reward) {
            setUpdating(true);
            setRewardID(reward?.RewardID);
            setImage(reward?.Image);
            setFormData({
                LogoLocation: reward?.RewardImageURL,
                ProductName: reward?.RewardName,
                Description: reward?.Description,
                RewardType: reward?.TypeOfReward,
                CostPrice: reward?.Price,
                Currency: reward?.Currency,
                DiscountType: reward?.TypeOfDiscount,
                Discount: reward?.DiscountInput,
                OfferPrice: reward?.OfferPrice,
                OfferType: reward?.TypeOfOffer,
                Address: reward?.BranchID?.toString() || "",
            });
        }
    }, [reward]);

    if (isLoading) {
        return (
            <div className="h-screen flex items-center justify-center">
                <div className="spinner"></div>
            </div>
        );
    }

    return (
        <div className="bg-white min-h-full py-2 px-2 sm:py-5 sm:px-10 scroll-smooth flex flex-col">
            {/* HEADER */}
            <div className="flex flex-row justify-between items-center">
                {/* TITLE */}
                <div className="flex flex-row items-center">
                    <div className="flex flex-col justify-center items-start gap-1">
                        <h1 className="text-[#1C1C1C] text-2xl font-medium font-poppins leading-9">
                            Create Reward
                        </h1>
                        <Breadcrumb currentState={location?.state} />
                    </div>
                </div>
            </div>

            {/* Content */}
            <div className="flex gap-8 p-8">
                <ServiceForm
                    formData={formData}
                    setFormData={setFormData}
                    errors={errors}
                    setErrors={setErrors}
                    image={image}
                    setImage={setImage}
                    updating={updating}
                    setUpdating={setUpdating}
                    showBackView={showBackView}
                    setShowBackView={setShowBackView}
                    allBranches={allBranches}
                    rewardID={rewardID}
                />
                <PreviewSection
                    formData={formData}
                    image={image}
                    showBackView={showBackView}
                    setShowBackView={setShowBackView}
                    updating={updating}
                    setUpdating={setUpdating}
                    deleteItem={deleteItem}
                    setDeleteItem={setDeleteItem}
                    setShowModal={setShowModal}
                    showModal={showModal}
                    rewardID={rewardID}
                />
            </div>
        </div>
    );
}

const ServiceForm = ({
    formData,
    showBackView,
    setShowBackView,
    setFormData,
    errors,
    setErrors,
    image,
    setImage,
    updating,
    setUpdating,
    allBranches,
    rewardID,
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { token } = useSelector((state) => state.auth);
    const [changeImage, setChangeImage] = useState(false);
    const [isDraft, setIsDraft] = useState(false);

    const discountOptions = [
        { name: "Percentage", value: "Percentage" },
        { name: "Price Reduction", value: "Price reduction" },
        { name: "Free", value: "Free" },
    ];

    const offerOptions = [
        { name: "OfferType", text: "Online", value: "Online" },
        { name: "OfferType", text: "Offline", value: "Offline" },
    ];

    const addressOptions = allBranches?.map(branch => ({
        name: "Address",
        text: branch.Name,
        text1: branch.Address,
        value: branch.BranchID.toString() // Convert BranchID to string for the value
    }));

    const handleDiscountChange = (value) => {
        setFormData((prevData) => ({
            ...prevData,
            DiscountType: value,
            Discount: value === "Percentage" || value === "Price reduction" ? prevData.Discount : '',
        }));
    };

    const handleOptionChange = (event) => {
        const { name, value } = event.target;
        if (name === 'Address') {
            const selectedAddress = addressOptions.find(option => option.value === value);
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
                AddressText: selectedAddress ? selectedAddress.text1 : '',
            }));
        }
        else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }
    };

    const handleCurrencyChange = (selectedOption) => {
        setFormData({
            ...formData,
            Currency: selectedOption.value,
        });
    };

    function handleOnChange(event) {
        const { name, value } = event.target;
        let maxLength;

        // Set the maxLength based on the field name
        switch (name) {
            case "ProductName":
                maxLength = 20;
                break;
            case "Description":
                maxLength = 100;
                break;
            case "CostPrice":
                maxLength = 6;
                break;
            case "Discount":
                if (formData.DiscountType === "Percentage") {
                    // Ensure the percentage is between 0 and 100
                    if (value > 100) return; // Reject any value above 100 for Percentage
                } else if (formData.DiscountType === "Price reduction") {
                    // Ensure the price reduction does not exceed the Cost Price
                    if (parseFloat(value) > parseFloat(formData.CostPrice)) return; // Reject values higher than CostPrice
                }
                maxLength = 6;
                break;
            default:
                maxLength = Infinity; // No maxLength for other fields
        }

        // Update form data if within allowed length
        if (value.length <= maxLength) {
            setFormData((prev) => ({
                ...prev,
                [name]: value,
            }));
            setErrors((prev) => ({
                ...prev,
                [name]: "",
            }));
        }
    }

    function handleSelect(name, option) {
        setFormData((prev) => ({
            ...prev,
            [name]: option,
        }));
        setErrors((prev) => ({
            ...prev,
            [name]: "",
        }));
    }

    const handleImageChange = async (e) => {
        if (updating) setChangeImage(true);
        const file = e.target.files[0];
        if (!file) return;
        const options = {
            maxSizeMB: 0.5,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
        };
        const toastId = toast.loading("Compressing Image...");
        const compressedFile = await imageCompression(file, options);
        toast.dismiss(toastId);
        setImage(compressedFile);
        uploadImage(compressedFile);
    };

    const uploadImage = async (file) => {
        const formData = new FormData();
        formData.append("RewardImage", file);
        try {
            const res = await uploadRewardOfferImage(formData, token);
            setFormData((prev) => ({
                ...prev,
                LogoLocation: res?.RewardImageUrl,
            }));
            setErrors((prev) => ({
                ...prev,
                LogoLocation: "",
            }));
            setImage(URL.createObjectURL(file));
        } catch (error) {
            console.log(error);
        }
    };

    const submitHandler = async (event, draft) => {
        event.preventDefault();
        const mandatoryFields = [
            "LogoLocation",
            "ProductName",
            "Description",
            "RewardType",
            "CostPrice",
            "Currency",
            "DiscountType",
            "OfferPrice",
            "OfferType",
            "Discount",
        ];
        const newErrors = {};

        mandatoryFields.forEach((key) => {
            const value = formData[key];

            // Skip validation for Discount if DiscountType is 'Free'
            if (key === 'Discount' && formData.DiscountType === 'Free') {
                return;
            }

            if (!value) {
                newErrors[key] = "This field is required";
            } else if (typeof value === "string" && value.trim() === "") {
                newErrors[key] = "This field cannot be empty";
            }
        });

        if (Object.keys(newErrors).length > 0) {
            toast.error("Please fill all the required fields");
            setErrors(newErrors);
            console.log("ERRORS", errors);
            return;
        }

        if (!updating) {
            const body = {
                RewardImageURL: formData?.LogoLocation,
                RewardName: formData?.ProductName,
                Description: formData?.Description,
                TypeOfReward: formData?.RewardType,
                Currency: formData?.Currency,
                Price: Number(formData?.CostPrice),
                TypeOfDiscount: formData?.DiscountType,
                DiscountInput: Number(formData?.Discount),
                TypeOfOffer: formData?.OfferType,
                Status: draft ? "Draft" : "Active",
                OfferPrice: parseFloat(formData?.OfferPrice),
                BranchID: formData?.Address || "",
            };

            try {
                dispatch(addNewRewardOffer(token, body, navigate));
            } catch (error) {
                console.error("Error adding reward offer:", error);
            }
        } else {
            let body;
            if (changeImage) {
                body = {
                    RewardImageURL: formData?.LogoLocation,
                    RewardName: formData?.ProductName,
                    Description: formData?.Description,
                    TypeOfReward: formData?.RewardType,
                    Currency: formData?.Currency,
                    Price: formData?.CostPrice,
                    TypeOfDiscount: formData?.DiscountType,
                    DiscountInput: formData?.Discount,
                    TypeOfOffer: formData?.OfferType,
                    Status: draft ? "Draft" : "Active",
                    OfferPrice: formData?.OfferPrice,
                    BranchID: formData?.Address || "",
                };
            } else {
                body = {
                    RewardName: formData?.ProductName,
                    Description: formData?.Description,
                    TypeOfReward: formData?.RewardType,
                    Currency: formData?.Currency,
                    Price: formData?.CostPrice,
                    TypeOfDiscount: formData?.DiscountType,
                    DiscountInput: formData?.Discount,
                    TypeOfOffer: formData?.OfferType,
                    Status: draft ? "Draft" : "Active",
                    OfferPrice: formData?.OfferPrice,
                    BranchID: formData?.Address || "",
                };
            }

            try {
                await dispatch(updateRewardOffer(token, rewardID, body, navigate));
                setUpdating(false);
                setChangeImage(false);
            } catch (error) {
                console.error("Error updating reward offer:", error);
            }
        }
    };

    // useEffect to calculate the OfferPrice
    useEffect(() => {
        const costPrice = parseFloat(formData.CostPrice) || 0;
        let offerPrice = costPrice;

        if (formData.DiscountType === "Percentage" && formData.Discount) {
            const discount = parseFloat(formData.Discount) || 0;
            offerPrice = costPrice - (costPrice * (discount / 100));
        } else if (formData.DiscountType === "Price reduction" && formData.Discount) {
            const discount = parseFloat(formData.Discount) || 0;
            offerPrice = costPrice - discount;
        } else if (formData.DiscountType === "Free") {
            offerPrice = 0;
        }

        // Update OfferPrice in formData with 2 decimal precision
        setFormData((prevData) => ({
            ...prevData,
            OfferPrice: offerPrice.toFixed(2),
        }));
    }, [formData.CostPrice, formData.DiscountType, formData.Discount]);

    return (
        <div className="w-1/2">
            <div className="bg-white rounded-lg shadow-md p-6 border border-sky-500">
                {/* Product Image */}
                <div className="mb-4">
                    <label
                        className={`w-full border-dashed ${errors.LogoLocation ? "border-red-500" : "border-[#8A8A8A]"
                            }  border-2 rounded-2xl p-4 flex flex-col items-center justify-center cursor-pointer bg-white
                    `}
                    >
                        {formData?.LogoLocation ? (
                            <>
                                <img
                                    src={image}
                                    alt="upload icon"
                                    className="w-14 h-14 object-contain"
                                />

                                <span className="text-sm font-medium font-poppins mt-2">
                                    Change Logo/Product Image
                                </span>
                            </>
                        ) : (
                            <>
                                <img
                                    src={uploadIcon}
                                    alt="upload icon"
                                    className="w-14 h-14 object-contain"
                                />
                                <span className="text-sm font-medium font-poppins mt-2">
                                    Add Logo/Product Image
                                </span>
                                <span className="text-[#555252] text-[10px] font-normal font-poppins mt-1">
                                    File : JPG, PNG, SVG Max 5 MB
                                </span>
                            </>
                        )}

                        <input
                            type="file"
                            accept="image/*"
                            name="LogoLocation"
                            value={undefined}
                            onChange={(e) => handleImageChange(e)}
                            style={{ display: "none" }}
                        />
                    </label>
                </div>

                {/* Type of Reward */}
                <div onClick={() => setShowBackView(false)} className="mb-4">
                    <label className="flex-1">
                        <p className="text-zinc-900 text-sm font-medium  font-poppins mb-2">
                            Type of Reward
                            <sup className="text-red-600">*</sup>
                        </p>

                        <Dropdown
                            placeholder={"Select"}
                            error={errors.RewardType}
                            name="RewardType"
                            options={["Lab Test", "Merchandise", "Treatment"]}
                            onSelect={handleSelect}
                            value={formData.RewardType}
                        />
                    </label>
                </div>

                {/* Reward Title */}
                <div onClick={() => setShowBackView(false)} className="mb-4">
                    <label>
                        <div className="flex items-center justify-between">
                            <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                                Reward Title
                                <sup className="text-red-600">*</sup>
                            </p>
                            <p className="text-right text-neutral-600 text-xs font-normal font-poppins">
                                {formData?.ProductName.length}/20
                            </p>
                        </div>
                        <input
                            type="text"
                            name="ProductName"
                            value={formData.ProductName}
                            onChange={handleOnChange}
                            maxLength={20}
                            style={{ wordBreak: "break-word", overflowWrap: "break-word" }}
                            placeholder="Enter Product name"
                            className={`w-full ${errors.ProductName ? "border-red-500" : ""
                                }  text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent break-words`}
                        />
                    </label>
                </div>

                {/* Reward Description */}
                <div onClick={() => setShowBackView(true)} className="mb-4">
                    <label>
                        <div className="flex items-center justify-between">
                            <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                                Reward Description
                                <sup className="text-red-600">*</sup>
                            </p>
                            <p className="text-right text-neutral-600 text-xs font-normal font-poppins">
                                {formData?.Description.length}/100
                            </p>
                        </div>
                        <textarea
                            type="text"
                            name="Description"
                            value={formData.Description}
                            onChange={handleOnChange}
                            maxLength={100}
                            placeholder="Provide some description about the product"
                            className={`w-full h-40 ${errors.Description ? "border-red-500" : ""
                                }  text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                        />
                    </label>
                </div>

                {/* Set Price */}
                <div onClick={() => setShowBackView(false)} className="mb-4">
                    <label>
                        <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                            Set Price
                            <sup className="text-red-600">*</sup>
                        </p>
                        <div className="flex border rounded-md">
                            {/* Currency Dropdown */}
                            <div className="w-20 bg-[#E5F2FF] flex items-center py-3 px-2">
                                <CurrencySelect
                                    value={formData.Currency}
                                    onChange={handleCurrencyChange}
                                    classNamePrefix="currency-select"
                                    className="w-full outline-none border-none bg-[#E5F2FF] text-[#3CA5DC] text-sm font-poppins font-normal appearance-none"
                                />
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className={`w-16 h-6 transform rotate-0 transition-transform duration-200`}
                                >
                                    <path
                                        d="M18 9.00005C18 9.00005 13.5811 15 12 15C10.4188 15 6 9 6 9"
                                        stroke="black"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </div>
                            {/* Price Input Field */}
                            <div className="w-full">
                                <input
                                    type="number"
                                    name="CostPrice"
                                    maxLength={6}
                                    onKeyDown={(e) => {
                                        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                                            e.preventDefault();
                                        }
                                    }}
                                    onWheel={(e) => {
                                        e.preventDefault();
                                        e.target.blur();
                                    }}
                                    value={formData.CostPrice}
                                    onChange={handleOnChange}
                                    placeholder="Enter Price"
                                    className={`w-full ${errors.CostPrice ? "border-red-500" : ""} text-black text-xs font-normal font-poppins bg-gray-100 p-4 border-l focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                                />
                            </div>
                        </div>
                    </label>
                </div>

                {/* Type of Discount */}
                <div className="mb-2">
                    <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                        Type of Discount
                        <sup className="text-red-600">*</sup>
                    </p>
                    <div className="flex flex-row self-start justify-start items-center gap-4">
                        {discountOptions.map((option) => (
                            <RadioButtonReward
                                key={option.value}
                                name="DiscountType"
                                id={option.value}
                                value={option.value}
                                textFont={"font-normal"}
                                text={option.name}
                                onChange={() => handleDiscountChange(option.value)}
                                checked={formData.DiscountType === option.value}
                            />
                        ))}
                    </div>
                </div>

                {/* Discount Value Input (conditionally rendered) */}
                {(formData.DiscountType === "Percentage" || formData.DiscountType === "Price reduction") && (
                    <div onClick={() => setShowBackView(false)} className="mb-2">
                        <label>
                            <input
                                type="number"
                                name="Discount"
                                maxLength={6}
                                onKeyDown={(e) => {
                                    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                                        e.preventDefault();
                                    }
                                }}
                                onWheel={(e) => {
                                    e.preventDefault();
                                    e.target.blur();
                                }}
                                value={formData.Discount}
                                onChange={handleOnChange}
                                placeholder={formData.DiscountType === "Percentage" ? "Input %age here" : "Input Price Reduction here"}
                                className={`w-full ${errors.Discount ? "border-red-500" : ""
                                    } text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                            />
                        </label>
                    </div>
                )}

                {/* Offer Price */}
                <div className="mt-2 mb-4">
                    <p className="text-zinc-900 text-xs font-semibold font-poppins mb-2">
                        Offer Price (Auto Calculated): <span className="text-[#545454] text-sm font-normal font-poppins">{formData?.OfferPrice}</span>
                    </p>
                </div>

                {/* Type of Offer */}
                <div onClick={() => setShowBackView(true)} className="mb-4">
                    <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                        Type of Offer
                        <sup className="text-red-600">*</sup>
                    </p>
                    <div className="flex flex-row self-start justify-start items-center gap-4">
                        {offerOptions.map((option) => (
                            <RadioButtonReward
                                key={option.value}
                                name="OfferType"
                                id={option.value}
                                value={option.value}
                                textFont={"font-normal"}
                                text={option.text}
                                onChange={handleOptionChange}
                                checked={formData.OfferType === option.value}
                            />
                        ))}
                    </div>
                </div>

                {/* Address */}
                {formData.OfferType === 'Offline' && (
                    <div className="mb-4">
                        <div className="flex items-center justify-between mb-2">
                            <p className="text-zinc-900 text-sm font-medium font-poppins">
                                Address
                                <sup className="text-red-600">*</sup>
                            </p>
                            <button onClick={() => { navigate("/branchManagement") }} className="text-right text-[#007AAE] text-sm font-semibold font-poppins">
                                Maintain Branches
                            </button>
                        </div>
                        <div className="flex flex-wrap flex-row self-start justify-start items-stretch gap-4">
                            {addressOptions.map((option) => (
                                <RadioButtonReward2
                                    key={option.value}
                                    name="Address"
                                    id={option.value}
                                    value={option.value}
                                    textFont={"font-normal"}
                                    text={option.text}
                                    text1={option.text1}
                                    onChange={handleOptionChange}
                                    checked={formData.Address === option.value}
                                />
                            ))}
                        </div>
                    </div>
                )}
            </div>
            <div className="flex flex-row items-center justify-center mt-6 space-x-4">
                <button
                    onClick={(e) => { setIsDraft(true); submitHandler(e, 1) }}
                    className="flex-1 px-5 py-3 flex items-center justify-center bg-white rounded-lg border border-sky-500 hover:scale-95"
                >
                    <div className="text-[#007AAE] text-base font-semibold font-poppins leading-normal">
                        Save Draft
                    </div>
                </button>
                <button
                    onClick={(e) => { setIsDraft(false); submitHandler(e, 0) }}
                    className="flex-1 px-5 py-3 flex items-center justify-center bg-sky-500 rounded-lg border border-sky-500 hover:scale-95"
                >
                    <div className="text-white text-base font-semibold font-poppins leading-normal">
                        Publish
                    </div>
                </button>
            </div>
        </div>
    );
};

const PhoneMockup = ({ children, showBackView, setShowBackView }) => {
    return (
        <div
            onClick={() => setShowBackView && setShowBackView(!showBackView)}
            className="relative mx-auto w-[300px] h-[600px]"
        >
            <img
                src={iphoneFrame}
                alt="iPhone Frame"
                className="w-full h-full object-contain"
            />
            <div className="absolute inset-y-[1.6%] left-[5%] right-[5.8%] overflow-hidden rounded-[40px]">{children}</div>
        </div>
    );
};

const PreviewSection = ({
    showBackView,
    setShowBackView,
    formData,
    image,
    updating,
    setUpdating,
    deleteItem,
    setDeleteItem,
    showModal,
    setShowModal,
    rewardID,
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { token } = useSelector((state) => state.auth);

    const deleteHandler = async (event, id) => {
        event.preventDefault();
        // Call API to Delete Reward
        try {
            await dispatch(deleteRewardOffer(token, id, navigate));
        } catch (error) {
            console.error("Error Deleting Reward:", error);
        }
        finally {
            setDeleteItem(null);
            setShowModal(() => false);
        }
    };

    const showDeleteModal = (item) => {
        setDeleteItem(item);
        setShowModal(() => true);
    };

    return (
        <div className="w-1/2">
            <div className="bg-white rounded-lg shadow-md p-6">
                <div className="text-center text-zinc-900 text-xl font-semibold font-poppins mb-7">
                    Preview for Patient
                </div>
                <div className="relative flex items-center justify-center h-[650px]">
                    <div>
                        <div
                            className={`absolute top-1 left-1/3 transition-all duration-300`}
                        >
                            <PhoneMockup
                                showBackView={showBackView}
                                setShowBackView={setShowBackView}
                            >
                                {showBackView ? (
                                    <FrontView
                                        formData={formData}
                                        image={image}
                                        showBackView={showBackView}
                                        setShowBackView={setShowBackView}
                                    />
                                ) : (
                                    <BackView
                                        formData={formData}
                                        image={image}
                                        showBackView={showBackView}
                                        setShowBackView={setShowBackView}
                                    />
                                )}
                            </PhoneMockup>
                        </div>
                        <div
                            className={`absolute top-12 left-1/4 transition-all duration-300`}
                        >
                            <PhoneMockup>
                                {showBackView ? (
                                    <BackView
                                        formData={formData}
                                        image={image}
                                        showBackView={showBackView}
                                        setShowBackView={setShowBackView}
                                    />
                                ) : (
                                    <FrontView
                                        formData={formData}
                                        image={image}
                                        showBackView={showBackView}
                                        setShowBackView={setShowBackView}
                                    />
                                )}
                            </PhoneMockup>
                        </div>
                    </div>
                </div>
                <div className="flex items-center justify-center space-x-10 mt-5">
                    <div
                        onClick={() => setShowBackView(false)}
                        className={`w-10 h-10 ${!showBackView
                            ? "bg-white border border-neutral-300"
                            : "bg-sky-100 border border-sky-400"
                            } rounded-full flex items-center justify-center`}
                    >
                        <IoChevronBack
                            className={`${!showBackView
                                ? "text-black cursor-not-allowed"
                                : "text-sky-500 cursor-pointer"
                                } `}
                        />
                    </div>
                    <div
                        onClick={() => setShowBackView(true)}
                        className={`w-10 h-10 ${showBackView
                            ? "bg-white border border-neutral-300"
                            : "bg-sky-100 border border-sky-400"
                            } rounded-full flex items-center justify-center`}
                    >
                        <IoChevronForward
                            className={`${showBackView
                                ? "text-black cursor-not-allowed"
                                : "text-sky-500 cursor-pointer"
                                } `}
                        />
                    </div>
                </div>
                <div className="w-full bg-gray-100 rounded-lg mt-7 p-2.5 flex items-center justify-center">
                    <div className="w-96 text-center text-black text-xl font-normal font-poppins">
                        This is how your offer will look to Patients on the App
                    </div>
                </div>
            </div>

            {/* Delete Reward MODAL */}
            <ModalComponent
                show={showModal}
                handleClose={() => { setDeleteItem(null); setShowModal(false); }}
                outSideModalPress={false}
            >
                <div className="w-[431px]">
                    <div className="flex flex-col items-center justify-between">
                        <img
                            src={deleteModalIcon}
                            alt="Delete Icon"
                            className="w-14 h-14 object-cover"
                        />
                        <div className="text-black text-[16px] font-semibold font-poppins text-center mt-6">
                            Delete Reward '{deleteItem?.ProductName}' ?
                        </div>
                        <div className="text-[#8A8A8A] text-sm font-normal font-poppins text-center mt-4">
                            Once deleted, this offer will be permanently deleted from the patient's app as well.
                        </div>
                        <div className="flex flex-row items-center justify-center mt-6 space-x-4 w-full">
                            <button
                                onClick={() => { setDeleteItem(null); setShowModal(false); }}
                                className="flex-1 w-32 px-2 py-3 flex items-center justify-center bg-white rounded-lg border border-sky-500 active:scale-95"
                            >
                                <div className="text-[#007AAE] text-sm font-semibold font-poppins leading-normal">
                                    Keep Reward
                                </div>
                            </button>
                            <button
                                onClick={(e) => { deleteHandler(e, rewardID) }}
                                className="flex-1 w-32 px-2 py-3 flex items-center justify-center bg-sky-500 rounded-lg border border-sky-500 active:scale-95"
                            >
                                <div className="text-white text-sm font-semibold font-poppins leading-normal">
                                    Delete Reward
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </ModalComponent>

            {updating === true && (
                <button onClick={() => showDeleteModal(formData)} className="flex self-end flex-row justify-end items-center w-full mt-6 gap-3">
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6 text-[#D92D20]"
                    >
                        <path
                            d="M19.3008 5.5L18.6811 15.5251C18.5227 18.0864 18.4436 19.3671 17.8016 20.2879C17.4841 20.7431 17.0755 21.1273 16.6015 21.416C15.6429 22 14.3598 22 11.7935 22C9.2239 22 7.93908 22 6.97983 21.4149C6.50558 21.1257 6.09678 20.7408 5.77946 20.2848C5.13766 19.3626 5.06023 18.0801 4.90539 15.5152L4.30078 5.5"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                        />
                        <path
                            d="M2.80078 5.5H20.8008M15.8565 5.5L15.1739 4.09173C14.7204 3.15626 14.4936 2.68852 14.1025 2.39681C14.0158 2.3321 13.9239 2.27454 13.8278 2.2247C13.3947 2 12.8749 2 11.8353 2C10.7696 2 10.2368 2 9.79646 2.23412C9.69888 2.28601 9.60576 2.3459 9.51807 2.41317C9.12242 2.7167 8.90141 3.20155 8.45939 4.17126L7.8537 5.5"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                        />
                        <path
                            d="M9.30078 16.5V10.5"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                        />
                        <path
                            d="M14.3008 16.5V10.5"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                        />
                    </svg>
                    <h1 className="text-[#D92D20] text-sm font-semibold font-poppins">
                        Delete Reward
                    </h1>
                </button>
            )}
        </div>
    );
};

const FrontView = ({ formData, image, showBackView, setShowBackView }) => (
    <div className="h-full w-full flex flex-col justify-between relative bg-cover"
        style={{ backgroundImage: `url(${rewardFrontBg})` }}>
        {/* <div className="absolute inset-0 bg-black opacity-50"></div> */}
        <img
            src={iphoneIsland}
            alt="iphone Island"
            className="w-16 h-6 object-contain absolute top-4 left-[6.5rem]"
        />
        <div className="flex flex-col justify-center items-start bg-white rounded-[10px] absolute bottom-[70px] right-[6px] w-[124px] p-1">
            <div className="flex flex-row justify-between items-center w-full">
                <img
                    src={image ? image : Tile1}
                    alt="Reward Image"
                    className="w-10 h-10 object-cover rounded-full"
                />
                <div className="flex flex-row justify-center items-center bg-[#E5F2FF] rounded-full w-6 h-6">
                    <IoIosInformationCircleOutline className="text-[#3CA5DC] text-lg" />
                </div>
            </div>

            {/* Product Name */}
            <h1 className="text-[#000000] text-[10px] font-semibold font-poppins mt-2">
                {formData?.ProductName || "Product Name"}
            </h1>

            {/* Using AH coin */}
            <div className="flex flex-row justify-start items-center w-full">
                <img
                    src={coin}
                    alt="Coin Icon"
                    className="w-3 h-3 object-contain rounded-full"
                />
                <h1 className="text-[#ACACAC] text-[10px] font-normal font-poppins mx-1">
                    Using
                </h1>
                <h1 className="text-[#3CA5DC] text-[10px] font-normal font-poppins">
                    10 AH
                </h1>
            </div>

            {/* Reward Type */}
            <div className={`flex flex-row justify-center items-center mt-2 px-1 py-[2px] ${formData?.RewardType === 'Merchandise' ? "bg-[#FDECD3]" : formData?.RewardType === 'Lab Test' ? "bg-[#E5F2FF]" : "bg-[#ECFFD0]"} rounded-[10px] gap-1`}>
                <img
                    src={formData?.RewardType === 'Merchandise' ? merchandisePreviewIcon : formData?.RewardType === 'Lab Test' ? labTestPreviewIcon : treatmentPreviewIcon}
                    alt={formData?.RewardType === 'Merchandise' ? merchandisePreviewIcon : formData?.RewardType === 'Lab Test' ? labTestPreviewIcon : treatmentPreviewIcon}
                    className="w-3 h-3 object-contain"
                />
                <h1 className={`${formData?.RewardType === 'Merchandise' ? "text-[#F7A025]" : formData?.RewardType === 'Lab Test' ? "text-[#3CA5DC]" : "text-[#86D511]"} text-[8px] font-normal font-poppins`}>
                    {formData?.RewardType === 'Merchandise' ? "Merchandise" : formData?.RewardType === 'Lab Test' ? "Lab Test" : "Treatment"}
                </h1>
            </div>

            {/* Offer Type */}
            <h1 className="text-[#ACACAC] text-[8px] font-normal font-poppins mt-2">
                {formData?.OfferType === 'Online' ? "Online" : "2 KMs away"}
            </h1>

            {/* Price */}
            <div className="flex flex-row justify-between items-center w-full mt-2">
                <div className="flex flex-row justify-start items-center w-full gap-1">
                    <h1 className="text-[#1C1C1C] text-[9px] font-semibold font-poppins">
                        {formData?.OfferPrice && formData?.Currency} {formData?.OfferPrice}
                    </h1>
                    <h1 className="text-[#ACACAC] text-[7px] font-semibold font-poppins line-through">
                        {formData?.CostPrice && formData?.Currency} {formData?.CostPrice || "-"}
                    </h1>
                </div>
                <div className="flex flex-row justify-center items-center gap-1">
                    <img
                        src={starIcon}
                        alt="Star Icon"
                        className="w-3 h-3 object-contain mb-1"
                    />
                    <h1 className="text-[#F7A025] text-[8px] font-normal font-poppins">
                        5.0
                    </h1>
                </div>
            </div>

            {/* Discount */}
            <div className={`w-full flex flex-row justify-center items-center mt-1 p-1 ${formData?.DiscountType === "Price reduction" || formData?.DiscountType === "Percentage" ? "bg-[#FDECD3]" : "bg-[#E5F2FF]"} rounded-[10px] gap-1`}>
                {formData?.DiscountType === "Price reduction" || formData?.DiscountType === "Percentage" ? (
                    <h1 className={`text-[#F7A025] text-sm font-bold font-poppins`}>{formData?.DiscountType === "Price reduction" && formData?.Currency} {formData?.Discount} {formData?.DiscountType === "Percentage" && "%"} off</h1>
                ) : (
                    <h1 className={`text-[#3CA5DC] text-sm font-bold font-poppins`}>Free</h1>
                )}
            </div>
        </div>
    </div>
);

const BackView = ({ formData, image, showBackView, setShowBackView }) => (
    <div className="h-full w-full flex flex-col justify-between relative bg-cover">
        {/* Card Content */}
        <div className="absolute top-10 flex flex-col justify-center items-start w-full rounded-xl bg-white py-3 px-5">
            {/*  */}
            {image ? (
                <img
                    src={image ? image : Tile1}
                    alt="Reward Image"
                    className="w-full h-36 object-cover rounded-lg"
                />
            ) : (
                <div className="w-full h-36 rounded-lg bg-[#888888]"></div>
            )}

            <div className="flex flex-row justify-between items-center w-full gap-2 mt-4">
                <h1 className="text-[#1C1C1C] text-sm font-semibold font-poppins">
                    {formData?.ProductName || "Product Name"}
                </h1>
                <h1 className="text-[#000000] text-[10px] font-normal font-poppins">
                    Partner Rating
                </h1>
            </div>
            <div className="flex flex-row justify-between items-center w-full gap-2 mt-1 relative">
                {formData?.CostPrice ? (
                    <h1 className="text-[#1C1C1C] text-xs font-semibold font-poppins">
                        {formData?.OfferPrice && formData?.Currency} {formData?.OfferPrice} <span className="text-[#ACACAC] text-[10px] font-semibold font-poppins line-through">{formData?.CostPrice && formData?.Currency} {formData?.CostPrice}</span>
                    </h1>
                ) : (
                    <h1 className="text-[#1C1C1C] text-xs font-semibold font-poppins">
                        -
                    </h1>
                )}
                <div className="flex flex-row justify-center items-center gap-1">
                    <IoIosArrowDown className="text-xs text-[#3CA5DC]" />
                    <div className="flex flex-row space-x-[2px]">
                        {Array(5).fill().map((_, index) => (
                            <img
                                key={index}
                                src={starIcon}
                                alt="Star Icon"
                                className="w-3 h-3 object-contain mb-1"
                            />
                        ))}
                    </div>
                </div>
                <h1 className="absolute -bottom-2 right-0 text-[#F7A025] text-[8px] font-normal font-poppins">
                    5.0
                </h1>
            </div>

            {/* Discount */}
            <div className={`w-24 self-start flex flex-row justify-center items-center mt-3 p-1 ${formData?.DiscountType === "Price reduction" || formData?.DiscountType === "Percentage" ? "bg-[#FDECD3]" : "bg-[#E5F2FF]"} rounded-[10px] gap-1`}>
                {formData?.DiscountType === "Price reduction" || formData?.DiscountType === "Percentage" ? (
                    <h1 className={`text-[#F7A025] text-sm font-bold font-poppins`}>{formData?.DiscountType === "Price reduction" && formData?.Currency} {formData?.Discount} {formData?.DiscountType === "Percentage" && "%"} off</h1>
                ) : (
                    <h1 className={`text-[#3CA5DC] text-sm font-bold font-poppins`}>Free</h1>
                )}
            </div>

            <h1 className="text-[#000000] text-[10px] font-normal font-poppins mt-4">
                Partner Name
            </h1>

            <h1 className="text-[#000000] text-[10px] font-semibold font-poppins mt-1">
                Accurate Health Labs
            </h1>

            <h1 className="text-[#1C1C1C] text-[8px] font-normal font-poppins mt-2">
                Location:
            </h1>

            {formData?.OfferType === 'Online' ? (
                <div className="flex flex-row justify-center items-center bg-[#E5F2FF] rounded-md p-1 mt-1">
                    <h1 className="text-[#3CA5DC] text-[9px] font-normal font-poppins">
                        Online
                    </h1>
                </div>
            ) : (
                <>
                    <div className="flex flex-row justify-start items-center w-full mt-1 gap-2">
                        <img
                            src={locationIcon}
                            alt="Location Icon"
                            className="w-8 h-8 object-contain"
                        />
                        <div className="flex flex-col justify-center items-start">
                            <h1 className="text-[#000000] text-[10px] font-semibold font-poppins">
                                {formData?.AddressText || "-"}
                            </h1>
                            <h1 className="text-[#888888] text-[7px] font-normal font-poppins mt-1">
                                - KMs Away
                            </h1>
                        </div>
                        <div className="flex flex-col justify-center items-center ml-auto">
                            <img
                                src={getDirectionIcon}
                                alt="Get Direction Icon"
                                className="w-5 h-5 object-contain"
                            />
                            <h1 className="text-[#979797] text-[7px] font-normal font-poppins">
                                Get directions
                            </h1>
                        </div>
                    </div>
                </>
            )}

            <div className="h-[6px] w-full bg-[#F3F5F7] my-2"></div>

            <h1 className="text-[#000000] text-xs font-semibold font-poppins">
                Description
            </h1>
            <h1 className="text-[#545454] text-[9px] font-normal font-poppins mt-2 break-words w-full">
                {formData?.Description || "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Facilis quidem porro blanditiis quasi accusamus itaque. Ab necessitatibus"}
            </h1>

            <div className="absolute -bottom-16 flex flex-row justify-start items-center w-full gap-1">
                <div className="w-[42%] flex flex-col justify-center items-center bg-[#D1D1D1] rounded-lg p-2">
                    <h1 className="text-[#F8F9FA] text-[9px] font-semibold font-poppins">
                        Back
                    </h1>
                </div>
                <div className="w-[42%] flex flex-row justify-center items-center bg-[#3CA5DC] rounded-lg p-2 px-1">
                    <img
                        src={basketShopIcon}
                        alt="Basket Shop Icon"
                        className="w-3 h-3 object-contain"
                    />
                    <h1 className="text-[#F8F9FA] text-[9px] font-semibold font-poppins">
                        (1) Add to Basket
                    </h1>
                </div>
            </div>
        </div >
    </div >
);

export default CreateReward;
