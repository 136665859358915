import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FaPlus, FaMinus, FaChevronLeft, FaHeartPulse } from "react-icons/fa6";
import Vertical_switch_light from "../../assets/Images/TreatmentProgram/Vertical_switch_light.png";
import Tile1 from "../../assets/Images/OurServices/Tile1.png";
import Tile2 from "../../assets/Images/OurServices/Tile2.png";
import Tile3 from "../../assets/Images/OurServices/Tile3.png";
import Tile4 from "../../assets/Images/OurServices/Tile4.png";
import noTreatmentPrgBg from "../../assets/Images/OurServices/noTreatmentPrgBg.png";
import editDropdownIcon from "../../assets/Images/OurServices/editDropdownIcon.png";
import cameraIcon from "../../assets/Images/OurServices/cameraIcon.png";
import deleteModalIcon from "../../assets/Images/OurServices/deleteModalIcon.png";
import editIcon from "../../assets/Images/OurServices/editIcon.png";
import arrowRightIcon from "../../assets/Images/OurServices/arrowRightIcon.png";
import binIcon from "../../assets/Images/OurServices/binIcon.png";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import AHlogo from "../../assets/Images/logo.png";
import Breadcrumb from "../../components/Breadcrumb";
import qrCodeImg from "../../assets/Images/Invoicing/qrCodeImg.png";
import { IoIosSearch } from "react-icons/io";
import { HiOutlineDotsVertical } from "react-icons/hi";
import ModalComponent from "../../components/Modal";
import { deleteTreatmentCategory, updateTreatmentCategory, uploadOurServicesImage } from "../../services/operations/ourServicesAPI";
import imageCompression from "browser-image-compression";
import toast from "react-hot-toast";
import Tooltip from "../../components/Tooltip";
import MultiSelectDropdownForOurServices from "../../components/MultiSelectDropdownForOurServices";

function OurServices() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { token } = useSelector((state) => state.auth);
    const { treatmentCategory, loading } = useSelector((state) => state.ourServices);
    const { allDoctors } = useSelector((state) => state.masterData);
    const [searchTerm, setSearchTerm] = useState("");
    const [dropdownVisible, setDropdownVisible] = useState(null);
    const [editingId, setEditingId] = useState(null);
    const [editedTitle, setEditedTitle] = useState("");
    const [initialTitle, setInitialTitle] = useState("");
    const [image, setImage] = useState(null);
    const [deleteItem, setDeleteItem] = useState(null);
    const dropdownRef = useRef(null);
    const [doctorsList, setDoctorsList] = useState([]);
    const [itemSelected, setItemSelected] = useState(null);
    const [fileInput, setFileInput] = useState(null);
    const [showDeleteCategModal, setShowDeleteCategModal] = useState(false);
    const [showAssignDrModal, setShowAssignDrModal] = useState(false);
    const [formData, setFormData] = useState({
        LogoLocation: "",
        Doctors: "",
    });
    const [errors, setErrors] = useState({
        LogoLocation: "",
        Doctors: "",
    });

    const handleSelect = (name, selectedOption) => {
        setFormData({
            ...formData,
            [name]: selectedOption,
        });
    };

    useEffect(() => {
        const doctorOptions = allDoctors.map((doctor) => ({
            id: doctor.DID,
            name: doctor.Name,
            speciality: doctor.RelatedSpecialities
                ? doctor.RelatedSpecialities.map(speciality => speciality.Speciality).join(', ')
                : 'N/A',
            image: doctor.Image
        }));
        setDoctorsList(doctorOptions);
    }, [allDoctors]);

    useEffect(() => {
        if (itemSelected?.DoctorsData) {
            // Extract Doctor IDs (DID) and set it in formData.Doctors
            const selectedDoctorIds = itemSelected.DoctorsData.map((doctor) => doctor.DID);
            setFormData((prevData) => ({
                ...prevData,
                Doctors: selectedDoctorIds, // Set selected doctor IDs
            }));
        }
    }, [itemSelected]);

    const DoctorAvatars = ({ doctors }) => {
        const navigate = useNavigate();
        const maxDisplay = 4; // Max number of Doctors to display
        const displayDoctors = doctors?.slice(0, maxDisplay);
        const remainingCount = doctors?.length - maxDisplay;

        return (
            <div className="flex items-center cursor-pointer">
                {/* Display up to 'maxDisplay' doctors */}
                {displayDoctors?.map((client, index) => (
                    <Tooltip key={client.DID} message={client.Name} position="bottom">
                        <div
                            onClick={() => { }}
                            className={`rounded-full p-0.5 ${index !== 0 ? "-ml-2" : ""
                                }`}
                        >
                            <img
                                src={client.Image}
                                alt={client.Name}
                                className="w-8 h-8 rounded-full"
                            />
                        </div>
                    </Tooltip>
                ))}

                {/* Display a bubble for remaining doctors */}
                {remainingCount > 0 && (
                    <Tooltip
                        message={doctors.slice(maxDisplay).map((client) => client.Name).join(", ")}
                        position="rightCenter"
                    >
                        <div className="w-8 h-8 rounded-full bg-sky-50 flex items-center justify-center -ml-2 ring-2 ring-gray-200 ring-offset-1 text-sky-400 text-xs font-medium font-poppins leading-none">
                            +{remainingCount}
                        </div>
                    </Tooltip>
                )}
            </div>
        );
    };

    // Hide dropdown if clicked outside
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownVisible(null);
            }
        };
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    const handleImageChange = async (e, id) => {
        const file = e.target.files[0];
        if (!file) return;
        const options = {
            maxSizeMB: 0.5,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
        };
        const toastId = toast.loading("Compressing Image...");
        const compressedFile = await imageCompression(file, options);
        toast.dismiss(toastId);
        setImage(compressedFile);
        uploadImage(compressedFile, id);
    };

    const uploadImage = async (file, id) => {
        const formData = new FormData();
        formData.append("ServiceImage", file);
        try {
            const res = await uploadOurServicesImage(formData, token);
            setFormData((prev) => ({
                ...prev,
                LogoLocation: res?.ServiceImageUrl,
            }));
            setImage(URL.createObjectURL(file));
            handleUpdate(res?.ServiceImageUrl, id);
        } catch (error) {
            console.error("Upload error:", error);
        }
    };

    // Function to handle form submission or dispatch update
    const handleUpdate = async (logoLocation, id) => {
        if (logoLocation || formData.LogoLocation) {
            const body = {
                "Image": logoLocation || formData.LogoLocation
            }
            await dispatch(updateTreatmentCategory(token, id, body, navigate));
        } else {
            console.log("No image uploaded");
        }
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    const filteredData = treatmentCategory.filter((item) =>
        item?.Name?.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleDotsClick = (id) => {
        setDropdownVisible(dropdownVisible === id ? null : id);
    };

    const handleRenameClick = (id, currentTitle) => {
        setEditingId(id);
        setEditedTitle(currentTitle);
        setInitialTitle(currentTitle); // Save initial title
        setDropdownVisible(null); // Close dropdown
    };

    const handleInputChange = (event) => {
        setEditedTitle(event.target.value);
    };

    const handleBlur = async (id) => {
        if (editedTitle.trim() !== "" && editedTitle !== initialTitle) {
            try {
                const body = {
                    Name: editedTitle,
                };
                await dispatch(updateTreatmentCategory(token, id, body, navigate));
            } catch (error) {
                console.error("Error updating treatment category:", error);
            }
        }
        setEditingId(null);
    };

    const handleDeleteCategoryClick = (item) => {
        if (!showDeleteCategModal) {
            setDeleteItem(item);  // Set the item to be deleted
            setShowDeleteCategModal(true);  // Open the delete modal
        }
    };

    const handleAssignDoctorsClick = (item) => {
        if (!showAssignDrModal) {
            setItemSelected(item);  // Set the selected item for the modal
            setShowAssignDrModal(true);  // Open the modal
        }
    };

    const handleSaveChanges = async (id) => {
        // Get the current selected doctor IDs from itemSelected
        const previousDoctorIds = itemSelected?.DoctorsData.map((doctor) => doctor.DID);

        // Compare the previous doctor IDs with the formData.Doctors
        const hasChanges = (
            previousDoctorIds.length !== formData.Doctors.length ||
            previousDoctorIds.some((doctorId) => !formData.Doctors.includes(doctorId))
        );

        // Proceed with API call only if there are changes (doctors added/removed)
        if (hasChanges) {
            try {
                const body = {
                    AssignDoctor: formData.Doctors,  // Assign the selected doctors
                };
                await dispatch(updateTreatmentCategory(token, id, body, navigate));
            } catch (error) {
                console.error("Error assigning doctors to treatment category:", error);
            }
        }

        setShowAssignDrModal(false);
        setItemSelected(null);
    };

    const deleteHandler = async (event, id) => {
        event.preventDefault();
        // Call API to Delete Treatment Category
        try {
            await dispatch(deleteTreatmentCategory(token, id, navigate));
        } catch (error) {
            console.error("Error Deleting Treatment Category:", error);
        }
        finally {
            setDeleteItem(null);
            setShowDeleteCategModal(false);
        }
    };

    const RenderTreatmentBlocks = () => {
        return (
            <div className="grid grid-cols-3 w-full justify-start items-stretch gap-6">
                {filteredData?.map((item, index) => (
                    <div
                        key={item?.OSTCID}
                        className={`w-full relative`}
                    >
                        {/* Upload Img Button */}
                        <label
                            className="flex flex-row justify-center items-center absolute top-4 right-4 active:scale-90 transition-transform cursor-pointer"
                        >
                            <img
                                src={cameraIcon}
                                alt="camera Icon"
                                className="w-12 h-12 object-cover"
                            />
                            {/* File Input */}
                            <input
                                type="file"
                                accept="image/*"
                                name="LogoLocation"
                                value={undefined}
                                onChange={(e) => { handleImageChange(e, item?.OSTCID) }}
                                style={{ display: "none" }}
                            />
                        </label>

                        <div className="h-full flex flex-col justify-between">
                            <button onClick={() => { navigate("serviceDetail", { state: { item } }) }} className="overflow-hidden flex-grow">
                                <img
                                    src={item?.Image}
                                    alt={item?.Name}
                                    className="w-full h-60 rounded-[20px] object-cover"
                                />
                            </button>
                            <div className="mt-4">
                                <div className="flex flex-row justify-between items-center w-full relative">
                                    {editingId === item?.OSTCID ? (
                                        <input
                                            type="text"
                                            value={editedTitle}
                                            onChange={handleInputChange}
                                            onBlur={() => handleBlur(item?.OSTCID)}
                                            autoFocus
                                            className="text-xl font-semibold leading-9 font-poppins bg-gray-100 p-1 rounded border-none focus:outline-none"
                                        />
                                    ) : (
                                        <button onClick={() => { navigate("serviceDetail", { state: { item } }) }} className="flex flex-row justify-start items-center w-full">
                                            <h2 className="text-xl font-semibold leading-9 font-poppins">
                                                {item?.Name}
                                            </h2>
                                            <div className="w-6 h-6 flex flex-col justify-center items-center rounded-full p-1 bg-[#DADADA] ml-2">
                                                <h2 className="text-black text-[10px] font-bold font-poppins leading-9 text-center">
                                                    {item?.TreatmentServices?.length}
                                                </h2>
                                            </div>
                                        </button>
                                    )}
                                    <HiOutlineDotsVertical className="cursor-pointer" onClick={() => handleDotsClick(item?.OSTCID)} />
                                    {dropdownVisible === item?.OSTCID && (
                                        <div ref={dropdownRef} className="absolute top-8 right-0 bg-white shadow rounded-md w-40 z-10">
                                            <button onClick={() => handleRenameClick(item?.OSTCID, item?.Name)} className="flex items-center justify-between px-4 py-2 hover:bg-[#F0F8FF] w-full text-[#1C1C1C] text-sm font-normal hover:text-[#3CA5DC] hover:font-semibold">
                                                <span className="">Rename</span>
                                                <img src={editIcon} alt="Edit" className="w-6 h-6" />
                                            </button>
                                            <button onClick={() => handleDeleteCategoryClick(item)} className="flex items-center justify-between px-4 py-2 hover:bg-[#F0F8FF] w-full text-[#1C1C1C] text-sm font-normal hover:text-[#3CA5DC] hover:font-semibold">
                                                <span className="">Delete</span>
                                                <img src={binIcon} alt="Delete" className="w-6 h-6" />
                                            </button>
                                        </div>
                                    )}
                                </div>
                                {/* Doctor Assigned */}
                                <div className="flex flex-row justify-start items-center w-full relative gap-4 mt-2">
                                    <h2 className="text-[#545454] text-sm font-medium leading-9 font-poppins">
                                        Doctors Assigned ({item?.DoctorsData?.length}) :
                                    </h2>
                                    <DoctorAvatars doctors={item?.DoctorsData} />
                                    <button onClick={() => handleAssignDoctorsClick(item)}>
                                        <img src={editDropdownIcon} alt="Edit" className="w-6 h-6" />
                                    </button>
                                </div>
                                <button onClick={() => { navigate("serviceDetail", { state: { item } }) }} className="flex flex-row justify-center items-center mt-4 w-full rounded-lg border border-[#3CA5DC] bg-white py-2 px-4 gap-2">
                                    <h2 className="text-[#3CA5DC] text-sm font-semibold leading-5 font-poppins">
                                        See Details
                                    </h2>
                                    <img
                                        src={arrowRightIcon}
                                        alt="Right Arrow Icon"
                                        className="w-6 h-6 object-contain"
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className="bg-white min-h-full py-2 px-2 sm:py-5 sm:px-10 scroll-smooth flex flex-col">
            {/* HEADER */}
            <div className="flex flex-row justify-between items-center">
                {/* TITLE */}
                <div className="flex flex-row items-center">
                    <div className="flex flex-col justify-center items-start gap-1">
                        <h1 className="text-[#1C1C1C] text-2xl font-medium font-poppins leading-9">
                            Our Services
                        </h1>
                        <Breadcrumb />
                    </div>
                </div>
            </div>

            {/* Search and Add treatment Btn */}
            <div className="flex flex-row justify-between items-center w-full gap-4 mt-6">
                <label
                    className={`flex flex-row items-center justify-start bg-[#F2F3F4] rounded-[20px] w-full py-2 px-4 gap-4`}
                >
                    <IoIosSearch className="text-[#8A8A8A] w-5 h-5" />
                    <input
                        type="text"
                        placeholder="Search Treatments and Services"
                        onChange={handleSearch}
                        className={`bg-[#F2F3F4] text-sm w-full text-[#8A8A8A] font-normal font-poppins focus:outline-none focus:border-transparent`}
                    />
                </label>

                <button
                    onClick={() => { navigate("addTreatmentCategory") }}
                    className="flex flex-row justify-center items-center rounded-md bg-[#3CA5DC] px-3 py-2 cursor-pointer hover:scale-110 duration-500 w-72"
                >
                    <FaPlus className="text-white mr-2 text-sm" />
                    <h1 className="text-[#E5F2FF] text-sm font-semibold font-poppins">
                        Add Treatment Category
                    </h1>
                </button>
            </div>

            {loading ? (
                <div className="h-screen flex items-center justify-center">
                    <div className="spinner"></div>
                </div>
            ) : (
                <>
                    {/* Content */}
                    {filteredData?.length === 0 ? (
                        <>
                            <div className="flex flex-col justify-center items-center w-full">
                                <img
                                    src={noTreatmentPrgBg}
                                    alt="No Treatment Category Available"
                                    className="w-[46.875rem] h-[31.25rem] rounded-[20px] object-cover"
                                />
                                <h1 className="text-[#545454] text-2xl font-normal font-poppins mt-2 leading-9">
                                    No Treatment Category is added as of yet
                                </h1>
                            </div>
                        </>
                    ) : (
                        <>
                            <h1 className="text-[#000000] text-[18px] font-medium font-poppins mt-6 leading-9">
                                List of Treatment Category ({filteredData.length})
                            </h1>
                            <div className="flex flex-wrap flex-row justify-center items-center w-full mt-4">
                                <RenderTreatmentBlocks />
                            </div>

                            {/* Assign Doctors MODAL */}
                            {showAssignDrModal && itemSelected && (
                                <>
                                    <ModalComponent
                                        show={showAssignDrModal}
                                        handleClose={() => { setShowAssignDrModal(false); setItemSelected(null); }}
                                        outSideModalPress={false}
                                    >
                                        <div className="w-[562px]">
                                            <div className="flex flex-col items-start justify-between">
                                                <h1 className="text-[#1C1C1C] text-[18px] font-semibold font-poppins">
                                                    Assign Doctors to this Treatment category
                                                </h1>
                                                <h2 className="text-[#1C1C1C] text-sm font-normal font-poppins mt-6 mb-1">
                                                    Assign Doctors to this Category
                                                </h2>

                                                <div className="w-full">
                                                    <MultiSelectDropdownForOurServices
                                                        placeholder="Select Doctors"
                                                        error={errors.Doctors}
                                                        name="Doctors"
                                                        options={doctorsList}
                                                        onSelect={handleSelect}
                                                        value={formData.Doctors}
                                                        labelKey="name"
                                                        valueKey="id"
                                                    />
                                                </div>
                                                <div className="flex flex-row items-center justify-center mt-6 space-x-4 w-full">
                                                    <button
                                                        onClick={() => { setShowAssignDrModal(false); setItemSelected(null); }}
                                                        className="flex-1 w-32 px-2 py-3 flex items-center justify-center bg-white rounded-lg border border-sky-500 active:scale-95"
                                                    >
                                                        <div className="text-[#007AAE] text-sm font-semibold font-poppins leading-normal">
                                                            Cancel
                                                        </div>
                                                    </button>
                                                    <button
                                                        onClick={(e) => { handleSaveChanges(itemSelected?.OSTCID) }}
                                                        className="flex-1 w-32 px-2 py-3 flex items-center justify-center bg-sky-500 rounded-lg border border-sky-500 active:scale-95"
                                                    >
                                                        <div className="text-white text-sm font-semibold font-poppins leading-normal">
                                                            Save Changes
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </ModalComponent>
                                </>
                            )}

                            {/* Delete Treatment Category MODAL */}
                            {showDeleteCategModal && deleteItem && (
                                <>
                                    <ModalComponent
                                        show={showDeleteCategModal}
                                        handleClose={() => { setDeleteItem(null); setShowDeleteCategModal(false); }}
                                        outSideModalPress={false}
                                    >
                                        <div className="w-[431px]">
                                            <div className="flex flex-col items-center justify-between">
                                                <img
                                                    src={deleteModalIcon}
                                                    alt="Delete Icon"
                                                    className="w-14 h-14 object-cover"
                                                />
                                                <div className="text-black text-[16px] font-semibold font-poppins text-center mt-6">
                                                    Delete Treatment Category '{deleteItem?.Name}' ?
                                                </div>
                                                <div className="text-[#8A8A8A] text-sm font-normal font-poppins text-center mt-4">
                                                    Once, deleted, the services under this category will also be disabled.
                                                </div>
                                                <div className="flex flex-row items-center justify-center mt-6 space-x-4 w-full">
                                                    <button
                                                        onClick={() => { setDeleteItem(null); setShowDeleteCategModal(false); }}
                                                        className="flex-1 w-32 px-2 py-3 flex items-center justify-center bg-white rounded-lg border border-sky-500 active:scale-95"
                                                    >
                                                        <div className="text-[#007AAE] text-sm font-semibold font-poppins leading-normal">
                                                            Cancel
                                                        </div>
                                                    </button>
                                                    <button
                                                        onClick={(e) => { deleteHandler(e, deleteItem?.OSTCID) }}
                                                        className="flex-1 w-32 px-2 py-3 flex items-center justify-center bg-sky-500 rounded-lg border border-sky-500 active:scale-95"
                                                    >
                                                        <div className="text-white text-sm font-semibold font-poppins leading-normal">
                                                            Delete Treatment
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </ModalComponent>
                                </>
                            )}
                        </>
                    )
                    }
                </>
            )}
        </div >
    );
}

export default OurServices;
