import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  treatmentCategory: [],
};

const ourServicesSlice = createSlice({
  name: "ourServices",
  initialState: initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setTreatmentCategory(state, action) {
      state.treatmentCategory = action.payload;
    },
  },
});

export const {
  setLoading,
  setTreatmentCategory,
} = ourServicesSlice.actions;

export default ourServicesSlice.reducer;
