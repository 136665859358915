import { combineReducers } from "@reduxjs/toolkit";

import authReducer from "../slices/authSlice";
import profileReducer from "../slices/profileSlice";
import membershipReducer from "../slices/membershipSlice";
import offersReducer from "../slices/OffersSlice";
import productImagesReducer from "../slices/ProductImageSlice";
import patientReducer from "../slices/patientSlice";
import dashboardReducer from "../slices/dashboardSlice";
import masterDataReducer from "../slices/masterDataSlice";
import breadcrumbReducer from "../slices/breadcrumbSlice"; 
import sidebarReducer from "../slices/sidebarSlice"; 
import medicalConciergeReducer from "../slices/medicalConciergeSlice";
import ourServicesReducer from "../slices/ourServicesSlice";
import rewardReducer from "../slices/rewardSlice";

const rootReducer = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  patient: patientReducer,
  dashboard : dashboardReducer,
  membership: membershipReducer,
  offers: offersReducer,
  productImages: productImagesReducer,
  masterData: masterDataReducer,
  breadcrumbs: breadcrumbReducer,
  sidebar: sidebarReducer,
  medicalConcierge: medicalConciergeReducer,
  ourServices: ourServicesReducer,
  reward: rewardReducer,
});

export default rootReducer;
