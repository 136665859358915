import React, { useEffect, useState } from "react";
import Breadcrumb from "../components/Breadcrumb";
import RadioIcon from "../components/RadioIcon";
import toast from "react-hot-toast";
import iphoneFrame from "../assets/Images/MedicalConcierge/iphoneFrame.svg";
import uploadProfile from "../assets/Images/MedicalConcierge/healthManagerImageIcon.svg";
import planPreviewBackground from "../assets/Images/MedicalConcierge/planPreviewBackground.png";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import initialPayment from "../assets/Images/MedicalConcierge/initialPayment.svg";
import info from "../assets/Images/MedicalConcierge/info.svg";
import experience from "../assets/Images/MedicalConcierge/experience.svg";
import location from "../assets/Images/MedicalConcierge/location.svg";
import education from "../assets/Images/MedicalConcierge/education.svg";
import language from "../assets/Images/MedicalConcierge/language.svg";
import verifiedTick from "../assets/Images/MedicalConcierge/verifiedTick.svg";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Steps } from "antd";
import imageCompression from "browser-image-compression";
import { uploadOrganizationLogo } from "../services/operations/masterDataAPI";
import { useDispatch, useSelector } from "react-redux";
import { FaPlus } from "react-icons/fa6";
import Dropdown from "../components/Dropdown";
import { countries } from "../data/country";
import MultiSelectDropdown from "../components/MultiSelectDropdown";
import moment from "moment/moment";
import { useLocation, useNavigate } from "react-router-dom";
import {
  addHealthManager,
  updateHealthManager,
} from "../services/operations/medicalConciergeAPI";
import { languageList } from "../data/language";

const Form = ({
  formData,
  setFormData,
  errors,
  setErrors,
  current,
  setCurrent,
  isViewing,
  image,
  setImage,
  healthManagerSpeciality,
  healthManager,
}) => {
  const { user } = useSelector((state) => state.profile);
  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validateStepFields = () => {
    let isValid = true;
    const newErrors = {};

    // Define required fields for each step
    const requiredFields = {
      0: [
        "ImageLocation",
        "FullName",
        "Experience",
        "Languages",
        "EducationDegree",
        "Speciality",
      ],
      1: ["PhoneNumber", "Email", "Address", "State", "Country"],
      2: ["StartTime", "EndTime"],
      3: ["Bio"],
    };

    if (requiredFields[current]) {
      requiredFields[current].forEach((field) => {
        // Ensure formData[field] is a string before calling trim()
        if (
          !formData[field] ||
          (typeof formData[field] === "string" && !formData[field].trim())
        ) {
          newErrors[field] = "This field is required";
          isValid = false;
        } else if (
          typeof formData[field] !== "string" &&
          !String(formData[field]).trim()
        ) {
          newErrors[field] = "This field is required";
          isValid = false;
        }
      });
    }

    // Update the errors state
    setErrors(newErrors);
    // Show error toast only if there is an error
    if (!isValid) {
      toast.error("Please fill all the required fields");
    }
    return isValid;
  };

  function handleSelect(name, option) {
    setFormData((prev) => ({
      ...prev,
      [name]: option,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: "",
    }));
  }

  const renderInputFields = () => {
    switch (current) {
      case 0:
        return (
          <>
            {/* Profile Picture */}
            <div className="mb-6">
              <label
                className={` border-dashed ${
                  errors.ImageLocation ? "border-red-500" : "border-zinc-500"
                }  border-2 rounded-md p-4 flex flex-col items-center justify-center cursor-pointer`}
              >
                {formData?.ImageLocation ? (
                  <>
                    <div className="w-20 h-20 shadow-lg rounded-full shadow-neutral-400">
                      <img
                        src={image}
                        alt="upload icon"
                        className="w-full h-full object-cover rounded-full"
                      />
                    </div>
                    {!isViewing && (
                      <div className="px-3 py-1.5 bg-sky-100 rounded-2xl justify-center items-center inline-flex cursor-pointer mt-3">
                        <p className="text-sky-400 text-xs font-semibold font-open">
                          Change Image
                        </p>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    <div className="w-14 h-14 bg-slate-100 rounded-full flex justify-center items-center">
                      <img
                        src={uploadProfile}
                        alt=""
                        className="w-6 h-9 object-contain cursor-pointer"
                      />
                    </div>
                    <div className="text-center text-stone-950 text-sm font-medium font-poppins leading-none mt-2">
                      Profile Photo
                    </div>
                    <div className="text-center text-neutral-600 text-xs font-normal font-poppins leading-3 mt-1">
                      (Click to Add)
                    </div>
                  </>
                )}
                <input
                  type="file"
                  accept="image/*"
                  name="Image"
                  disabled={isViewing}
                  value={undefined}
                  onChange={(e) => handleImageChange(e)}
                  style={{ display: "none" }}
                />
              </label>
            </div>
            {/* Full Name */}
            <div className="mb-6">
              <label>
                <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                  Full Name
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="text"
                  name="FullName"
                  value={formData.FullName}
                  onChange={handleOnChange}
                  maxLength={25}
                  disabled={isViewing}
                  placeholder="Enter name of health manager"
                  className={`w-full ${
                    errors.FullName ? "border-red-500" : ""
                  } text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>
            {/* Years of Experience */}
            <div className="mb-6">
              <label>
                <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                  Years of Experience
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  name="Experience"
                  type="number"
                  onKeyDown={(e) => {
                    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                      e.preventDefault();
                    }
                  }}
                  onWheel={(e) => {
                    e.preventDefault();
                    e.target.blur();
                  }}
                  value={formData.Experience}
                  onChange={handleOnChange}
                  disabled={isViewing}
                  placeholder="Ex: 6"
                  className={`w-full ${
                    errors.Experience ? "border-red-500" : ""
                  } text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>
            {/* Languages */}
            <div className="mb-6">
              <p className="text-zinc-900 text-sm font-medium  font-poppins mb-2">
                Languages Known
                <sup className="text-red-600">*</sup>
              </p>
              <MultiSelectDropdown
                disabled={isViewing}
                placeholder="Select Languages"
                error={errors.Languages}
                name="Languages"
                options={languageList}
                onSelect={handleSelect}
                value={formData.Languages}
                labelKey="name"
                valueKey="id"
              />
            </div>
            {/* Education Degree, Speciality */}
            <div className="mb-6 flex space-x-4">
              {/* Education Degree */}
              <label className="flex-1">
                <p className="text-zinc-900 text-sm font-medium  font-poppins mb-2">
                  Education degree
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="text"
                  name="EducationDegree"
                  value={formData.EducationDegree}
                  onChange={handleOnChange}
                  maxLength={25}
                  disabled={isViewing}
                  placeholder="Enter Education Degree"
                  className={`w-full ${
                    errors.EducationDegree ? "border-red-500" : ""
                  } text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>

              {/* Speciality */}
              <label className="flex-1">
                <p className="text-zinc-900 text-sm font-medium  font-poppins mb-2">
                  Subject Speciality
                  <sup className="text-red-600">*</sup>
                </p>
                <Dropdown
                  disabled={isViewing}
                  placeholder={"Select ex:Neurology"}
                  error={errors.Speciality}
                  name="Speciality"
                  options={healthManagerSpeciality}
                  onSelect={handleSelect}
                  value={formData.Speciality}
                  labelKey={"Speciality"}
                  valueKey={"SID"}
                />
              </label>
            </div>

            {/* <button className="flex items-center justify-start space-x-2">
              <FaPlus className="text-sky-500 text-base" />
              <div className="text-sky-500 text-base font-semibold font-poppins leading-tight">
                Add Another Education
              </div>
            </button> */}
          </>
        );
      case 1:
        return (
          <>
            {/* Phone Number */}
            <div className="mb-6">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  Phone Number
                  <sup className="text-red-600">*</sup>
                </p>
                <PhoneInput
                  className="number"
                  country={"ch"}
                  disabled={isViewing}
                  value={formData.PhoneNumber}
                  onChange={(value) => {
                    setFormData({ ...formData, PhoneNumber: value });
                    setErrors({ ...errors, PhoneNumber: "" });
                  }}
                  inputClass={`w-full text-black text-xs font-normal font-poppins rounded-lg p-3 border ${
                    errors.PhoneNumber ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                  inputStyle={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "#F3F5F7",
                    border: errors.PhoneNumber && "1px solid rgb(239 68 68)",
                    outline: "none",
                    borderRadius: 6,
                  }}
                  buttonStyle={{
                    borderLeft:
                      errors.PhoneNumber && "1px solid rgb(239 68 68)",
                    borderTop: errors.PhoneNumber && "1px solid rgb(239 68 68)",
                    borderBottom:
                      errors.PhoneNumber && "1px solid rgb(239 68 68)",
                    borderRight: errors.PhoneNumber && "1px solid transparent",
                    borderTopLeftRadius: 6,
                    borderBottomLeftRadius: 6,
                  }}
                />
              </label>
            </div>
            {/* Email */}
            <div className="mb-6">
              <label>
                <p className="text-zinc-950 text-xs font-semibold font-poppins mb-2">
                  Email
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="email"
                  name="Email"
                  disabled={isViewing}
                  value={formData.Email}
                  onChange={handleOnChange}
                  placeholder="Input your email here"
                  className={`w-full text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border ${
                    errors.Email ? "border-red-500" : ""
                  }  focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>
            {/* Address */}
            <div className="mb-6">
              <label>
                <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                  Address
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="text"
                  name="Address"
                  value={formData.Address}
                  onChange={handleOnChange}
                  disabled={isViewing}
                  placeholder="Enter Address"
                  className={`w-full ${
                    errors.Address ? "border-red-500" : ""
                  } text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>
            {/* State, Country */}
            <div className="mb-6 flex space-x-4">
              {/* State */}
              <label className="flex-1">
                <p className="text-zinc-900 text-sm font-medium  font-poppins mb-2">
                  State
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="text"
                  name="State"
                  value={formData.State}
                  onChange={handleOnChange}
                  maxLength={25}
                  disabled={isViewing}
                  placeholder="Enter State"
                  className={`w-full ${
                    errors.State ? "border-red-500" : ""
                  } text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>

              {/* Country */}
              <label className="flex-1">
                <p className="text-zinc-900 text-sm font-medium  font-poppins mb-2">
                  Country
                  <sup className="text-red-600">*</sup>
                </p>
                <Dropdown
                  disabled={isViewing}
                  placeholder={"Select Country"}
                  error={errors.Country}
                  name="Country"
                  options={countries}
                  onSelect={handleSelect}
                  value={formData.Country}
                  labelKey="label"
                  valueKey="value"
                  enableSearch={true}
                />
              </label>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className="mb-6 flex space-x-4">
              {/* Start Time */}
              <label className="flex-1">
                <p className="text-zinc-900 text-sm font-medium  font-poppins mb-2">
                  Start Time
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="number"
                  name="StartTime"
                  onKeyDown={(e) => {
                    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                      e.preventDefault();
                    }
                  }}
                  onWheel={(e) => {
                    e.preventDefault();
                    e.target.blur();
                  }}
                  value={formData.StartTime}
                  disabled={isViewing}
                  onChange={handleOnChange}
                  placeholder="ex: 9: a.m"
                  className={`w-full ${
                    errors.StartTime ? "border-red-500" : ""
                  } text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
              {/* End Time */}
              <label className="flex-1">
                <p className="text-zinc-900 text-sm font-medium  font-poppins mb-2">
                  End Time
                  <sup className="text-red-600">*</sup>
                </p>
                <input
                  type="number"
                  name="EndTime"
                  onKeyDown={(e) => {
                    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                      e.preventDefault();
                    }
                  }}
                  onWheel={(e) => {
                    e.preventDefault();
                    e.target.blur();
                  }}
                  value={formData.EndTime}
                  disabled={isViewing}
                  onChange={handleOnChange}
                  placeholder="ex: 5: p.m"
                  className={`w-full ${
                    errors.EndTime ? "border-red-500" : ""
                  } text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>
            <div className="text-zinc-500 text-xs font-normal font-poppins">
              Enter working hours availability for Mon to Fri
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div className="mb-6">
              <label>
                <div className="flex items-center justify-between">
                  <p className="text-zinc-900 text-sm font-medium font-poppins mb-2">
                    About the Health Manager
                    <sup className="text-red-600">*</sup>
                  </p>
                  <p className="text-right text-neutral-600 text-xs font-normal font-poppins">
                    {formData?.Bio.length}/100
                  </p>
                </div>
                <textarea
                  type="text"
                  name="Bio"
                  value={formData.Bio}
                  onChange={handleOnChange}
                  maxLength={100}
                  disabled={isViewing}
                  placeholder="Provide  a brief intro , their experience, key qualifications etc."
                  className={`w-full  ${
                    errors.Bio ? "border-red-500" : ""
                  } text-black text-xs font-normal font-poppins bg-gray-100 rounded-md p-4 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent`}
                />
              </label>
            </div>
          </>
        );
      default:
        return <></>;
    }
  };

  function handleOnChange(event) {
    const { name, value } = event.target;
    let maxLength;

    // Set the maxLength based on the field name
    switch (name) {
      case "Experience":
        maxLength = 2;
        break;
      case "StartTime":
        maxLength = 2;
        break;
      case "EndTime":
        maxLength = 2;
        break;
      default:
        maxLength = Infinity; // No maxLength for other fields
    }

    // For other types, handle validation without removing non-numeric characters
    if (value.length <= maxLength) {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  }

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    const toastId = toast.loading("Compressing Image...");
    const compressedFile = await imageCompression(file, options);
    toast.dismiss(toastId);
    uploadImage(compressedFile);
  };

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("ProfileImage", file);
    try {
      const res = await uploadOrganizationLogo(token, formData);
      setFormData((prev) => ({
        ...prev,
        ImageLocation: res?.ProfileImageUrl,
      }));
      setErrors((prev) => ({
        ...prev,
        ImageLocation: "",
      }));
      setImage(URL.createObjectURL(file));
    } catch (error) {
      console.log(error);
    }
  };

  const next = () => {
    if (current < steps.length - 1 && validateStepFields()) {
      setCurrent(current + 1);
    } else if (current === steps.length - 1 && validateStepFields()) {
      submitHandler();
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const formatTime = (time, isEndTime = false) => {
    // Convert time to a number
    let hour = parseInt(time, 10);

    // If it's EndTime, add 12 to convert to PM
    if (isEndTime && hour < 12) {
      hour += 12;
    }

    // Format the hour to 24-hour format with leading zero if necessary
    const formattedHour = hour < 10 ? `0${hour}` : hour;

    // Return the formatted time in HH:MM:SS format
    return `${formattedHour}:00:00`;
  };

  const submitHandler = async () => {
    const body = {
      Name: formData.FullName,
      Gender: "Male",
      Email: formData.Email,
      // CountryCode: "+91",
      PhoneNumber: formData.PhoneNumber,
      JoiningDate: moment().format("YYYY-MM-DD"),
      Bio: formData.Bio,
      ORGID: user.ORGID,
      Experience: parseInt(formData.Experience),
      Address: formData.Address,
      Qualification: formData.EducationDegree,
      Speciality: [formData.Speciality],
      PreferredLanguage: formData.Languages,
      ProfileImageUrl: formData.ImageLocation,
      Country: formData.Country,
      State: formData.State,
      StartTime: formatTime(formData.StartTime),
      EndTime: formatTime(formData.EndTime, true),
    };

    if (healthManager?.DID) {
      dispatch(updateHealthManager(token, healthManager?.DID, body, navigate));
    } else {
      dispatch(addHealthManager(token, body, navigate));
    }
  };
  return (
    <div className="w-1/2">
      <div className="bg-white rounded-lg shadow-md p-6 border border-sky-500">
        {renderInputFields(current)}
      </div>
      <div className="flex flex-row items-center justify-center mt-6 space-x-4">
        {current > 0 && (
          <button
            onClick={prev}
            className="flex-1 px-5 py-3 flex items-center justify-center bg-white rounded-lg border border-sky-500"
          >
            <div className="text-sky-500 text-base font-semibold font-poppins leading-normal">
              Previous
            </div>
          </button>
        )}
        <button
          onClick={next}
          className="flex-1 px-5 py-3 flex items-center justify-center bg-sky-500 rounded-lg border border-sky-500"
        >
          <div className="text-white text-base font-semibold font-poppins leading-normal">
            {current === steps.length - 1 ? "Submit" : "Next"}
          </div>
        </button>
      </div>
    </div>
  );
};

const PhoneMockup = ({ children }) => {
  return (
    <div className="relative mx-auto w-[300px] h-[600px]">
      <img
        src={iphoneFrame}
        alt="iPhone Frame"
        className="w-full h-full object-contain"
      />
      <div className="absolute inset-[9%] overflow-hidden">{children}</div>
    </div>
  );
};

const PreviewSection = ({ formData, image, healthManagerSpeciality }) => {
  return (
    <div className="w-1/2">
      <div className="bg-white rounded-lg shadow-md p-6">
        <div className="text-center text-zinc-900 text-xl font-semibold font-poppins mb-7">
          Preview for Patient
        </div>
        <PhoneMockup>
          <FrontView
            formData={formData}
            image={image}
            healthManagerSpeciality={healthManagerSpeciality}
          />
        </PhoneMockup>
        <div className="w-full bg-gray-100 rounded-lg mt-7 p-2.5 flex items-center justify-center">
          <div className="w-96 text-center text-black text-xl font-normal font-poppins">
            This is how your health manager will look to Patients on the App
          </div>
        </div>
      </div>
    </div>
  );
};

const FrontView = ({ formData, image, healthManagerSpeciality }) => {
  const languageMap = languageList.reduce((acc, lang) => {
    acc[lang.id] = lang.name;
    return acc;
  }, {});

  const specialityMap = healthManagerSpeciality.reduce((acc, spec) => {
    acc[spec.SID] = spec.Speciality;
    return acc;
  }, {});
  return (
    <div className="h-full flex flex-col justify-between">
      <div>
        {/* FlatTopBar */}
        <div className="flex items-center space-x-14">
          <IoChevronBack className="cursor-pointer" />
          <div className="text-zinc-950 text-xs font-semibold font-poppins select-none ">
            Health Manager
          </div>
        </div>
        {/* Profile Picture */}
        {formData?.ImageLocation ? (
          <div className="relative w-full h-72 rounded-tl-3xl rounded-tr-3xl mt-4">
            <img
              src={image}
              alt=""
              className="w-full h-full rounded-tl-3xl rounded-tr-3xl"
            />
            <div className="absolute top-2 left-1/2 transform -translate-x-1/2 flex items-center text-center text-white text-sm font-black font-poppins leading-7 tracking-tight whitespace-nowrap">
              {formData?.FullName || "Manager's Name"}
              <img
                src={verifiedTick}
                alt="Verified Tick"
                className="w-5 h-5 ml-2"
              />
            </div>
          </div>
        ) : (
          <div className="w-full h-72 bg-slate-200 rounded-tl-3xl rounded-tr-3xl mt-4 animate-pulse">
            <div className="flex items-center justify-center space-x-2 pt-2">
              <div className="text-white text-sm font-black font-poppins leading-7 tracking-tight">
                {formData?.FullName || "Manager Name"}
              </div>
              <img
                src={verifiedTick}
                alt="Verified Tick"
                className="w-5 h-5 ml-2"
              />
            </div>
          </div>
        )}

        <div className="w-full bg-gray-100 rounded-bl-3xl rounded-br-3xl mt-2 p-3">
          <div className=" text-slate-700 text-xs font-medium font-poppins">
            Health Manager Details:
          </div>
          <div className="flex items-start space-x-1 mt-3">
            <img src={info} alt="" className="w-3 h-3" />
            <div className="text-slate-700 text-[8px] font-normal font-poppins">
              {formData?.Bio || "Manager's Bio here"}
            </div>
          </div>
          <div className="grid grid-cols-2 gap-2 mt-2">
            <div className="flex items-center space-x-1">
              <img src={experience} alt="" className="w-3 h-3" />
              <div className="text-slate-700 text-[8px] font-normal font-poppins">
                {formData?.Experience || 0} Years Experience
              </div>
            </div>
            <div className="flex items-center space-x-1">
              <img src={location} alt="" className="w-3 h-3" />
              <div className="text-slate-700 text-[8px] font-normal font-poppins">
                {(formData?.State &&
                  formData?.Country &&
                  formData?.State +
                    ", " +
                    formData?.Country.charAt(0).toUpperCase() +
                    formData?.Country.slice(1).toLowerCase()) ||
                  "Manager's Address"}
              </div>
            </div>
            <div className="flex space-x-1">
              <img src={education} alt="" className="w-3 h-3" />
              <div className="text-slate-700 text-[8px] font-normal font-poppins">
                {(formData?.EducationDegree &&
                  formData?.Speciality &&
                  formData?.EducationDegree +
                    ", " +
                    specialityMap[formData?.Speciality]) ||
                  "Manager's Education"}
              </div>
            </div>
            <div className="flex space-x-1">
              <img src={language} alt="" className="w-3 h-3" />
              <div className="text-slate-700 text-[8px] font-normal font-poppins">
                <div className="text-slate-700 text-[8px] font-normal font-poppins">
                  {(
                    formData?.Languages?.map((langId) => languageMap[langId]) ||
                    []
                  ).join(", ") || "English, Spanish"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Button */}
      {/* <button className="py-2 bg-sky-500 rounded-lg">
    <div className="text-white text-sm font-semibold font-poppins leading-6 text-center">
      Back
    </div>
  </button> */}
    </div>
  );
};

function AddNewHealthManager() {
  const { healthManagerSpeciality } = useSelector(
    (state) => state.medicalConcierge
  );
  const location = useLocation();
  const user = location.state || {};
  const [isViewing, setIsViewing] = useState(false);
  const [image, setImage] = useState("");
  const [formData, setFormData] = useState({
    ImageLocation: "",
    FullName: "",
    Experience: "",
    Languages: [""],
    EducationDegree: "",
    Speciality: "",
    PhoneNumber: "",
    Email: "",
    Address: "",
    State: "",
    Country: "",
    Bio: "",
    StartTime: "",
    EndTime: "",
  });
  const [errors, setErrors] = useState({
    ImageLocation: "",
    FullName: "",
    Experience: "",
    Languages: "",
    EducationDegree: "",
    Speciality: "",
    PhoneNumber: "",
    Email: "",
    Address: "",
    State: "",
    Country: "",
    Bio: "",
    StartTime: "",
    EndTime: "",
  });
  const [current, setCurrent] = useState(0);
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const convertTo12HourFormat = (time) => {
    const [hours] = time.split(":");
    const adjustedHours = hours % 12 || 12; // Convert '0' to '12' for 12 AM
    return adjustedHours.toString();
  };

  useEffect(() => {
    setFormData({
      ImageLocation: user?.ProfileImageUrl || "",
      FullName: user?.Name || "",
      Experience: user?.Experience || "",
      EducationDegree: user?.Qualification || "",
      PhoneNumber: user?.Phone || "",
      Email: user?.Email || "",
      Address: user?.Address || "",
      State: user?.State || "",
      Country: user?.Country || "",
      Bio: user?.Bio || "",
      StartTime: user.Availability
        ? convertTo12HourFormat(user.Availability.StartTime)
        : "",
      EndTime: user.Availability
        ? convertTo12HourFormat(user.Availability.EndTime)
        : "",
      Speciality: user?.Speciality?.[0] || "",
      Languages:
        languageList
          .filter((language) => user?.PreferredLanguage?.includes(language.id))
          .map((language) => language.id) || [],
    });
    setImage(user?.Image || "");
  }, [user?.DID]);

  useEffect(() => {
    if (user.isViewing) setIsViewing(true);
  }, [user]);

  return (
    <div className="bg-[#FCFCFC] min-h-full p-6 scroll-smooth">
      {/* Header */}
      {isViewing ? (
        <div className="text-zinc-900 text-2xl font-medium font-poppins">
          Health Manager Details
        </div>
      ) : (
        <div className="text-zinc-900 text-2xl font-medium font-poppins">
          {user?.DID ? "Edit" : "Add"} Health Manager
        </div>
      )}
      <Breadcrumb />
      <div className="w-1/2 mt-5">
        <Steps
          size="small"
          labelPlacement="vertical"
          current={current}
          items={items}
          className="custom-steps"
        />
      </div>
      <div className="flex gap-8 p-8">
        <Form
          formData={formData}
          setFormData={setFormData}
          errors={errors}
          setErrors={setErrors}
          current={current}
          setCurrent={setCurrent}
          isViewing={isViewing}
          image={image}
          setImage={setImage}
          healthManagerSpeciality={healthManagerSpeciality}
          healthManager={user}
        />
        <PreviewSection
          formData={formData}
          image={image}
          healthManagerSpeciality={healthManagerSpeciality}
        />
      </div>
    </div>
  );
}

export default AddNewHealthManager;

const steps = [
  {
    title: "Basic Details",
  },
  {
    title: "Contact Info",
  },
  {
    title: "Working Hours",
  },
  {
    title: "Manager's Bio",
  },
];
