import { toast } from "react-hot-toast";
import {
    setLoading,
    setTreatmentCategory,
} from "../../slices/ourServicesSlice";
import { apiConnector } from "../apiConnector";
import { ourServicesEndpoints } from "../apis";
import { getBucketURL } from "../../utils/regex";

const {
    GET_TREATMENT_CATEGORY,
    ADD_TREATMENT_CATEGORY,
    UPDATE_TREATMENT_CATEGORY,
    DELETE_TREATMENT_CATEGORY,
    UPLOAD_OUR_SERVICES_IMAGE,
    ADD_TREATMENT_SERVICE,
    UPDATE_TREATMENT_SERVICE,
    DELETE_TREATMENT_SERVICE,
} = ourServicesEndpoints;

// GET TREATMENT CATEGORY
export function getTreatmentCategory(token) {
    return async (dispatch) => {
        dispatch(setLoading(true));
        try {
            const response = await apiConnector(
                "GET",
                GET_TREATMENT_CATEGORY,
                null,
                {
                    Authorization: `Bearer ${token}`,
                }
            );

            if (!response?.status === 200) {
                throw new Error(response?.data?.message);
            }

            const categoriesWithImages = await Promise.all(
                response?.data?.OurServiceCategories.map(async (category) => {
                    // Fetch category image only if it's not null
                    const categoryImageURL = category?.Image ? await getBucketURL(category?.Image) : category?.Image;

                    const treatmentsWithImages = await Promise.all(
                        category?.TreatmentServices.map(async (treatment) => {
                            // Fetch treatment image only if it's not null
                            const treatmentImageURL = treatment?.Image ? await getBucketURL(treatment?.Image) : treatment?.Image;

                            return {
                                ...treatment,
                                Image: treatmentImageURL,
                            };
                        })
                    );

                    const doctorsWithImages = await Promise.all(
                        category?.DoctorsData.map(async (doctor) => {
                            // Fetch DoctorsData  image only if it's not null
                            const Image = doctor?.ProfileImageUrl ? await getBucketURL(doctor?.ProfileImageUrl) : doctor?.ProfileImageUrl;

                            return {
                                ...doctor,
                                Image: Image,
                            };
                        })
                    );

                    return {
                        ...category,
                        Image: categoryImageURL,
                        TreatmentServices: treatmentsWithImages,
                        DoctorsData: doctorsWithImages,
                    };
                })
            );
            const sortedCategories = categoriesWithImages.sort((a, b) => {
                return new Date(b.CreatedAt) - new Date(a.CreatedAt);
              });
            dispatch(setTreatmentCategory(sortedCategories || []));
        } catch (error) {
            dispatch(setTreatmentCategory([]));
            console.log(
                "GET_TREATMENT_CATEGORY API ERROR............",
                error
            );
            // toast.error(error?.response?.data?.message || "Unable to fetch lab partners");
        }
        dispatch(setLoading(false));
    };
}

// ADD TREATMENT CATEGORY
export function addNewTreatmentCategory(token, body, navigate) {
    return async (dispatch) => {
        const toastId = toast.loading("Loading...");
        dispatch(setLoading(true));
        try {
            const response = await apiConnector("POST", ADD_TREATMENT_CATEGORY, body, {
                Authorization: `Bearer ${token}`,
            });

            if (!response?.status === 200) {
                throw new Error(response?.data?.message);
            }

            // console.log("addNewTreatmentCategory RESPONSE............", response);
            toast.success("Treatment Category Created Successfully");
            dispatch(getTreatmentCategory(token));
            navigate("/ourServices");
        } catch (error) {
            console.log("addNewTreatmentCategory ERROR............", error);
            toast.error(error?.response?.data?.message || "Could Not Add Treatment Category");
        }
        dispatch(setLoading(false));
        toast.dismiss(toastId);
    };
}

//PUT UPDATE TREATMENT CATEGORY
export function updateTreatmentCategory(token, ID, body, navigate) {
    return async (dispatch) => {
        const toastId = toast.loading("Loading...");
        dispatch(setLoading(true));
        try {
            const response = await apiConnector(
                "PUT",
                UPDATE_TREATMENT_CATEGORY + "/" + ID,
                body,
                {
                    Authorization: `Bearer ${token}`,
                }
            );

            if (!response?.status === 200) {
                throw new Error(response?.data?.message);
            }
            toast.success("Treatment Category Updated Successfully");
            dispatch(getTreatmentCategory(token));
            navigate("/ourServices");
        } catch (error) {
            console.log("updateTreatmentCategory api ERROR............", error);
            toast.error("Could Not Update Treatment Category");
        }
        dispatch(setLoading(false));
        toast.dismiss(toastId);
    };
}

// DELETE TREATMENT CATEGORY
export function deleteTreatmentCategory(token, ID, navigate) {
    return async (dispatch) => {
        const toastId = toast.loading("Loading...");
        dispatch(setLoading(true));
        try {
            const response = await apiConnector(
                "DELETE",
                DELETE_TREATMENT_CATEGORY + "/" + ID,
                null,
                {
                    Authorization: `Bearer ${token}`,
                }
            );
            if (!response?.status === 200) {
                throw new Error(response?.data?.message);
            }
            toast.success(
                response?.data?.message || "Treatment Category Deleted"
            );
            dispatch(getTreatmentCategory(token));
            navigate("/ourServices");
        } catch (error) {
            console.log(
                "DELETE TREATMENT CATEGORY API ERROR :",
                error
            );
            toast.error(
                error?.response?.data?.message ||
                "ERROR WHILE DELETING TREATMENT CATEGORY"
            );
        }
        dispatch(setLoading(false));
        toast.dismiss(toastId);
    };
}


//UPLOAD OUR SERVICES IMAGE
export const uploadOurServicesImage = async (formData, token) => {
    const toastId = toast.loading("Loading...");
    let result = [];
    try {
        const response = await apiConnector(
            "POST",
            UPLOAD_OUR_SERVICES_IMAGE,
            formData,
            {
                Authorization: `Bearer ${token}`,
            }
        );
        result = response?.data;
        if (response.status !== 200) {
            throw new Error(response.data.message);
        }
    } catch (error) {
        console.error("UPLOAD OUR SERVICE IMAGE API ERROR:", error);
        toast.error("Oops! Something went wrong. Please try again.");
    }
    toast.dismiss(toastId);
    return result;
};


// ADD TREATMENT SERVICE
export function addNewTreatmentService(token, body, navigate) {
    return async (dispatch) => {
        const toastId = toast.loading("Loading...");
        dispatch(setLoading(true));
        try {
            const response = await apiConnector("POST", ADD_TREATMENT_SERVICE, body, {
                Authorization: `Bearer ${token}`,
            });

            if (!response?.status === 200) {
                throw new Error(response?.data?.message);
            }

            // console.log("addNewTreatmentCategory RESPONSE............", response);
            toast.success("Treatment Service Created Successfully");
            dispatch(getTreatmentCategory(token));
            navigate("/ourServices");
        } catch (error) {
            console.log("addNewTreatmentService ERROR............", error);
            toast.error(error?.response?.data?.message || "Could Not Add Treatment Service");
        }
        dispatch(setLoading(false));
        toast.dismiss(toastId);
    };
}

//PUT UPDATE TREATMENT SERVICE
export function updateTreatmentService(token, ID, body, navigate) {
    return async (dispatch) => {
        const toastId = toast.loading("Loading...");
        dispatch(setLoading(true));
        try {
            const response = await apiConnector(
                "PUT",
                UPDATE_TREATMENT_SERVICE + "/" + ID,
                body,
                {
                    Authorization: `Bearer ${token}`,
                }
            );

            if (!response?.status === 200) {
                throw new Error(response?.data?.message);
            }
            toast.success("Treatment Service Updated Successfully");
            dispatch(getTreatmentCategory(token));
            navigate("/ourServices");
        } catch (error) {
            console.log("updateTreatmentService api ERROR............", error);
            toast.error("Could Not Update Treatment SERVICE");
        }
        dispatch(setLoading(false));
        toast.dismiss(toastId);
    };
}

// DELETE TREATMENT SERVICE
export function deleteTreatmentService(token, ID, navigate) {
    return async (dispatch) => {
        const toastId = toast.loading("Loading...");
        dispatch(setLoading(true));
        try {
            const response = await apiConnector(
                "DELETE",
                DELETE_TREATMENT_SERVICE + "/" + ID,
                null,
                {
                    Authorization: `Bearer ${token}`,
                }
            );
            if (!response?.status === 200) {
                throw new Error(response?.data?.message);
            }
            toast.success(
                response?.data?.message || "Treatment Service Deleted"
            );
            dispatch(getTreatmentCategory(token));
            navigate("/ourServices");
        } catch (error) {
            console.log(
                "DELETE TREATMENT SERVICE API ERROR :",
                error
            );
            toast.error(
                error?.response?.data?.message ||
                "ERROR WHILE DELETING TREATMENT SERVICE"
            );
        }
        dispatch(setLoading(false));
        toast.dismiss(toastId);
    };
}