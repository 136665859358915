import moment from "moment";
import React from "react";
import addAppointment from "../assets/Images/PatientDashboard/addAppointmentIcon.png";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import Tooltip from "./Tooltip";
function PatientCardView({ patient }) {
  const navigate = useNavigate();
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied to clipboard");
  };
  return (
    <div className="mt-6 grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
      {patient.map((user) => (
        <div
          key={user.AHUID}
          className="bg-white rounded-lg border border-gray-200 shadow-md p-6 flex flex-col space-y-6"
        >
          {/* Image and Actions  */}
          <div className="flex flex-row items-center justify-between">
            <div
              onClick={() => {
                navigate("/patientDashboard/patientProfile", { state: user });
              }}
              className="flex items-center cursor-pointer"
            >
              <div
                className={`${
                  user?.IsMedicalConciergeMember &&
                  " bg-gradient-to-r from-[#F6C86E] to-[#FFEE54] rounded-full p-0.5"
                } mr-3`}
              >
                <img
                  src={user?.Image}
                  alt="PatientPic"
                  className="w-10 h-10  rounded-full"
                />
              </div>
              <div>
                <p className="text-gray-900 text-sm font-medium font-poppins leading-tight">
                  {user?.Name}
                </p>
                <p className="text-zinc-500 text-sm font-normal font-poppins leading-tight">
                  {user?.Gender},{" "}
                  {new Date().getFullYear() - new Date(user.DOB).getFullYear()}
                </p>
              </div>
            </div>

            <div className="flex flex-row items-center space-x-2">
              <Tooltip message="View Profile">
                <button
                  onClick={() => {
                    navigate("/patientDashboard/patientProfile", {
                      state: user,
                    });
                  }}
                  className="p-1 rounded-full group group-hover:bg-sky-100"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 text-neutral-600 group-hover:text-sky-400"
                  >
                    <path
                      d="M21.3448 11.045C21.6488 11.4713 21.8008 11.6845 21.8008 12C21.8008 12.3155 21.6488 12.5287 21.3448 12.955C19.9787 14.8706 16.49 19 11.8008 19C7.11156 19 3.62288 14.8706 2.25682 12.955C1.95279 12.5287 1.80078 12.3155 1.80078 12C1.80078 11.6845 1.95279 11.4713 2.25682 11.045C3.62288 9.12944 7.11156 5 11.8008 5C16.49 5 19.9787 9.12944 21.3448 11.045Z"
                      stroke="currentColor"
                      stroke-width="1.5"
                    />
                    <path
                      d="M14.8008 12C14.8008 10.3431 13.4577 9 11.8008 9C10.1439 9 8.80078 10.3431 8.80078 12C8.80078 13.6569 10.1439 15 11.8008 15C13.4577 15 14.8008 13.6569 14.8008 12Z"
                      stroke="currentColor"
                      stroke-width="1.5"
                    />
                  </svg>
                </button>
              </Tooltip>

              <Tooltip message="Manage">
                <button
                  onClick={() => {
                    navigate("/patientDashboard/managePatient", {
                      state: user,
                    });
                  }}
                  className="p-1 rounded-full group group-hover:bg-sky-100"
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6 text-neutral-600 group-hover:text-sky-400"
                  >
                    <path
                      d="M16.0149 4.98239L17.4166 3.58063C18.1908 2.80646 19.446 2.80646 20.2202 3.58063C20.9943 4.3548 20.9943 5.60998 20.2202 6.38415L18.8184 7.78591M16.0149 4.98239L10.781 10.2163C9.73571 11.2616 9.21304 11.7842 8.85715 12.4211C8.50125 13.058 8.14318 14.5619 7.80078 16C9.23887 15.6576 10.7428 15.2995 11.3797 14.9436C12.0166 14.5877 12.5392 14.0651 13.5845 13.0198L18.8184 7.78591M16.0149 4.98239L18.8184 7.78591"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M20.8008 12C20.8008 16.2426 20.8008 18.364 19.4828 19.682C18.1648 21 16.0434 21 11.8008 21C7.55814 21 5.43682 21 4.1188 19.682C2.80078 18.364 2.80078 16.2426 2.80078 12C2.80078 7.75736 2.80078 5.63604 4.1188 4.31802C5.43682 3 7.55814 3 11.8008 3"
                      stroke="currentColor"
                      stroke-width="1.5"
                      stroke-linecap="round"
                    />
                  </svg>
                </button>
              </Tooltip>
            </div>
          </div>

          {/* Basic Info */}
          <div className="space-y-3">
            <div className="flex justify-between space-x-2">
              <div className="text-gray-800 text-xs font-normal font-poppins">
                Phone Number:
              </div>
              <div className="flex items-center text-neutral-600 text-xs font-normal font-poppins">
                {user.Phone}
                <button
                  onClick={() => handleCopy(user.Phone)}
                  className="ml-2 active:scale-90"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 20 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 h-4 text-sky-400"
                  >
                    <path
                      d="M8 12.5C8 10.143 8 8.9645 8.73223 8.23223C9.4645 7.5 10.643 7.5 13 7.5H13.8333C16.1903 7.5 17.3688 7.5 18.1011 8.23223C18.8333 8.9645 18.8333 10.143 18.8333 12.5V13.3333C18.8333 15.6903 18.8333 16.8688 18.1011 17.6011C17.3688 18.3333 16.1903 18.3333 13.8333 18.3333H13C10.643 18.3333 9.4645 18.3333 8.73223 17.6011C8 16.8688 8 15.6903 8 13.3333V12.5Z"
                      stroke="currentColor"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.664 7.5013C14.662 5.03706 14.6247 3.76064 13.9074 2.88666C13.7689 2.71788 13.6141 2.56312 13.4454 2.4246C12.5234 1.66797 11.1536 1.66797 8.41406 1.66797C5.6745 1.66797 4.30471 1.66797 3.38275 2.4246C3.21397 2.56311 3.05921 2.71788 2.9207 2.88666C2.16406 3.80862 2.16406 5.1784 2.16406 7.91797C2.16406 10.6576 2.16406 12.0273 2.9207 12.9493C3.0592 13.1181 3.21397 13.2728 3.38275 13.4113C4.25674 14.1286 5.53315 14.1659 7.9974 14.1679"
                      stroke="currentColor"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="flex justify-between items-center space-x-2">
              <div className=" text-gray-800 text-xs font-normal font-poppins">
                Email ID:
              </div>
              <div className="flex items-center text-neutral-600 text-xs font-normal font-poppins">
                {user.Email}
                <button
                  onClick={() => handleCopy(user.Email)}
                  className="ml-2 active:scale-90"
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 20 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-4 h-4 text-sky-400"
                  >
                    <path
                      d="M8 12.5C8 10.143 8 8.9645 8.73223 8.23223C9.4645 7.5 10.643 7.5 13 7.5H13.8333C16.1903 7.5 17.3688 7.5 18.1011 8.23223C18.8333 8.9645 18.8333 10.143 18.8333 12.5V13.3333C18.8333 15.6903 18.8333 16.8688 18.1011 17.6011C17.3688 18.3333 16.1903 18.3333 13.8333 18.3333H13C10.643 18.3333 9.4645 18.3333 8.73223 17.6011C8 16.8688 8 15.6903 8 13.3333V12.5Z"
                      stroke="currentColor"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14.664 7.5013C14.662 5.03706 14.6247 3.76064 13.9074 2.88666C13.7689 2.71788 13.6141 2.56312 13.4454 2.4246C12.5234 1.66797 11.1536 1.66797 8.41406 1.66797C5.6745 1.66797 4.30471 1.66797 3.38275 2.4246C3.21397 2.56311 3.05921 2.71788 2.9207 2.88666C2.16406 3.80862 2.16406 5.1784 2.16406 7.91797C2.16406 10.6576 2.16406 12.0273 2.9207 12.9493C3.0592 13.1181 3.21397 13.2728 3.38275 13.4113C4.25674 14.1286 5.53315 14.1659 7.9974 14.1679"
                      stroke="currentColor"
                      strokeWidth="1.25"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="flex justify-between space-x-2">
              <div className="text-gray-800 text-xs font-normal font-poppins">
                Last Activity:
              </div>
              <div className="text-neutral-600 text-xs font-normal font-poppins overflow-hidden overflow-ellipsis">
                {moment(user?.LastActivity).format("DD MMM YYYY")}
              </div>
            </div>
          </div>

          {/* Add Appointment */}
          <button
            onClick={() => {
              navigate("/patientDashboard/bookAppointment", {
                state: user,
              });
            }}
            className="p-2 bg-gray-100 rounded shadow flex flex-row items-center justify-center space-x-2 active:scale-95"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6 text-zinc-900"
            >
              <path
                d="M7.99072 1.5C7.57617 1.5 7.24072 1.83545 7.24072 2.25V2.93042H6.06592C3.94263 2.93042 2.21484 4.65747 2.21484 6.78076V18.6489C2.21484 20.7722 3.94263 22.5 6.06592 22.5H17.9341C20.0574 22.5 21.7852 20.7722 21.7852 18.6489V6.78076C21.7852 4.65747 20.0574 2.93042 17.9341 2.93042H16.7593V2.25C16.7593 1.83545 16.4238 1.5 16.0093 1.5C15.5947 1.5 15.2593 1.83545 15.2593 2.25V2.93042H8.74072V2.25C8.74072 1.83545 8.40527 1.5 7.99072 1.5ZM15.2593 4.43042V5.11011C15.2593 5.52466 15.5947 5.86011 16.0093 5.86011C16.4238 5.86011 16.7593 5.52466 16.7593 5.11011V4.43042H17.9341C19.2305 4.43042 20.2852 5.48511 20.2852 6.78076V18.6489C20.2852 19.9453 19.2305 21 17.9341 21H6.06592C4.76953 21 3.71484 19.9453 3.71484 18.6489V6.78076C3.71484 5.48511 4.76953 4.43042 6.06592 4.43042H7.24072V5.11011C7.24072 5.52466 7.57617 5.86011 7.99072 5.86011C8.40527 5.86011 8.74072 5.52466 8.74072 5.11011V4.43042H15.2593Z"
                fill="currentColor"
              />
              <path
                d="M6.375 12.7148C6.375 15.8167 8.89819 18.3398 12 18.3398C15.1018 18.3398 17.625 15.8167 17.625 12.7148C17.625 9.61304 15.1018 7.08984 12 7.08984C8.89819 7.08984 6.375 9.61304 6.375 12.7148ZM12 8.58984C14.2742 8.58984 16.125 10.4407 16.125 12.7148C16.125 14.989 14.2742 16.8398 12 16.8398C9.72583 16.8398 7.875 14.989 7.875 12.7148C7.875 10.4407 9.72583 8.58984 12 8.58984Z"
                fill="currentColor"
              />
              <path
                d="M10.125 13.4648H11.25V14.5898C11.25 15.0044 11.5854 15.3398 12 15.3398C12.4146 15.3398 12.75 15.0044 12.75 14.5898V13.4648H13.875C14.2896 13.4648 14.625 13.1294 14.625 12.7148C14.625 12.3003 14.2896 11.9648 13.875 11.9648H12.75V10.8398C12.75 10.4253 12.4146 10.0898 12 10.0898C11.5854 10.0898 11.25 10.4253 11.25 10.8398V11.9648H10.125C9.71045 11.9648 9.375 12.3003 9.375 12.7148C9.375 13.1294 9.71045 13.4648 10.125 13.4648Z"
                fill="currentColor"
              />
            </svg>

            <div className="text-zinc-900 text-xs font-medium font-poppins">
              Add New Appointment
            </div>
          </button>
        </div>
      ))}
    </div>
  );
}

export default PatientCardView;
