import { toast } from "react-hot-toast";
import {
  setLoading,
  setConciergePlans,
  setConciergeFeatures,
  setHealthManagers,
  setHealthManagerSpeciality,
  setConciergeMembers,
} from "../../slices/medicalConciergeSlice";
import { apiConnector } from "../apiConnector";
import { medicalConciergeEndpoints, patientManagementEndpoints } from "../apis";
import { getBucketURL } from "../../utils/regex";
import { getAllPatients } from "./patientManagementAPI";

const {
  GET_MEDICAL_CONCIERGE_SPECIALITY,
  GET_MEDICAL_CONCIERGE_PLANS,
  GET_MEDICAL_CONCIERGE_FEATURES,

  GET_HEALTH_MANAGERS,
  ADD_HEALTH_MANAGER,
  UPDATE_HEALTH_MANAGER,
  DELETE_HEALTH_MANAGER,

  CREATE_MEDICAL_CONCIERGE_PLANS,
  UPDATE_MEDICAL_CONCIERGE_PLANS,
  DELETE_MEDICAL_CONCIERGE_PLANS,

  ADD_NOTES,
  ASSIGN_HEALTH_MANAGER,
  ASSIGN_HEALTH_MANAGER_BULK,
  GET_ASSIGNED_PATIENTS,
  ADD_CONCIERGE_PATIENT,
  GET_CONCIERGE_PATIENT,
} = medicalConciergeEndpoints;

const { ADD_NEW_PATIENT_API } = patientManagementEndpoints;

// GET HEALTH MANAGER SPECIALITY
export function getHealthManagerSpeciality(token) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "GET",
        GET_MEDICAL_CONCIERGE_SPECIALITY,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      console.log(
        "GET_MEDICAL_CONCIERGE_SPECIALITY API RESPONSE............",
        response
      );
      dispatch(setHealthManagerSpeciality(response?.data?.Speciality || []));
    } catch (error) {
      dispatch(setHealthManagerSpeciality([]));
      console.log(
        "GET_MEDICAL_CONCIERGE_SPECIALITY API ERROR............",
        error
      );
      // toast.error(error?.response?.data?.message || "Unable to fetch lab partners");
    }
    dispatch(setLoading(false));
  };
}
// GET MEDICAL CONCIERGE PLANS
export function getMedicalConciergePlans(token) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "GET",
        GET_MEDICAL_CONCIERGE_PLANS,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      console.log(
        "GET_MEDICAL_CONCIERGE_PLANS API RESPONSE............",
        response
      );
      dispatch(setConciergePlans(response?.data?.data || []));
    } catch (error) {
      dispatch(setConciergePlans([]));
      console.log("GGET_MEDICAL_CONCIERGE_PLANS API ERROR............", error);
      // toast.error(error?.response?.data?.message || "Unable to fetch lab partners");
    }
    dispatch(setLoading(false));
  };
}

// GET MEDICAL CONCIERGE FEATURES
export function getMedicalConciergeFeatures(token) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "GET",
        GET_MEDICAL_CONCIERGE_FEATURES,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      dispatch(setConciergeFeatures(response?.data?.Features || []));
    } catch (error) {
      dispatch(setConciergeFeatures([]));
      console.log(
        "GET_MEDICAL_CONCIERGE_FEATURES API ERROR............",
        error
      );
      // toast.error(error?.response?.data?.message || "Unable to fetch lab partners");
    }
    dispatch(setLoading(false));
  };
}

// ********************HEALTH MANAGERS**************************

// GET HEALTH MANAGERS
export function getHealthManagers(token) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("GET", GET_HEALTH_MANAGERS, null, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      const healthManagerWithImage = await Promise.all(
        response?.data?.HealthManagersWithAssignClients.map(async (manager) => {
          let managerImage = null;
          if (manager?.ProfileImageUrl) {
            managerImage = await getBucketURL(manager?.ProfileImageUrl);
          }

          const assignedClientsWithImages = await Promise.all(
            manager?.AssignedClients.map(async (client) => {
              let clientImage = null;
              if (client?.ProfileImageUrl) {
                clientImage = await getBucketURL(client?.ProfileImageUrl);
              }
              return { ...client, Image: clientImage };
            })
          );

          return {
            ...manager,
            Image: managerImage,
            AssignedClients: assignedClientsWithImages,
          };
        })
      );

      dispatch(setHealthManagers(healthManagerWithImage || []));
    } catch (error) {
      dispatch(setHealthManagers([]));
      console.log("GET_HEALTH_MANAGERS API ERROR............", error);
      // toast.error(error?.response?.data?.message || "Unable to fetch lab partners");
    }
    dispatch(setLoading(false));
  };
}

// ADD HEALTH MANAGER
export function addHealthManager(token, body, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", ADD_HEALTH_MANAGER, body, {
        Authorization: `Bearer ${token}`,
      });
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      console.log("ADD_HEALTH_MANAGER RESPONSE", response);
      toast.success("Health Manager Added Successfully");
      dispatch(getHealthManagers(token));
      navigate(-1);
    } catch (error) {
      console.log("ADD_HEALTH_MANAGER API ERROR :", error);
      toast.error(
        error?.response?.data?.message || "Error While Adding Health Manager"
      );
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

//UPDATE HEALTH MANAGER
export function updateHealthManager(token, ID, body, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "PUT",
        UPDATE_HEALTH_MANAGER + "/" + ID,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      console.log("UPDATE_HEALTH_MANAGER RESPONSE", response);
      toast.success("Health Manager Updated Successfully");
      dispatch(getHealthManagers(token));
      navigate(-1);
    } catch (error) {
      console.log("UPDATE_HEALTH_MANAGER API ERROR :", error);
      toast.error(
        error?.response?.data?.message || "Error While Updating Health Manager"
      );
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

//DELETE HEALTH MANAGER
export function deleteHealthManager(token, ID) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "DELETE",
        DELETE_HEALTH_MANAGER + "/" + ID,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      console.log("DELETE_HEALTH_MANAGER RESPONSE", response);
      toast.success("Health Manager Deleted Successfully");
      dispatch(getHealthManagers(token));
    } catch (error) {
      console.log("DELETE_HEALTH_MANAGER API ERROR :", error);
      toast.error(
        error?.response?.data?.message || "Error While Deleting Health Manager"
      );
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// ********************CONCIERGE PLAN**************************

// CREATE MEDICAL CONCIERGE PLAN
export function createMedicalConciergePlans(token, body, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "POST",
        CREATE_MEDICAL_CONCIERGE_PLANS,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      toast.success("Plan Added Successfully");
      dispatch(getMedicalConciergePlans(token));
      navigate(-1);
    } catch (error) {
      console.log("CREATE_MEDICAL_CONCIERGE_PLANS API ERROR :", error);
      toast.error(
        error?.response?.data?.message || "Error While Adding New Plan"
      );
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// UPDATE MEDICAL CONCIERGE PLAN
export function updateConciergePlan(token, ID, body, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "PUT",
        UPDATE_MEDICAL_CONCIERGE_PLANS + "/" + ID,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      toast.success("Plan Updated Successfully");
      dispatch(getMedicalConciergePlans(token));
      navigate(-1);
    } catch (error) {
      console.log("UPDATE_MEDICAL_CONCIERGE_PLANS API ERROR :", error);
      toast.error(
        error?.response?.data?.message || "Error While Updating Plan"
      );
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// DELETE MEDICAL CONCIERGE PLAN
export function deleteConciergePlan(token, ID) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "DELETE",
        DELETE_MEDICAL_CONCIERGE_PLANS + "/" + ID,
        null,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      toast.success("Plan Deleted Successfully");
      dispatch(getMedicalConciergePlans(token));
    } catch (error) {
      console.log("DELETE_MEDICAL_CONCIERGE_PLANS API ERROR :", error);
      toast.error(
        error?.response?.data?.message || "Error While Deleting Plan"
      );
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// ADD NOTES
export function addNotes(token, body) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", ADD_NOTES, body, {
        Authorization: `Bearer ${token}`,
      });
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      toast.success("Notes Added Successfully");
      dispatch(getAllConciergeUser(token));
    } catch (error) {
      console.log("ADD_NOTES API ERROR :", error);
      toast.error(error?.response?.data?.message || "Error While Adding Notes");
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// ASSIGN HEALTH MANAGER
export function assignHealthManager(token, body) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", ASSIGN_HEALTH_MANAGER, body, {
        Authorization: `Bearer ${token}`,
      });
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      toast.success("Health Manager Assigned Successfully");
      dispatch(getAssignedPatients(token));
      dispatch(getAllConciergeUser(token));
      dispatch(getHealthManagers(token));
    } catch (error) {
      console.log("ASSIGN_HEALTH_MANAGER API ERROR :", error);
      toast.error(
        error?.response?.data?.message || "Error While Assigning Health Manager"
      );
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}
// ASSIGN HEALTH MANAGER IN BULK
export function assignHealthManagerBulk(token, body) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector(
        "POST",
        ASSIGN_HEALTH_MANAGER_BULK,
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      );
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      toast.success("Health Manager Assigned Successfully");
      dispatch(getAssignedPatients(token));
      dispatch(getAllConciergeUser(token));
      dispatch(getHealthManagers(token));
    } catch (error) {
      console.log("ASSIGN_HEALTH_MANAGER API ERROR :", error);
      toast.error(
        error?.response?.data?.message || "Error While Assigning Health Manager"
      );
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// GET ASSIGN PATIENTS FOR PARTICULAR HEALTH MANAGER
export function getAssignedPatients(token) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("GET", GET_ASSIGNED_PATIENTS, null, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      const patient = response?.data?.medicalConciergeMembers.map(
        async (patient) => {
          const Image = await getBucketURL(patient?.ProfileImageUrl);
          patient.Image = Image;
          return patient;
        }
      );

      const updatedPatientList = await Promise.all(patient);
      dispatch(setConciergeMembers(updatedPatientList || []));
    } catch (error) {
      console.log("GET_HEALTH_MANAGERS API ERROR............", error);
      // toast.error(error?.response?.data?.message || "Unable to fetch lab partners");
    }
    dispatch(setLoading(false));
  };
}

// ADD NEW CONCIERGE USER
export function addNewConciergeUser(token, data) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    let result;
    try {
      const response = await apiConnector("POST", ADD_NEW_PATIENT_API, data, {
        Authorization: `Bearer ${token}`,
      });
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      dispatch(getAllPatients(token));
      result = response?.data?.PatientData;
    } catch (error) {
      console.log("ADD_CONCIERGE_PATIENT API ERROR............", error);
      toast.error(error.message);
    }
    toast.dismiss(toastId);
    return result;
  };
}

// Assign Health Manager and Plan
export function assignHealthManagerAndPlan(token, body, navigate) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", ADD_CONCIERGE_PATIENT, body, {
        Authorization: `Bearer ${token}`,
      });
      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }
      toast.success("User Added Successfully");
      dispatch(getAllPatients(token));
      dispatch(getHealthManagers(token));
      dispatch(getAllConciergeUser(token));
      navigate(-1);
    } catch (error) {
      console.log("ADD_CONCIERGE_PATIENT API ERROR :", error);
      toast.error(error?.response?.data?.message || "Error While Adding User");
    }
    // dispatch(setLoading(false));
    toast.dismiss(toastId);
  };
}

// GET ALL CONCIERGE MEMBERS
export function getAllConciergeUser(token) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("GET", GET_CONCIERGE_PATIENT, null, {
        Authorization: `Bearer ${token}`,
      });

      if (!response?.status === 200) {
        throw new Error(response?.data?.message);
      }

      const patient = response?.data?.medicalConciergeMembers.map(
        async (patient) => {
          const Image = await getBucketURL(patient?.ProfileImageUrl);
          patient.Image = Image;
          return patient;
        }
      );

      const updatedPatientList = await Promise.all(patient);
      dispatch(setConciergeMembers(updatedPatientList || []));
    } catch (error) {
      console.log("GET_CONCIERGE_PATIENT API ERROR............", error);
      // toast.error(error?.response?.data?.message || "Unable to fetch lab partners");
    }
    dispatch(setLoading(false));
  };
}
