import React, { useState } from "react";
import { IoMicSharp } from "react-icons/io5";
import { BsCameraFill } from "react-icons/bs";
import { ImPhone } from "react-icons/im";
import { PiWaveformBold } from "react-icons/pi";
import {
    MeetingProvider,
    useMeeting,
    useParticipant,
} from "@videosdk.live/react-sdk";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { addNotesToPatientProfile1, addPrescription1 } from "../../services/operations/masterDataAPI";
import { setShowMeetingView } from "../../slices/dashboardSlice";

export default function Controls(props) {
    const dispatch = useDispatch();
    const { token } = useSelector((state) => state.auth);
    const { doctorDetails } = useSelector((state) => state.profile);

    const { leave, toggleWebcam, toggleMic, localMicOn, localWebcamOn, end } = useMeeting();

    const [showEndCallOptions, setShowEndCallOptions] = useState(false);

    const handleMicStatus = async () => {
        toggleMic();
    };

    const handleEndCall = () => {
        if (props.canEndCall && doctorDetails) {
            const body = { DID: doctorDetails.DID };

            const updatedNotesBody = {
                ...props.notesBody,
                IsSessionEnd: 1,
            };

            dispatch(addPrescription1(token, props.prescriptionBody));
            dispatch(addNotesToPatientProfile1(token, props.sessID, updatedNotesBody, body));

            props.onExpand(false);
            dispatch(setShowMeetingView(false));
            end();
        } else {
            props.setShowModal(true);
        }
    };

    return (
        <div className={`absolute ${props.showAudioDictation && !props.isExpanded ? 'bottom-[3.7rem]' : 'bottom-2'} w-full flex flex-row justify-center items-center gap-2 z-99`}>
            <button onClick={handleMicStatus} className={`flex justify-center items-center w-[40px] h-[40px] p-1 rounded-full cursor-pointer hover:scale-110 duration-500 ${localMicOn ? "bg-[#E5F2FF]" : "bg-[#F3F5F6]"}`}>
                <IoMicSharp className={`text-lg ${localMicOn ? "text-[#3CA5DC]" : "text-[#ACACAC]"}`} />
            </button>
            <button onClick={() => toggleWebcam()} className={`flex justify-center items-center w-[40px] h-[40px] p-1 rounded-full cursor-pointer hover:scale-110 duration-500 ${localWebcamOn ? "bg-[#E5F2FF]" : "bg-[#F3F5F6]"}`}>
                <BsCameraFill className={`text-lg ${localWebcamOn ? "text-[#3CA5DC]" : "text-[#ACACAC]"}`} />
            </button>
            <button disabled={props.isAudioDone} onClick={() => { if (!props.isAudioDone) props.setShowAudioDictation() }} className={`flex justify-center items-center w-[40px] h-[40px] p-1 rounded-full cursor-pointer ${!props.isAudioDone ? "hover:scale-110 duration-500" : ""} ${props.isAudioDone ? "bg-[#F3F5F6]" : "bg-[#E5F2FF]"}`}>
                <PiWaveformBold className={`text-lg ${props.isAudioDone ? "text-[#ACACAC]" : "text-[#3CA5DC]"}`} />
            </button>

            <div className="relative">
                <button onClick={() => setShowEndCallOptions(!showEndCallOptions)} className="flex justify-center items-center w-[40px] h-[40px] p-1 rounded-full cursor-pointer hover:scale-110 duration-500 bg-[#DF535F]">
                    <ImPhone className="text-lg text-white" />
                </button>

                {showEndCallOptions && (
                    <div className="absolute bottom-[50px] w-52 flex flex-col items-center p-2 bg-white shadow-lg rounded-lg">
                        <button
                            onClick={() => {
                                setShowEndCallOptions(false);
                                leave();
                            }}
                            className="w-full py-2 px-4 text-black hover:bg-gray-200 rounded-lg"
                        >
                            Leave Call
                        </button>
                        <button
                            onClick={() => {
                                setShowEndCallOptions(false);
                                handleEndCall();
                            }}
                            className="w-full py-2 px-4 text-white bg-[#DF535F] hover:bg-[#c04c4c] rounded-lg mt-2"
                        >
                            End Call for Everyone
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}
