import React, { useEffect, useRef, useState } from "react";

const MultiSelectDropdown = ({
  name,
  placeholder,
  options,
  onSelect,
  error,
  value,
  labelKey,
  valueKey,
  disabled = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const dropdownRef = useRef(null);
  console.log(error);

  const toggleDropdown = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleSelect = (option, e) => {
    e.preventDefault();
    e.stopPropagation();
    const isSelected = selectedOptions.some(
      (item) =>
        (valueKey ? item[valueKey] : item) ===
        (valueKey ? option[valueKey] : option)
    );
    let updatedSelection;
    if (isSelected) {
      updatedSelection = selectedOptions.filter(
        (item) =>
          (valueKey ? item[valueKey] : item) !==
          (valueKey ? option[valueKey] : option)
      );
    } else {
      updatedSelection = [...selectedOptions, option];
    }
    setSelectedOptions(updatedSelection);
    onSelect(
      name,
      updatedSelection.map((item) => (valueKey ? item[valueKey] : item))
    );
  };

  const removeOption = (option, e) => {
    e.preventDefault();
    e.stopPropagation();
    const updatedSelection = selectedOptions.filter(
      (item) =>
        (valueKey ? item[valueKey] : item) !==
        (valueKey ? option[valueKey] : option)
    );
    setSelectedOptions(updatedSelection);
    onSelect(
      name,
      updatedSelection.map((item) => (valueKey ? item[valueKey] : item))
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (value && Array.isArray(value)) {
      const selectedValues = options.filter((option) =>
        value.includes(valueKey ? option[valueKey] : option)
      );
      setSelectedOptions(selectedValues);
    }
  }, [value, options, valueKey]);

  const getOptionLabel = (option) => {
    if (typeof option === "string") return option;
    return labelKey ? option[labelKey] : option.toString();
  };

  return (
    <div className="relative text-left" ref={dropdownRef}>
      <button
        disabled={disabled}
        className={`w-full ${
          error ? "border-red-500" : ""
        } min-h-[48px] text-xs font-normal font-poppins bg-gray-100 rounded-md p-2 border focus:outline-none focus:ring-1 focus:ring-sky-200 focus:border-transparent flex items-center justify-between`}
        onClick={toggleDropdown}
      >
        <div className="flex flex-wrap items-center gap-2">
          {selectedOptions.length > 0 ? (
            selectedOptions.map((option) => (
              <button
                disabled={disabled}
                onClick={(e) => removeOption(option, e)}
                key={valueKey ? option[valueKey] : option}
                className="px-4 py-1 bg-sky-100 rounded-3xl border border-sky-500 flex items-center justify-center space-x-10 text-center text-sky-500 text-sm font-semibold font-poppins leading-none"
              >
                {getOptionLabel(option)}
                {!disabled && (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-5 h-5"
                  >
                    <path
                      d="M15.8327 4.1665L4.16602 15.8332M4.16602 4.1665L15.8327 15.8332"
                      stroke="#3CA5DC"
                      stroke-width="1.25"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                )}
              </button>
            ))
          ) : (
            <span className="text-gray-400">{placeholder}</span>
          )}
        </div>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={`w-6 h-6 ${
            isOpen
              ? "transform rotate-180 transition-transform duration-200"
              : "transform rotate-0 transition-transform duration-200"
          }`}
        >
          <path
            d="M18 9.00005C18 9.00005 13.5811 15 12 15C10.4188 15 6 9 6 9"
            stroke="black"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>

      {isOpen && (
        <div className="absolute left-0 mt-1 w-full bg-white border rounded-lg py-2 shadow-lg z-10 max-h-[22vh] overflow-y-auto scroll-smooth scrollbar-thin scrollbar-thumb-sky-500 scrollbar-track-sky-100 scrollbar-thumb-rounded-full scrollbar-track-rounded-full">
          {options.length > 0 ? (
            options.map((option, index) => (
              <div
                key={valueKey ? option[valueKey] : index}
                className={`flex items-center justify-between w-full text-left px-4 py-2.5 ${
                  selectedOptions.some(
                    (item) =>
                      (valueKey ? item[valueKey] : item) ===
                      (valueKey ? option[valueKey] : option)
                  )
                    ? "bg-sky-50 hover:bg-sky-200"
                    : "hover:bg-gray-100"
                } text-slate-800 text-sm font-normal font-poppins leading-tight cursor-pointer`}
                onClick={(e) => handleSelect(option, e)}
              >
                <span className="flex items-center w-full">
                  <input
                    type="checkbox"
                    className="mr-3"
                    checked={selectedOptions.some(
                      (item) =>
                        (valueKey ? item[valueKey] : item) ===
                        (valueKey ? option[valueKey] : option)
                    )}
                    onClick={(e) => e.stopPropagation()}
                  />
                  {getOptionLabel(option)}
                </span>
              </div>
            ))
          ) : (
            <div className="px-4 py-2 text-sm text-gray-500">
              No options available
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MultiSelectDropdown;
