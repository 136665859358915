import React from "react";
import TitleWithAddButton from "./TitleWithAddButton";

function AssociatedPhysicians() {
  return (
    <div>
      <TitleWithAddButton title="Associated Physicians" showAddButton={true} />
    </div>
  );
}

export default AssociatedPhysicians;
