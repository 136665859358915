import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  rewardOffers: [],
};

const rewardSlice = createSlice({
  name: "reward",
  initialState: initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setRewardOffers(state, action) {
      state.rewardOffers = action.payload;
    },
  },
});

export const {
  setLoading,
  setRewardOffers,
} = rewardSlice.actions;

export default rewardSlice.reducer;
