import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import { useDispatch } from "react-redux";
import moment from "moment";
import Dropdown from "../components/Dropdown";

function ActivityLog() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const patient = location.state || {};

  const [sortOption, setSortOption] = useState("Newest First");
  const [filterOption, setFilterOption] = useState("All");
  const [filteredActivities, setFilteredActivities] = useState([]);

  const activities = [
    {
      id: 1,
      title: "Medication Adjusted",
      description: "Increased dosage of Insulin to 10 units",
      time: "Just now",
      author: "Dr. Paul Smith",
      date: "2nd Sep, 2024",
    },
    {
      id: 2,
      title: "Test Results Uploaded !",
      description: "CBC Test Results added",
      time: "4:30 P.M",
      author: "Kelly R.",
      date: "28th Aug, 2024",
    },
    {
      id: 3,
      title: "Patient User Details Updated",
      description: "Address & Phone no. edited.",
      time: "4:30 P.M",
      author: "Kelly R.",
      date: "10th Aug, 2024",
    },
    {
      id: 4,
      title: "Pre-Medical Condition Added",
      description: "Added diabetes as pre-medical condition",
      time: "Just now",
      author: "Dr. Paul Smith",
      date: "2nd Aug, 2024",
    },
    {
      id: 5,
      title: "Lifestyle status changed",
      description: 'Changed "Exercise" status to active',
      time: "4:30 P.M",
      author: "Kelly R.",
      date: "28th January, 2024",
    },
  ];

  useEffect(() => {
    let sortedActivities = [...activities];

    // Sorting logic
    if (sortOption === "Newest First") {
      sortedActivities.sort((a, b) => new Date(b.date) - new Date(a.date));
    } else if (sortOption === "Oldest First") {
      sortedActivities.sort((a, b) => new Date(a.date) - new Date(b.date));
    }

    // Filtering logic
    if (filterOption !== "All") {
      const filterDate = moment().subtract(
        parseInt(filterOption.split(" ")[1]),
        "days"
      );
      sortedActivities = sortedActivities.filter((activity) =>
        moment(activity.date, "DD MMM, YYYY").isAfter(filterDate)
      );
    }

    setFilteredActivities(sortedActivities);
  }, [sortOption, filterOption]);

  return (
    <div className="bg-[#FCFCFC] min-h-full p-6 scroll-smooth">
      {/* Header */}
      <div className="flex flex-row items-center justify-between">
        <div className="text-zinc-900 text-2xl font-medium font-poppins">
          Medical Concierge
        </div>
      </div>
      <Breadcrumb />

      <div className="flex justify-between mt-6">
        <div className="w-60 bg-white rounded-lg shadow py-7 flex flex-col items-center justify-center">
          <div
            className={`${
              patient?.IsMedicalConciergeMember &&
              " bg-gradient-to-r from-[#F6C86E] to-[#FFEE54] rounded-full p-0.5"
            }`}
          >
            <img
              src={patient.Image}
              alt="PatientPic"
              className="w-20 h-20 rounded-full"
            />
          </div>
          {patient?.IsMedicalConciergeMember === 1 && (
            <div className="w-full py-1 bg-yellow-50 flex items-center justify-center mt-4">
              <div className="w-48 text-center text-yellow-600 text-sm font-semibold font-poppins leading-tight">
                Conceirge Member since{" "}
                {moment(patient?.MedicalConciergeJoiningDate).format(
                  "DD MMMM YYYY"
                )}
              </div>
            </div>
          )}
          <div className="text-center text-black text-base font-semibold font-poppins mt-4">
            {patient.Name || patient.FirstName + " " + patient.LastName}
          </div>
          <div className="text-center text-zinc-500 text-xs font-normal font-poppins my-2">
            No : {patient.AHUID}
          </div>

          {patient.Age !== null && patient.Age !== undefined ? (
            <div className="text-center text-zinc-900 text-xs font-normal font-poppins">
              {patient.Age} Years Old
            </div>
          ) : (
            <div className="text-center text-zinc-900 text-xs font-normal font-poppins">
              {new Date().getFullYear() - new Date(patient.DOB).getFullYear()}{" "}
              Years Old
            </div>
          )}
        </div>
        <div className="w-40">
          
          <Dropdown
            name="filter"
            options={["Last 7 days", "Last 30 days", "Last 90 days", "All"]}
            onSelect={(name, value) => setFilterOption(value)}
          />
        </div>
      </div>

      <div className="w-full mx-auto mt-6">
        {filteredActivities.map((activity, index) => (
          <div key={activity.id} className="my-4">
            {/* Date separator */}
            {index === 0 || filteredActivities[index - 1].date !== activity.date ? (
              <div className="flex items-center my-2">
                <div className="flex-grow border-t border-gray-300"></div>
                <span className="px-2 text-gray-400 text-xs">
                  {activity.date}
                </span>
                <div className="flex-grow border-t border-gray-300"></div>
              </div>
            ) : null}

            {/* Activity card */}
            <div className="bg-white p-4 shadow rounded-lg">
              <div className="flex justify-between items-center">
                <div>
                  <h3 className="font-semibold">{activity.title}</h3>
                  <p className="text-sm text-gray-600 mt-1">
                    {activity.description}
                  </p>
                </div>
                <div>
                  <p className="text-right text-xs text-gray-500">{activity.time}</p>
                  <p className="text-xs text-gray-500 mt-2">
                    -BY {activity.author}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ActivityLog;